const ASSETS = {
  kopvillkor:
    'https://jarvaveckan.se/wp-content/uploads/2024/11/Kopvillkor-integritetspolicy-och-GDPR-Stiftelsen-Jarvaveckan-v2.0.pdf',
  varderingarUppforandekod:
    'https://jarvaveckan.se/wp-content/uploads/2024/11/Vardegrund-och-uppforandekod-Stiftelsen-Jarvaveckan-v2.0.pdf',
  arrangorBanner:
    'https://jarvaveckan.se/wp-content/uploads/2025/01/Arrangor_1080x1320.jpg',
};

export { ASSETS };
