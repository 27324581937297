import {
  TableHeaderCell,
  TableHeader,
  TableBody,
  TableCell,
  TableRow,
  Table,
} from 'semantic-ui-react';

function RenderInvitedUsers(props) {
  const { invitedUsers } = props;

  if (!invitedUsers?.length) return null;

  return (
    <div>
      <br />
      <br />
      <h4>Inbjudna användare</h4>
      <Table striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>E-post</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {invitedUsers?.map(({ email }) => (
            <TableRow key={email}>
              <TableCell>{email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default RenderInvitedUsers;
