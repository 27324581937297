import { BrowserRouter as Router } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './utils/suppressWarnings';
import { configureStore } from './redux-config/configureStore';
import ScrollToTop from './components/ScrollToTop';
import SemanticStyles from './semantic-import';
import Offline from './components/Offline';
import GlobalStyle from './global-styles';
import App from './containers/App';

import 'sanitize.css';

const initialState = {};
const store = configureStore(initialState);

const MOUNT_NODE = document.getElementById('app');
let root;

// Ensure we only create root once
if (!root) {
  root = createRoot(MOUNT_NODE);
}

function AppWithState() {
  return (
    <Provider store={store}>
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <StyleSheetManager shouldForwardProp={isPropValid}>
          <ScrollToTop>
            <App />
            <SemanticStyles />
            <GlobalStyle />
            <Offline />
          </ScrollToTop>
        </StyleSheetManager>
      </Router>
    </Provider>
  );
}

if (module.hot) {
  module.hot.accept();
}

root.render(<AppWithState />);
