import { useState } from 'react';

import FileOrImagePreview from '../FileOrImagePreview/FileOrImagePreview';

function RenderInputs(props) {
  const { formFields, formValues = {} } = props;

  const [previewSrc, setPreviewSrc] = useState({});

  if (!formFields) return null;

  const handleFileChange = (event, name) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const newPreviews = files.map((file) => URL.createObjectURL(file)); // Generate URLs for each file

    // Update state with the new previews for the specific field name
    setPreviewSrc((prevPreviews) => ({
      ...prevPreviews,
      [name]: newPreviews,
    }));
  };

  const renderFilePreviews = (name) => {
    return (
      previewSrc[name] &&
      previewSrc[name]?.map((src, index) => (
        <div key={index + name}>
          <FileOrImagePreview img={src} />
          <span className="upload-note" style={{ marginTop: 0 }}>
            New image
          </span>
        </div>
      ))
    );
  };

  return formFields.map((item, index) => {
    const {
      label,
      name,
      value,
      checked,
      required,
      options,
      info,
      labelInfo,
      min,
      type = 'text',
      editable = true,
      readOnly = false,
      multiple = false,
    } = item;

    let defaultValue = formValues[name] ?? value;
    const defaultChecked = formValues[name] ?? checked;

    const readOnlyComputed = readOnly || (formValues[name] ? !editable : false);

    switch (type) {
      case 'select':
        if (!multiple && Array.isArray(defaultValue)) {
          defaultValue = formValues?.[name]?.[0] ?? value;
        }

        return (
          <label key={index} htmlFor={name}>
            <span className="input-label">{label || name}:</span>
            {labelInfo && (
              <span className="input-label-info">{` ${labelInfo}`}</span>
            )}
            {required && <span className="required">*</span>}
            <select
              data-name={name}
              name={name}
              required={required}
              readOnly={readOnlyComputed}
              multiple={multiple}
              defaultValue={defaultValue}
            >
              <option value="">-</option>
              {options.map((option, index2) => (
                <option
                  data-value={option.value}
                  key={index2}
                  value={option.value}
                >
                  {option.text}
                </option>
              ))}
            </select>
            {info && <span className="info">{info}</span>}
          </label>
        );

      case 'textarea':
        return (
          <label key={index} htmlFor={name}>
            <span className="input-label">{label || name}:</span>
            {labelInfo && (
              <span className="input-label-info">{` ${labelInfo}`}</span>
            )}
            <textarea
              name={name}
              data-name={name}
              required={required}
              readOnly={readOnlyComputed}
              cols={30}
              rows={10}
              defaultValue={defaultValue}
            />
            {info && <span className="info">{info}</span>}
          </label>
        );

      case 'file':
        return (
          <label key={index} htmlFor={name}>
            <span className="input-label">{label || name}:</span>
            {labelInfo && (
              <span className="input-label-info">{` ${labelInfo}`}</span>
            )}
            {/* if src exist show image */}
            <input
              type={type}
              name={name}
              data-name={name}
              required={required}
              readOnly={readOnlyComputed}
              accept="image/*,.svg"
              multiple // Allows multiple file selection
              onChange={(event) => handleFileChange(event, name)}
            />
            <span className="upload-note">
              * allowed: svg,png - max size 1MB
            </span>
            {renderFilePreviews(name)}
            <FileOrImagePreview
              key={`${name}-defaultvalue`}
              img={defaultValue}
            />
            <span className="upload-note" style={{ marginTop: 0 }}>
              Uploaded image
            </span>
            {info && <span className="info">{info}</span>}
          </label>
        );

      case 'checkbox':
        return (
          <label key={index} htmlFor={name}>
            <span className="input-label">{label || name}:</span>
            {labelInfo && (
              <span className="input-label-info">{` ${labelInfo}`}</span>
            )}
            {required && <span className="required">*</span>}
            <input
              type={type}
              name={name}
              required={required}
              data-name={name}
              defaultChecked={defaultChecked}
              defaultValue={defaultValue}
            />
            {info && <span className="info">{info}</span>}
          </label>
        );

      default:
        return (
          <label key={index} htmlFor={name}>
            <span className="input-label">{label || name}:</span>
            {labelInfo && (
              <span className="input-label-info">{` ${labelInfo}`}</span>
            )}
            {required && <span className="required">*</span>}
            <input
              type={type}
              name={name}
              required={required}
              data-name={name}
              readOnly={readOnlyComputed}
              defaultValue={defaultValue}
              min={min}
            />
            {info && <span className="info">{info}</span>}
          </label>
        );
    }
  });
}

export default RenderInputs;
