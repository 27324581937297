import { useEffect, useState } from 'react';

import {
  FormField,
  Checkbox,
  Message,
  Button,
  Loader,
  Form,
} from 'semantic-ui-react';

import { ORGANISATION_OPTIONS } from '../../configs';
import { checkOrgNrExist } from '../../api';

function CompanyDetails(props) {
  const {
    handleInputChange = '',
    handleBlur = '',
    nextStep = '',
    orgEmail = '',
    orgName = '',
    orgNr = '',
    step = 1,
    totalSteps = 2,
    typeOfOrg = '',
    hemsida = '',
    disableSubmit = '',
    selectedOrg = '',
    createNewBranch = false,
    handleSubmit = '',
    handleCancelClick = '',
    isOrgNrInUseLoading = null,
    apiError = null,
    orgNrExists = null,
    orgData = null,
    disableSubmitOrgNr = false,
    setState = () => {},
  } = props;

  const [loading, setLoading] = useState(false);

  const orgNameExists = orgData?.orgList?.some(
    (org) => org.orgName.toLowerCase() === orgName.toLowerCase(),
  );

  const handleOrgNrExists = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await checkOrgNrExist({ data: { orgNr } });
      setState((prevState) => ({
        ...prevState,
        disableSubmitOrgNr: true,
        isOrgNrInUseLoading: true,
        apiError: null,
        ...data,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        disableSubmitOrgNr: false,
        apiError: error,
      }));
    } finally {
      setLoading(false);
      setState((prevState) => ({
        ...prevState,
        isOrgNrInUseLoading: false,
      }));
    }
  };

  const showOrgNrCheckButton = !disableSubmitOrgNr;

  // if orgNr changes then we reset disableSubmitOrgNr to false
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      disableSubmitOrgNr: false,
      orgNrExists: null,
      orgData: null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgNr]);

  const handleLocalSubmit = (e) => {
    if (showOrgNrCheckButton) {
      return handleOrgNrExists(e);
    }

    if (selectedOrg) {
      return handleSubmit(e);
    }

    return nextStep(e);
  };

  const organisationForm = (buttonText = 'Fortsätt', disabled = false) => {
    return (
      <>
        <Form.Input
          maxLength="140"
          onChange={handleInputChange}
          required
          id="orgName"
          name="orgName"
          label="Organisationsnamn"
          value={orgName}
          type="text"
          error={orgNameExists}
        />
        {orgNameExists && (
          <Message error>
            <Message.Header>Organisationsnamn finns redan</Message.Header>
            <Message.Content>
              <p>
                Välj ett annat organisationsnamn. Detta namn finns redan i
                systemet.
              </p>
            </Message.Content>
          </Message>
        )}

        <Form.Select
          onChange={handleInputChange}
          required
          id="typeOfOrg"
          name="typeOfOrg"
          label="Typ av organisation"
          options={ORGANISATION_OPTIONS}
          placeholder="Välj ett alternativ"
          value={typeOfOrg}
        />
        <Form.Input
          maxLength="140"
          onChange={handleInputChange}
          id="orgEmail"
          name="orgEmail"
          label="Organisations e-post"
          type="email"
          value={orgEmail}
          placeholder="name@org.se"
        />
        <Form.Input
          maxLength="140"
          onChange={handleInputChange}
          id="hemsida"
          name="hemsida"
          label="Hemsida"
          type="text"
          value={hemsida}
          placeholder="hemsida.se"
        />
        <br />
        <Message
          error
          header={apiError && apiError.code}
          content={apiError && apiError.message}
        />
        <Button type="submit" fluid primary disabled={disabled}>
          {buttonText}
        </Button>
      </>
    );
  };

  const renderNextStep = () => {
    if (orgNrExists) {
      return (
        <>
          <Message info>
            <p>
              Det finns organisationer med detta organisationsnummer. Välj en
              befintlig organisation att ansluta till eller skapa en ny
              organisation med separat konto och fakturering.
            </p>
          </Message>

          {!createNewBranch && orgData && (
            <>
              <p>Välj en organisation att ansluta till:</p>
              <Form.Select
                fluid
                label=""
                placeholder="Välj organisation"
                clearable
                selection
                options={orgData?.orgList?.map((org) => ({
                  key: org.branchId,
                  text: org.orgName,
                  value: org.branchId,
                  description: `Ansök om att bli medlem (Organisations nr: ${org.orgNr})`,
                }))}
                name="selectedOrg"
                value={selectedOrg}
                onChange={handleInputChange}
                search
                selectOnBlur={false}
              />
            </>
          )}

          {!selectedOrg && (
            <>
              <p>
                Eller skapa ny organisation med separat konto och fakturering:
              </p>
              <Checkbox
                onChange={handleInputChange}
                id="createNewBranch"
                name="createNewBranch"
                checked={createNewBranch}
                label={`Jag vill skapa ny organisation med organisationsnummer ${orgNr}`}
              />
              <br />
              <br />
              {createNewBranch &&
                organisationForm(
                  undefined,
                  orgName?.length < 3 || orgNameExists,
                )}
            </>
          )}

          {selectedOrg && (
            <>
              <br />

              <br />
              <Button
                type="submit"
                fluid
                primary
                disabled={!selectedOrg || !orgNrExists || disableSubmit}
              >
                {selectedOrg ? 'Ansök om att bli medlem' : 'Skapa konto'}
              </Button>
            </>
          )}
        </>
      );
    }

    if (!showOrgNrCheckButton) {
      return organisationForm(undefined, orgNr.length < 11);
    }
    return null;
  };

  return (
    <div>
      <br />
      <h3>Dina organisationsuppgifter</h3>
      <p>
        Du kan gå med i en befintlig organisation eller skapa en ny. Skriv in
        ditt organisationsnummer och klicka på knappen för att kontrollera om
        det finns en organisation med detta nummer.
      </p>
      <span style={{ float: 'right' }}>
        Steg {step} av {totalSteps}
      </span>
      <br />
      <Form
        error={Boolean(apiError) || Boolean(orgNameExists)}
        onSubmit={handleLocalSubmit}
      >
        <FormField className="email-signup-field">
          <Form.Input
            onChange={handleInputChange}
            onBlur={handleBlur}
            maxLength="20"
            minLength="11"
            label="Organisationsnummer (11-20 siffror i formatet 802481–1658)"
            placeholder="802481–1658"
            required
            id="orgNr"
            name="orgNr"
            type="text"
            value={orgNr}
            error={Boolean(apiError)}
          />
          {isOrgNrInUseLoading && <Loader active inline size="small" />}
          {apiError && orgNrExists === null && (
            <Message error header={apiError.code} content={apiError.message} />
          )}
        </FormField>
        {showOrgNrCheckButton && (
          <Button
            type="submit"
            fluid
            primary
            loading={loading}
            disabled={orgNr.length < 11 || disableSubmitOrgNr || loading}
          >
            Kontrollera organisationsnummer
          </Button>
        )}
        {renderNextStep()}
      </Form>
      <br />
      <div
        style={{
          margin: '15px 0',
          marginBottom: 0,
        }}
      >
        <br />
        <br />
        <br />
        <br />
        <span>
          Behöver du hjälp? Maila oss på{' '}
          <a
            href="mailto:info@jarvaveckan.se"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@jarvaveckan.se
          </a>
        </span>
        <a href="#" style={{ float: 'right' }} onClick={handleCancelClick}>
          Avbryt
        </a>
      </div>
    </div>
  );
}

export default CompanyDetails;
