import { useState } from 'react';

import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { withFirestore } from 'react-redux-firebase';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { StyledFormContainer, Wrapper } from '../../styledComponents';
import handleFirebaseError from '../../utils/handleFirebaseError';
import TermsLinks from '../TermsLinks';

function Login(props) {
  const { goToSignUp, admin, firebase, auth, toPath } = props;
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    email: '',
    password: '',
    isClicked: true,
    loginAuthError: false,
    modalOpen: false,
    signInLoading: false,
    resetPasswordLoading: false,
    emailSuccess: false,
    emailError: false,
  });

  const handleOpen = (e) => {
    e.preventDefault();
    setFormState((prev) => ({
      ...prev,
      modalOpen: true,
      emailSuccess: false,
      emailError: false,
    }));
  };

  const handleClose = () => {
    setFormState((prev) => ({ ...prev, modalOpen: false }));
  };

  const handleInputChange = (_, { name, value }) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
      isClicked: prev.isClicked ? false : prev.isClicked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formState;
    if (email === '' && password === '') {
      return;
    }

    setFormState((prev) => ({
      ...prev,
      isClicked: true,
      signInLoading: true,
    }));

    if (auth.isLoaded && auth.isEmpty) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          navigate(toPath);
        })
        .catch((error) => {
          setFormState((prev) => ({
            ...prev,
            isClicked: false,
            signInLoading: false,
            loginAuthError: handleFirebaseError(error),
          }));
        });
    }
  };

  const resetPassword = () => {
    const { email } = formState;
    setFormState((prev) => ({ ...prev, resetPasswordLoading: true }));

    if (email !== '') {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setFormState((prev) => ({
            ...prev,
            resetPasswordLoading: false,
            emailSuccess: true,
          }));
          window.localStorage.setItem('email', email);
        })
        .catch((error) => {
          setFormState((prev) => ({
            ...prev,
            resetPasswordLoading: false,
            emailError: handleFirebaseError(error),
          }));
        });
    }
  };

  const {
    email,
    password,
    isClicked,
    loginAuthError,
    modalOpen,
    resetPasswordLoading,
    signInLoading,
    emailSuccess,
    emailError,
  } = formState;

  return (
    <Wrapper>
      <StyledFormContainer>
        <h3>Logga in</h3>
        <p>
          Ny på Järvaveckans bokningssida?{' '}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              goToSignUp();
            }}
          >
            Skapa ett nytt konto
          </a>
        </p>
        <br />
        <br />
        <Form
          loading={signInLoading}
          onSubmit={handleSubmit}
          error={Boolean(loginAuthError)}
        >
          <Form.Input
            maxLength="120"
            type="email"
            autoComplete="username"
            onChange={handleInputChange}
            required
            id="loginOrgNr"
            name="email"
            placeholder="Email"
            label="E-post"
            value={email}
          />
          <Form.Input
            maxLength="40"
            onChange={handleInputChange}
            value={password}
            required
            label="Lösenord"
            type="password"
            id="loginPassword"
            name="password"
            autoComplete="current-password"
            placeholder="Password"
          />
          <Message error content={loginAuthError || ''} />
          <Button type="submit" fluid primary disabled={isClicked}>
            Logga in
          </Button>
          <br />
          <br />
          <a onClick={handleOpen} href="#reset-password">
            Har du glömt ditt lösenord?
          </a>
          <br />
          {admin ? (
            ''
          ) : (
            <>
              <p>
                Alla ansökningar genomgår en granskning innan de godkänns för
                ett konto hos Järvaveckan.
              </p>
              <TermsLinks />
            </>
          )}
        </Form>
        <Modal
          open={modalOpen}
          onClose={handleClose}
          size="tiny"
          closeOnDimmerClick
          dimmer="inverted"
        >
          <Modal.Content>
            <Form onSubmit={resetPassword} loading={resetPasswordLoading}>
              <Form.Input
                maxLength="120"
                type="email"
                onChange={handleInputChange}
                required
                id="loginOrgNr"
                name="email"
                placeholder="Email"
                label="Skriv in e-postadressen kopplad till ditt konto:"
                value={email}
                autoComplete="username"
              />
              {emailSuccess ? (
                <>
                  <Message
                    positive
                    content="Ett e-postmeddelande har skickats med instruktioner om hur du återställer ditt lösenord."
                  />
                  <Button
                    primary
                    basic
                    icon="cancel"
                    labelPosition="right"
                    content="Stäng"
                    type="button"
                    floated="right"
                    onClick={handleClose}
                  />
                </>
              ) : (
                <>
                  {emailError && (
                    <Message
                      negative
                      header="Det finns inget konto som använder den e-postadressen."
                      list={[emailError]}
                    />
                  )}
                  <Button
                    secondary
                    basic
                    icon="cancel"
                    labelPosition="right"
                    content="Avbryt"
                    type="button"
                    onClick={handleClose}
                  />
                  <Button
                    primary
                    icon="mail"
                    labelPosition="right"
                    content="Skicka"
                    type="submit"
                    floated="right"
                  />
                </>
              )}
            </Form>
          </Modal.Content>
        </Modal>
      </StyledFormContainer>
    </Wrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  auth: (state) => state.firebase.auth,
});

export default compose(withFirestore, connect(mapStateToProps))(Login);
