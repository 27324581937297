import { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Form, Button } from 'semantic-ui-react';

import { clearDateBeforeSaving } from '../../utils/clearDateBeforeSaving';
import { REDUX_PATHS_CURRENT_USER } from '../../configs';

const FORM_FIELDS = [
  {
    name: 'fakturamottagare',
    label: 'Er referens',
    type: 'text',
    maxLength: 120,
    required: true,
  },
  {
    name: 'fakturaadress',
    label: 'Fakturaadress',
    type: 'text',
    maxLength: 160,
  },
  {
    name: 'postnummer',
    label: 'Postnummer',
    type: 'text',
    maxLength: 120,
  },
  {
    name: 'ort',
    label: 'Ort',
    type: 'text',
    maxLength: 120,
  },
  {
    name: 'fakturaEmail',
    label: 'E-post för PDF faktura',
    type: 'email',
    maxLength: 120,
    required: true,
  },
];

function RenderUserDetails(props) {
  const { currentUserOrganisation, firestore } = props;

  const [user, setUser] = useState(currentUserOrganisation);
  const [originaluser, setOriginaluser] = useState(currentUserOrganisation);
  const [loading, setloading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [disableButton, setdisableButton] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isEmpty(user)) {
      const {
        fakturaEmail = '',
        fakturaadress = '',
        fakturamottagare = '',
        ort = '',
        postnummer = '',
      } = currentUserOrganisation || {};

      setOriginaluser({
        fakturaEmail,
        fakturaadress,
        fakturamottagare,
        ort,
        postnummer,
      });

      setUser({
        fakturaEmail,
        fakturaadress,
        fakturamottagare,
        ort,
        postnummer,
      });
    }
  }, [currentUserOrganisation, user]);

  const handleInputChange = ({ target: { name, value } }) => {
    if (disableButton) setdisableButton(false);
    setUser({ ...user, [name]: value });
  };

  // const handleInputChange = ({ target: { name, value } }) =>
  //   console.log(name, value) || setUser(set(user, [name], value));

  const saveToDatabase = (e) => {
    e.preventDefault();
    if (isEqual(user, originaluser)) {
      setloading(false);
      setReadOnly(true);
      return;
    }
    // console.count('submit');
    setReadOnly(true);
    const reduxPath = REDUX_PATHS_CURRENT_USER.currentUserOrganisation;
    const databasePath = currentUserOrganisation.dbCollection;
    const bookingId = currentUserOrganisation.branchId;

    setloading(true);
    setdisableButton(true);

    const cleanedData = clearDateBeforeSaving(user);

    firestore
      .set(
        {
          collection: databasePath,
          doc: bookingId,
          path: reduxPath,
        },
        {
          ...cleanedData,
          updatedAt: new Date().toISOString(),
        },
        { merge: true },
      )
      .then(() => {
        console.info('saved successfully');
      })
      .catch((fbError) => {
        console.error(fbError);
        setError({ error });
        setUser(null);
      })
      .finally(() => {
        setloading(false);
      });
  };

  // Render form fields dynamically
  const renderFormFields = () =>
    FORM_FIELDS.map(({ name, label, type, maxLength, required }) => (
      <li key={name}>
        <Form.Input
          label={label}
          name={name}
          type={type}
          maxLength={maxLength}
          required={required}
          value={user?.[name] || ''}
          readOnly={readOnly}
          onChange={handleInputChange}
        />
      </li>
    ));

  const handleEdit = (shouldEdit) => {
    setReadOnly(!shouldEdit);
    if (!shouldEdit) {
      setUser(currentUserOrganisation);
    }
  };

  const renderButtons = () => {
    if (readOnly) {
      return (
        <Button
          type="button"
          onClick={() => handleEdit(true)}
          content="Redigera uppgifter"
          primary
          labelPosition="right"
          icon="edit outline"
        />
      );
    }

    return (
      <div className="flex no-gap">
        <Button
          type="submit"
          loading={loading}
          disabled={disableButton}
          primary
          content="Spara"
          labelPosition="right"
          icon="checkmark"
        />
        <Button
          basic
          type="button"
          onClick={() => handleEdit(false)}
          primary
          content="Avbryt"
          icon="cancel"
          labelPosition="right"
        />
      </div>
    );
  };

  if (isEmpty(currentUserOrganisation)) return null;

  return (
    <Form
      loading={loading}
      onSubmit={saveToDatabase}
      style={{ display: 'block' }}
    >
      <br />
      <br />
      <ul aria-label="Fakturauppgifter">{renderFormFields()}</ul>
      <br />
      {renderButtons()}
    </Form>
  );
}

export default RenderUserDetails;
