import { useState } from 'react';

import { Form } from 'semantic-ui-react';

import { organisationRequests } from '../../api';
import { cleanFormData } from '../../utils';

function InviteUser(props) {
  const {
    computedIsAdmin,
    currentUser,
    branchUserEmails = [],
    updateState,
  } = props;

  // loader, error state success state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  if (!computedIsAdmin) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // get form data
    const formData = cleanFormData(e.target);
    const email = formData.get('email') || '';

    const isAlreadyBranchUser = branchUserEmails.some(
      ({ email: userEmail }) => userEmail === email,
    );

    if (isAlreadyBranchUser) {
      console.error(`users ${email} already exists`);
      setError({
        message: 'Användaren finns redan',
      });
      setLoading(false);
      setSuccess(null);
      return;
    }

    // invite user
    try {
      const config = {
        action: 'inviteUserToOrganisation',
        data: {
          email,
          currentUser: { ...currentUser, userId: currentUser.id },
        },
      };
      const { data } = await organisationRequests.post(config);
      setSuccess({ data, email });

      // Clear the form
      e?.target?.reset?.();
    } catch (err) {
      console.error('error sending invite', err);
      setError(err);
    } finally {
      setLoading(false);
      updateState();
    }
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <hr className="hr-line" />
      <br />
      <h2>Bjud in medlemmar</h2>
      <p>
        Här kan du bjuda in nya medlemmar till din organisation. Du kan bjuda in
        användare som inte är medlemmar i din organisation än. Den inbjudna användaren kommer att få ett e-postmeddelande om att den har blivit inbjuden.
      </p>

      {success?.email && (
        <div className="invitations-container">
          <h3>Inbjudan skickad</h3>
          <p>Inbjudan skickad till {success.email}.</p>
        </div>
      )}

      <Form error={!!error} onSubmit={handleSubmit}>
        <Form.Input
          type="email"
          name="email"
          placeholder="E-postadress"
          label="E-postadress"
          required
          disabled={loading}
          error={error?.message}
        />
        <Form.Button type="submit" disabled={loading} loading={loading} primary>
          Skicka inbjudan
        </Form.Button>
      </Form>
    </div>
  );
}

export default InviteUser;
