import { useState } from 'react';

import { isEmpty, filter, includes, omit, get, isNil } from 'lodash';
import { Button, Message, Modal } from 'semantic-ui-react';

import {
  OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
  BOOKING_COLLECTION_CURRENT_EVENT,
  REDUX_PATHS_CURRENT_USER,
} from '../../configs';
import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import getSwedishFormatNumber from '../../utils/getSwedishFormatNumber';
import RenderUserDetails from '../../components/RenderUserDetails';
import BookingConfirm from '../../components/BookingConfirm';
import ContentEditor from '../../components/ContentEditor';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import priceOfTillagg from '../../utils/priceOfTillagg';
import { isCurrentCountMax } from '../../utils';
import minusIcon from '../../assets/minus.svg';
import plusIcon from '../../assets/plus.svg';
import { TillaggContainer } from './styles';

const defaultState = {
  modalOpen: false,
  kopvillkor: '',
  workManInfo: '',
  godkannKopvillkorOchBekraftelse: false,
  godkannJarvaVekanKod: false,
  submitting: false,
  showBookingConfirm: false,
};

function Tillagg(props) {
  const [state, setState] = useState(defaultState);

  const { products, firestore, currentUser, currentUserOrganisation, stats } =
    props;

  if (isEmpty(products)) {
    return null;
  }

  const collection = BOOKING_COLLECTION_CURRENT_EVENT.tillaggBookings;
  const path = REDUX_PATHS_CURRENT_USER.currentUserTillaggBookings;

  const productCategoryKey = 'tillagg';

  const { modalOpen, error, showBookingConfirm } = state;

  const handleOpen = () =>
    setState((prevState) => ({
      ...prevState,
      modalOpen: true,
    }));

  const handleClose = () =>
    setState((prevState) => ({
      ...prevState,
      modalOpen: false,
    }));

  const handleCheckboxChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;

    setState((prevState) => ({ ...prevState, [name]: localValue }));
  };

  const handleNextButtonClick = (data) => {
    setState((prevState) => ({
      ...prevState,
      selectedProduct: { ...data, ...state },
    }));
  };

  // #region handleSubmit
  const handleSubmit = async () => {
    setState((prevState) => ({ ...prevState, submitting: true }));

    const { organizerName, orgName } = currentUserOrganisation || {};

    const selectedProduct = get(state, 'selectedProduct');

    if (!selectedProduct) {
      return 'empty product';
    }

    const omitState = [
      'step',
      'kopvillkor',
      'workManInfo',
      'modalOpen',
      'selectedProduct',
      'submitting',
      'showBookingConfirm',
      'submitting',
      'createdAt',
      'updatedAt',
      'images',
    ];

    const combinedData = {
      ...state,
      ...selectedProduct,
      ...priceOfTillagg(selectedProduct),
    };

    const selectedState = omit(combinedData, omitState);

    const cleanedOrganisation = omit(
      currentUserOrganisation,
      OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
    );

    const { dbDocId, email, telefonnummer, firstName, secondName } =
      currentUser || {};
    const toEmails = [email];
    const ccEmails = cleanedOrganisation?.users?.filter(
      (userEmail) => userEmail !== email,
    );
    const emailsToSend = {
      to: toEmails,
      cc: ccEmails,
    };

    const bookedBy = {
      dbDocId,
      email,
      telefonnummer,
      firstName,
      secondName,
    };

    const bookingDataRaw = {
      ...selectedState,
      ...priceOfTillagg(selectedProduct),
      branchId: currentUserOrganisation.branchId,
      branchDbPath: currentUserOrganisation.dbPath,
      organisation: cleanedOrganisation,
      organizerName: organizerName || orgName,
      emailsToSend,
      bookedBy,
      createdAt: new Date().toISOString(),
    };

    const bookingData = omit(bookingDataRaw, isNil);

    firestore
      .add(
        {
          collection,
        },
        bookingData,
      )
      .then(() => {
        setState({ ...defaultState, showBookingConfirm: true });
      })
      .catch((err) =>
        setState((prevState) => ({
          ...prevState,
          submitting: false,
          error: err,
        })),
      )
      .finally(() => {
        firestore
          .get({
            collection,
            path,
            where: [['branchId', '==', currentUserOrganisation.branchId]],
          })
          .catch((err) => {
            console.error(err);
          });

        firestore
          .get({
            collection,
            path,
          })
          .catch((err) => {
            console.error(err);
          });
      });
  };
  // #endregion

  const selectedProducts = filter(products, (item) => {
    const { category, showInBookingPage } = item;
    if (showInBookingPage) {
      return includes(category, productCategoryKey);
    }
    return false;
  });

  const renderProducts = () => {
    if (isEmpty(selectedProducts)) {
      return (
        <WhiteBox>
          <h4>Produkter inte tillgängliga.</h4>
        </WhiteBox>
      );
    }
    return selectedProducts?.map((product) => {
      const { productId, pris, title } = product;

      const { max, count, maxQuantity } = stats?.[productId] || {};

      // console.log('Tillagg: stats?.[productId]', stats?.[productId]);

      const quantityId = `quantity_${productId}`;

      const quantity = state[quantityId] || 1;

      const isCurrentCountMaxRes = isCurrentCountMax({
        productId,
        stats,
      });

      return (
        <WhiteBox
          className="border"
          id={`scroll_to_${productId}`}
          key={productId}
        >
          <div className="media-text">
            <div>
              <h3 className="no-underline">{title}</h3>
              <ContentEditor
                contentId={`page-book-tillagg-${productId}-description`}
              >
                Add description here.
              </ContentEditor>
            </div>
            <div className="medium-image">
              <ContentEditor
                contentId={`page-user-book-tillagg-${productId}-main-1-image`}
                placeHolder="Lägg till bild här..."
              >
                {`<img
                      src="https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FPartnerskap%20-%205x5%2Fimg-10.jpg?alt=media&token=bea2cd5e-0a72-4f23-b807-7b50400f9979"
                      alt="people"
                      height="auto"
                      width="100%"
                      />
               `}
              </ContentEditor>
            </div>
          </div>
          <hr style={{ color: 'rgba(0, 0, 0, 0.10)' }} />

          <div className="price-row">
            <div>
              <p>PRIS</p>
              <div className="pris">
                <b>{getSwedishFormatNumber(pris)}</b> kr exkl.moms
              </div>
            </div>

            {/* To allow multiple selection */}
            {maxQuantity > 1 ? (
              <div>
                <p>VÄLJ ANTAL</p>
                <div className="quantity-button-group">
                  <Button
                    primary
                    className="icon-button"
                    onClick={() => {
                      setState((prevState) => {
                        const currentQuantity = prevState?.[quantityId] || 1;
                        if (currentQuantity < 2) {
                          return prevState;
                        }

                        const newQuantity = currentQuantity - 1;
                        return { ...prevState, [quantityId]: newQuantity };
                      });
                    }}
                    disabled={quantity < 2}
                  >
                    <img className="img-icon" src={minusIcon} alt="remove" />
                  </Button>
                  <span className="count">{quantity}</span>
                  <Button
                    primary
                    className="icon-button"
                    onClick={() => {
                      setState((prevState) => {
                        const newQuantity = (prevState?.[quantityId] || 1) + 1;
                        return { ...prevState, [quantityId]: newQuantity };
                      });
                    }}
                    disabled={
                      quantity >= maxQuantity ||
                      quantity >= max ||
                      count + quantity >= max
                    }
                  >
                    <img className="img-icon" src={plusIcon} alt="add" />
                  </Button>
                </div>
              </div>
            ) : null}

            <div>
              <Button
                primary
                className="offset-bottom-3px"
                size="large"
                floated="right"
                icon="right arrow"
                labelPosition="right"
                content={isCurrentCountMaxRes?.label || 'Bekräfta bokning'}
                disabled={isCurrentCountMaxRes?.isDisabled}
                onClick={() => {
                  handleNextButtonClick({ ...product });
                  handleOpen();
                }}
              />
            </div>
          </div>
        </WhiteBox>
      );
    });
  };

  const renderConfirmationModal = () => {
    if (!modalOpen) {
      return null;
    }

    const { godkannKopvillkorOchBekraftelse, godkannJarvaVekanKod } = state;

    const { organizerName, orgName } = currentUserOrganisation || {};

    const selectedProduct = get(state, 'selectedProduct');

    if (!selectedProduct) {
      return 'empty product';
    }

    const { pris, title } = selectedProduct;

    const { totalPrice, quantity } = priceOfTillagg(selectedProduct);

    const { submitting } = state;

    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size="tiny"
        closeOnDimmerClick={false}
        dimmer="inverted"
      >
        <Modal.Content>
          <WhiteBox>
            <DataBox>
              <div>
                <ul aria-label="Vänligen bekräfta dina uppgifter">
                  <li>
                    <TextCopy name="confirm-your-details" />
                  </li>
                  <li aria-label="Organisationsnamn">
                    <span className="form-like">
                      {organizerName || orgName}
                    </span>
                  </li>
                  <li aria-label="Tillägg">
                    <span className="form-like">{title}</span>
                  </li>
                  <li aria-label="Pris/Antal">
                    <span className="form-like">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </span>
                  </li>
                  <li aria-label="Antal">
                    <span className="form-like">{quantity}</span>
                  </li>
                  <li aria-label="Pris">
                    <span className="form-like">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </span>
                  </li>
                </ul>
              </div>
              {currentUserOrganisation && (
                <RenderUserDetails
                  currentUserOrganisation={currentUserOrganisation}
                  firestore={firestore}
                />
              )}
              <TermsCheckBox handleCheckboxChange={handleCheckboxChange} />
            </DataBox>
          </WhiteBox>
        </Modal.Content>
        {error ? (
          <Message negative header="Error occurred" content={error} />
        ) : null}
        <Modal.Actions>
          <div className="flex no-gap">
            <Button
              basic
              onClick={handleClose}
              primary
              content="Avbryt"
              icon="cancel"
              labelPosition="right"
            />
            <Button
              type="submit"
              loading={submitting}
              disabled={
                !(godkannKopvillkorOchBekraftelse && godkannJarvaVekanKod)
              }
              primary
              content="Bekräfta"
              labelPosition="right"
              icon="checkmark"
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <TillaggContainer>
      <ContentContainer>
        <section>
          <WhiteBox>
            <h1>
              <ContentEditor inline contentId="page-tillagg-title-1">
                Övriga produkter, projekt och tjänster
              </ContentEditor>
            </h1>
          </WhiteBox>
          {showBookingConfirm ? <BookingConfirm /> : renderProducts()}
        </section>
      </ContentContainer>
      {renderConfirmationModal()}
      <br />
      <br />
    </TillaggContainer>
  );
}

export default Tillagg;
