import { P } from '../../styledComponents';
import ContentEditor from '../ContentEditor';

function TextCopy(props) {
  const { name } = props;

  switch (name) {
    case 'seminarLive-description':
      // <TextCopy name="seminarLive-description" />
      return (
        <ContentEditor contentId="page-user-book-seminar-live-description-text-copy" />
      );

    case 'seminar-description':
      // <TextCopy name="seminar-description" />
      return (
        <ContentEditor contentId="page-user-book-seminar-description-text-copy" />
      );

    case 'partner-description':
      // <TextCopy name="partner-description" />
      return (
        <ContentEditor contentId="user-book-content-partner-description-text-copy" />
      );

    case 'confirm-your-details':
      // <TextCopy name="confirm-your-details" />
      return (
        <ContentEditor contentId="page-user-book-confirm-your-details-text-copy" />
      );
    case 'tillagg-description':
      // <TextCopy name="tillagg-description" />
      return (
        <ContentEditor contentId="page-user-book-tillagg-description-text-copy" />
      );
    case 'torghandel-description':
      // <TextCopy name="torghandel-description"   />
      return (
        <ContentEditor contentId="page-user-book-torghandel-description-text-copy" />
      );
    case 'foodtruck-description':
      // <TextCopy name="foodtruck-description"   />
      return (
        <ContentEditor contentId="page-user-book-foodtruck-description-text-copy" />
      );

    case 'medarrangor-text-copy':
      // <TextCopy name="medarrangor-text-copy" />
      return (
        <h5>
          Medarrangör:{' '}
          <span className="reset-text-tranform">
            Kommer ni att arrangera ert seminarium
            <span className="red"> eller tältaktiviteter </span> tillsammans med
            andra organisationer? lägg till dem här! det är för Järvaveckans
            egen informationsinhämtning och informationen kan komma att
            publiceras på vår hemsida och på andra platser.
          </span>
        </h5>
      );

    case 'highlites-text-copy':
      // <TextCopy name="highlites-text-copy" />
      return (
        <P>
          För att redigera dina uppgifter, klicka på knappen Redigera uppgifter
          längst ner på sidan.
        </P>
      );

    default:
      return null;
  }
}

export default TextCopy;
