import { IS_STAGE } from '../configs';

// suppressWarnings.js
if (IS_STAGE) {
  console.info(
    "accomplice:dev suppressing warnings, for react=^18.*.*, don't update react",
  );
  const originalError = console.error;

  const warningsToSuppress = [
    'uses the legacy',
    'for defaultProps',
    'findDOMNode',
  ];

  console.error = (...args) => {
    const stringFirstArg = args[0];
    // if stringFirstArg is an object, check if it has a message property
    if (
      typeof stringFirstArg === 'string' &&
      warningsToSuppress.some((warning) => stringFirstArg.includes(warning))
    ) {
      return;
    }

    originalError(...args);
  };
}
