import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { reactReduxFirebase } from 'react-redux-firebase';
import { reduxFirestore } from 'redux-firestore';
import reduxReset from 'redux-reset';
import ReduxThunk from 'redux-thunk';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

import {
  FIRESTORE_PORT,
  STORAGE_PORT,
  IS_EMULATOR,
  AUTH_PORT,
  IS_LIVE,
} from '../configs';
import fbConfig from '../configs/firebaseConfig';
import { createReducer } from './reducers';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableLogging: false,
  useFirestoreForStorageMeta: true,
};

// initialize instance only if not already initialized
if (!firebase?.apps?.length) {
  firebase.initializeApp(fbConfig());

  if (IS_EMULATOR) {
    firebase.auth().useEmulator(`http://localhost:${AUTH_PORT}`);
    firebase.firestore().useEmulator('localhost', FIRESTORE_PORT);
    firebase.storage().useEmulator('localhost', STORAGE_PORT);
  }

  // initialize Firestore
  const firestore = firebase.firestore();
  const settings = {
    merge: true,
    ignoreUndefinedProperties: true,
  };
  firestore.settings(settings);
}

function configureStore(initialState = {}) {
  const middlewares = [ReduxThunk, ReduxThunk.withExtraArgument(firebase)];
  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    !IS_LIVE &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(
      ...enhancers,
      reduxReset(),
      reactReduxFirebase(firebase, rrfConfig),
      reduxFirestore(firebase),
    ),
  );

  store.injectedReducers = {};

  return store;
}

export { configureStore, firebase };
