import { PRODUCT_CATEGORIES_OPTIONS } from '../../configs';

const formFields = [
  {
    type: 'text',
    label: 'PE:account ProductId',
    name: 'peProductId',
    value: '',
    placeHolder: '',
    readOnly: true,
    info: 'Will be added to PE:accounting on create.',
  },
  {
    type: 'text',
    label: 'Product Id',
    name: 'productId',
    value: '',
    placeHolder: '',
    editable: false,
    required: true,
    info: 'The product ID is a unique identifier for this product. It should be a short string of letters and numbers, with no spaces or special characters. Will be added to PE:accounting',
  },
  {
    type: 'text',
    label: 'Title',
    name: 'title',
    value: '',
    placeHolder: '',
    required: true,
    info: 'Title Will be added to PE:accounting',
  },
  {
    type: 'number',
    label: 'Pris',
    name: 'pris',
    min: 0,
    value: '',
    required: true,
  },
  {
    type: 'number',
    label: 'Antal möjliga bokningar per order',
    name: 'maxQuantity',
    min: 1,
    value: 1,
    required: true,
  },
  {
    type: 'number',
    label: 'Mängd tillgängliga produkter totalt',
    name: 'max',
    min: 0,
    value: 0,
    required: true,
    info: 'Mängd tillgängliga produkter totalt available',
  },
  {
    type: 'number',
    label: 'count',
    name: 'count',
    min: 0,
    value: 0,
    readOnly: true,
    info: 'Used to keep track of how many of this product has been sold',
  },
  {
    type: 'select',
    label: 'Category',
    name: 'category',
    required: true,
    value: 'tillagg',
    multiple: false,
    options: PRODUCT_CATEGORIES_OPTIONS,
  },
  {
    type: 'number',
    label: 'Sort Order',
    name: 'order',
    value: 1,
    required: true,
    info: 'Order Will be used to sort the products in the booking page',
  },
  {
    type: 'checkbox',
    label: 'Show in booking page',
    name: 'showInBookingPage',
    value: true,
    checked: true,
    info: 'Show in booking page Will be used to show or hide the product in the booking page',
  },
  // {
  //   type: 'textarea',
  //   label: 'Description',
  //   name: 'description',
  //   value: '',
  //   placeHolder: '',
  //   required: true
  // },
  // {
  //   type: 'file',
  //   label: 'Image',
  //   name: 'image',
  //   value: '',
  //   placeHolder: '',
  //   required: true
  // }
];

export { formFields };
