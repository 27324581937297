import { useState } from 'react';

import { Form, Button, Message, FormField } from 'semantic-ui-react';

import { StyledFormContainer } from '../../styledComponents';
import { isValidEmail } from '../../utils';
import TermsCheckBox from '../TermsCheckBox';
import { checkUserExist } from '../../api';
import TermsLinks from '../TermsLinks';

function UserDetails(props) {
  const {
    handleInputChange,
    checkPassword,
    checkFormFilled,
    values = {},
    goToLogin,
    handleSubmit,
  } = props;

  const {
    firstName = '',
    secondName = '',
    email = '',
    password = '',
    repeatPassword = '',
    titel = '',
    passordError,
    createUserError,
    formFilled,
    godkannKopvillkorOchBekraftelse,
    godkannJarvaVekanKod,
    loading,
  } = values;

  const [isEmailInUse, setIsEmailInUse] = useState(null);
  const [isEmailInUseLoading, setIsEmailInUseLoading] = useState(null);
  const [isEmailInUseError, setIsEmailInUseError] = useState(null);

  const disabled =
    isEmailInUse || isEmailInUseLoading || !email || isEmailInUse === null;
  const disabledButton =
    !(!passordError && formFilled && !isEmailInUse) ||
    !godkannKopvillkorOchBekraftelse ||
    !godkannJarvaVekanKod;

  const checkEmailAvailable = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isValidEmail(email)) {
      setIsEmailInUseError('Invalid email');
      return;
    }

    try {
      setIsEmailInUseLoading(true);
      const result = await checkUserExist(email);
      setIsEmailInUse(result);
      setIsEmailInUseError(null);
    } catch (error) {
      console.error('Error checking if user exists:', error);
      setIsEmailInUseError(error.message);
      setIsEmailInUse(null);
    } finally {
      setIsEmailInUseLoading(false);
    }
  };

  return (
    <div>
      <StyledFormContainer>
        <h3>Fyll i nedan för att skapa ett konto</h3>
        <p>
          Har du redan ett konto?{' '}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              goToLogin();
            }}
          >
            Logga in
          </a>
        </p>
        <br />
        <br />
        <Form
          error={Boolean(createUserError || isEmailInUseError || isEmailInUse)}
          onSubmit={handleSubmit}
          loading={loading}
        >
          <FormField className="email-signup-field">
            <Form.Input
              maxLength="140"
              onChange={handleInputChange}
              value={email}
              required
              label="E-post"
              id="email"
              name="email"
              type="email"
              placeholder="namn@mail.se"
              autoComplete="username"
              autoFocus
              error={isEmailInUseError || isEmailInUse}
            />
            <Button
              compact
              icon="check"
              type="button"
              size="tiny"
              content="Verifiera"
              onClick={checkEmailAvailable}
              disabled={!email || isEmailInUseLoading}
              loading={isEmailInUseLoading}
            />
            {isEmailInUseLoading === false && isEmailInUse === false && (
              <Message info>
                <h5>E-postadressen är tillgänglig</h5>
                <p>Du kan registrera ett konto med denna e-postadress.</p>
              </Message>
            )}
            {isEmailInUse && (
              <Message error>
                <h5>E-postadressen är redan i bruk</h5>
                <p>
                  Du kan bara registrera ett konto med en given e-postadress.
                </p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                >
                  Logga in
                </a>
              </Message>
            )}
          </FormField>
          <Form.Input
            disabled={disabled}
            maxLength="140"
            onChange={handleInputChange}
            value={titel}
            id="titel"
            name="titel"
            label="Befattning / Titel"
          />
          <Form.Input
            disabled={disabled}
            maxLength="140"
            onChange={handleInputChange}
            required
            id="firstName"
            name="firstName"
            label="Förnamn"
            value={firstName}
            placeholder="Förnamn"
            type="text"
            autoComplete="given-name"
          />
          <Form.Input
            disabled={disabled}
            maxLength="140"
            onChange={handleInputChange}
            required
            id="secondName"
            name="secondName"
            label="Efternamn"
            value={secondName}
            placeholder="Efternamn"
            autoComplete="family-name"
          />
          <Form.Input
            disabled={disabled}
            onChange={handleInputChange}
            value={password}
            required
            label="Lösenord"
            type="password"
            minLength="6"
            maxLength="40"
            id="password"
            name="password"
            placeholder="Lösenord"
            autoComplete="new-password"
            suggested="new-password"
          />
          <Form.Input
            disabled={disabled}
            onChange={(e, target) => {
              handleInputChange(e, target);
              checkPassword();
            }}
            onBlur={() => checkFormFilled(1)}
            value={repeatPassword}
            required
            label="Upprepa lösenord"
            type="password"
            autoComplete="new-password"
            suggested="new-password"
            minLength="6"
            maxLength="40"
            name="repeatPassword"
            id="repeatPassword"
            placeholder="Upprepa lösenord"
            error={passordError}
          />
          <TermsCheckBox handleInputChange={handleInputChange} />
          {createUserError && <Message error content={createUserError} />}
          <br />
          <br />
          <Button type="submit" fluid primary disabled={disabledButton}>
            Skapa konto
          </Button>
        </Form>
        <TermsLinks />
      </StyledFormContainer>
    </div>
  );
}

export default UserDetails;
