import { useState, useEffect } from 'react';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { isLoaded } from 'react-redux-firebase';
import { Message } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import RenderCompanyListItem from '../../components/RenderCompanyListItem';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import { branchIdWithOrgNr } from '../../redux-config/selectors';
import SpinnerLoader from '../../components/SpinnerLoader';
import { IosPaperOutline } from '../../components/Icons';
import { organisationRequests } from '../../api';

import '../../components/RenderCompanyListItem/styles.css';

const Applicants = (props) => {
  const { pendingOrganisations, firestore } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [branchData, setBranchData] = useState(null);

  useEffect(() => {
    const fetchBranchData = async () => {
      if (!pendingOrganisations?.length) return;

      const newBranchData = {};

      for (const organisation of pendingOrganisations || []) {
        const { orgNr, branchId } = organisation;

        if (!orgNr || !branchId) continue;

        try {
          const branchDoc = await firestore
            .collection('organisations')
            .doc(orgNr)
            .collection('branches')
            .doc(branchId)
            .get();

          if (branchDoc.exists) {
            newBranchData[branchIdWithOrgNr(organisation)] = {
              ...branchDoc.data(),
              orgNr,
              branchId,
            };
          }
        } catch (error) {
          console.error('Error fetching branch data:', error);
        }
      }

      setBranchData(newBranchData);
    };

    fetchBranchData();
  }, [pendingOrganisations, firestore]);

  if (!isLoaded(pendingOrganisations)) {
    return <SpinnerLoader />;
  }

  const handleAccept = async (orgData) => {
    setApiError(null);
    const confirm = window.confirm(`handleAccept ${orgData.orgNr}`);

    if (confirm) {
      setIsProcessing(true);
      try {
        await organisationRequests.post({
          action: 'approveOrganisation',
          data: orgData,
        });

        // console.info('response handleAccept', response);
      } catch (error) {
        console.error('error handleAccept', error);
        setApiError(
          error || {
            code: 'error',
            message: 'Ett fel uppstod vid godkännande av organisation',
          },
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const handleReject = async (orgData) => {
    setApiError(null);
    const confirm = window.confirm(`handleReject ${orgData.orgNr}`);

    if (confirm) {
      setIsProcessing(true);
      try {
        await organisationRequests.post({
          action: 'rejectOrganisation',
          data: orgData,
        });

        // console.info('response handleReject', response);
      } catch (error) {
        console.error('error handleReject', error);
        setApiError(
          error || {
            code: 'error',
            message: 'Ett fel uppstod vid godkännande av organisation',
          },
        );
      } finally {
        setIsProcessing(false);
      }
    }
  };

  return (
    <ContentContainer>
      <section className="pink">
        <WhiteBox>
          <h3>
            <IosPaperOutline /> Ansökningar
          </h3>
          {isEmpty(branchData) ? (
            <p>Inga obesvarade ansökningar.</p>
          ) : (
            <>
              <TransitionGroup>
                {pendingOrganisations?.map((item) => {
                  // if empty object, return null
                  if (Object.keys(item).length === 0) {
                    return null;
                  }

                  const { orgNr, branchId } = item;

                  if (!orgNr || !branchId) {
                    return null;
                  }

                  const branch = branchData[branchIdWithOrgNr(item)];

                  if (!branch) {
                    return null;
                  }

                  return (
                    <CSSTransition
                      key={`${item.branchId}-${item.orgNr}`}
                      timeout={500}
                      classNames="fade"
                    >
                      <RenderCompanyListItem
                        orgData={branch}
                        handleAccept={handleAccept}
                        handleReject={handleReject}
                        disabled={isProcessing}
                      />
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
              {apiError && (
                <Message negative>
                  <Message.Header>Fel vid ansökan</Message.Header>
                  <p>{apiError.message}</p>
                </Message>
              )}
            </>
          )}
        </WhiteBox>
      </section>
    </ContentContainer>
  );
};

export default Applicants;
