// import camelCase from 'lodash/camelCase';
import deburr from 'lodash/deburr';

import priceOfSelectedProduct from './priceOfSelectedProduct';
import transformNgoText from './transformNgoText';
import tentsTotalPrice from './tentsTotalPrice';
import { SLACK_URL } from '../configs';

const scrollToTop = () => window.scrollTo(0, 0);

const sendDataToSlack = (text, payload) => {
  const data = {
    text: text,
    attachments: [
      {
        text: payload,
      },
    ],
  };

  fetch(SLACK_URL, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
    },
    body: JSON.stringify(data),
  }).catch((err) => console.error('Error sending data to slack', err));
};

// Constants for labels
const BOOKING_STATUS_LABELS = {
  COMING_SOON: 'Kan bokas snart',
  FULLY_BOOKED: 'Fullbokat',
};

/**
 * Checks if a product has reached its maximum booking capacity
 * @param {Object} data - The booking data
 * @param {string} data.productId - Product identifier
 * @param {Object} data.stats - Booking statistics
 * @param {string} [data.fieldLabel] - Original field label
 * @param {string} [data.period] - Booking period (p1Count or p2Count for tents)
 * @returns {Object} Object containing label and disabled state
 */
const isCurrentCountMax = (data) => {
  const { productId, stats, fieldLabel = '', period } = data;

  const currentStats = stats?.[productId] || {};

  // Return default state if no stats available
  if (!currentStats) {
    return { label: fieldLabel, isDisabled: true };
  }

  const {
    p1Count = null,
    p2Count = null,
    p1Max = null,
    p2Max = null,
    count = null,
    max = null,
  } = currentStats;

  // Handle tent period bookings
  if (period === 'p1Count' || period === 'p2Count') {
    const isPeriod1 = period === 'p1Count';
    const currentCount = isPeriod1 ? p1Count : p2Count;
    const maxCount = isPeriod1 ? p1Max : p2Max;

    if (maxCount === 0) {
      return { label: BOOKING_STATUS_LABELS.COMING_SOON, isDisabled: true };
    }

    const isDisabled = currentCount >= maxCount;
    const label = isDisabled
      ? `${fieldLabel} ${BOOKING_STATUS_LABELS.FULLY_BOOKED}`
      : fieldLabel;

    return { label, isDisabled };
  }

  // Handle other products
  const isDisabled = count >= max;

  if (max === 0) {
    return { label: BOOKING_STATUS_LABELS.COMING_SOON, isDisabled: true };
  }

  const label = isDisabled ? BOOKING_STATUS_LABELS.FULLY_BOOKED : fieldLabel;
  return { label, isDisabled };
};

function deburrString(inputString) {
  if (!inputString) {
    return;
  }
  return deburr(inputString);
}

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Validate email format
const isValidEmail = (email) => email && emailPattern.test(email);

export * from './cleanOrganisationNumber';
export * from './createOrgDbPaths';
export * from './cleanOrgNr';
export * from './cleanStrings';
export {
  scrollToTop,
  isCurrentCountMax,
  transformNgoText,
  tentsTotalPrice,
  sendDataToSlack,
  priceOfSelectedProduct,
  deburrString,
  emailPattern,
  isValidEmail,
};
