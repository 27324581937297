import { get } from 'lodash';
import jsonexport from 'jsonexport';
import { saveAs } from 'file-saver';

import tentsTotalPrice from '../../utils/tentsTotalPrice';
import { getSaysLeftText } from '../../utils/time-utils';
import { branchIdWithOrgNr } from '../../redux-config/selectors';

// construct user object with all bookings
const addUserToBooking = (bookingsArray, branchData, filterBy) => {
  const dataObj = { ...bookingsArray };
  const newObj = {};

  Object.keys(dataObj).forEach((bookingId) => {
    const currentBooking = get(dataObj, bookingId);

    if (!currentBooking) {
      return;
    }

    const {
      userId,
      taltyta,
      quantity,
      isFullPeriod,
      period1,
      period2,
      priceDiscount = 0,
      discount = 0,
    } = currentBooking;

    const period1count = period1 ? 1 * quantity : '';
    const period2count = period2 ? 1 * quantity : '';

    const periodString = isFullPeriod ? '1 & 2' : period1 ? '1' : '2';

    const branchIdWithOrgNrString = branchIdWithOrgNr(
      currentBooking?.organisation || {},
    );

    if (filterBy(dataObj[bookingId])) {
      newObj[bookingId] = {
        ...branchData[branchIdWithOrgNrString],
        ...dataObj[bookingId],
        bookingId: bookingId,
        authId: userId,
        tent: taltyta,
        periods: periodString,
        period1count,
        period2count,
        typeOfOrg: get(branchData[branchIdWithOrgNrString], 'typeOfOrg'),
        sum: tentsTotalPrice(currentBooking),
        discount: (discount + priceDiscount) * -1,
        fakturaDatum: getSaysLeftText(currentBooking),
      };
    }
  });
  return newObj;
};

// construct user object with all bookings
const addUserToSeminar = (seminarArray, branchData) => {
  const dataObj = { ...seminarArray };
  const newObj = {};
  Object.keys(dataObj).forEach((bookingId) => {
    if (!bookingId) {
      return;
    }

    const currentBooking = get(dataObj, bookingId);

    if (!currentBooking) {
      return;
    }

    const { userId } = currentBooking;

    const branchIdWithOrgNrString = branchIdWithOrgNr(
      currentBooking?.organisation || {},
    );

    newObj[bookingId] = {
      ...branchData[branchIdWithOrgNrString],
      ...dataObj[bookingId],
      authId: userId,
      bookingId: bookingId,
      sum:
        parseInt(get(currentBooking, 'pris'), 10) -
        get(currentBooking, 'discount', 0),
      discount: -get(currentBooking, 'discount', 0),
      typeOfOrg: get(branchData[branchIdWithOrgNrString], 'typeOfOrg'),
      fakturaDatum: getSaysLeftText(currentBooking),
    };
  });
  return newObj;
};

const addUserToAllBookings = (bookArray, branchData) => {
  return bookArray.map((currentBooking) => {
    const {
      id,
      userId,
      quantity,
      isFullPeriod,
      period1,
      period2,
      category,
      priceDiscount = 0,
      discount = 0,
    } = currentBooking;

    const branchIdWithOrgNrString = branchIdWithOrgNr(
      currentBooking?.organisation || {},
    );

    if (category.includes('seminar')) {
      return {
        ...branchData[branchIdWithOrgNrString],
        ...currentBooking,
        authId: userId,
        bookingId: id,
        sum:
          parseInt(get(currentBooking, 'pris'), 10) -
          get(currentBooking, 'discount', 0),
        discount: -get(currentBooking, 'discount', 0),
        typeOfOrg: get(branchData[branchIdWithOrgNrString], 'typeOfOrg'),
        fakturaDatum: getSaysLeftText(currentBooking),
      };
    }

    const period1count = period1 ? 1 * quantity : '';
    const period2count = period2 ? 1 * quantity : '';

    const periodString = isFullPeriod ? '1 & 2' : period1 ? '1' : '2';

    return {
      ...branchData[branchIdWithOrgNrString],
      ...currentBooking,
      authId: userId,
      bookingId: id,
      periods: periodString,
      period1count,
      period2count,
      typeOfOrg: get(branchData[branchIdWithOrgNrString], 'typeOfOrg'),
      sum: tentsTotalPrice(currentBooking),
      discount: (discount + priceDiscount) * -1,
      fakturaDatum: getSaysLeftText(currentBooking),
    };
  });
};

// Function to save as CSV
function saveAsCSV(dataToExport) {
  jsonexport(
    dataToExport,
    {
      rowDelimiter: ';',
    },
    (err, csv) => {
      if (err) return console.error(err);
      const csvblob = new Blob([`\ufeff${csv}`], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(csvblob, 'bookings.csv');
    },
  );
}

// Function to save as JSON
function saveAsJSON(dataToExport) {
  const jsonString = JSON.stringify(dataToExport, null, 2);
  const jsonBlob = new Blob([jsonString], {
    type: 'application/json;charset=utf-8',
  });
  saveAs(jsonBlob, 'bookings.json');
}

// Function to export data in both formats
function exportData(dataToExport, fileFormat) {
  if (fileFormat === 'json') {
    return saveAsJSON(dataToExport);
  }
  return saveAsCSV(dataToExport);
}

export { addUserToBooking, addUserToSeminar, addUserToAllBookings, exportData };
