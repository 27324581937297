import { Form, Button, Message } from 'semantic-ui-react';

import { StyledFormContainer } from '../../styledComponents';

function Invoice(props) {
  const {
    handleInputChange,

    handleSubmit,
    prevStep,
    handleCancelClick,
    step,
    totalSteps,
    fakturamottagare,
    fakturaadress,
    postnummer,
    ort,
    telefonnummer,
    fakturaEmail,
    disableSubmit,
    apiError,
  } = props;

  return (
    <div>
      <StyledFormContainer>
        <h3>Fyll i organisationens faktureringsuppgifter</h3>
        <a href="#" onClick={prevStep}>
          Föregående
        </a>
        <span style={{ float: 'right' }}>
          Steg {step} av {totalSteps}
        </span>
        <br />
        <br />
        <Form onSubmit={handleSubmit} error={Boolean(apiError)}>
          <Form.Input
            maxLength="120"
            onChange={handleInputChange}
            required
            id="fakturamottagare"
            name="fakturamottagare"
            label="Fakturamottagare / referens"
            placeholder="Namn på fakturamottagare"
            value={fakturamottagare}
            type="text"
            autoFocus
          />
          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            required
            id="fakturaadress"
            name="fakturaadress"
            label="Fakturaadress"
            placeholder="Gatuadress 1, lgh 1617"
            type="text"
            value={fakturaadress}
          />
          <Form.Input
            placeholder="16453"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="postnummer"
            name="postnummer"
            label="Postnummer"
            type="text"
            value={postnummer}
          />
          <Form.Input
            placeholder="Kista"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="ort"
            name="ort"
            label="Ort"
            type="text"
            value={ort}
          />
          <Form.Input
            placeholder="0744444448"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="telefonnummer"
            name="telefonnummer"
            label="Telefonnummer"
            type="text"
            value={telefonnummer}
          />
          <Form.Input
            placeholder="name@mail.se"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="fakturaEmail"
            name="fakturaEmail"
            label="E-post för PDF faktura"
            type="email"
            value={fakturaEmail}
          />
          <Message
            error
            header={apiError && apiError.code}
            content={apiError && apiError.message}
          />
          <br />
          <Button type="submit" fluid primary disabled={disableSubmit}>
            Skapa organisation
          </Button>
        </Form>
        <br />
        <div>
          <span>Behöver du hjälp? Maila oss på info@jarvaveckan.se</span>
          <a href="#" style={{ float: 'right' }} onClick={handleCancelClick}>
            Avbryt
          </a>
        </div>
      </StyledFormContainer>
    </div>
  );
}

export default Invoice;
