import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { JarvaButtonBasic, vars } from '../../styledComponents';
import { EVENT_DURATION_STRING } from '../../configs';
import { IosContactOutline } from '../Icons';

const HeaderContainer = styled.nav`
  padding: 1rem 1.5rem;

  background-color: var(--jarva-light-back);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;

  a {
    text-decoration: none;
  }
`;

const SiteLogo = styled.h1`
  position: relative;
  z-index: 2;
  color: ${vars.jarvaTextDark};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: var(--font-size--label);
  padding: 0;
  margin: 0;
  span {
    font-weight: 450;
    margin-left: 0.6rem;
    font-size: var(--font-size--label);
  }
`;

const NavLinkStyled = styled(NavLink)`
  ${JarvaButtonBasic};
  background-color: var(--button-grey);
  color: var(--jarva-blue);
  font-size: var(--font-size--subheading);
  &:hover {
    background-color: var(--jarva-blue);
    color: white;
  }
  &.active {
    background-color: var(--jarva-blue);
    color: white;
  }
`;

const LogOutButton = styled.a`
  ${JarvaButtonBasic};
  background-color: var(--button-grey);
  color: var(--jarva-blue);
  font-size: var(--font-size--subheading);
  &:hover {
    background-color: var(--jarva-blue);
    color: white;
  }
`;

const Header = (props) => {
  const { firebase, dispatch, isAdmin } = props;

  const handleLogout = (e) => {
    e.preventDefault();
    firebase.logout().finally(() => {
      dispatch({
        type: 'RESET',
      });
    });
  };

  return (
    <HeaderContainer>
      <SiteLogo>
        <a href="https://jarvaveckan.se">Järvaveckan</a>{' '}
        <span>{EVENT_DURATION_STRING}</span>
      </SiteLogo>
      <div className="flex center">
        {isAdmin && <NavLinkStyled to="/admin">Administratör</NavLinkStyled>}
        <NavLinkStyled to="/user/user-account">
          <IosContactOutline />
        </NavLinkStyled>
        <LogOutButton onClick={handleLogout}>Logga ut</LogOutButton>
      </div>
    </HeaderContainer>
  );
};

export default Header;
