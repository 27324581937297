import { memo, useEffect, useMemo, useState, useCallback } from 'react';

import { isEmpty, keyBy } from 'lodash';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'semantic-ui-react';

import { AdminSideBar } from '../../components/SideBar';
import SpinnerLoader from '../../components/SpinnerLoader';
import {
  filterOrganisationsByStatuses,
  getAuth,
  getBookings,
  getOrderedBookings,
  getOrderedProductBookings,
  getOrderedSeminarBookings,
  getOrderedSeminarLiveBookings,
  getOrderedSponsorBookings,
  getOrderedTillaggBookings,
  getOrderedUsers,
  getOrganisations,
  getProductBookings,
  getProducts,
  getProfile,
  getSeminarBookings,
  getSeminarLiveBookings,
  getSponsorBookings,
  getStats,
  getTillaggBookings,
} from '../../redux-config/selectors';
import AdminHome from '../AdminHome';
import AdminRegister from '../AdminRegister';
import AdminSettings from '../AdminSettings';
import Applicants from '../Applicants';
import BookingRegister from '../BookingRegister';
import ProductsSettings from '../ProductsSettings';
import ResetDatabase from '../ResetDatabase';
import SeminarLivePlacementList from '../SeminarLivePlacementList';
import SeminarPlacementList from '../SeminarPlacementList';
import {
  BOOKING_COLLECTION_CURRENT_EVENT,
  FB_COLLECTIONS,
  INITIAL_LOADING_TIME,
} from '../../configs';

const AdminDashBoard = (props) => {
  // console.log('AdminDashBoard: props', props);

  const ADMIN_PATH = '/admin';

  const {
    firestore,
    orderedSeminarBookings,
    orderedSeminarLiveBookings,
    products,
    stats,
    organisations,
    isAdmin,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [branchData, setBranchData] = useState({});
  const [users, setUsers] = useState({});
  const navigate = useNavigate();

  // Handle loading state with cleanup
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), INITIAL_LOADING_TIME);
    return () => clearTimeout(timer);
  }, []);

  // Error handling utility
  const handleError = useCallback((error, customMessage = '') => {
    console.error(customMessage, error);
    setError({
      message: customMessage || error.message,
      details: error,
    });
    setIsLoading(false);
  }, []);

  // Redirect if not admin
  useEffect(() => {
    if (!isAdmin) {
      const timer = setTimeout(() => navigate('/'), INITIAL_LOADING_TIME);
      return () => clearTimeout(timer);
    }
  }, [isAdmin, navigate]);

  // Memoize filtered organisations
  const { pendingOrganisations, approvedOrganisations } = useMemo(
    () => filterOrganisationsByStatuses(organisations),
    [organisations],
  );

  // Fetch Branch Data
  useEffect(() => {
    if (isEmpty(approvedOrganisations)) return;

    let isMounted = true;
    const fetchBranchData = async () => {
      setIsLoading(true);
      try {
        const results = await Promise.all(
          approvedOrganisations.map(async ({ orgNr, branchId }) => {
            const docRef = firestore
              .collection('organisations')
              .doc(orgNr)
              .collection('branches')
              .doc(branchId);
            const doc = await docRef.get();

            return doc.exists
              ? { dbDocId: doc.id, ...doc.data(), id: `${orgNr}__${branchId}` }
              : null;
          }),
        );

        if (isMounted) {
          setBranchData(keyBy(results.filter(Boolean), 'id')); // Remove nulls
        }
      } catch (error) {
        if (isMounted) {
          handleError(error, 'Error fetching branch data:');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchBranchData();
    return () => {
      isMounted = false;
    };
  }, [approvedOrganisations, firestore, handleError]);

  // Fetch Users Data
  useEffect(() => {
    let isMounted = true;
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await firestore.collection('users').get();
        const usersData = {};
        usersSnapshot.forEach((doc) => {
          usersData[doc.id] = { id: doc.id, dbDocId: doc.id, ...doc.data() };
        });
        if (isMounted) {
          setUsers(usersData);
        }
      } catch (error) {
        if (isMounted) {
          handleError(error, 'Error fetching users:');
        }
      }
    };

    fetchUsers();
    return () => {
      isMounted = false;
    };
  }, [firestore, handleError]);

  if (isLoading) {
    return <SpinnerLoader />;
  }

  if (error) {
    return (
      <div className="error-container">
        <h3>Error</h3>
        <p>{error.message}</p>
        <Button onClick={() => setError(null)}>Dismiss</Button>
      </div>
    );
  }

  const renderRoutes = () => (
    <Routes>
      <Route
        path="/"
        element={<AdminHome {...props} branchData={branchData} users={users} />}
      />
      <Route
        path="applicants"
        element={
          <Applicants
            pendingOrganisations={pendingOrganisations}
            firestore={firestore}
          />
        }
      />
      <Route
        path="register"
        element={
          <AdminRegister {...props} branchData={branchData} users={users} />
        }
      />
      <Route
        path="bookings"
        element={
          <BookingRegister {...props} branchData={branchData} users={users} />
        }
      />
      <Route
        path="seminarlist"
        element={
          <SeminarPlacementList
            orderedSeminarBookings={orderedSeminarBookings}
            firestore={firestore}
          />
        }
      />
      <Route
        path="seminarlivelist"
        element={
          <SeminarLivePlacementList
            orderedSeminarLiveBookings={orderedSeminarLiveBookings}
            firestore={firestore}
          />
        }
      />
      <Route
        path="settings"
        element={
          <AdminSettings
            stats={stats}
            products={products}
            firestore={firestore}
          />
        }
      />
      <Route path="reset-database" element={<ResetDatabase {...props} />} />
      <Route path="products-settings" element={<ProductsSettings />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  return (
    <div className="columns">
      <AdminSideBar
        className="sidenav"
        home={`${ADMIN_PATH}`}
        applicantsUrl={`${ADMIN_PATH}/applicants`}
        book={`${ADMIN_PATH}/book`}
        register={`${ADMIN_PATH}/register`}
        settings={`${ADMIN_PATH}/settings`}
        resetDatabase={`${ADMIN_PATH}/reset-database`}
        seminarlist={`${ADMIN_PATH}/seminarlist`}
        seminarlivelist={`${ADMIN_PATH}/seminarlivelist`}
        bookings={`${ADMIN_PATH}/bookings`}
        productsSettings={`${ADMIN_PATH}/products-settings`}
        applicantCount={pendingOrganisations?.length}
      />
      <div className="main">{renderRoutes()}</div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  profile: getProfile,
  auth: getAuth,
  bookings: getBookings,
  tillaggBookings: getTillaggBookings,
  seminarBookings: getSeminarBookings,
  seminarLiveBookings: getSeminarLiveBookings,
  sponsorBookings: getSponsorBookings,
  productBookings: getProductBookings,
  orderedUsers: getOrderedUsers,
  orderedBookings: getOrderedBookings,
  orderedTillaggBookings: getOrderedTillaggBookings,
  orderedProductBookings: getOrderedProductBookings,
  orderedSeminarBookings: getOrderedSeminarBookings,
  orderedSeminarLiveBookings: getOrderedSeminarLiveBookings,
  orderedSponsorBookings: getOrderedSponsorBookings,
  stats: getStats,
  products: getProducts,
  organisations: getOrganisations,
});

const withConnect = connect(mapStateToProps);

export default memo(
  compose(
    withConnect,
    firestoreConnect(() => [
      {
        collection: FB_COLLECTIONS.products,
        path: FB_COLLECTIONS.products,
      },
      {
        collection: FB_COLLECTIONS.organisations,
        path: FB_COLLECTIONS.organisations,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.bookings,
        path: FB_COLLECTIONS.bookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.tillaggBookings,
        path: FB_COLLECTIONS.tillaggBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
        path: FB_COLLECTIONS.seminarBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarLiveBookings,
        path: FB_COLLECTIONS.seminarLiveBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings,
        path: FB_COLLECTIONS.sponsorBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.productBookings,
        path: FB_COLLECTIONS.productBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
        path: FB_COLLECTIONS.stats,
      },
    ]),
  )(AdminDashBoard),
);
