import { useEffect, useState, useCallback } from 'react';

import { withFirestore } from 'react-redux-firebase';
import { isEmpty } from 'lodash';
import { compose } from 'redux';

import RenderBranchUsersRequests from './RenderBranchUsersRequests';
import { DataBox, WhiteBox } from '../../styledComponents';
import RenderInvitedUsers from './RenderInvitedUsers';
import RenderBranchUsers from './RenderBranchUsers';
import InviteUser from './InviteUser';

function OrgUserManagement(props) {
  const { currentUser, currentUserOrganisation, isAdmin, firestore } = props;

  const [branchUsers, setBranchUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);

  const { status = {} } = currentUserOrganisation || {};

  const computedIsAdmin =
    isAdmin || (status.approved && status.role === 'admin');

  const fetchBranchUsers = useCallback(async () => {
    if (isEmpty(currentUser.orgDbPaths)) return;

    const { orgUserPaths } = currentUser.orgDbPaths || {};

    try {
      // Get branch users
      const branchUsersSnapshot = await firestore
        .collection(orgUserPaths.dbCollection)
        .get();

      const branchUsers = branchUsersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setBranchUsers(branchUsers);
    } catch (err) {
      console.error('Error fetching firestore data:', err);
    }
  }, [currentUser.orgDbPaths, firestore]);

  useEffect(() => {
    if (computedIsAdmin) {
      fetchBranchUsers();
    }
  }, [computedIsAdmin, fetchBranchUsers]);

  const fetchInvitedUsers = useCallback(async () => {
    if (isEmpty(currentUserOrganisation)) return;

    try {
      // Get branch users
      const invitedUsersSnapshot = await firestore
        .collection('invites')
        .where('orgData.orgName', '==', currentUserOrganisation.orgName)
        .where('orgData.orgNr', '==', currentUserOrganisation.orgNr)
        .get();

      const invitedUsers = invitedUsersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setInvitedUsers(invitedUsers);
    } catch (err) {
      console.error('Error fetching firestore data:', err);
    }
  }, [currentUserOrganisation, firestore]);

  useEffect(() => {
    if (computedIsAdmin) {
      fetchInvitedUsers();
    }
  }, [computedIsAdmin, fetchInvitedUsers]);

  if (!computedIsAdmin) return null;

  const branchUsersCount = branchUsers.length;
  const { email } = currentUser || {};

  const categorizedUsers = branchUsers.reduce(
    (acc, user) => {
      if (email && user.email === email) {
        acc.currentAdminUser = user;
      }
      if (user.approved) {
        acc.approved.push(user);
      }
      if (user.pending || user.isOrgPending) {
        acc.pending.push(user);
      }
      return acc;
    },
    {
      approved: [],
      pending: [],
      rejected: [],
      currentAdminUser: null,
    },
  );

  const {
    approved: approvedUsers,
    pending: pendingUsers,
    currentAdminUser,
  } = categorizedUsers;

  return (
    <WhiteBox>
      <DataBox>
        <hr className="hr-line" />
        <br />
        <h2>Hantera medlemmar</h2>
        {branchUsersCount > 0 && <p>Antal medlemmar: {branchUsersCount}</p>}
      </DataBox>

      <RenderBranchUsers
        users={approvedUsers}
        currentAdminUser={currentAdminUser}
        currentUser={currentUser}
        updateState={() => {
          fetchBranchUsers();
        }}
      />

      <RenderBranchUsersRequests
        users={pendingUsers}
        currentAdminUser={currentAdminUser}
        currentUser={currentUser}
        firestore={firestore}
        computedIsAdmin={computedIsAdmin}
        updateState={() => {
          fetchBranchUsers();
        }}
      />

      <RenderInvitedUsers invitedUsers={invitedUsers} />

      <InviteUser
        computedIsAdmin={computedIsAdmin}
        currentUser={currentUser}
        branchUserEmails={branchUsers}
        updateState={() => {
          fetchInvitedUsers();
          fetchBranchUsers();
        }}
      />
    </WhiteBox>
  );
}

export default compose(withFirestore)(OrgUserManagement);
