// import transformNgoText from './transformNgoText';
import { DAYS_TO_INVOICE } from '../configs';
import transformNgoText from './transformNgoText';

const getTimeStampNumber = (timeStampParam) => {
  const timeStamp = Number(timeStampParam);

  if (Number.isNaN(timeStamp)) return null;

  // Handle numeric timestamps (e.g., UNIX timestamps in various scales)
  if (typeof timeStamp === 'number') {
    const SECONDS_THRESHOLD = 1e9; // 1 billion (approx. 2001-09-09T01:46:40Z)
    const MILLISECONDS_THRESHOLD = 1e12; // 1 trillion (approx. 2001-09-09T01:46:40.000Z)
    const NANOSECONDS_THRESHOLD = 1e18; // 1 quintillion (nanoseconds)

    let timeStampNumber = Math.floor(timeStamp);

    // Ensure the timeStamp is positive
    if (timeStamp < 0) {
      timeStampNumber = null;
    }

    if (timeStamp >= NANOSECONDS_THRESHOLD) {
      // Nanoseconds, convert to seconds
      timeStampNumber = Math.floor(timeStamp / 1e9);
    }

    if (timeStamp >= MILLISECONDS_THRESHOLD) {
      // Microseconds, convert to seconds
      timeStampNumber = Math.floor(timeStamp / 1e6);
    }

    if (timeStamp >= SECONDS_THRESHOLD) {
      // Milliseconds, convert to seconds
      timeStampNumber = Math.floor(timeStamp / 1000);
    }

    // Assume it's already in seconds
    return timeStampNumber;
  }
};

const getTimeInSeconds = (timeStamp) => {
  // Handle null/undefined cases
  if (timeStamp == null) return null;

  try {
    // Handle Firestore Timestamp (e.g., { seconds: 1234567890, nanoseconds: 0 })
    if (typeof timeStamp === 'object' && 'seconds' in timeStamp) {
      return Math.floor(timeStamp.seconds);
    }

    // Handle Date objects
    if (timeStamp instanceof Date) {
      return Math.floor(timeStamp.getTime() / 1000);
    }

    // Handle numeric timestamps (e.g., UNIX timestamps in various scales)
    const timeStampNumber = Number(timeStamp);
    if (!Number.isNaN(timeStampNumber) && typeof timeStampNumber === 'number') {
      return getTimeStampNumber(timeStampNumber);
    }

    // Handle string timestamps
    if (typeof timeStamp === 'string') {
      // new Date("Mon, 28 Mar 2022 13:07:47 GMT")
      // new Date("2022-03-28T09:15:56.000Z")
      // new Date("2024-10-22T13:08:44.114Z")
      // Fallback: Parse general date strings
      const parsedDate = new Date(timeStamp);
      if (!Number.isNaN(parsedDate.getTime())) {
        return Math.floor(parsedDate.getTime() / 1000);
      }

      return null; // Invalid date string
    }

    // If none of the above, return null for invalid formats
    return null;
  } catch (error) {
    console.error('Error normalizing timeStamp:', error.message, timeStamp);
    return null;
  }
};

/**
 * Normalizes different timeStamp formats to Unix timeStamp in seconds.
 * @param {Date|number|string|{seconds: number, nanoseconds: number}|null|undefined} timeStamp - Input timeStamp in various formats.
 * @returns {number|null} Normalized timeStamp in seconds or null if invalid.
 */
const normalizeTimestamp = (timeStamp) => {
  if (!timeStamp) return null;

  const timeStampNumber = getTimeInSeconds(timeStamp);
  // console.info('timeStamp     timeStamp', timeStamp);
  // console.info('timeStampNumber seconds', timeStampNumber);
  // if (timeStamp === undefined) debugger;
  return timeStampNumber;
};

/**
 * Normalizes different timeStamp formats to Unix timeStamp in seconds.
 * @param {Date|number|string|{seconds: number, nanoseconds: number}|null|undefined} timeStamp - Input timeStamp in various formats.
 * @returns {string|null} Normalized timeStamp in seconds or null if invalid.
 */
const getIsoDate = (timeStamp) => {
  if (timeStamp == null) return null;
  const timeStampNumber = normalizeTimestamp(timeStamp);
  if (timeStampNumber == null) return null;
  return new Date(timeStampNumber * 1000).toISOString();
};

const getDateFromTimeStamp = (timeStamp) => {
  const timeStampNumber = normalizeTimestamp(timeStamp);

  // Return fallback if no timestamp provided
  if (timeStampNumber === null) {
    return 'Aldrig inloggad';
  }

  try {
    // Convert seconds to milliseconds for Date constructor
    const date = new Date(timeStampNumber * 1000);
    // Validate the resulting date
    if (Number.isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return date.toLocaleDateString('sv-SE');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Ogiltigt datum';
  }
};

const timeStampsVars = ['createAt', 'updateAt', 'createdAt', 'updatedAt'];
const numberVars = [
  'pris',
  'sum',
  'quantity',
  'tent',
  'period1count',
  'period2count',
  'sum',
  'discount',
];

/**
 * Handle table data transform
 * @param {string} name - The name of the column
 * @param {any} value - The value of the column
 * @returns {string} The transformed value
 */
const handleTableDataTransform = (name, value) => {
  // Handle null/undefined
  if (!value) return '';

  // // Handle NGO case
  if (value === 'ngo') {
    return transformNgoText('ngo');
  }

  // handle all timeStamps
  if (timeStampsVars.includes(name)) {
    const date = getDateFromTimeStamp(value);
    return date;
  }

  if (numberVars.includes(name)) {
    return value.toLocaleString('sv-SE');
  }

  // if array value join with comma
  if (Array.isArray(value)) {
    return value.join(', ');
  }

  // Handle Firestore timestamp
  if (typeof value === 'object') {
    if ('seconds' in value && typeof value.seconds === 'number') {
      return getDateFromTimeStamp(value);
    }
    // Handle empty objects
    if (Object.keys(value).length === 0) {
      return '';
    }
    // Try to convert object to string
    try {
      return String(value);
    } catch {
      return '';
    }
  }

  // Handle other types
  return String(value);
};

const daysLeft = (updatedAt) => {
  const SECONDS_IN_A_DAY = 24 * 60 * 60;
  const adjustmentDays = DAYS_TO_INVOICE + 1;

  const normalizedCreatedAt = normalizeTimestamp(updatedAt);

  if (normalizedCreatedAt === null) {
    return null;
  }

  try {
    const timeLeft = normalizedCreatedAt + adjustmentDays * SECONDS_IN_A_DAY;
    const timeNow = Math.floor(Date.now() / 1000);
    const daysRemaining = Math.floor((timeLeft - timeNow) / SECONDS_IN_A_DAY);

    return Math.max(0, daysRemaining);
  } catch (error) {
    console.error('Error calculating days left:', error);
    return null;
  }
};

const getSaysLeftText = (booking) => {
  const { createdAt, sentInvoice, invoiceCreatedAt } = booking;

  if (!createdAt) {
    return 'Data createdAt missing';
  }

  try {
    const createAtLocal = normalizeTimestamp(createdAt);
    if (createAtLocal === null) {
      return 'Ogiltigt createdAt datum';
    }

    const daysRemaining = daysLeft(createdAt);

    if (sentInvoice) {
      if (!invoiceCreatedAt) {
        return 'Faktura skickad (datum saknas)';
      }
      const invoiceCreatedAtLocal = normalizeTimestamp(invoiceCreatedAt);
      if (invoiceCreatedAtLocal === null) {
        return 'Faktura skickad (ogiltigt datum)';
      }
      return `Faktura skickad ${handleTableDataTransform('', { seconds: invoiceCreatedAtLocal })}`;
    }

    const expectedInvoiceDate = handleTableDataTransform('', {
      seconds: createAtLocal + DAYS_TO_INVOICE * 24 * 60 * 60,
    });

    return daysRemaining > 0
      ? `Ni har ${daysRemaining} dagar kvar att avboka. Faktura skickas efter ${expectedInvoiceDate}`
      : `Faktura skickas efter ${expectedInvoiceDate}`;
  } catch (error) {
    console.error('Error generating days left text:', error);
    return 'Ett fel uppstod';
  }
};

export {
  normalizeTimestamp,
  daysLeft,
  getSaysLeftText,
  handleTableDataTransform,
  getDateFromTimeStamp,
  getIsoDate,
};
