import { isEmpty } from 'lodash';
import { Button, Form, Grid, Label } from 'semantic-ui-react';

import ContentEditor from '../../components/ContentEditor';
import FileOrImagePreview from '../../components/FileOrImagePreview/FileOrImagePreview';
import { IosContactOutline } from '../../components/Icons';
import {
  OMIT_NESTED_FIELDS_ORGANISATION,
  ORGANISATION_OPTIONS,
} from '../../configs';
import { DataBox, WhiteBox } from '../../styledComponents';
import { transformNgoText } from '../../utils';

function RenderOrganisationDetails(props) {
  const {
    currentUserOrganisation,
    state,
    isFromUser,
    toggleReadOnlyMode,
    handleCancel,
    toggleState,
    saveToDatabase,
    handleInputChange,
    setState,
  } = props;

  if (isEmpty(currentUserOrganisation)) return;

  const {
    stateCurrentUserOrganisation,
    readOnlyCurrentUserOrganisation: stateReadonly,
    isSaveClicked,
    loading,
  } = state;

  const readOnlyCurrentUserOrganisation = !stateReadonly;
  const reduxPath = isFromUser ? 'currentUserOrganisation' : '';

  const newData = {
    ...currentUserOrganisation,
    ...stateCurrentUserOrganisation,
  };

  const {
    orgEmail = '',
    fakturaEmail = '',
    fakturaadress = '',
    fakturamottagare = '',
    orgName = '',
    orgNr = '',
    ort = '',
    postnummer = '',
    telefonnummer = '',
    typeOfOrg = '',
    organizerName = '',
    beskriv = '',
    hemsida,
    fb,
    instagram,
    linkedin,
    twitter,
    logo,
    branchId,
    dbCollection,
  } = newData;

  const renderButtons = () => {
    return (
      <Grid.Row className="floating-edit-buttons">
        <Grid.Column>
          <br />
          <div className="flex no-gap">
            {readOnlyCurrentUserOrganisation ? (
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  toggleReadOnlyMode(
                    'readOnlyCurrentUserOrganisation',
                    branchId,
                    'stateCurrentUserOrganisation',
                  )();
                }}
                content="Redigera uppgifter"
                primary
                labelPosition="right"
                icon="edit outline"
              />
            ) : (
              <>
                <Button
                  type="submit"
                  loading={loading}
                  disabled={isSaveClicked}
                  primary
                  content="Spara"
                  labelPosition="right"
                  icon="checkmark"
                />
                <Button
                  basic
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancel();
                  }}
                  primary
                  content="Avbryt"
                  icon="cancel"
                  labelPosition="right"
                />
              </>
            )}
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  };

  return (
    <WhiteBox key={branchId}>
      <DataBox>
        <h3
          onClick={(e) => {
            e.preventDefault();
            toggleState('userOpen');
          }}
        >
          <IosContactOutline />
          Kontouppgifter - ej synliga utåt
        </h3>
        <Form
          loading={loading}
          className="horizontal-container"
          onSubmit={saveToDatabase({
            dbCollection,
            reduxPath,
            data: newData,
            omitFields: OMIT_NESTED_FIELDS_ORGANISATION,
            branchId,
            stateDataId: 'stateCurrentUserOrganisation',
            readOnlyId: 'readOnlyCurrentUserOrganisation',
          })}
        >
          <Grid stackable columns="equal" stretched>
            <Grid.Row>
              <Grid.Column>
                <ul aria-label="Organisationsuppgifter">
                  <li>
                    <Form.Input
                      label="Företag"
                      maxLength="90"
                      type="text"
                      name="orgName"
                      value={orgName || ''}
                      readOnly
                      // readOnly={
                      //   isAdmin && !isFromUser
                      //     ? readOnlyCurrentUserOrganisation
                      //     : true
                      // }
                      // onChange={handleInputChange(
                      //   'stateCurrentUserOrganisation',
                      // )}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Organisationsnummer"
                      maxLength="20"
                      minLength="11"
                      aria-label="Organisationsnummer (i formatet 000000-0000)"
                      id="orgNr"
                      name="orgNr"
                      type="text"
                      value={orgNr || ''}
                      readOnly
                      // readOnly={
                      //   isAdmin && !isFromUser
                      //     ? readOnlyCurrentUserOrganisation
                      //     : true
                      // }
                      // onChange={handleInputChange(
                      //   'stateCurrentUserOrganisation',
                      // )}
                    />
                  </li>
                  <li aria-label="Organisationstyp">
                    {typeOfOrg && <Label>{transformNgoText(typeOfOrg)}</Label>}
                    <Form.Select
                      label="Organisationstyp"
                      onChange={(_, target) => {
                        const { name, value } = target;
                        setState((prevState) => {
                          const updateState = {
                            stateCurrentUserOrganisation: {
                              ...prevState.stateCurrentUserOrganisation,
                              [name]: value,
                            },
                          };
                          return updateState;
                        });
                      }}
                      required
                      id="typeOfOrg"
                      name="typeOfOrg"
                      options={ORGANISATION_OPTIONS}
                      value={transformNgoText(typeOfOrg) || ''}
                      disabled={readOnlyCurrentUserOrganisation}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Telefon"
                      maxLength="20"
                      type="text"
                      name="telefonnummer"
                      required
                      value={telefonnummer || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="E-post"
                      maxLength="120"
                      type="email"
                      name="orgEmail"
                      required
                      value={orgEmail || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                </ul>
              </Grid.Column>
              <Grid.Column>
                <ul aria-label="Fakturauppgifter">
                  <li>
                    <Form.Input
                      label="Er referens"
                      required
                      maxLength="120"
                      type="text"
                      name="fakturamottagare"
                      value={fakturamottagare || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Fakturaadress"
                      maxLength="160"
                      type="text"
                      name="fakturaadress"
                      required
                      value={fakturaadress || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Postnummer"
                      maxLength="120"
                      type="text"
                      name="postnummer"
                      required
                      value={postnummer || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Ort"
                      maxLength="120"
                      type="text"
                      name="ort"
                      required
                      value={ort || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="E-post för PDF faktura"
                      maxLength="120"
                      type="email"
                      required
                      name="fakturaEmail"
                      value={fakturaEmail || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                </ul>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h3>
                  <ContentEditor
                    inline
                    contentId="page-org-account-section-2-title"
                  >
                    Uppgifter som kommer synas och presenteras i digitala och
                    tryckta kanaler
                  </ContentEditor>
                </h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ul aria-label="Organisationsuppgifter">
                  <li>
                    <Form.Input
                      label="Namn på arrangör/organisation (max 70 tecken)"
                      maxLength="120"
                      required
                      type="text"
                      name="organizerName"
                      value={organizerName || orgName || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                  <li>
                    <Form.TextArea
                      label="Beskrivning av er organisation (max 800 tecken)"
                      maxLength="800"
                      name="beskriv"
                      value={beskriv || ''}
                      readOnly={readOnlyCurrentUserOrganisation}
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                    />
                  </li>
                </ul>
              </Grid.Column>
              <Grid.Column>
                <ul aria-label="Social media">
                  <li>
                    <Form.Input
                      label="Hemsida (hela URL - https:// )"
                      maxLength="140"
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                      readOnly={readOnlyCurrentUserOrganisation}
                      id="hemsida"
                      name="hemsida"
                      // type="url"
                      value={hemsida || ''}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Facebook (hela URL - https:// )"
                      maxLength="140"
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                      readOnly={readOnlyCurrentUserOrganisation}
                      id="fb"
                      name="fb"
                      // type="url"
                      value={fb || ''}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Instagram (hela URL - https:// )"
                      maxLength="140"
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                      readOnly={readOnlyCurrentUserOrganisation}
                      id="instagram"
                      name="instagram"
                      // type="url"
                      value={instagram || ''}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="Linkedin (hela URL - https:// )"
                      maxLength="140"
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                      readOnly={readOnlyCurrentUserOrganisation}
                      id="linkedin"
                      name="linkedin"
                      // type="url"
                      value={linkedin || ''}
                    />
                  </li>
                  <li>
                    <Form.Input
                      label="X (hela URL - https:// )"
                      maxLength="140"
                      onChange={handleInputChange(
                        'stateCurrentUserOrganisation',
                      )}
                      readOnly={readOnlyCurrentUserOrganisation}
                      id="twitter"
                      name="twitter"
                      // type="url"
                      value={twitter || ''}
                    />
                  </li>
                </ul>
              </Grid.Column>
              <Grid.Column>
                <ul aria-label="Organisationens logotyp">
                  <li aria-label="Logo">
                    <Form.Field>
                      <label htmlFor="logo">
                        <span className="upload-note">
                          Lägg upp organisationens logotyp
                        </span>
                        <span className="upload-note">
                          * allowed: svg,png - max size 1MB
                        </span>
                        <Form.Input
                          type="file"
                          disabled={readOnlyCurrentUserOrganisation}
                          id="logo"
                          name="logo"
                          accept="image/*,.svg"
                          onChange={handleInputChange(
                            'stateCurrentUserOrganisation',
                          )}
                        />
                      </label>
                      <FileOrImagePreview img={logo} />
                    </Form.Field>
                  </li>
                </ul>
              </Grid.Column>
            </Grid.Row>

            {/* Buttons */}
            {renderButtons()}
          </Grid>
        </Form>
      </DataBox>
    </WhiteBox>
  );
}

export default RenderOrganisationDetails;
