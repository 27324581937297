import { useState } from 'react';

import { withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';
import { omit } from 'lodash';

import UserDetails from '../../components/UserDetails';
import { sendDataToSlack } from '../../utils';
import { FB_COLLECTIONS, REDUX_PATHS_CURRENT_USER } from '../../configs';

const DEFAULT_STATE = {
  firstName: '',
  secondName: '',
  titel: '',
  email: '',
  password: '',
  repeatPassword: '',
  passwordError: false,
  formFilled: false,
  godkannKopvillkorOchBekraftelse: false,
  godkannJarvaVekanKod: false,
  disableSubmit: false,
  createUserError: null,
  loading: false,
};

const restoreState = () => {
  const sessionState = window.sessionStorage.getItem('signupForm');

  if (!sessionState) {
    return DEFAULT_STATE;
  }

  try {
    const parsedState = JSON.parse(sessionState);
    return {
      ...DEFAULT_STATE,
      ...parsedState,
    };
  } catch (error) {
    console.error('Error parsing session state:', error);
    return DEFAULT_STATE;
  }
};

function SignUpContainer({ firebase, firestore, goToLogin }) {
  const [state, setState] = useState(restoreState);

  const setSessionStorage = (currentState) => {
    window.sessionStorage.setItem(
      'signupForm',
      JSON.stringify({
        ...currentState,
      }),
    );
  };

  const createNewUser = async () => {
    const { password, email } = state;

    const omitFields = [
      'password',
      'repeatPassword',
      'passwordError',
      'formFilled',
      'disableSubmit',
      'createUserError',
      'loading',
    ];

    const data = omit(state, omitFields);

    setSessionStorage(state);
    setState((prev) => ({ ...prev, disableSubmit: true, loading: true }));

    try {
      const authRes = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      const { uid } = authRes.user;

      if (!uid) {
        throw new Error('Uid is empty');
      }

      await firestore.set(
        {
          collection: FB_COLLECTIONS.users,
          doc: uid,
          path: REDUX_PATHS_CURRENT_USER.currentUser,
        },
        {
          email,
          welcomeMailSent: true,
          id: uid,
          dbDocId: uid,
          userId: uid,
          ...data,
        },
        { merge: true },
      );

      window.sessionStorage.removeItem('signupForm');
      console.info('user created');
      setState((prev) => ({ ...prev, createUserError: null }));

      sendDataToSlack(
        'NEW USER: Successfully sent to DB',
        JSON.stringify(authRes),
      );
    } catch (error) {
      console.error('Error during sign-up!', error);
      const { code, message } = error;
      let errorMessage = message;

      if (code === 'auth/email-already-in-use') {
        errorMessage = 'Denna e-postadress är redan registrerad.';
      }

      setState((prev) => ({ ...prev, createUserError: errorMessage }));

      sendDataToSlack(
        'Error during sign-up!',
        JSON.stringify({ email: state.email, error }),
      );
    } finally {
      setState((prev) => ({ ...prev, disableSubmit: false, loading: false }));
    }
  };

  const handleInputChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;
    setState((prev) => ({
      ...prev,
      [name]: localValue,
      createUserError: prev.createUserError ? null : prev.createUserError,
      disableSubmit: prev.disableSubmit ? false : prev.disableSubmit,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    createNewUser();
  };

  const checkFormFilled = (step) => {
    if (step !== 1) return;

    const requiredFields = [
      'firstName',
      'secondName',
      'email',
      'password',
      'repeatPassword',
    ];
    const allFieldsFilled = requiredFields.every(
      (field) => (state[field] || '').length > 0,
    );

    if (allFieldsFilled) {
      setState((prev) => ({ ...prev, formFilled: true }));
    }
  };

  const checkPassword = () => {
    setState((prev) => ({
      ...prev,
      passwordError: !(prev.password === prev.repeatPassword),
    }));
    checkFormFilled(1);
  };

  return (
    <UserDetails
      handleInputChange={handleInputChange}
      checkPassword={checkPassword}
      checkFormFilled={checkFormFilled}
      goToLogin={goToLogin}
      values={state}
      handleSubmit={handleSubmit}
    />
  );
}

export default compose(withFirestore)(SignUpContainer);
