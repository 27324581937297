import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { combineReducers } from 'redux';

function customClaimsProvider(state = {}, action) {
  switch (action.type) {
    case 'CLAIMS_UPDATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    claims: customClaimsProvider,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    ...injectedReducers,
  });

  return rootReducer;
}

export { createReducer };
