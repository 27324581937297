const APP_EMULATOR = process.env.APP_EMULATOR || '';
const APP_ENV = process.env.APP_ENV || '';

const IS_EMULATOR = APP_EMULATOR === 'local';
const IS_STAGE = APP_ENV === 'stage';
const IS_LIVE = APP_ENV === 'live';

const AUTH_PORT = 9099;
const FIRESTORE_PORT = 8080;
const STORAGE_PORT = 9199;
const FUNCTIONS_PORT = 5001;

const SLACK_URL = IS_LIVE
  ? 'https://hooks.slack.com/services/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'
  : 'https://hooks.slack.com/services/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';

export {
  IS_EMULATOR,
  IS_STAGE,
  IS_LIVE,
  AUTH_PORT,
  FIRESTORE_PORT,
  STORAGE_PORT,
  FUNCTIONS_PORT,
  SLACK_URL,
};
