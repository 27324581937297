import { memo, useEffect, useState } from 'react';

import { Button, Form, Grid, Icon, Label } from 'semantic-ui-react';
import { cloneDeep, get, isEmpty, map, set, unset } from 'lodash';

import {
  torghandelFormInputs,
  foodtruckFormInputs,
  defaultFormFields,
} from '../ProductBookings/formFields';
import {
  TILLGANGLIGHET_OPTIONS,
  SPRAK_OPTIONS,
  AMNE_OPTIONS,
  MAX_SELECT,
  BOOKING_COLLECTION_CURRENT_EVENT,
  REDUX_PATHS_CURRENT_USER,
  FB_COLLECTIONS,
  INITIAL_LOADING_TIME,
} from '../../configs';
import {
  SeminarLogo,
  StarLogo,
  TentLogo,
  WarnLogo,
} from '../../components/Logos';
import FileOrImagePreview from '../../components/FileOrImagePreview/FileOrImagePreview';
import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import { clearDateBeforeSaving } from '../../utils/clearDateBeforeSaving';
import { daysLeft, getSaysLeftText } from '../../utils/time-utils';
import KontaktaWorkman from '../../components/KontaktaWorkman';
import ContentEditor from '../../components/ContentEditor';
import TextCopy from '../../components/TextCopy/TextCopy';
import { uploadFileToStorage } from '../../api';
import Wrapper from './style';
import SpinnerLoader from '../../components/SpinnerLoader';

const INITIAL_STATE = {
  readOnlyCurrentUser: '',
  readOnlyCurrentUserBookings: {},
  readOnlyCurrentUserTillaggBookings: {},
  readOnlyCurrentUserProductBookings: {},
  readOnlyrenderSponsorBookings: {},
  readOnlySeminarBookings: {},
  readOnlySeminarLiveBookings: {},
  stateCurrentUser: {},
  stateCurrentUserBookings: {},
  stateCurrentUserTillaggBookings: {},
  stateCurrentUserProductBookings: {},
  stateSponsorBookings: {},
  stateSeminarBookings: {},
  stateSeminarLiveBookings: {},
  isDeleteClicked: false,
  isSaveClicked: false,
  uploading: false,
  cosponsors: {},
  loading: false,
  isOpen: {},
  uploadingCurrentUserBookings: false,
  stateReadonly: {},
};

function UserBookings(props) {
  const [state, setState] = useState(INITIAL_STATE);
  const [isLoading, setIsLoading] = useState(true);

  const {
    currentUserBookings,
    currentUserTillaggBookings,
    currentUserProductBookings,
    currentUserSeminars,
    currentUserSeminarsLive,
    currentUserSponsor,
    claims,
    firestore,
    currentUserOrganisation,
    isFromUser,
    isFromAdminPage,
  } = props;

  const isAdmin = get(claims, 'admin', false);

  const {
    readOnlyCurrentUserBookings,
    readOnlyCurrentUserTillaggBookings,
    readOnlyCurrentUserProductBookings,
    readOnlyrenderSponsorBookings,
    stateCurrentUserBookings,
    stateCurrentUserTillaggBookings,
    stateCurrentUserProductBookings,
    stateSponsorBookings,
    stateSeminarBookings,
    stateSeminarLiveBookings,
    isDeleteClicked,
    isSaveClicked,
    loading,
    isOpen,
    stateReadonly,
  } = state;

  // Handle loading state with cleanup
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), INITIAL_LOADING_TIME);
    return () => clearTimeout(timer);
  }, []);

  // update state with props with proper cleanup
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setState((prevState) => {
        const updatedValues = {
          stateCurrentUserBookings: currentUserBookings || {},
          stateCurrentUserTillaggBookings: currentUserTillaggBookings || {},
          stateCurrentUserProductBookings: currentUserProductBookings || {},
          stateSeminarBookings: currentUserSeminars || {},
          stateSeminarLiveBookings: currentUserSeminarsLive || {},
          stateSponsorBookings: currentUserSponsor || {},
        };

        // Prevent unnecessary state updates
        if (
          Object.keys(updatedValues).every(
            (key) =>
              JSON.stringify(prevState[key]) ===
              JSON.stringify(updatedValues[key]),
          )
        ) {
          return prevState; // No changes, avoid re-render
        }

        return { ...prevState, ...updatedValues };
      });
    }

    return () => {
      isMounted = false;
    };
  }, [
    currentUserBookings,
    currentUserTillaggBookings,
    currentUserProductBookings,
    currentUserSeminars,
    currentUserSeminarsLive,
    currentUserSponsor,
  ]);

  const toggleState = (stateKey, key) => {
    if (key) {
      setState((prevState) => ({
        ...prevState,
        [stateKey]: {
          ...prevState[stateKey],
          [key]: !prevState[stateKey][key],
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [stateKey]: !prevState[stateKey],
      }));
    }
  };

  const toggleReadOnlyMode =
    (readOnlyId, bookingID = '') =>
    () => {
      if (isSaveClicked) {
        setState((prevState) => ({
          ...prevState,
          isSaveClicked: false,
        }));
      }

      if (bookingID && readOnlyId !== 'readOnlyCurrentUser') {
        setState((prevState) => ({
          ...prevState,
          [readOnlyId]: {
            ...prevState[readOnlyId],
            [bookingID]: !prevState[readOnlyId][bookingID],
          },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [readOnlyId]: !prevState[readOnlyId],
        }));
      }
    };

  const refetchData = ({ databasePath, reduxPath, isDelete = false }) => {
    const bookingPaths = [
      REDUX_PATHS_CURRENT_USER.currentUserSeminarsBookings,
      REDUX_PATHS_CURRENT_USER.currentUserSeminarsLiveBookings,
      REDUX_PATHS_CURRENT_USER.currentUserBookings,
      REDUX_PATHS_CURRENT_USER.currentUserTillaggBookings,
      REDUX_PATHS_CURRENT_USER.currentUserProductBookings,
      REDUX_PATHS_CURRENT_USER.currentUserSponsorBookings,
    ];

    if (bookingPaths.includes(reduxPath)) {
      firestore
        .get({
          collection: databasePath,
          path: reduxPath,
          where: [['branchId', '==', currentUserOrganisation?.branchId]],
        })
        .catch(console.error);
    }

    if (isFromAdminPage) {
      firestore
        .get({
          collection: databasePath,
          path: reduxPath,
        })
        .catch(console.error);
    }

    if (isDelete) {
      const eventBookings = [
        {
          collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
          path: FB_COLLECTIONS.seminarBookings,
        },
        {
          collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarLiveBookings,
          path: FB_COLLECTIONS.seminarLiveBookings,
        },
      ];

      eventBookings.forEach(({ collection, path }) => {
        firestore.get({ collection, path }).catch(console.error);
      });
    }
  };

  //  #region saveToDatabase
  const saveToDatabase = (args) => async () => {
    const {
      databasePath,
      bookingId,
      reduxPath,
      data,
      stateDataId,
      readOnlyId,
    } = args;

    toggleReadOnlyMode(readOnlyId, bookingId)();
    const { [stateDataId]: isDataChanged } = state;

    if (databasePath && !isEmpty(isDataChanged)) {
      const { firestore, firebase } = props;

      // console.log('data to be saved', data);

      setState((prevState) => ({
        ...prevState,
        loading: true,
        isSaveClicked: true,
      }));

      const cleanedData = clearDateBeforeSaving(data);

      const { discount, userId, deltagare } = data;

      const newDiscount = discount && Math.abs(discount);

      const newDataToSave = {
        ...cleanedData,
        discount: newDiscount || 0,
        updatedAt: new Date().toISOString(),
      };

      // if deltagare exists upload files and save url
      if (deltagare) {
        const deltagareWithUploads = [];

        for (const item of deltagare) {
          const { logo: logoLocal } = item || {};

          if (typeof logoLocal === 'object') {
            try {
              const downloadURL = await uploadFileToStorage({
                uid: userId,
                fileList: logoLocal,
                firebase,
              });

              if (downloadURL) {
                deltagareWithUploads.push({ ...item, logo: downloadURL });
              }
            } catch (error) {
              console.error('deltagare', error);
            } finally {
              console.info('deltagare', 'request finished');
            }
          } else {
            deltagareWithUploads.push(item);
          }
        }

        newDataToSave.deltagare = deltagareWithUploads;
      }

      const fileListItems = [];
      // Assuming 'data' is an object that includes all the file inputs
      Object.entries(data).forEach(([name, value]) => {
        if (value instanceof FileList) {
          fileListItems.push({ name, value });
        }
      });

      for (const item of fileListItems) {
        if (!item) {
          return;
        }

        const { name, value } = item;

        try {
          let downloadURL = '';

          // for user profile there is no userId
          if (name === 'logo' && !userId) {
            const tempUserId = userId || bookingId; // for user profile there is no userId

            downloadURL = await uploadFileToStorage({
              uploadPath: `uploads/company_logo/${tempUserId}`,
              uid: tempUserId,
              fileList: value,
              firebase,
            });
          } else {
            downloadURL = await uploadFileToStorage({
              uid: userId,
              fileList: value,
              firebase,
            });
          }

          if (downloadURL) {
            newDataToSave[name] = downloadURL || '';
          }
        } catch (error) {
          console.error(name, error);
        } finally {
          console.info(name, 'request finished');
        }
      }

      // console.log('Account newDataToSave', newDataToSave);

      // firebase save function
      firestore
        .set(
          {
            collection: databasePath,
            doc: bookingId,
            path: reduxPath || databasePath,
          },
          { ...newDataToSave },
          { merge: true },
        )
        .then(() => {
          console.info('saved successfully');
        })
        .catch((error) => {
          console.error(error);
          setState((prevState) => ({
            ...prevState,
            error,
          }));
        })
        .finally(() => {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));

          refetchData({
            databasePath,
            reduxPath,
          });
        });
    }
  };
  //  #endregion

  //  #region deleteFromDatabase
  const deleteFromDatabase = (args) => () => {
    const { databasePath, data, id, reduxPath, stateDataId } = args;

    // TODO:refactor to use modals

    const confirmDelete = window.confirm(
      'Är du säkert på att du vill ta bort din bokning?',
    );

    if (firestore && id && confirmDelete) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        isDeleteClicked: true,
      }));

      // add to deleted collection
      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.deletedBookings,
            doc: id,
          },
          {
            ...{ data },
            collection: BOOKING_COLLECTION_CURRENT_EVENT.deletedBookings,
            deletedAt: new Date().toISOString(),
          },
          { merge: true },
        )
        .then(() => {
          console.info('delete successfull');
        })
        .catch((err) => console.error('delete fail', err));

      // delete from database
      firestore
        .delete({
          collection: databasePath,
          doc: id,
          path: reduxPath,
        })
        .then(() => {
          console.info('Successfully deleted');
          setState((prevState) => ({
            ...prevState,
            [stateDataId]: {},
          }));

          refetchData({
            databasePath,
            reduxPath,
            isDelete: true,
          });
        })
        .catch((error) => {
          console.error(error);
          setState((prevState) => ({
            ...prevState,
            error,
          }));
        })
        .finally(() => {
          setState((prevState) => ({
            ...prevState,
            isDeleteClicked: false,
            ...INITIAL_STATE,
          }));
        });
    }
  };
  //  #endregion

  //  #region handleCancel
  const handleCancel = () => {
    const { registerState } = props;
    setState((prevState) => ({
      ...prevState,
      ...INITIAL_STATE,
    }));
    if (registerState) registerState();
  };
  //  #endregion

  /* handle booking change */
  const handleInputChange =
    (stateKey, id, deltagareIndex, objectKey) => (event) => {
      const {
        target: { name, value, files, type, checked },
      } = event;

      setState((prevState) => {
        const localState = cloneDeep(prevState);
        const fieldValue = type === 'checkbox' ? checked : files || value;

        const path = objectKey
          ? `${stateKey}.${id}.${objectKey}[${deltagareIndex}].${name}`
          : `${stateKey}.${id}.${name}`;

        set(localState, path, fieldValue);

        return {
          ...prevState,
          isSaveClicked: false,
          ...localState,
        };
      });
    };

  // Existing functions using handleInputChange

  const handleProductsBookingChange = (stateDataId, bookingId) =>
    handleInputChange(stateDataId, bookingId);

  const handleSponsorInputChange = (id, deltagareIndex, objectKey) =>
    handleInputChange('stateSponsorBookings', id, deltagareIndex, objectKey);

  const handleBookingChange = (id, deltagareIndex, objectKey) =>
    handleInputChange(
      'stateCurrentUserBookings',
      id,
      deltagareIndex,
      objectKey,
    );

  const handleSeminarInputChange = (id, deltagareIndex, objectKey) =>
    handleInputChange('stateSeminarBookings', id, deltagareIndex, objectKey);

  const handleSeminarLiveInputChange = (id, deltagareIndex, objectKey) =>
    handleInputChange(
      'stateSeminarLiveBookings',
      id,
      deltagareIndex,
      objectKey,
    );

  /* rendring bookings */

  //  #region renderCurrentUserBookings
  const renderCurrentUserBookings = (bookings) => {
    if (isEmpty(bookings)) return;

    // console.log('tents all bookings', bookings);

    const bookingKeys = Object.keys(bookings);

    // loops through all bookings
    return bookingKeys?.map((bookingId) => {
      const databasePath = BOOKING_COLLECTION_CURRENT_EVENT.bookings;
      const reduxPath = isFromUser
        ? REDUX_PATHS_CURRENT_USER.currentUserBookings
        : FB_COLLECTIONS.bookings;
      const currentReadOnly = !readOnlyCurrentUserBookings[bookingId];

      const stateDataId = 'stateCurrentUserBookings';
      const readOnlyId = 'readOnlyCurrentUserBookings';

      const newData = {
        ...bookings[bookingId],
        ...stateCurrentUserBookings[bookingId],
      };

      const {
        amne,
        tillganglighet,
        sprak,
        taltyta,
        cosponsors,
        cosponsorsCount,
        createdAt,
        discount,
        fullPeriodLabel,
        highlightsDay1,
        highlightsDay2,
        highlightsDay3,
        highlightsDay4,
        isFullPeriod,
        labelDay1,
        labelDay2,
        labelDay3,
        labelDay4,
        ovrigt,
        period1,
        period1Label,
        period2,
        period2Label,
        quantity,
        sentInvoice,
        invoiceCreatedAt,
        totalPrice,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2,
        erbjuderJobbLink,
        erbjuderStudierLink,
        erbjuderStudierAntal,
        erbjuderJobbAntal,
        erbjuderJobbCheckbox,
        erbjuderStudierCheckbox,
      } = newData;

      // console.log('amne', amne);

      let datum = '';

      if (isFullPeriod) {
        datum = fullPeriodLabel;
      } else if (period1) {
        datum = period1Label;
      } else if (period2) {
        datum = period2Label;
      }

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(totalPrice).toLocaleString(
            'sv',
          )})`}
        >
          <Form.Input
            max={totalPrice}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={isAdmin && !isFromUser ? currentReadOnly : true}
            onChange={handleBookingChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createdAt,
        sentInvoice,
        invoiceCreatedAt,
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                toggleState('isOpen', bookingId);
              }}
            >
              <TentLogo />
              Tält - Tältyta {taltyta || ''}
            </h3>
            <Form
              loading={loading}
              className="horizontal-container"
              onSubmit={saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId,
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Tält">{`Tältyta ${taltyta || ''}`}</li>
                    <li aria-label="Datum">{datum}</li>
                    <li aria-label="Antal">{quantity}</li>
                    <li aria-label="Pris">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>
                  <br />
                  <ul aria-label="Uppgifter för tältet">
                    <li>
                      <Form.TextArea
                        onChange={handleBookingChange(bookingId)}
                        readOnly={currentReadOnly}
                        label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                        maxLength="800"
                        id="ovrigt"
                        name="ovrigt"
                        value={ovrigt || ''}
                      />
                    </li>
                  </ul>
                  <br />
                  <ul aria-label="Erbjuder jobb/studier">
                    <li>
                      <Form.Checkbox
                        onChange={handleBookingChange(bookingId)}
                        readOnly={currentReadOnly}
                        id="erbjuderJobbCheckbox"
                        name="erbjuderJobbCheckbox"
                        checked={erbjuderJobbCheckbox}
                        label="Erbjuder jobb"
                      />
                    </li>

                    {erbjuderJobbCheckbox ? (
                      <>
                        <li>
                          <Form.Input
                            type="url"
                            onChange={handleBookingChange(bookingId)}
                            readOnly={currentReadOnly}
                            id="erbjuderJobbLink"
                            name="erbjuderJobbLink"
                            value={erbjuderJobbLink || ''}
                            placeholder="https://example.com"
                            label="Länk till erbjudanden"
                          />
                        </li>
                        <li>
                          <Form.Input
                            type="number"
                            min="1"
                            onChange={handleBookingChange(bookingId)}
                            readOnly={currentReadOnly}
                            id="erbjuderJobbAntal"
                            name="erbjuderJobbAntal"
                            value={erbjuderJobbAntal || ''}
                            label="Antal erbjudanden"
                          />
                        </li>
                      </>
                    ) : (
                      ''
                    )}
                    <li>
                      <Form.Checkbox
                        onChange={handleBookingChange(bookingId)}
                        readOnly={currentReadOnly}
                        id="erbjuderStudierCheckbox"
                        name="erbjuderStudierCheckbox"
                        checked={erbjuderStudierCheckbox}
                        label="Erbjuder studier"
                      />
                    </li>
                    {erbjuderStudierCheckbox ? (
                      <>
                        <li>
                          <Form.Input
                            type="url"
                            onChange={handleBookingChange(bookingId)}
                            readOnly={currentReadOnly}
                            id="erbjuderStudierLink"
                            name="erbjuderStudierLink"
                            value={erbjuderStudierLink || ''}
                            placeholder="https://example.com"
                            label="Länk till erbjudna studier"
                          />
                        </li>
                        <li>
                          <Form.Input
                            type="number"
                            min="1"
                            onChange={handleBookingChange(bookingId)}
                            readOnly={currentReadOnly}
                            id="erbjuderStudierAntal"
                            name="erbjuderStudierAntal"
                            value={erbjuderStudierAntal || ''}
                            label="Antal erbjudna studier"
                          />
                        </li>
                      </>
                    ) : (
                      ''
                    )}
                  </ul>

                  <br />
                  <ul aria-label="Kategorityp">
                    <li>
                      <p>Ämne</p>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(_, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleBookingChange(bookingId)({
                            target,
                          });
                        }}
                        disabled={currentReadOnly}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                    </li>
                    <li>
                      <p>Tillgänglighet</p>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        onChange={(_, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleBookingChange(bookingId)({
                            target,
                          });
                        }}
                        disabled={currentReadOnly}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                    </li>
                    <li>
                      <p>Språk</p>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(_, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleBookingChange(bookingId)({
                            target,
                          });
                        }}
                        disabled={currentReadOnly}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />
                    </li>
                  </ul>
                  <br />
                  <ul aria-label="Extern länk för anmälan.">
                    <li>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={currentReadOnly}
                        onChange={handleBookingChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        type="url"
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                    </li>
                  </ul>
                  <br />
                  <ul aria-label="KONTAKTPERSONER FÖR TÄLTET">
                    {/* contact 1 */}
                    <li>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              onChange={handleBookingChange(bookingId)}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={handleBookingChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={handleBookingChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="20"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>
                  <br />
                  <ul aria-label="KONTAKTPERSONER FÖR TÄLTET">
                    {/* contact 1 */}
                    <li>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              onChange={handleBookingChange(bookingId)}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={handleBookingChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={handleBookingChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="20"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>

                    {/* contact 2 */}
                    <li>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              onChange={handleBookingChange(bookingId)}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={handleBookingChange(bookingId)}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={handleBookingChange(bookingId)}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="20"
                          onChange={handleBookingChange(bookingId)}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>
                  <br />
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Highlights / Händer i tältet">
                    <li>
                      <TextCopy name="highlites-text-copy" />
                      <br />
                      <br />
                    </li>
                    {period1 && (
                      <>
                        <li
                          aria-label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay1"
                            value={highlightsDay1 || ''}
                            readOnly={currentReadOnly}
                            onChange={handleBookingChange(bookingId)}
                          />
                        </li>
                        <li
                          aria-label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay2"
                            value={highlightsDay2 || ''}
                            readOnly={currentReadOnly}
                            onChange={handleBookingChange(bookingId)}
                          />
                        </li>
                      </>
                    )}
                    {period2 && (
                      <>
                        <li
                          aria-label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay3"
                            value={highlightsDay3 || ''}
                            readOnly={currentReadOnly}
                            onChange={handleBookingChange(bookingId)}
                          />
                        </li>
                        <li
                          aria-label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                        >
                          <Form.TextArea
                            maxLength="330"
                            className="short"
                            rows="2"
                            name="highlightsDay4"
                            value={highlightsDay4 || ''}
                            readOnly={currentReadOnly}
                            onChange={handleBookingChange(bookingId)}
                          />
                        </li>
                      </>
                    )}
                  </ul>

                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />
                      <br />
                      <br />
                      {/* render cosponsors */}
                      {map(cosponsors, (value, id) => {
                        if (value === undefined) {
                          return null;
                        }
                        const {
                          hemsida: itemHemsida,
                          organisationsnamn,
                          organisationsnummer,
                        } = value || {};

                        return (
                          <fieldset key={id}>
                            <legend>Medarrangör</legend>
                            {currentReadOnly ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setState((prevState) => {
                                      const localState = cloneDeep(prevState);

                                      unset(
                                        localState,
                                        `stateCurrentUserBookings.${bookingId}.cosponsors.${id}`,
                                      );

                                      set(
                                        localState,
                                        `stateCurrentUserBookings.${bookingId}.cosponsorsCount`,
                                        cosponsorsCount - 1,
                                      );

                                      return {
                                        ...prevState,
                                        ...localState,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={handleBookingChange(
                                bookingId,
                                id,
                                'cosponsors',
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={handleBookingChange(
                                bookingId,
                                id,
                                'cosponsors',
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnummer (i formatet 000000-0000)"
                              maxLength="20"
                              minLength="11"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                              // pattern="^\d{6}-\d{4}$|^\d{3}\s?\d{3}\s?\d{3}$|^\d{8}$|^\d{7}-\d{1}$"
                            />

                            <Form.Input
                              onChange={handleBookingChange(
                                bookingId,
                                id,
                                'cosponsors',
                              )}
                              readOnly={currentReadOnly}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={itemHemsida || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {currentReadOnly ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            setState((prevState) => {
                              const localState = cloneDeep(prevState);

                              const newItemKey = `s${cosponsorsCount + 1}`;

                              set(
                                localState,
                                `stateCurrentUserBookings.${bookingId}.cosponsors`,
                                {
                                  ...cosponsors,
                                  [newItemKey]: { id: newItemKey },
                                },
                              );

                              set(
                                localState,
                                `stateCurrentUserBookings.${bookingId}.cosponsorsCount`,
                                cosponsorsCount + 1,
                              );

                              return {
                                ...prevState,
                                ...localState,
                              };
                            });
                          }}
                          // disabled={size(cosponsors) >= 4}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Row verticalAlign="top">
                  <Grid.Column width={16}>
                    <p>
                      <WarnLogo />
                      {daysLeftText}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {currentReadOnly ? (
                      <div className="flex no-gap">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            toggleReadOnlyMode(
                              'readOnlyCurrentUserBookings',
                              bookingId,
                              'stateCurrentUserBookings',
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        <KontaktaWorkman />
                        {(!sentInvoice && daysLeft(createdAt) > 0) ||
                        isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              deleteFromDatabase({
                                databasePath,
                                data: newData,
                                id: bookingId,
                                reduxPath,
                                stateDataId: `stateCurrentUserBookings.${bookingId}`,
                              })();
                            }}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Spara"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          basic
                          primary
                          type="button"
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCancel();
                          }}
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderCurrentUserTillaggBookings
  const renderCurrentUserTillaggBookings = (bookings) => {
    if (isEmpty(bookings)) return;

    // console.log('tents all bookings', bookings);

    const bookingKeys = Object.keys(bookings);

    // loops through all bookings
    return bookingKeys?.map((bookingId) => {
      const databasePath = BOOKING_COLLECTION_CURRENT_EVENT.tillaggBookings;
      const reduxPath = isFromUser
        ? REDUX_PATHS_CURRENT_USER.currentUserTillaggBookings
        : FB_COLLECTIONS.tillaggBookings;
      const currentReadOnly = !readOnlyCurrentUserTillaggBookings?.[bookingId];

      const stateDataId = 'stateCurrentUserTillaggBookings';
      const readOnlyId = 'readOnlyCurrentUserTillaggBookings';

      const newData = {
        ...bookings[bookingId],
        ...stateCurrentUserTillaggBookings[bookingId],
      };

      const {
        title,
        createdAt,
        discount,
        quantity,
        sentInvoice,
        invoiceCreatedAt,
        totalPrice,
      } = newData;

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(totalPrice).toLocaleString(
            'sv',
          )})`}
        >
          <Form.Input
            max={totalPrice}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={isAdmin && !isFromUser ? currentReadOnly : true}
            onChange={handleProductsBookingChange(stateDataId, bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createdAt,
        sentInvoice,
        invoiceCreatedAt,
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                toggleState('isOpen', bookingId);
              }}
            >
              <StarLogo /> Tillägg - {title || ''}
            </h3>
            <Form
              loading={loading}
              className="horizontal-container"
              onSubmit={saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId,
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Antal">{quantity}</li>
                    <li aria-label="Pris">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>
                </Grid.Column>
                <Grid.Column />
                <Grid.Row verticalAlign="top">
                  <Grid.Column width={16}>
                    <p>
                      <WarnLogo />
                      {daysLeftText}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className="flex no-gap">
                      {(!sentInvoice && daysLeft(createdAt) > 0) || isAdmin ? (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            deleteFromDatabase({
                              databasePath,
                              data: newData,
                              id: bookingId,
                              reduxPath,
                              stateDataId: `${stateDataId}.${bookingId}`,
                            })();
                          }}
                          disabled={isDeleteClicked}
                          content="Ta bort bokning"
                          icon={{ name: 'delete', color: 'red' }}
                          basic
                          primary
                          labelPosition="right"
                        />
                      ) : null}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderCurrentUserProductBookings
  const renderCurrentUserProductBookings = (bookings) => {
    if (isEmpty(bookings)) return;

    // console.log('tents all bookings', bookings);

    const bookingKeys = Object.keys(bookings);

    // loops through all bookings
    return bookingKeys?.map((bookingId) => {
      const databasePath = BOOKING_COLLECTION_CURRENT_EVENT.productBookings;
      const reduxPath = isFromUser
        ? REDUX_PATHS_CURRENT_USER.currentUserProductBookings
        : FB_COLLECTIONS.productBookings;
      const currentReadOnly = !readOnlyCurrentUserProductBookings?.[bookingId];

      const stateDataId = 'stateCurrentUserProductBookings';
      const readOnlyId = 'readOnlyCurrentUserProductBookings';

      const newData = {
        ...bookings[bookingId],
        ...stateCurrentUserProductBookings[bookingId],
      };

      const {
        title,
        createdAt,
        discount,
        quantity,
        sentInvoice,
        invoiceCreatedAt,
        totalPrice,
        productId,
      } = newData;

      let formFields = [];

      switch (productId) {
        case 'foodtruck':
          formFields = foodtruckFormInputs;
          break;
        case 'torghandel':
          formFields = torghandelFormInputs;
          break;
        default:
          formFields = defaultFormFields;
          break;
      }

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(totalPrice).toLocaleString(
            'sv',
          )})`}
        >
          <Form.Input
            max={totalPrice}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={isAdmin && !isFromUser ? currentReadOnly : true}
            onChange={handleProductsBookingChange(stateDataId, bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createdAt,
        sentInvoice,
        invoiceCreatedAt,
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                toggleState('isOpen', bookingId);
              }}
            >
              <StarLogo /> {title || ''}
            </h3>
            <Form
              loading={loading}
              className="horizontal-container"
              onSubmit={saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId,
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Antal">{quantity}</li>
                    <li aria-label="Pris">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    {formFields.map(
                      ({ type, label, name, required, labelInf }) => {
                        switch (type) {
                          case 'textarea':
                            return (
                              <li aria-label={label}>
                                <Form.TextArea
                                  maxLength="330"
                                  label={label}
                                  id={name}
                                  name={name}
                                  value={newData[name] || ''}
                                  readOnly={currentReadOnly}
                                  required={required}
                                  onChange={handleProductsBookingChange(
                                    stateDataId,
                                    bookingId,
                                  )}
                                />
                              </li>
                            );

                          case 'file': {
                            return (
                              <li aria-label={label}>
                                <Form.Field>
                                  <label htmlFor={name}>
                                    <span className="upload-note">
                                      {labelInf}
                                    </span>
                                    <span className="upload-note">
                                      * allowed: svg,png - max size 1MB
                                    </span>
                                    <Form.Input
                                      type="file"
                                      id={name}
                                      name={name}
                                      accept="image/*,.svg"
                                      disabled={currentReadOnly}
                                      onChange={handleProductsBookingChange(
                                        stateDataId,
                                        bookingId,
                                      )}
                                    />
                                  </label>
                                  <FileOrImagePreview
                                    img={newData[name] || ''}
                                  />
                                </Form.Field>
                              </li>
                            );
                          }

                          default:
                            return (
                              <li aria-label={label}>
                                <Form.Input
                                  readOnly={currentReadOnly}
                                  onChange={handleProductsBookingChange(
                                    stateDataId,
                                    bookingId,
                                  )}
                                  label={label}
                                  id={name}
                                  name={name}
                                  value={newData[name] || ''}
                                />
                              </li>
                            );
                        }
                      },
                    )}
                  </ul>
                </Grid.Column>
                <Grid.Row verticalAlign="top">
                  <Grid.Column width={16}>
                    <p>
                      <WarnLogo />
                      {daysLeftText}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {currentReadOnly ? (
                      <div className="flex no-gap">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            toggleReadOnlyMode(
                              readOnlyId,
                              bookingId,
                              stateDataId,
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        {(!sentInvoice && daysLeft(createdAt) > 0) ||
                        isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              deleteFromDatabase({
                                databasePath,
                                data: newData,
                                id: bookingId,
                                reduxPath,
                                stateDataId,
                              })();
                            }}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Bekräfta"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCancel();
                          }}
                          basic
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderSponsorBookings
  const renderSponsorBookings = (bookings) => {
    if (isEmpty(bookings)) return false;

    const bookingKeys = Object.keys(bookings);

    return bookingKeys?.map((bookingId) => {
      const databasePath = BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings;
      const reduxPath = isFromUser
        ? REDUX_PATHS_CURRENT_USER.currentUserSponsorBookings
        : FB_COLLECTIONS.sponsorBookings;
      const currentReadOnly = !readOnlyrenderSponsorBookings[bookingId];

      const stateDataId = 'stateSponsorBookings';
      const readOnlyId = 'readOnlyrenderSponsorBookings';

      const newData = {
        ...bookings[bookingId],
        ...stateSponsorBookings[bookingId],
      };

      const {
        amne,
        tillganglighet,
        sprak,
        taltyta,
        cosponsors,
        cosponsorsCount,
        createdAt,
        fullPeriodLabel,
        highlightsDay1,
        highlightsDay2,
        highlightsDay3,
        highlightsDay4,
        labelDay1,
        labelDay2,
        labelDay3,
        labelDay4,
        ovrigt,
        pris,
        sentInvoice,
        invoiceCreatedAt,
        logo,
        discount = 0,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2,
        productId,
      } = newData;

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(pris).toLocaleString('sv')})`}
        >
          <Form.Input
            max={pris}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={isAdmin && !isFromUser ? currentReadOnly : true}
            onChange={handleSponsorInputChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createdAt,
        sentInvoice,
        invoiceCreatedAt,
      });

      const bookingTitle =
        productId === 'sponsor' ? 'Stödorganisation' : 'Partner';

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                toggleState('isOpen', bookingId);
              }}
            >
              <TentLogo /> {bookingTitle}
            </h3>
            <Form
              loading={loading}
              onSubmit={saveToDatabase({
                databasePath,
                data: newData,
                stateDataId,
                readOnlyId,
                reduxPath,
                bookingId,
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Tält">{`Tältyta ${taltyta || ''}`}</li>
                    <li aria-label="Datum">{fullPeriodLabel}</li>
                    <li aria-label="Pris">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>
                  <br />
                  <ul aria-label="Uppgifter för tältet">
                    <li>
                      <Form.TextArea
                        readOnly={currentReadOnly}
                        onChange={handleSponsorInputChange(bookingId)}
                        label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                        maxLength="800"
                        id="ovrigt"
                        name="ovrigt"
                        value={ovrigt || ''}
                      />
                      <Form.Field>
                        <label htmlFor="logo">
                          Bifoga organisationens logotyp
                          <span className="upload-note">
                            * allowed: jpg, jpeg, png, svg - max size 5MB
                          </span>
                          <Form.Input
                            type="file"
                            id="logo"
                            name="logo"
                            accept="image/*,.svg"
                            disabled={currentReadOnly}
                            onChange={handleSponsorInputChange(bookingId)}
                          />
                        </label>
                        <i className="note">* This is the uploaded file</i>
                        <br />
                        {typeof logo === 'string' ? (
                          <img
                            className="uploads-preview"
                            src={logo}
                            alt="logo"
                          />
                        ) : null}
                      </Form.Field>
                      <h5>Ämne</h5>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSponsorInputChange(bookingId)({
                            target,
                          });
                        }}
                        disabled={currentReadOnly}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                      <br />
                      <br />
                      <h5>Tillgänglighet</h5>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSponsorInputChange(bookingId)({
                            target,
                          });
                        }}
                        disabled={currentReadOnly}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                      <h5>Språk</h5>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSponsorInputChange(bookingId)({
                            target,
                          });
                        }}
                        disabled={currentReadOnly}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />

                      <br />
                      <br />
                      <h5>Extern länk för anmälan.</h5>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={currentReadOnly}
                        onChange={handleSponsorInputChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        type="url"
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                      <br />
                      <br />

                      <h5>KONTAKTPERSONER FÖR TÄLTET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      {/* contact 1 */}
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              onChange={handleSponsorInputChange(
                                bookingId
                              )}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="20"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      {/* contact 2 */}
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              onChange={handleSponsorInputChange(
                                bookingId
                              )}
                              disabled={currentReadOnly}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="120"

                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          readOnly={currentReadOnly}
                          maxLength="330"

                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="140"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          readOnly={currentReadOnly}
                          maxLength="20"
                          onChange={handleSponsorInputChange(bookingId)}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Highlights / Händer i tältet">
                    <li>
                      <TextCopy name="highlites-text-copy" />
                    </li>
                    <li
                      aria-label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay1"
                        value={highlightsDay1 || ''}
                        readOnly={currentReadOnly}
                        onChange={handleSponsorInputChange(bookingId)}
                      />
                    </li>
                    <li
                      aria-label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay2"
                        value={highlightsDay2 || ''}
                        readOnly={currentReadOnly}
                        onChange={handleSponsorInputChange(bookingId)}
                      />
                    </li>
                    <li
                      aria-label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay3"
                        value={highlightsDay3 || ''}
                        readOnly={currentReadOnly}
                        onChange={handleSponsorInputChange(bookingId)}
                      />
                    </li>
                    <li
                      aria-label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                    >
                      <Form.TextArea
                        maxLength="330"
                        type="text"
                        name="highlightsDay4"
                        value={highlightsDay4 || ''}
                        readOnly={currentReadOnly}
                        onChange={handleSponsorInputChange(bookingId)}
                      />
                    </li>
                  </ul>
                  <br />
                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />

                      <br />
                      <br />
                      {/* render cosponsors */}
                      {map(cosponsors, (value, key) => {
                        if (value === undefined) {
                          return null;
                        }
                        const {
                          hemsida,
                          organisationsnamn,
                          organisationsnummer,
                        } = value || {};

                        return (
                          <fieldset key={key}>
                            <legend>Medarrangör</legend>

                            {currentReadOnly ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setState((prevState) => {
                                      const localState = cloneDeep(prevState);

                                      unset(
                                        localState,
                                        `stateSponsorBookings.${bookingId}.cosponsors.${key}`,
                                      );

                                      set(
                                        localState,
                                        `stateSponsorBookings.${bookingId}.cosponsorsCount`,
                                        cosponsorsCount - 1,
                                      );

                                      return {
                                        ...prevState,
                                        ...localState,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={handleSponsorInputChange(
                                bookingId,
                                key,
                                'cosponsors',
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={handleSponsorInputChange(
                                bookingId,
                                key,
                                'cosponsors',
                              )}
                              readOnly={currentReadOnly}
                              label="Organisationsnummer (i formatet 000000-0000)"
                              maxLength="20"
                              minLength="11"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                              // pattern="^\d{6}-\d{4}$|^\d{3}\s?\d{3}\s?\d{3}$|^\d{8}$|^\d{7}-\d{1}$"
                            />

                            <Form.Input
                              onChange={handleSponsorInputChange(
                                bookingId,
                                key,
                                'cosponsors',
                              )}
                              readOnly={currentReadOnly}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={hemsida || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {currentReadOnly ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            setState((prevState) => {
                              const localState = cloneDeep(prevState);

                              const newItemKey = `s${cosponsorsCount + 1}`;

                              set(
                                localState,
                                `stateSponsorBookings.${bookingId}.cosponsors`,
                                {
                                  ...cosponsors,
                                  [newItemKey]: { id: newItemKey },
                                },
                              );

                              set(
                                localState,
                                `stateSponsorBookings.${bookingId}.cosponsorsCount`,
                                cosponsorsCount + 1,
                              );

                              return {
                                ...prevState,
                                ...localState,
                              };
                            });
                          }}
                          // disabled={size(cosponsors) >= 4}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>

                <Grid.Row>
                  <p>
                    <WarnLogo />
                    {daysLeftText}
                  </p>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {currentReadOnly ? (
                      <div className="flex no-gap">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            toggleReadOnlyMode(
                              'readOnlyrenderSponsorBookings',
                              bookingId,
                              'staterenderSponsorBookings',
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        <KontaktaWorkman />
                        {daysLeft(createdAt) >= 0 || isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              deleteFromDatabase({
                                databasePath,
                                data: newData,
                                id: bookingId,
                                reduxPath,
                                stateDataId: 'stateSponsorBookings',
                              })();
                            }}
                            loading={loading}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Bekräfta"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCancel();
                          }}
                          basic
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderSeminarBookings
  const renderSeminarBookings = (seminar) => {
    if (isEmpty(seminar)) return;

    const databasePath = BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings;
    const reduxPath = isFromUser
      ? REDUX_PATHS_CURRENT_USER.currentUserSeminarsBookings
      : FB_COLLECTIONS.seminarBookings;

    return Object.keys(seminar)?.map((bookingId) => {
      const readOnlySeminarBookings = !(stateReadonly[bookingId] || '');
      const currentBooking = seminar[bookingId];

      const stateDataId = 'stateSeminarBookings';
      const readOnlyId = 'readOnlySeminarBookings';

      // [stateDataId][bookingId]
      const newData = {
        ...currentBooking,
        ...stateSeminarBookings[bookingId],
      };

      const {
        beskriv,
        coArrangers,
        createdAt,
        dayString,
        deltagare,
        discount,
        amne,
        tillganglighet,
        sprak,
        moderator,
        moderatorBild,
        moderatorBio,
        moderatorFotograf,
        moderatorJobbtitel,
        moderatorOrganisation,
        namn,
        pris,
        sentInvoice,
        invoiceCreatedAt,
        timeSlot,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2,
      } = newData;

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(pris).toLocaleString('sv')})`}
        >
          <Form.Input
            max={Number(pris).toLocaleString('sv')}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={isAdmin && !isFromUser ? readOnlySeminarBookings : true}
            onChange={handleSeminarInputChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createdAt,
        sentInvoice,
        invoiceCreatedAt,
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                toggleState('isOpen', bookingId);
              }}
            >
              <SeminarLogo /> Seminarietid — {dayString} ({timeSlot})
            </h3>
            <Form
              loading={loading}
              onSubmit={saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId,
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Datum">{dayString}</li>
                    <li aria-label="Tid">{timeSlot}</li>
                    <li aria-label="Pris">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>

                  <ul aria-label="Uppgifter för seminariet">
                    <li>
                      <Form.Input
                        maxLength="90"
                        readOnly={readOnlySeminarBookings}
                        onChange={handleSeminarInputChange(bookingId)}
                        label="Namn på seminariet (max 90 tecken inkl. blanksteg)"
                        id="Namn"
                        name="namn"
                        value={namn || ''}
                        required
                        autoComplete="off"
                      />
                      <Form.TextArea
                        readOnly={readOnlySeminarBookings}
                        onChange={handleSeminarInputChange(bookingId)}
                        id="beskriv"
                        name="beskriv"
                        label="Beskrivning av seminariet (max 800 tecken inkl. blanksteg) "
                        maxLength="800"
                        value={beskriv || ''}
                        autoComplete="off"
                      />

                      <br />
                      <br />
                      <h5>
                        Välj de ämnen, språk och tillgänglighetsanpassningar som
                        passar in på ert seminarium. det går att välja flera
                        alternativ.
                      </h5>

                      <p>Ämne</p>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        label="Ämne"
                        disabled={readOnlySeminarBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSeminarInputChange(bookingId)({
                            target,
                          });
                        }}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                      <p>Tillgänglighet</p>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        label="Tillgänglighet"
                        disabled={readOnlySeminarBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSeminarInputChange(bookingId)({ target });
                        }}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                      <p>Språk</p>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        label="Språk"
                        multiple
                        disabled={readOnlySeminarBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSeminarInputChange(bookingId)({ target });
                        }}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />
                    </li>

                    <li>
                      <h5>Extern länk för anmälan.</h5>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={readOnlySeminarBookings}
                        onChange={handleSeminarInputChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        type="url"
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                    </li>

                    <li>
                      <h5>Moderator</h5>
                      <fieldset>
                        <legend>Moderator</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="moderator"
                          name="moderator"
                          value={moderator || ''}
                          label="Namn på moderator"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="moderatorJobbtitel"
                          name="moderatorJobbtitel"
                          value={moderatorJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="moderatorOrganisation"
                          name="moderatorOrganisation"
                          value={moderatorOrganisation || ''}
                          label="Organisation"
                        />
                        <Form.TextArea
                          maxLength="330"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="moderatorBio"
                          name="moderatorBio"
                          value={moderatorBio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        />
                        <Form.Field>
                          <label htmlFor="moderatorBild">
                            Lägg upp profilbild
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="moderatorBild"
                              name="moderatorBild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarBookings}
                              onChange={handleSeminarInputChange(bookingId)}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof moderatorBild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={moderatorBild}
                              alt="moderatorBild"
                            />
                          ) : null}
                          <Form.Input
                            maxLength="120"
                            readOnly={readOnlySeminarBookings}
                            onChange={handleSeminarInputChange(bookingId)}
                            id="moderatorFotograf"
                            name="moderatorFotograf"
                            value={moderatorFotograf || ''}
                            label="Fotograf/bildkälla"
                          />
                        </Form.Field>
                      </fieldset>
                    </li>
                    <li>
                      <h5>KONTAKTPERSONER FÖR SEMINARIET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      {/* contact 1 */}
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarBookings}
                              onChange={handleSeminarInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      {/* contact 2 */}
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild2">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarBookings}
                              onChange={handleSeminarInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          readOnly={readOnlySeminarBookings}
                          onChange={handleSeminarInputChange(bookingId)}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />
                      <br />
                      <br />
                      {/* render coArrangers */}
                      {map(coArrangers, (value, key) => {
                        if (value === undefined) {
                          return;
                        }

                        const {
                          hemsida: hemsidaItem,
                          organisationsnamn,
                          organisationsnummer,
                        } = value || {};

                        return (
                          <fieldset key={key}>
                            <legend>Medarrangör</legend>

                            {readOnlySeminarBookings ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setState((prevState) => {
                                      const localData = cloneDeep(prevState);

                                      unset(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers.${key}`,
                                      );

                                      const coArrangersLocal = get(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`,
                                      );

                                      const newArray = coArrangersLocal?.filter(
                                        (item) => {
                                          return !!item;
                                        },
                                      );

                                      set(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`,
                                        [...newArray],
                                      );

                                      return {
                                        ...prevState,
                                        ...localData,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={(event, target) =>
                                handleSeminarInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers',
                                )({ target })
                              }
                              readOnly={readOnlySeminarBookings}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                handleSeminarInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers',
                                )({ target })
                              }
                              readOnly={readOnlySeminarBookings}
                              label="Organisationsnummer (i formatet 000000-0000)"
                              maxLength="20"
                              minLength="11"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                              // pattern="^\d{6}-\d{4}$|^\d{3}\s?\d{3}\s?\d{3}$|^\d{8}$|^\d{7}-\d{1}$"
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                handleSeminarInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers',
                                )({ target })
                              }
                              readOnly={readOnlySeminarBookings}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={hemsidaItem || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {readOnlySeminarBookings ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const coArrangersLocal = get(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`,
                              );

                              coArrangersLocal.push({});

                              const newArray = coArrangersLocal?.filter(
                                (item) => {
                                  return !!item;
                                },
                              );

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`,
                                [...newArray],
                              );

                              return {
                                ...prevState,
                                ...localData,
                              };
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Paneldeltagare">
                    <li>
                      {map(deltagare, (item, index) => {
                        if (item === undefined) {
                          return null;
                        }
                        const {
                          beskrivning,
                          deltagare: deltagareItem,
                          fotografCaption,
                          jobbTitel,
                          logo,
                          organisation,
                        } = item || {};
                        return (
                          <Form.Group key={index} grouped>
                            <fieldset>
                              <legend>Paneldeltagare {`${index + 1}`}</legend>
                              {readOnlySeminarBookings ? (
                                ''
                              ) : (
                                <div style={{ textAlign: 'right' }}>
                                  <Icon
                                    name="trash alternate outline"
                                    color="red"
                                    size="large"
                                    link
                                    onClick={(e) => {
                                      e.preventDefault();

                                      setState((prevState) => {
                                        const localData = cloneDeep(prevState);

                                        const localStateCopy = get(
                                          localData,
                                          `${stateDataId}.${bookingId}`,
                                        );

                                        // delete localStateCopy.deltagare[index];

                                        localStateCopy.deltagare.splice(
                                          index,
                                          1,
                                        );

                                        const newState = {
                                          [stateDataId]: {
                                            ...prevState[stateDataId],
                                            [bookingId]: localStateCopy,
                                          },
                                        };

                                        return {
                                          ...prevState,
                                          ...newState,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              )}
                              <Form.Input
                                maxLength="120"
                                label="Namn på deltagare"
                                type="text"
                                name="deltagare"
                                value={deltagareItem || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Field>
                                <label htmlFor="logo">
                                  Lägg upp profilbild
                                  <span className="upload-note">
                                    (* allowed: jpg, jpeg, png, svg - max size
                                    5MB)
                                  </span>
                                  <Form.Input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    accept="image/*,.svg"
                                    disabled={readOnlySeminarBookings}
                                    onChange={(event) =>
                                      handleSeminarInputChange(
                                        bookingId,
                                        index,
                                        'deltagare',
                                      )(event)
                                    }
                                  />
                                </label>
                                <i className="note">
                                  * This is the uploaded file
                                </i>
                                <br />
                                {typeof logo === 'string' ? (
                                  <img
                                    className="uploads-preview"
                                    src={logo}
                                    alt="logo"
                                  />
                                ) : null}
                              </Form.Field>
                              <Form.Input
                                maxLength="120"
                                label="Fotograf/bildkälla"
                                type="text"
                                name="fotografCaption"
                                value={fotografCaption || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Jobbtitel"
                                type="text"
                                name="jobbTitel"
                                value={jobbTitel || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Organisation"
                                type="text"
                                name="organisation"
                                value={organisation || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.TextArea
                                maxLength="330"
                                label="Kort beskrivning om deltagaren (max 330 tecken)"
                                name="beskrivning"
                                value={beskrivning || ''}
                                readOnly={readOnlySeminarBookings}
                                onChange={(event, target) =>
                                  handleSeminarInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                              />
                            </fieldset>
                          </Form.Group>
                        );
                      })}

                      {!readOnlySeminarBookings && (
                        <Button
                          secondary
                          size="tiny"
                          content="Lägg till deltagare"
                          icon="plus user"
                          labelPosition="right"
                          onClick={(e) => {
                            e.preventDefault();
                            setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const deltagareArray = get(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`,
                              );

                              deltagareArray.push({});

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`,
                                deltagareArray,
                              );

                              return {
                                ...prevState,
                                ...localData,
                              };
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Row>
                  <p>
                    <WarnLogo />
                    {daysLeftText}
                  </p>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {readOnlySeminarBookings ? (
                      <div className="flex no-gap">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            toggleReadOnlyMode(
                              'readOnlySeminarBookings',
                              bookingId,
                              'stateSeminarBookings',
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        {(!sentInvoice && daysLeft(createdAt) > 0) ||
                        isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              deleteFromDatabase({
                                databasePath,
                                data: currentBooking,
                                id: bookingId,
                                reduxPath,

                                stateDataId: 'stateSeminarBookings',
                              })();
                            }}
                            loading={loading}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Spara"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleCancel();
                          }}
                          type="button"
                          basic
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  //  #region renderSeminarLiveBookings
  const renderSeminarLiveBookings = (seminar) => {
    if (isEmpty(seminar)) return;

    const reduxPath = isFromUser
      ? REDUX_PATHS_CURRENT_USER.currentUserSeminarsLiveBookings
      : FB_COLLECTIONS.seminarLiveBookings;
    const databasePath = BOOKING_COLLECTION_CURRENT_EVENT.seminarLiveBookings;

    return Object.keys(seminar)?.map((bookingId) => {
      const readOnlySeminarLiveBookings = !(stateReadonly[bookingId] || '');
      const currentBooking = seminar[bookingId];

      const stateDataId = 'stateSeminarLiveBookings';
      const readOnlyId = 'readOnlySeminarLiveBookings';

      const newData = {
        ...currentBooking,
        ...stateSeminarLiveBookings[bookingId],
      };

      const {
        beskriv,
        coArrangers,
        createdAt,
        dayString,
        deltagare,
        discount,
        amne,
        tillganglighet,
        sprak,
        moderator,
        moderatorBild,
        moderatorBio,
        moderatorFotograf,
        moderatorJobbtitel,
        moderatorOrganisation,
        namn,
        pris,
        sentInvoice,
        invoiceCreatedAt,
        timeSlot,
        reservationSiteLink,
        kontaktperson,
        kontaktJobbtitel,
        kontaktOrganisation,
        kontaktEmail,
        kontaktTel,
        kontaktperson2,
        kontaktJobbtitel2,
        kontaktOrganisation2,
        kontaktEmail2,
        kontaktTel2,
      } = newData;

      const liDiscount = (
        <li
          aria-label={`Rabatt i kr (max ${Number(pris).toLocaleString('sv')})`}
        >
          <Form.Input
            max={pris}
            min="0"
            type="number"
            onWheel={(e) => e.target.blur()}
            name="discount"
            value={discount}
            readOnly={
              isAdmin && !isFromUser ? readOnlySeminarLiveBookings : true
            }
            onChange={handleSeminarLiveInputChange(bookingId)}
          />
        </li>
      );

      const showDiscount =
        isFromUser && discount ? liDiscount : !isFromUser ? liDiscount : '';

      const daysLeftText = getSaysLeftText({
        createdAt,
        sentInvoice,
        invoiceCreatedAt,
      });

      return (
        <WhiteBox
          key={bookingId}
          className={isOpen[bookingId] ? 'collapsable' : 'collapsable closed'}
        >
          <DataBox>
            <h3
              onClick={(e) => {
                e.preventDefault();
                toggleState('isOpen', bookingId);
              }}
            >
              <SeminarLogo /> Seminarietid — livesändning — {dayString} (
              {timeSlot})
            </h3>
            <Form
              loading={loading}
              onSubmit={saveToDatabase({
                databasePath,
                reduxPath,
                data: newData,
                bookingId,
                stateDataId,
                readOnlyId,
              })}
            >
              <Grid stackable columns={2}>
                <Grid.Column>
                  <ul aria-label="Specifikation">
                    <li aria-label="Datum">{dayString}</li>
                    <li aria-label="Tid">{timeSlot}</li>
                    <li aria-label="Pris">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </li>
                    {showDiscount}
                  </ul>

                  <ul aria-label="Uppgifter för seminariet">
                    <li>
                      <Form.Input
                        maxLength="90"
                        readOnly={readOnlySeminarLiveBookings}
                        onChange={handleSeminarLiveInputChange(bookingId)}
                        label="Namn på seminariet (max 90 tecken inkl. blanksteg)"
                        id="Namn"
                        name="namn"
                        value={namn || ''}
                        required
                        autoComplete="off"
                      />
                      <Form.TextArea
                        readOnly={readOnlySeminarLiveBookings}
                        onChange={handleSeminarLiveInputChange(bookingId)}
                        id="beskriv"
                        name="beskriv"
                        label="Beskrivning av seminariet (max 800 tecken inkl. blanksteg) "
                        maxLength="800"
                        value={beskriv || ''}
                        autoComplete="off"
                      />
                      <br />
                      <br />
                      <h5>
                        Välj de ämnen, språk och tillgänglighetsanpassningar som
                        passar in på ert seminarium. det går att välja flera
                        alternativ.
                      </h5>

                      <p>Ämne</p>
                      {amne &&
                        map(amne, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        multiple
                        label="Ämne"
                        disabled={readOnlySeminarLiveBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          return handleSeminarLiveInputChange(bookingId)({
                            target,
                          });
                        }}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                      />
                      <p>Tillgänglighet</p>
                      {tillganglighet &&
                        map(tillganglighet, (item, i) => (
                          <Label key={i}>{item}</Label>
                        ))}
                      <br />
                      <br />
                      <Form.Select
                        label="Tillgänglighet"
                        disabled={readOnlySeminarLiveBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSeminarLiveInputChange(bookingId)({
                            target,
                          });
                        }}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                      />
                      <p>Språk</p>
                      {sprak &&
                        map(sprak, (item, i) => <Label key={i}>{item}</Label>)}
                      <br />
                      <br />
                      <Form.Select
                        label="Språk"
                        multiple
                        disabled={readOnlySeminarLiveBookings}
                        onChange={(event, target) => {
                          if (target?.value?.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          handleSeminarLiveInputChange(bookingId)({
                            target,
                          });
                        }}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                      />
                    </li>

                    <li>
                      <h5>Extern länk för anmälan.</h5>
                      <Label className="info">
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </Label>
                      <Form.Input
                        readOnly={readOnlySeminarLiveBookings}
                        onChange={handleSeminarLiveInputChange(bookingId)}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        type="url"
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                        så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                        den länk du fyller i."
                      />
                    </li>

                    <li>
                      <h5>Moderator</h5>
                      <fieldset>
                        <legend>Moderator</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="moderator"
                          name="moderator"
                          value={moderator || ''}
                          label="Namn på moderator"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="moderatorJobbtitel"
                          name="moderatorJobbtitel"
                          value={moderatorJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="moderatorOrganisation"
                          name="moderatorOrganisation"
                          value={moderatorOrganisation || ''}
                          label="Organisation"
                        />
                        <Form.TextArea
                          maxLength="330"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="moderatorBio"
                          name="moderatorBio"
                          value={moderatorBio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        />
                        <Form.Field>
                          <label htmlFor="moderatorBild">
                            Lägg upp profilbild
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="moderatorBild"
                              name="moderatorBild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarLiveBookings}
                              onChange={handleSeminarLiveInputChange(bookingId)}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof moderatorBild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={moderatorBild}
                              alt="moderatorBild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="moderatorFotograf"
                          name="moderatorFotograf"
                          value={moderatorFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                      </fieldset>
                    </li>
                    <li>
                      <h5>KONTAKTPERSONER FÖR SEMINARIET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      {/* contact 1 */}
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild"
                              name="kontaktProfilbild"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarLiveBookings}
                              onChange={handleSeminarLiveInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild}
                              alt="kontaktProfilbild"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktFotograf"
                          name="kontaktFotograf"
                          value={kontaktFotograf || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktKortbio"
                          name="kontaktKortbio"
                          value={kontaktKortbio || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      {/* contact 2 */}
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        {/* <Form.Field>
                          <label htmlFor="kontaktProfilbild2">
                            Lägg upp profilbild (valfritt)
                            <span className="upload-note">
                              * allowed: jpg, jpeg, png, svg - max size 5MB
                            </span>
                            <Form.Input
                              type="file"
                              id="kontaktProfilbild2"
                              name="kontaktProfilbild2"
                              accept="image/*,.svg"
                              disabled={readOnlySeminarLiveBookings}
                              onChange={handleSeminarLiveInputChange(
                                bookingId
                              )}
                            />
                          </label>
                          <i className="note">* This is the uploaded file</i>
                          <br />
                          {typeof kontaktProfilbild2 === 'string' ? (
                            <img
                              className="uploads-preview"
                              src={kontaktProfilbild2}
                              alt="kontaktProfilbild2"
                            />
                          ) : null}
                        </Form.Field>
                        <Form.Input
                          maxLength="120"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktFotograf2"
                          name="kontaktFotograf2"
                          value={kontaktFotograf2 || ''}
                          label="Fotograf/bildkälla"
                        />
                        <Form.TextArea
                          maxLength="330"

                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(
                            bookingId
                          )}
                          id="kontaktKortbio2"
                          name="kontaktKortbio2"
                          value={kontaktKortbio2 || ''}
                          label="Kort bio (max 330 tecken inkl. blanksteg)"
                        /> */}
                        <Form.Input
                          maxLength="140"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          readOnly={readOnlySeminarLiveBookings}
                          onChange={handleSeminarLiveInputChange(bookingId)}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <TextCopy name="medarrangor-text-copy" />
                      <br />
                      <br />
                      {/* render coArrangers */}
                      {map(coArrangers, (value, key) => {
                        if (value === undefined) {
                          return;
                        }

                        const {
                          hemsida: hemsidaItem,
                          organisationsnamn,
                          organisationsnummer,
                        } = value || {};

                        return (
                          <fieldset key={key}>
                            <legend>Medarrangör</legend>

                            {readOnlySeminarLiveBookings ? (
                              ''
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                <Icon
                                  name="trash alternate outline"
                                  color="red"
                                  size="large"
                                  link
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setState((prevState) => {
                                      const localData = cloneDeep(prevState);

                                      unset(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers.${key}`,
                                      );

                                      const coArrangersLocal = get(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`,
                                      );

                                      const newArray = coArrangersLocal?.filter(
                                        (item) => {
                                          return !!item;
                                        },
                                      );

                                      set(
                                        localData,
                                        `${stateDataId}.${bookingId}.coArrangers`,
                                        [...newArray],
                                      );

                                      return {
                                        ...prevState,
                                        ...localData,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            )}

                            <Form.Input
                              onChange={(event, target) =>
                                handleSeminarLiveInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers',
                                )({ target })
                              }
                              readOnly={readOnlySeminarLiveBookings}
                              label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                              maxLength="120"
                              name="organisationsnamn"
                              value={organisationsnamn || ''}
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                handleSeminarLiveInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers',
                                )({ target })
                              }
                              readOnly={readOnlySeminarLiveBookings}
                              label="Organisationsnummer (i formatet 000000-0000)"
                              maxLength="20"
                              minLength="11"
                              name="organisationsnummer"
                              value={organisationsnummer || ''}
                              // pattern="^\d{6}-\d{4}$|^\d{3}\s?\d{3}\s?\d{3}$|^\d{8}$|^\d{7}-\d{1}$"
                            />

                            <Form.Input
                              onChange={(event, target) =>
                                handleSeminarLiveInputChange(
                                  bookingId,
                                  key,
                                  'coArrangers',
                                )({ target })
                              }
                              readOnly={readOnlySeminarLiveBookings}
                              label="Hemsida (max 160 tecken inkl. blanksteg)"
                              maxLength="160"
                              name="hemsida"
                              value={hemsidaItem || ''}
                            />
                            <br />
                            <br />
                          </fieldset>
                        );
                      })}

                      {readOnlySeminarLiveBookings ? (
                        ''
                      ) : (
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const coArrangersLocal = get(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`,
                              );

                              coArrangersLocal.push({});

                              const newArray = coArrangersLocal?.filter(
                                (item) => {
                                  return !!item;
                                },
                              );

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.coArrangers`,
                                [...newArray],
                              );

                              return {
                                ...prevState,
                                ...localData,
                              };
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Paneldeltagare">
                    <li>
                      {map(deltagare, (item, index) => {
                        if (item === undefined) {
                          return null;
                        }
                        const {
                          beskrivning,
                          deltagare: deltagareItem,
                          fotografCaption,
                          jobbTitel,
                          logo,
                          organisation,
                        } = item || {};
                        return (
                          <Form.Group key={index} grouped>
                            <fieldset>
                              <legend>Paneldeltagare {`${index + 1}`}</legend>
                              {readOnlySeminarLiveBookings ? (
                                ''
                              ) : (
                                <div style={{ textAlign: 'right' }}>
                                  <Icon
                                    name="trash alternate outline"
                                    color="red"
                                    size="large"
                                    link
                                    onClick={(e) => {
                                      e.preventDefault();

                                      setState((prevState) => {
                                        const localData = cloneDeep(prevState);

                                        const localStateCopy = get(
                                          localData,
                                          `${stateDataId}.${bookingId}`,
                                        );

                                        // delete localStateCopy.deltagare[index];

                                        localStateCopy.deltagare.splice(
                                          index,
                                          1,
                                        );

                                        const newState = {
                                          [stateDataId]: {
                                            ...prevState[stateDataId],
                                            [bookingId]: localStateCopy,
                                          },
                                        };

                                        return {
                                          ...prevState,
                                          ...newState,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              )}
                              <Form.Input
                                maxLength="120"
                                label="Namn på deltagare"
                                type="text"
                                name="deltagare"
                                value={deltagareItem || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Field>
                                <label htmlFor="logo">
                                  Lägg upp profilbild (valfritt)
                                  <span className="upload-note">
                                    * allowed: jpg, jpeg, png, svg - max size
                                    5MB
                                  </span>
                                  <Form.Input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    accept="image/*,.svg"
                                    disabled={readOnlySeminarLiveBookings}
                                    onChange={(event) =>
                                      handleSeminarLiveInputChange(
                                        bookingId,
                                        index,
                                        'deltagare',
                                      )(event)
                                    }
                                  />
                                </label>
                                <i className="note">
                                  * This is the uploaded file
                                </i>
                                <br />
                                {typeof logo === 'string' ? (
                                  <img
                                    className="uploads-preview"
                                    src={logo}
                                    alt="logo"
                                  />
                                ) : null}
                              </Form.Field>
                              <Form.Input
                                maxLength="120"
                                label="Fotograf/bildkälla"
                                type="text"
                                name="fotografCaption"
                                value={fotografCaption || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Jobbtitel"
                                type="text"
                                name="jobbTitel"
                                value={jobbTitel || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.Input
                                maxLength="120"
                                label="Organisation"
                                type="text"
                                name="organisation"
                                value={organisation || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                                autoComplete="off"
                              />
                              <Form.TextArea
                                maxLength="330"
                                label="Kort beskrivning om deltagaren (max 330 tecken)"
                                name="beskrivning"
                                value={beskrivning || ''}
                                readOnly={readOnlySeminarLiveBookings}
                                onChange={(event, target) =>
                                  handleSeminarLiveInputChange(
                                    bookingId,
                                    index,
                                    'deltagare',
                                  )({ target })
                                }
                              />
                            </fieldset>
                          </Form.Group>
                        );
                      })}

                      {!readOnlySeminarLiveBookings && (
                        <Button
                          secondary
                          size="tiny"
                          content="Lägg till deltagare"
                          icon="plus user"
                          labelPosition="right"
                          onClick={(e) => {
                            e.preventDefault();
                            setState((prevState) => {
                              const localData = cloneDeep(prevState);

                              const deltagareArray = get(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`,
                              );

                              deltagareArray.push({});

                              set(
                                localData,
                                `${stateDataId}.${bookingId}.deltagare`,
                                deltagareArray,
                              );

                              return {
                                ...prevState,
                                ...localData,
                              };
                            });
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Row>
                  <p>
                    <WarnLogo />
                    {daysLeftText}
                  </p>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {readOnlySeminarLiveBookings ? (
                      <>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            toggleReadOnlyMode(
                              'readOnlySeminarLiveBookings',
                              bookingId,
                              'stateSeminarLiveBookings',
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        {(!sentInvoice && daysLeft(createdAt) > 0) ||
                        isAdmin ? (
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              deleteFromDatabase({
                                databasePath,
                                data: currentBooking,
                                id: bookingId,
                                reduxPath,
                                stateDataId: 'stateSeminarLiveBookings',
                              })();
                            }}
                            loading={loading}
                            disabled={isDeleteClicked}
                            content="Ta bort bokning"
                            icon={{ name: 'delete', color: 'red' }}
                            basic
                            primary
                            labelPosition="right"
                          />
                        ) : null}
                      </>
                    ) : (
                      <div className="flex no-gap">
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Spara"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleCancel();
                          }}
                          type="button"
                          basic
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      );
    });
  };
  //  #endregion

  const hasBookings =
    !isEmpty(currentUserBookings) ||
    !isEmpty(currentUserTillaggBookings) ||
    !isEmpty(currentUserProductBookings) ||
    !isEmpty(currentUserSeminars) ||
    !isEmpty(currentUserSeminarsLive) ||
    !isEmpty(currentUserSponsor);

  if (isLoading) {
    return <SpinnerLoader />;
  }

  return (
    <Wrapper>
      <ContentContainer>
        <section className="pink">
          <WhiteBox transparent>
            <h1>
              <ContentEditor
                inline
                contentId="page-user-account-mina-bookings-title"
              >
                Mina bokningar
              </ContentEditor>
            </h1>
            {hasBookings ? (
              <ContentEditor
                inline
                contentId="page-user-account-mina-bookings-hasbookings-text"
              >
                {`<p>För att redigera dina bokningar, klicka på knappen
                    <b>Redigera uppgifter</b> längst ner på varje bokning.</p>`}
              </ContentEditor>
            ) : (
              <ContentEditor
                inline
                contentId="page-user-account-mina-bookings-no-bookings-text"
              >
                {'<p>Du har inga bokningar.</p>'}
              </ContentEditor>
            )}
          </WhiteBox>

          {renderCurrentUserBookings(currentUserBookings)}
          {renderCurrentUserTillaggBookings(currentUserTillaggBookings)}
          {renderCurrentUserProductBookings(currentUserProductBookings)}
          {renderSponsorBookings(currentUserSponsor)}
          {renderSeminarBookings(currentUserSeminars)}
          {renderSeminarLiveBookings(currentUserSeminarsLive)}
        </section>
      </ContentContainer>
    </Wrapper>
  );
}

export default memo(UserBookings);
