import { memo, useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { Route, Routes, Navigate } from 'react-router-dom';
import { size, get } from 'lodash';

import {
  BOOKING_COLLECTION_CURRENT_EVENT,
  FB_COLLECTIONS,
  INITIAL_LOADING_TIME,
  REDUX_PATHS_CURRENT_USER,
} from '../../configs';
import {
  getCurrentUserSeminarsLiveBookings,
  getTotalCurrentSeminarLiveBookings,
  getCurrentUserSeminarsBookings,
  getTotalCurrentSeminarBookings,
  getTotalCurrentTillaggBookings,
  getTotalCurrentSponsorBookings,
  getTotalCurrentProductBookings,
  getCurrentUserTillaggBookings,
  getCurrentUserSponsorBookings,
  getCurrentUserProductBookings,
  getOrderedSeminarLiveBookings,
  getTotalCurrentTentBookings,
  getCurrentUserOrganisation,
  getCurrentUserOrganisationJoinPending,
  getOrderedSeminarBookings,
  getCurrentUserBookings,
  getCurrentUser,
  getProducts,
  getProfile,
  getAuthId,
  getStats,
} from '../../redux-config/selectors';
import SpinnerLoader from '../../components/SpinnerLoader';
import OrganisationAccount from '../OrganisationAccount';
import Information from '../../components/Information';
import { UserSideBar } from '../../components/SideBar';
import ProductBookings from '../ProductBookings';
import UserBookings from '../UserBookings';
import SeminarLive from '../SeminarLive';
import UserAccount from '../UserAccount';
import HomePage from '../HomePage';
import Seminar from '../Seminar';
import Sponsor from '../Sponsor';
import Tillagg from '../Tillagg';
import Book from '../Book';
import Tent from '../Tent';

function UserDashBoard(props) {
  // console.log('UserDashBoard: props', props);

  const url = '/user';
  const [isLoading, setIsLoading] = useState(true);

  const {
    currentUser,
    currentUserBookings,
    profile,
    firebase,
    firestore,
    products,
    currentUserTillaggBookings,
    currentUserSeminars,
    currentUserSeminarsLive,
    currentUserSponsor,
    currentUserProductBookings,
    seminars,
    seminarsLive,
    stats,
    currentUserOrganisation,
    currentUserOrganisationJoinPending,
  } = props;

  // Handle loading state with cleanup
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), INITIAL_LOADING_TIME);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading || !isLoaded(profile) || !isLoaded(currentUser)) {
    return <SpinnerLoader />;
  }

  const { isOrgApproved, isOrgRejected } = currentUser || {};

  if (isOrgApproved) {
    if (
      !isLoaded(profile) ||
      !isLoaded(currentUser) ||
      !isLoaded(currentUserBookings) ||
      !isLoaded(products) ||
      !isLoaded(currentUserTillaggBookings) ||
      !isLoaded(currentUserSeminars) ||
      !isLoaded(currentUserSeminarsLive) ||
      !isLoaded(currentUserSponsor) ||
      !isLoaded(currentUserProductBookings) ||
      !isLoaded(seminars) ||
      !isLoaded(seminarsLive) ||
      !isLoaded(stats) ||
      !isLoaded(currentUserOrganisation)
    ) {
      return <SpinnerLoader />;
    }
  }

  const renderRoutes = () => (
    <Routes>
      <Route
        path="/"
        element={
          isOrgApproved ? (
            <HomePage
              isOrgApproved={isOrgApproved}
              orgUserPendingCount={size(currentUserOrganisationJoinPending)}
            />
          ) : (
            <Navigate to="org-account" />
          )
        }
      />
      <Route
        path="user-account"
        element={
          <UserAccount
            isFromUser
            currentUser={currentUser}
            firestore={firestore}
            firebase={firebase}
          />
        }
      />

      <Route
        path="org-account"
        element={<OrganisationAccount {...props} isFromUser />}
      />
      {isOrgApproved && !isOrgRejected ? (
        <Route
          path="org-account/medlemmar"
          element={
            <OrganisationAccount {...props} isFromUser currentTab="medlemmar" />
          }
        />
      ) : null}

      <Route path="information" element={<Information firebase={firebase} />} />
      <Route path="bookings" element={<UserBookings {...props} isFromUser />} />

      {isOrgApproved ? (
        <>
          <Route path="book" element={<Book {...props} />} />
          <Route path="book/tent" element={<Tent {...props} />} />
          <Route path="book/seminar" element={<Seminar {...props} />} />
          <Route
            path="book/seminar-live"
            element={<SeminarLive {...props} />}
          />
          <Route path="book/sponsor" element={<Sponsor {...props} />} />
          <Route path="book/tillagg" element={<Tillagg {...props} />} />
          <Route
            path="book/torghandel"
            element={
              <ProductBookings
                {...props}
                product={get(products, 'torghandel', {})}
              />
            }
          />
          <Route
            path="book/foodtruck"
            element={
              <ProductBookings
                {...props}
                product={get(products, 'foodtruck', {})}
              />
            }
          />
        </>
      ) : (
        <Route path="book" element={<Book {...props} disableButtons />} />
      )}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  return (
    <div className="columns">
      <UserSideBar
        className="sidenav"
        home={`${url}`}
        orgUserPendingCount={size(currentUserOrganisationJoinPending)}
        orgAccount={`${url}/org-account`}
        userAccount={`${url}/user-account`}
        minaBookings={`${url}/bookings`}
        book={`${url}/book`}
        information={`${url}/information`}
        tillagg={`${url}/tillagg`}
        torghandel={`${url}/torghandel`}
        foodtruck={`${url}/foodtruck`}
        isOrgApproved={isOrgApproved}
        isOrgRejected={isOrgRejected}
      />
      <div className="main">{renderRoutes()}</div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  profile: getProfile,
  currentUser: getCurrentUser,
  currentUserBookings: getCurrentUserBookings,
  currentUserTillaggBookings: getCurrentUserTillaggBookings,
  currentUserSeminars: getCurrentUserSeminarsBookings,
  currentUserSeminarsLive: getCurrentUserSeminarsLiveBookings,
  currentUserSponsor: getCurrentUserSponsorBookings,
  currentUserProductBookings: getCurrentUserProductBookings,
  totalCurrentTentBookings: getTotalCurrentTentBookings,
  totalCurrentTillaggBookings: getTotalCurrentTillaggBookings,
  totalCurrentSeminarBookings: getTotalCurrentSeminarBookings,
  totalCurrentSeminarLiveBookings: getTotalCurrentSeminarLiveBookings,
  totalCurrentSponsorBookings: getTotalCurrentSponsorBookings,
  totalCurrentProductBookings: getTotalCurrentProductBookings,
  seminars: getOrderedSeminarBookings,
  seminarsLive: getOrderedSeminarLiveBookings,
  authId: getAuthId,
  stats: getStats,
  products: getProducts,
  currentUserOrganisation: getCurrentUserOrganisation,
  currentUserOrganisationJoinPending: getCurrentUserOrganisationJoinPending,
  // log: (state) => console.info('logState: firestore', state.firestore),
});

const withConnect = connect(mapStateToProps);

const createFirestoreConnect = (props) => {
  const { auth, currentUser, currentUserOrganisation } = props;
  const { uid, email } = auth || {};
  if (!uid || !email) {
    return [];
  }

  const { orgDbPaths, isOrgApproved } = currentUser || {};
  const { orgUserPaths, branchPaths } = orgDbPaths || {};
  const { branchId, orgNr } = currentUserOrganisation || {};

  const query = [
    {
      collection: FB_COLLECTIONS.users,
      doc: uid,
      path: REDUX_PATHS_CURRENT_USER.currentUser,
    },
    {
      collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
      path: FB_COLLECTIONS.seminarBookings,
    },
    {
      collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarLiveBookings,
      path: FB_COLLECTIONS.seminarLiveBookings,
    },
    {
      collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
      path: FB_COLLECTIONS.stats,
    },
    {
      collection: FB_COLLECTIONS.products,
      path: FB_COLLECTIONS.products,
    },
  ];

  if (orgUserPaths) {
    const orgQuery = [
      {
        collection: orgUserPaths.dbCollection,
        doc: orgUserPaths.dbDocId,
        path: REDUX_PATHS_CURRENT_USER.currentUserOrganisationStatus,
      },
    ];

    if (currentUserOrganisation?.status?.approved) {
      orgQuery.push({
        collection: branchPaths.dbCollection,
        doc: branchPaths.dbDocId,
        path: REDUX_PATHS_CURRENT_USER.currentUserOrganisation,
      });
    }

    query.push(...orgQuery);
  }

  if (!isOrgApproved) {
    return query;
  }

  if (branchId) {
    query.push(
      // current user specific data
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.bookings,
        where: [['branchId', '==', branchId]],
        path: REDUX_PATHS_CURRENT_USER.currentUserBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.tillaggBookings,
        where: [['branchId', '==', branchId]],
        path: REDUX_PATHS_CURRENT_USER.currentUserTillaggBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
        where: [['branchId', '==', branchId]],
        path: REDUX_PATHS_CURRENT_USER.currentUserSeminarsBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarLiveBookings,
        where: [['branchId', '==', branchId]],
        path: REDUX_PATHS_CURRENT_USER.currentUserSeminarsLiveBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings,
        where: [['branchId', '==', branchId]],
        path: REDUX_PATHS_CURRENT_USER.currentUserSponsorBookings,
      },
      {
        collection: BOOKING_COLLECTION_CURRENT_EVENT.productBookings,
        where: [['branchId', '==', branchId]],
        path: REDUX_PATHS_CURRENT_USER.currentUserProductBookings,
      },
      {
        collection: `organisations/${orgNr}/branches/${branchId}/users`,
        where: [['pending', '==', true]],
        path: REDUX_PATHS_CURRENT_USER.currentUserOrganisationJoinPending,
      },
    );
  }

  return query;
};

export default memo(
  compose(
    withConnect,
    firestoreConnect((props) => createFirestoreConnect(props)),
  )(UserDashBoard),
);
