function TentLogo({ width = 19.7, height = 20.5 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34.7 35.5">
      <defs>
        <style>{'.a{fill:#2337ec;}'}</style>
      </defs>
      <g transform="translate(5867.037 -6480.5)">
        <g transform="translate(-5867.037 6480.5)">
          <path
            className="a"
            d="M434.329,555.232v17.22a1.047,1.047,0,1,1-2.095,0V548.781a1.051,1.051,0,0,1,.723-1,43.234,43.234,0,0,0,9.12-4.041,29.3,29.3,0,0,0,6.729-5.375l.074-.074a1.037,1.037,0,0,1,1.483.074,29.191,29.191,0,0,0,6.71,5.375,45.452,45.452,0,0,0,9.046,4.022,1.053,1.053,0,0,1,.816,1.02v23.671a1.057,1.057,0,0,1-2.113,0v-17.22h-3.54V566.78a1.057,1.057,0,0,1-2.113,0V555.232H439.982V566.78a1.047,1.047,0,1,1-2.095,0V555.232Z"
            transform="translate(-432.234 -538.013)"
          />
          <path
            className="a"
            d="M442.641,600.62l-5.346,5.728V587.254h5.346Z"
            transform="translate(-435.647 -571.215)"
          />
          <path
            className="a"
            d="M518.191,600.62l5.346,5.728V587.254h-5.346Z"
            transform="translate(-490.193 -571.215)"
          />
        </g>
        <g transform="translate(-5859.296 6503.458)">
          <g transform="translate(7.594)">
            <path
              className="a"
              d="M483.112,618.12a1.41,1.41,0,0,0-1.414-1.414h-.972a1.41,1.41,0,0,0-1.414,1.414v1.259h3.8Z"
              transform="translate(-479.312 -614.032)"
            />
            <path
              className="a"
              d="M483.753,609.713a1.237,1.237,0,1,1-2.474,0,1.216,1.216,0,0,1,1.237-1.215A1.2,1.2,0,0,1,483.753,609.713Z"
              transform="translate(-480.638 -608.497)"
            />
          </g>
          <g transform="translate(13.956 0.001)">
            <path
              className="a"
              d="M502.623,618.12a1.41,1.41,0,0,0-1.414-1.414h-.95a1.41,1.41,0,0,0-1.414,1.414v1.259h3.8C502.623,619.357,502.623,618.12,502.623,618.12Z"
              transform="translate(-498.845 -614.033)"
            />
            <circle
              className="a"
              cx={1.215}
              cy={1.215}
              r={1.215}
              transform="translate(0.663)"
            />
          </g>
          <g transform="translate(1.187)">
            <path
              className="a"
              d="M463.42,618.12a1.41,1.41,0,0,0-1.414-1.414h-.95a1.41,1.41,0,0,0-1.414,1.414v1.259h3.8V618.12Z"
              transform="translate(-459.642 -614.032)"
            />
            <path
              className="a"
              d="M464.061,609.713a1.212,1.212,0,0,1-1.215,1.215,1.245,1.245,0,0,1-1.237-1.215,1.226,1.226,0,0,1,2.452,0Z"
              transform="translate(-460.968 -608.497)"
            />
          </g>
          <rect
            className="a"
            width={18.911}
            height={6.648}
            transform="translate(0 5.893)"
          />
        </g>
      </g>
    </svg>
  );
}

function SeminarLogo({ width = 20.7, height = 25.5 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20.943 44.035">
      <g transform="translate(5852 -7116.184)" fill="#2337ec">
        <path
          d="M478.1,819.722a1.946,1.946,0,0,0,.486-1l-.242-.058a17.76,17.76,0,0,1-2.657.226h0c-.166,0-.326,0-.482-.011v2.138A5.438,5.438,0,0,0,478.1,819.722Z"
          transform="translate(-6320.57 6316.843)"
        />
        <circle
          cx="4.177"
          cy="4.177"
          r="4.177"
          transform="translate(-5844.518 7116.184)"
        />
        <path
          d="M463.789,825.522l-.072-.364h0q-.3.06-.631.114c-4.55.756-5.692,5.389-5.7,5.4l.361.09a5.818,5.818,0,0,1,.238-.7,6.861,6.861,0,0,1,5.16-4.43Q463.484,825.583,463.789,825.522Z"
          transform="translate(-6309.387 6312.77)"
        />
        <path
          d="M463.781,799.78a3.4,3.4,0,0,0,1.8,1.588,7.19,7.19,0,0,0,2.733.452,18.727,18.727,0,0,0,3.2-.318,1.485,1.485,0,0,0-.514-2.925,15.919,15.919,0,0,1-2.683.273,3.426,3.426,0,0,1-1.791-.326c-.08-.057-.078-.071-.085-.079a.275.275,0,0,1-.018-.123.536.536,0,0,1,0-.063,2.378,2.378,0,0,1,.285-.843,6.123,6.123,0,0,1,1.124-1.472v2.521c.133.009.279.014.441.014h.04a15.566,15.566,0,0,0,2.62-.268,1.872,1.872,0,0,1,.323-.028,1.892,1.892,0,0,1,.32.028l.4-2.109a1.531,1.531,0,1,1,1.7.4L472.9,799.2a1.875,1.875,0,0,1,.175.521,1.854,1.854,0,0,1-.87,1.91l-.169.591-.467-.111a2.316,2.316,0,0,1-.576,1.164,5.878,5.878,0,0,1-3.168,1.426v19.68a2.3,2.3,0,0,0,4.591,0v-16.76h.86v16.76a2.3,2.3,0,0,0,4.591,0v-27.8a8.009,8.009,0,0,1,.331.787,22.967,22.967,0,0,1,.962,7.545c0,1.024-.033,2.149-.105,3.383a1.486,1.486,0,0,0,1.4,1.57l.087,0a1.486,1.486,0,0,0,1.482-1.4c.075-1.287.11-2.47.11-3.557-.008-5.6-.9-8.7-2.123-10.569a5.242,5.242,0,0,0-1.983-1.848,3.7,3.7,0,0,0-1.141-.365,1.435,1.435,0,0,0-.451-.074h-7.172c-.035,0-.069,0-.1.005h-.013a3.957,3.957,0,0,0-2.06.694,9.041,9.041,0,0,0-2.407,2.311,5.8,5.8,0,0,0-1.232,3.253v.008A3.228,3.228,0,0,0,463.781,799.78Z"
          transform="translate(-6313.189 6333.545)"
        />
      </g>
    </svg>
  );
}

function WarnLogo({ width = 20.7, height = 25.5, color = '#E6B5D4', style }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50.161 46.297"
      style={{ marginRight: 10, float: 'left', ...style }}
    >
      <path
        className="a"
        style={{ fill: color }}
        d="M69.81,65.917,48.458,104.884a3.665,3.665,0,0,0,3.267,5.413H94.441a3.673,3.673,0,0,0,3.267-5.413L76.344,65.917A3.743,3.743,0,0,0,69.81,65.917ZM75.2,82.085l-.434,14.709H71.389l-.434-14.709ZM73.077,104.8a2.22,2.22,0,1,1,2.3-2.218A2.24,2.24,0,0,1,73.077,104.8Z"
        transform="translate(-48 -64)"
      />
    </svg>
  );
}

function StarLogo({ width = 25, height = 35, color = '#2337EC' }) {
  return (
    <svg viewBox="0 0 37 35" fill="none" width={width} height={height}>
      <path
        d="M16.7091 1.6992C17.2719 -0.0115081 19.7222 -0.0115087 20.285 1.6992L23.2454 10.6983C23.4971 11.4633 24.2189 11.9813 25.0334 11.9813L34.6135 11.9813C36.4347 11.9813 37.1919 14.2831 35.7185 15.3403L27.968 20.9021C27.3091 21.3749 27.0334 22.213 27.2851 22.9781L30.2455 31.9771C30.8083 33.6879 28.8259 35.1104 27.3526 34.0531L19.6021 28.4914C18.9432 28.0186 18.051 28.0186 17.3921 28.4914L9.64158 34.0531C8.16823 35.1104 6.18585 33.6879 6.74862 31.9771L9.70904 22.9781C9.96071 22.213 9.685 21.3749 9.0261 20.9021L1.27562 15.3403C-0.197729 14.2831 0.559472 11.9813 2.38063 11.9813L11.9608 11.9813C12.7752 11.9813 13.497 11.4633 13.7487 10.6983L16.7091 1.6992Z"
        style={{ fill: color }}
      />
    </svg>
  );
}

export { TentLogo, SeminarLogo, WarnLogo, StarLogo };
