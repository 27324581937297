import { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Message } from 'semantic-ui-react';

function UserManagement(props) {
  const { firebase } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const getParameterByName = (args) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(args);
  };

  const actionCode = getParameterByName('oobCode');

  const [inputValues, setvalue] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    newEmail: '',
  });
  const [submittingPassword, setSubmittingPassword] = useState(false);
  const [error, setError] = useState(false);

  const handleInputChange = (_, { name, value }) => {
    setvalue({ ...inputValues, [name]: value });
  };

  const { email, password, repeatPassword } = inputValues;

  const handleResetPassword = (e) => {
    e.preventDefault();
    setSubmittingPassword(true);
    if (password === repeatPassword) {
      firebase
        .confirmPasswordReset(actionCode, password)
        .then(() => {
          firebase
            .login({
              email,
              password,
            })
            .then(() => {
              setSubmittingPassword(false);
              navigate('/');
            })
            .catch((err) => {
              console.error(err);
              setError({ ...err });
              setSubmittingPassword(false);
            });
        })
        .catch((err) => {
          console.error('error', error);
          setError({ ...err });
          setSubmittingPassword(false);
        });
    }
  };

  useEffect(() => {
    if (!actionCode) {
      navigate('/');
    }
  }, [actionCode, navigate]);

  return (
    <Form
      style={{ maxWidth: 400, margin: 'auto', padding: 30 }}
      onSubmit={handleResetPassword}
      error={Boolean(error)}
    >
      <h3>UserManagement</h3>
      <Form.Input
        maxLength="120"
        type="email"
        onChange={handleInputChange}
        required
        id="loginOrgNr"
        name="email"
        placeholder="E-post"
        label="E-post"
        value={email}
        autoComplete="username"
      />
      <Form.Input
        maxLength="40"
        onChange={handleInputChange}
        value={password}
        required
        label="Nytt lösenord"
        type="password"
        id="Nytt lösenord"
        name="password"
        placeholder="Nytt lösenord"
        autoComplete="new-password"
        suggested="new-password"
      />
      <Form.Input
        maxLength="40"
        onChange={handleInputChange}
        value={repeatPassword}
        required
        label="Upprepa nytt lösenord"
        type="password"
        id="Upprepa nytt lösenord"
        name="repeatPassword"
        placeholder="Upprepa nytt lösenord"
        autoComplete="new-password"
        suggested="new-password"
      />
      {error && <Message negative header={error.code} list={[error.message]} />}
      <div
        style={{
          display: 'flex',
        }}
      >
        <Button
          secondary
          basic
          icon="cancel"
          labelPosition="right"
          content="Avbryt"
          type="button"
          onClick={() => navigate('/')}
        />
        <Button
          type="submit"
          primary
          loading={submittingPassword}
          content="Bekräfta"
          labelPosition="right"
          icon="checkmark"
          disabled={
            password !== repeatPassword ||
            password === '' ||
            repeatPassword === '' ||
            password.length < 6 ||
            repeatPassword.length < 6 ||
            submittingPassword
          }
        />
      </div>
    </Form>
  );
}

export default UserManagement;
