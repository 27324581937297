import { useState } from 'react';

import {
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  Message,
  Table,
} from 'semantic-ui-react';

import {
  JarvaButtonSecondary,
  JarvaButtonPrimary,
} from '../../styledComponents';
import { ButtonContainer } from '../HomePage/homePageStyles';
import { organisationRequests } from '../../api';

function RenderBranchUsersRequests(props) {
  const { users, currentAdminUser, currentUser, computedIsAdmin, updateState } =
    props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAccept = async (e, user) => {
    e.preventDefault();

    // confirm
    const confirm = window.confirm(
      'Är du säker på att du vill godkänna användaren?',
    );
    if (!confirm) return;

    setLoading(true);
    setError(null);
    try {
      await organisationRequests.post({
        action: 'approveNewBranchUser',
        data: {
          user,
          currentAdminUser,
          currentUser: { ...currentUser, userId: currentUser.id },
        },
      });
    } catch (err) {
      console.error('error handleAccept', err);
      setError(err?.message);
    } finally {
      setLoading(false);
      updateState();
    }
  };

  const handleReject = async (e, user) => {
    e.preventDefault();

    // confirm
    const confirm = window.confirm(
      'Är du säker på att du vill avböja användaren?',
    );
    if (!confirm) return;

    setLoading(true);
    setError(null);
    try {
      await organisationRequests.post({
        action: 'rejectNewBranchUser',
        data: {
          user,
          currentAdminUser,
          currentUser: { ...currentUser, userId: currentUser.id },
        },
      });
    } catch (err) {
      console.error('error handleReject', err);
      setError(err?.message);
    } finally {
      setLoading(false);
      updateState();
    }
  };

  if (!computedIsAdmin || !users || users.length === 0) return null;

  return (
    <div>
      <br />
      <h4>Användare som vill bli medlemmar</h4>
      <Table striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Namn</TableHeaderCell>
            <TableHeaderCell>E-post</TableHeaderCell>
            <TableHeaderCell>Rättighet</TableHeaderCell>
            <TableHeaderCell>Godkänd</TableHeaderCell>
            <TableHeaderCell>Åtgärder</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => {
            const {
              role = '',
              firstName = '',
              secondName = '',
              approved = '',
              name = '',
              email,
            } = user || {};
            return (
              <TableRow key={email}>
                <TableCell>{name || `${firstName} ${secondName}`}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>{approved ? 'Ja' : 'Nej'}</TableCell>
                <TableCell>
                  <ButtonContainer>
                    <JarvaButtonPrimary
                      onClick={(e) => handleAccept(e, user)}
                      disabled={loading}
                    >
                      Godkänn
                    </JarvaButtonPrimary>
                    <JarvaButtonSecondary
                      onClick={(e) => handleReject(e, user)}
                      disabled={loading}
                    >
                      Avböj
                    </JarvaButtonSecondary>
                  </ButtonContainer>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div>
        {error && <Message>{error}</Message>}
        {loading && <Message>Laddar...</Message>}
      </div>
    </div>
  );
}

export default RenderBranchUsersRequests;
