import { Checkbox, Message } from 'semantic-ui-react';

import { ASSETS } from '../../configs';

function TermsCheckBox(props) {
  const {
    handleInputChange,
    handleCheckboxChange,
    godkannKopvillkorOchBekraftelse,
    godkannJarvaVekanKod,
  } = props;

  if (handleInputChange) {
    return (
      <div>
        <Checkbox
          onChange={handleInputChange}
          id="godkannKopvillkorOchBekraftelse"
          name="godkannKopvillkorOchBekraftelse"
          checked={godkannKopvillkorOchBekraftelse}
          required
          label="Jag samtycker till att Järvaveckan hanterar och lagrar mina
            personuppgifter."
        />
        <div className="checkbox-padding">
          Läs vår{' '}
          <a href={ASSETS.kopvillkor} target="_blank" rel="noopener noreferrer">
            Integritetspolicy
          </a>
        </div>
        <br />
        <Checkbox
          name="godkannJarvaVekanKod"
          id="godkannJarvaVekanKod"
          checked={godkannJarvaVekanKod}
          onChange={handleInputChange}
          required
          label="Jag godkänner Järvaveckans värderingar och uppförandekod"
        />
        <div className="checkbox-padding">
          <a
            href={ASSETS.varderingarUppforandekod}
            target="_blank"
            rel="noopener noreferrer"
          >
            Värderingar och Uppförandekod
          </a>
        </div>
        <Message info>
          Har du frågor? Kontakta oss på{' '}
          <a href="mailto:info@jarvaveckan.se">info@jarvaveckan.se</a>
        </Message>
      </div>
    );
  }

  if (handleCheckboxChange) {
    return (
      <>
        <br />
        <br />
        <Checkbox
          name="godkannKopvillkorOchBekraftelse"
          id="godkannKopvillkorOchBekraftelse"
          checked={godkannKopvillkorOchBekraftelse}
          onChange={handleCheckboxChange}
          label="Vänligen bekräfta att du godkänner våra Köpvillkor och GDPR innan du gör din bekräftelse. Beställningen är bindande 5 dagar efter ni mottagit orderbekräftelsen."
        />
        <a
          href={ASSETS.kopvillkor}
          className="checkbox-padding"
          target="_blank"
          rel="noopener noreferrer"
        >
          Köpvillkor och GDPR
        </a>
        <br />
        <br />
        <Checkbox
          name="godkannJarvaVekanKod"
          id="godkannJarvaVekanKod"
          checked={godkannJarvaVekanKod}
          onChange={handleCheckboxChange}
          label="Jag godkänner Järvaveckans värderingar och uppförandekod"
        />
        <a
          href={ASSETS.varderingarUppforandekod}
          className="checkbox-padding"
          target="_blank"
          rel="noopener noreferrer"
        >
          Värderingar och Uppförandekod
        </a>
      </>
    );
  }

  return null;
}

export default TermsCheckBox;
