import ContentEditor from '../../components/ContentEditor';
import { WhiteBox, JarvaDivider } from '../../styledComponents';
import { BookingContainer } from './SeminarStyles';

function RenderBookingSection(props) {
  const { days, makeSeminarOptions } = props;

  return (
    <WhiteBox>
      <BookingContainer>
        <div className="header">
          <ContentEditor
            inline
            contentId="page-user-book-seminar-booking-title"
          >
            {
              'För att boka, klicka på en eller flera lediga tider och välj sedan <i>Nästa Steg</i> för att gå vidare.'
            }
          </ContentEditor>
          <div className="color-nav">
            <span className="free">Ledig tid</span>
            <span className="chosen">Vald tid</span>
            <span className="booked">Fullbokad tid</span>
          </div>
        </div>
        <JarvaDivider />
        <ul>
          {days &&
            days?.map((day, index) => (
              <li key={day}>
                <p>{day}</p>
                <div className="bookables">{makeSeminarOptions(index)}</div>
              </li>
            ))}
        </ul>
      </BookingContainer>
    </WhiteBox>
  );
}

export default RenderBookingSection;
