import { firebase } from '../redux-config/configureStore';

import {
  BOOKING_COLLECTION_CURRENT_EVENT,
  EVENT_YEAR,
  IMAGE_UPLOAD_CONFIG,
} from '../configs';

/**
 *
 * @param {string} id user id
 * @returns {string} path
 */
const userPath = (id) => (id ? `users/${id}` : 'users');
// userPath(); //?

/**
 *
 * @param {string} id bookings id
 * @returns {string} path
 */
const bookingsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.bookings}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.bookings;
/**
 *
 * @param {string} id bookings id
 * @returns {string} path
 */
const tillaggBookingsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.tillaggBookings}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.tillaggBookings;

/**
 *
 * @param {string} id bookings id
 * @returns {string} path
 */
const productBookingsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.productBookings}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.productBookings;

/**
 *
 * @param {string} id seminarBookings id
 * @returns {string} path
 */
const seminarBookingsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings;

/**
 *
 * @param {string} id seminarBookings id
 * @returns {string} path
 */
const seminarLiveBookingsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.seminarLiveBookings}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.seminarLiveBookings;

/**
 *
 * @param {string} id sponsorBookings id
 * @returns {string} path
 */
const sponsorBookingsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings;

/**
 *
 * @param {string} id sponsorBookings id
 * @returns {string} path
 */
const deletedBookingsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.deletedBookings}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.deletedBookings;

/**
 *
 * @param {string} id stats document id
 * @returns {string} path
 */
const statsPath = (id) =>
  id
    ? `${BOOKING_COLLECTION_CURRENT_EVENT.stats}/${id}`
    : BOOKING_COLLECTION_CURRENT_EVENT.stats;

/**
 *
 * @param {Object} [customFirebase] - Optional firebase instance (defaults to redux firebase)
 * @param {String} assetName assetName
 * @return {Promise} url
 * @returns {string} path
 */
const getAssetUrl = async (customFirebase = firebase, assetName) => {
  if (!customFirebase && !assetName) {
    console.warn('getAssetUrl: missing firebase or assetName');
    return;
  }

  const url = await customFirebase
    .storage()
    .ref(assetName)
    .getDownloadURL()
    .catch((err) => {
      console.error('getAssetUrl: ', err.message);
    });

  return url || '';
};

/**
 *
 * @param {{ uploadPath:string, uid:string, fileList:FileList, customFirebase:firebase }}
 * @returns
 * @returns {string} path
 */
const uploadFileToStorage = async ({
  uploadPath,
  uid,
  fileList,
  customFirebase = firebase,
}) => {
  if (!(fileList instanceof FileList)) {
    return console.error('fileList: type FileList required');
  }

  if (!uid) {
    return console.error('uid required');
  }

  if (!customFirebase) {
    return console.error('firebase required');
  }

  const storageRef = customFirebase.storage().ref();
  const file = fileList[0];
  const metadata = {
    contentType: file.type,
  };

  let uploadPathByYear = '';

  if (uploadPath) {
    uploadPathByYear = uploadPath;
  } else {
    uploadPathByYear = `uploads/${EVENT_YEAR}/${uid}`;
  }

  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');

  const uploadTask = storageRef
    .child(`${uploadPathByYear}/${timestamp}_${file.name}`)
    .put(file, metadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      customFirebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        console.info(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          case customFirebase.storage.TaskState.PAUSED:
            console.info('Upload is paused');
            break;
          case customFirebase.storage.TaskState.RUNNING:
            console.info('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            reject(
              new Error("User doesn't have permission to access the object"),
            );
            break;
          case 'storage/canceled':
            reject(new Error('User canceled the upload'));
            break;
          case 'storage/unknown':
            reject(
              new Error('Unknown error occurred, inspect error.serverResponse'),
            );
            break;
          default:
            break;
        }
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      },
    );
  });
};

/**
 *
 * @param {object} param0 param0
 * @param {string} param0.uploadPath uploadPath
 * @param {File} param0.file file
 * @return {Promise} url
 */
const uploadSingleFileToStorage = async ({ uploadPath, file }) => {
  if (!file) {
    return console.error('File of type File required');
  }

  const storageRef = firebase.storage().ref();

  const metadata = {
    contentType: file.type,
  };

  const uploadTask = storageRef
    .child(`${uploadPath}/${new Date().toISOString()}_${file.name}`)
    .put(file, metadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        console.info(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.info('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING:
            console.info('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            reject(
              new Error("User doesn't have permission to access the object"),
            );
            break;
          case 'storage/canceled':
            reject(new Error('User canceled the upload'));
            break;
          case 'storage/unknown':
            reject(
              new Error('Unknown error occurred, inspect error.serverResponse'),
            );
            break;
          default:
            break;
        }
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      },
    );
  });
};

function resizeImage(file, maxWidth) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let { width } = img;
      let { height } = img;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        file.type,
        IMAGE_UPLOAD_CONFIG.quality,
      );
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    img.src = URL.createObjectURL(file);
  });
}

const editorImagesUpload = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const storageRef = firebase.storage().ref();
    const uploadRef = storageRef.child(
      `uploads/content/${new Date().toISOString()}-${blobInfo.filename()}`,
    );

    resizeImage(blobInfo.blob(), IMAGE_UPLOAD_CONFIG.maxWidth)
      .then((resizedBlob) => {
        const uploadTask = uploadRef.put(resizedBlob);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progressPercentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progress(progressPercentage);
          },
          (error) => {
            reject(new Error(`Upload failed: ${error.message}`));
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                resolve(downloadURL);
              })
              .catch((error) => {
                reject(
                  new Error(`Failed to get download URL: ${error.message}`),
                );
              });
          },
        );
      })
      .catch((error) => {
        reject(new Error(`Failed to resize image: ${error.message}`));
      });
  });

export {
  userPath,
  bookingsPath,
  tillaggBookingsPath,
  seminarBookingsPath,
  seminarLiveBookingsPath,
  sponsorBookingsPath,
  productBookingsPath,
  deletedBookingsPath,
  statsPath,
  getAssetUrl,
  uploadFileToStorage,
  uploadSingleFileToStorage,
  editorImagesUpload,
  resizeImage,
};
