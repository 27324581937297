import { memo, useEffect } from 'react';

import {
  isEmpty,
  isLoaded,
  withFirebase,
  withFirestore,
} from 'react-redux-firebase';
import { Navigate, Route, Routes } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getProfile,
  getContent,
  getClaims,
  getAuth,
} from '../../redux-config/selectors';
import UserManagement from '../../components/UserManagement';
import SpinnerLoader from '../../components/SpinnerLoader';
import { getIsoDate } from '../../utils/time-utils';
import AdminDashBoard from '../AdminDashBoard';
import Header from '../../components/Header';
import UserDashBoard from '../UserDashBoard';
import { AppContainer } from './appStyles';
import LandingPage from '../LandingPage';
import { FB_COLLECTIONS } from '../../configs';

const App = (props) => {
  // console.log('App: props', props);

  const { firebase, dispatch, claims, auth, firestore } = props;

  const { uid, lastLoginAt, createdAt } = auth || {};

  useEffect(() => {
    const updateLoginStatus = async () => {
      if (!uid) return;

      const user = firebase.auth().currentUser;
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          const newClaims = idTokenResult.claims;
          if (newClaims) {
            dispatch({ type: 'CLAIMS_UPDATE', payload: newClaims });
          }
        } catch (error) {
          console.error('Error updating claims:', error);
        }
      }

      const hasLoggedInBefore =
        sessionStorage.getItem('hasLoggedInBefore') === 'true';

      if (!hasLoggedInBefore) {
        try {
          if (!lastLoginAt || !createdAt) return;

          const userDocRef = firestore.collection('users').doc(uid);
          const lastLoginAtIso = getIsoDate(lastLoginAt);
          const createdAtIso = getIsoDate(createdAt);

          sessionStorage.setItem('hasLoggedInBefore', 'true');

          await userDocRef.set(
            {
              loggedin: lastLoginAtIso,
              createdAt: createdAtIso,
            },
            { merge: true },
          );

          // console.info('Login status updated successfully');
        } catch (error) {
          console.error('Error updating login status:', error);
          sessionStorage.removeItem('hasLoggedInBefore');
        }
      }
    };

    updateLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  // Fetch Users Data
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const contentSnapshot = await firestore.get({
          collection: FB_COLLECTIONS.content,
          path: FB_COLLECTIONS.content,
        });

        const contentData = {};
        contentSnapshot.forEach((doc) => {
          contentData[doc.id] = { id: doc.id, dbDocId: doc.id, ...doc.data() };
        });
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    fetchContent();
  }, [firestore]);

  // Modified loading check
  if (!isLoaded(auth) || !isLoaded(claims)) {
    return <SpinnerLoader />;
  }

  // If user is not logged in, show landing page we can be in admin or user
  if (isEmpty(auth)) {
    return (
      <AppContainer>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/__/auth/*"
            element={<UserManagement firebase={firebase} />}
          />
          <Route
            path="/admin"
            element={<LandingPage toPath="/admin" admin />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AppContainer>
    );
  }

  const isAdmin = claims?.admin || false;

  return (
    <AppContainer>
      <Header isAdmin={isAdmin} dispatch={dispatch} firebase={firebase} />
      <Routes>
        <Route path="/user/*" element={<UserDashBoard {...props} />} />
        <Route
          path="/admin/*"
          element={<AdminDashBoard isAdmin={isAdmin} {...props} />}
        />
        <Route path="*" element={<Navigate to="/user" replace />} />
      </Routes>
    </AppContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  auth: getAuth,
  profile: getProfile,
  claims: getClaims,
  content: getContent,
  // log: (store) => console.log('store', store),
});

export default memo(
  compose(connect(mapStateToProps), withFirebase, withFirestore)(App),
);
