import copy from 'copy-to-clipboard';
import { Button } from 'semantic-ui-react';
import { ToastContainer, toast, Bounce } from 'react-toastify';

function KontaktaWorkman() {
  const notify = () =>
    toast(
      <p>
        E-postadressen har kopierats till urklipp
        <br />
        <a href="mailto:monterservice@workman.se">monterservice@workman.se</a>
      </p>,
      {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      },
    );

  return (
    <>
      <Button
        primary
        basic
        labelPosition="right"
        content="Kontakta Workman"
        icon="mail"
        onClick={() => {
          copy('monterservice@workman.se');
          notify();
        }}
        as="a"
        href="mailto:monterservice@workman.se"
      />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </>
  );
}

export default KontaktaWorkman;
