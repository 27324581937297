import { isEmpty, omit } from 'lodash';
import { firebase } from '../redux-config/configureStore';

import { cleanOrganisationNumber } from './cleanOrganisationNumber';
import { OMIT_FIREBASE_TYPES } from '../configs';

export const clearDateBeforeSaving = (data) => {
  if (isEmpty(data)) {
    return data;
  }

  const newData = omit(data, OMIT_FIREBASE_TYPES);

  // clean organisation number
  if (newData.orgNr) {
    newData.orgNr = cleanOrganisationNumber(newData.orgNr);
  }

  Object.keys(newData).forEach((key) => {
    if (
      newData[key] instanceof firebase.firestore.Timestamp ||
      newData[key] instanceof firebase.firestore.DocumentReference ||
      newData[key] instanceof FileList ||
      typeof newData[key] === 'function'
    ) {
      delete newData[key];
    } else if (
      typeof newData[key] === 'object' &&
      !Array.isArray(newData[key])
    ) {
      newData[key] = clearDateBeforeSaving(newData[key]);
    }
  });

  // console.info('newData', newData);

  return newData;
};
