import { useState, useCallback, Fragment, useEffect } from 'react';

import {
  Checkbox,
  Message,
  Button,
  Modal,
  Label,
  Form,
  Grid,
} from 'semantic-ui-react';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import size from 'lodash/size';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import {
  OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
  TILLGANGLIGHET_OPTIONS,
  SPRAK_OPTIONS,
  AMNE_OPTIONS,
  MAX_SELECT,
  BOOKING_COLLECTION_CURRENT_EVENT,
  REDUX_PATHS_CURRENT_USER,
  FB_COLLECTIONS,
} from '../../configs';
import BookingInformationNote from '../../components/BookingInformationNote';
import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import RenderUserDetails from '../../components/RenderUserDetails';
import RenderCosponsor from '../../components/RenderCosponsor';
import BookingConfirm from '../../components/BookingConfirm';
import ContentEditor from '../../components/ContentEditor';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import RenderTents from '../../components/RenderTents';
import { uploadFileToStorage } from '../../api';
import { priceOfSelectedProduct } from '../../utils';
import { SelectProducts } from './styles';

// path : /user/book/tent

const defaultState = {
  step: 1,
  cosponsorsCount: 0,
  cosponsors: {},
  modalOpen: false,
  kopvillkor: '',
  workManInfo: '',
  godkannKopvillkorOchBekraftelse: false,
  godkannJarvaVekanKod: false,
  submitting: false,
  hideFormFields: true,
  error: null,
  ovrigt: '',
  highlightsDay1: '',
  highlightsDay2: '',
  highlightsDay3: '',
  highlightsDay4: '',
  amne: '',
  tillganglighet: '',
  sprak: '',
  reservationSiteLink: '',
  kontaktperson: '',
  kontaktJobbtitel: '',
  kontaktOrganisation: '',
  kontaktEmail: '',
  kontaktTel: '',
  kontaktperson2: '',
  kontaktJobbtitel2: '',
  kontaktOrganisation2: '',
  kontaktEmail2: '',
  kontaktTel2: '',
  erbjuderJobbLink: '',
  erbjuderStudierLink: '',
  erbjuderStudierAntal: '',
  erbjuderJobbAntal: '',
  erbjuderJobbCheckbox: false,
  erbjuderStudierCheckbox: false,
};

function Tent(props) {
  const FILTER_KEY = 'tent';

  const {
    firebase,
    products,
    loading,
    firestore,
    currentUserOrganisation,
    currentUser,
    auth: { uid },
  } = props;

  const [state, setState] = useState(defaultState);

  // if step changes then we scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.step]);

  const handleOpen = useCallback(
    () => setState((prevState) => ({ ...prevState, modalOpen: true })),
    [],
  );

  const handleClose = useCallback(
    () => setState((prevState) => ({ ...prevState, modalOpen: false })),
    [],
  );

  const handleInputChange = useCallback(
    (cosponsorsId) => (e) => {
      const {
        target: { name, value, type, checked },
      } = e;
      if (cosponsorsId) {
        setState((prevState) => {
          const { cosponsors } = prevState;
          const newState = { ...cosponsors };
          newState[cosponsorsId] = {
            ...cosponsors[cosponsorsId],
            [name]: value,
          };
          return { ...prevState, cosponsors: newState };
        });
      } else {
        const localValue = type === 'checkbox' ? checked : value;
        setState((prevState) => ({ ...prevState, [name]: localValue }));
      }
    },
    [],
  );

  const handleCheckboxChange = useCallback(
    (_, { name, value, type, checked }) => {
      const localValue = type === 'checkbox' ? checked : value;
      setState((prevState) => ({ ...prevState, [name]: localValue }));
    },
    [],
  );

  const deleteCosponsors = useCallback((key) => {
    setState((prevState) => {
      const cosponsors = { ...prevState.cosponsors };
      delete cosponsors[key];
      return {
        ...prevState,
        cosponsors,
        cosponsorsCount: prevState.cosponsorsCount - 1,
      };
    });
  }, []);

  const handleNextButtonClick = useCallback((data) => {
    setState((prevState) => ({
      ...prevState,
      step: prevState.step + 1,
      selectedProduct: { ...data },
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    setState((prevState) => ({ ...prevState, submitting: true }));

    const { organizerName, orgName } = currentUserOrganisation || {};

    const omitState = [
      'step',
      'kopvillkor',
      'workManInfo',
      'modalOpen',
      'selectedProduct',
      'logo',
      'kontaktProfilbild',
      'kontaktProfilbild2',
      'submitting',
    ];

    const selectedState = omit(state, omitState);

    const selectedProduct = get(state, 'selectedProduct');

    if (isEmpty(selectedProduct)) {
      return 'empty product';
    }

    const filteredProductProperties = omit(selectedProduct, [
      'createdAt',
      'updatedAt',
      'images',
    ]);

    const cleanedOrganisation = omit(
      currentUserOrganisation,
      OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
    );

    const { dbDocId, email, telefonnummer, firstName, secondName } =
      currentUser || {};
    const toEmails = [email];
    const ccEmails = cleanedOrganisation?.users?.filter(
      (userEmail) => userEmail !== email,
    );
    const emailsToSend = {
      to: toEmails,
      cc: ccEmails,
    };

    const bookedBy = {
      dbDocId,
      email,
      telefonnummer,
      firstName,
      secondName,
    };

    const bookingDataRaw = {
      ...selectedState,
      ...filteredProductProperties,
      ...priceOfSelectedProduct(selectedProduct),
      userId: uid,
      collection: BOOKING_COLLECTION_CURRENT_EVENT.bookings,
      sentInvoice: false,
      branchId: currentUserOrganisation.branchId,
      branchDbPath: currentUserOrganisation.dbPath,
      organisation: cleanedOrganisation,
      organizerName: organizerName || orgName,
      emailsToSend,
      bookedBy,
      createdAt: new Date().toISOString(),
    };

    const { logo, kontaktProfilbild, kontaktProfilbild2 } = state;

    if (logo) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid: currentUserOrganisation.branchId,
          fileList: logo,
          firebase,
        });

        if (downloadURL) {
          bookingDataRaw.logo = downloadURL;
        }
      } catch (error) {
        console.error('upload logo error', error);
      } finally {
        console.info('logo uploaded request finished');
      }
    }

    if (kontaktProfilbild) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid: currentUserOrganisation.branchId,
          fileList: kontaktProfilbild,
          firebase,
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild', error);
      } finally {
        console.info('kontaktProfilbild request finished');
      }
    }

    if (kontaktProfilbild2) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid: currentUserOrganisation.branchId,
          fileList: kontaktProfilbild2,
          firebase,
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild2 = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild2', error);
      } finally {
        console.info('kontaktProfilbild2 request finished');
      }
    }

    const bookingData = omit(bookingDataRaw, isNil);

    firestore
      .add(
        {
          collection: BOOKING_COLLECTION_CURRENT_EVENT.bookings,
        },
        bookingData,
      )
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          step: prevState.step + 1,
        }));
      })
      .catch((err) =>
        setState((prevState) => ({
          ...prevState,
          error: err,
        })),
      )
      .finally(() => {
        firestore
          .get({
            collection: BOOKING_COLLECTION_CURRENT_EVENT.bookings,
            path: REDUX_PATHS_CURRENT_USER.currentUserBookings,
            where: ['branchId', '==', currentUserOrganisation.branchId],
          })
          .catch((err) => console.error(err))
          .finally(() =>
            setState((prevState) => ({
              ...prevState,
              submitting: false,
            })),
          );

        firestore
          .get({
            collection: BOOKING_COLLECTION_CURRENT_EVENT.bookings,
            path: FB_COLLECTIONS.bookings,
          })
          .catch((err) => console.error(err))
          .finally(() =>
            setState((prevState) => ({
              ...prevState,
              submitting: false,
            })),
          );
      });
  }, [currentUser, currentUserOrganisation, firebase, firestore, state, uid]);

  const selectedProducts = (products) =>
    filter(products, (item) => {
      const { category } = item;
      return includes(category, FILTER_KEY);
    });

  const renderSteps = () => {
    const { step, godkannKopvillkorOchBekraftelse, godkannJarvaVekanKod } =
      state;

    if (loading) {
      return null;
    }

    const { organizerName, orgName } = currentUserOrganisation || {};
    const selectedProduct = get(state, 'selectedProduct', {});

    switch (step) {
      case 2: {
        if (isEmpty(selectedProduct)) {
          return 'empty product';
        }

        const {
          productId,
          pris,
          taltyta,
          labelDay1,
          labelDay2,
          labelDay3,
          labelDay4,
          period1Label,
          period2Label,
          fullPeriodLabel,
        } = selectedProduct;

        const {
          quantity,
          period1,
          period2,
          bredvid,
          noOfPeriods,
          isFullPeriod,
          totalPrice,
        } = priceOfSelectedProduct(selectedProduct);

        const {
          error,
          ovrigt,
          cosponsors,
          highlightsDay1,
          highlightsDay2,
          highlightsDay3,
          highlightsDay4,
          submitting,
          modalOpen,
          amne,
          tillganglighet,
          sprak,
          reservationSiteLink,
          kontaktperson,
          kontaktJobbtitel,
          kontaktOrganisation,
          kontaktEmail,
          kontaktTel,
          kontaktperson2,
          kontaktJobbtitel2,
          kontaktOrganisation2,
          kontaktEmail2,
          kontaktTel2,
          erbjuderJobbLink,
          erbjuderStudierLink,
          erbjuderStudierAntal,
          erbjuderJobbAntal,
          erbjuderJobbCheckbox,
          erbjuderStudierCheckbox,
        } = state;

        let localTitle = 'tältyta';

        if (productId === 'tent5x5') {
          localTitle = 'tältplats';
        }

        return (
          <Fragment key={productId}>
            <WhiteBox transparent>
              <h2>Information om din {localTitle}</h2>
              <ContentEditor contentId="page-book-tent-step-2-info">
                Här kan ni fylla i information kring era aktiviteter på
                Järvaveckan 2024. För att säkra er plats och genomföra bokningen
                behöver ni inledningsvis enbart fylla i uppgifterna under Mitt
                Konto för att kunna göra en bokning.
              </ContentEditor>
              <p>
                <br />
                {isFullPeriod ? (
                  <>
                    <b>Full Period:</b> {fullPeriodLabel} <br />
                  </>
                ) : (
                  (period1 && (
                    <>
                      <b>Period-1:</b> {period1Label} <br />
                    </>
                  )) ||
                  (period2 && (
                    <>
                      <b>Period-2:</b> {period2Label} <br />
                    </>
                  ))
                )}
                <b>Tält:</b> <span className="titleCase">{localTitle}</span>{' '}
                {taltyta}.<br />
                <b>Antal Period:</b> {noOfPeriods}
                <br />
                <b>Pris/period:</b> {Number(pris).toLocaleString('sv')} kr
                exkl.moms <br />
                <b>Antal:</b> {quantity} <br />
                {bredvid && (
                  <>
                    <b>Bredvid:</b> JA <br />
                  </>
                )}
                <b>Summa Pris:</b> {Number(totalPrice).toLocaleString('sv')} kr
                exkl.moms
                <br />
              </p>

              <BookingInformationNote />
            </WhiteBox>
            <WhiteBox>
              <Form
                error={Boolean(error)}
                onSubmit={handleOpen}
                loading={submitting}
              >
                <Grid stackable columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <section>
                        <h5>Uppgifter för tältet</h5>
                        <Form.TextArea
                          onChange={handleInputChange()}
                          label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                          maxLength="800"
                          id="ovrigt"
                          name="ovrigt"
                          value={ovrigt || ''}
                        />
                      </section>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid stackable columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <h5>Erbjuder jobb/studier</h5>

                      <Checkbox
                        onChange={handleInputChange()}
                        id="erbjuderJobbCheckbox"
                        name="erbjuderJobbCheckbox"
                        checked={erbjuderJobbCheckbox}
                        label="Erbjuder jobb"
                      />

                      {erbjuderJobbCheckbox ? (
                        <>
                          <span
                            style={{ marginTop: '10px', display: 'block' }}
                          />
                          <Form.Input
                            type="url"
                            onChange={handleInputChange()}
                            id="erbjuderJobbLink"
                            name="erbjuderJobbLink"
                            value={erbjuderJobbLink || ''}
                            placeholder="https://example.com"
                            label="Länk till erbjudanden"
                          />
                          <Form.Input
                            type="number"
                            min="1"
                            onChange={handleInputChange()}
                            id="erbjuderJobbAntal"
                            name="erbjuderJobbAntal"
                            value={erbjuderJobbAntal || ''}
                            label="Antal erbjudanden"
                          />
                        </>
                      ) : (
                        ''
                      )}
                      <br />
                      <Checkbox
                        onChange={handleInputChange()}
                        id="erbjuderStudierCheckbox"
                        name="erbjuderStudierCheckbox"
                        checked={erbjuderStudierCheckbox}
                        label="Erbjuder studier"
                      />
                      {erbjuderStudierCheckbox ? (
                        <>
                          <span
                            style={{ marginTop: '10px', display: 'block' }}
                          />
                          <Form.Input
                            type="url"
                            onChange={handleInputChange()}
                            id="erbjuderStudierLink"
                            name="erbjuderStudierLink"
                            value={erbjuderStudierLink || ''}
                            placeholder="https://example.com"
                            label="Länk till erbjudna studier"
                          />
                          <Form.Input
                            type="number"
                            min="1"
                            onChange={handleInputChange()}
                            id="erbjuderStudierAntal"
                            name="erbjuderStudierAntal"
                            value={erbjuderStudierAntal || ''}
                            label="Antal erbjudna studier"
                          />
                        </>
                      ) : (
                        ''
                      )}
                      <br />

                      <h5>Kategorityp</h5>
                      <Form.Select
                        multiple
                        label="Ämne"
                        onChange={(_, target) => {
                          const { name, value } = target;
                          if (value.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          setState((prevState) => ({
                            ...prevState,
                            [name]: value,
                          }));
                        }}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                        placeholder="välj ett eller flera"
                      />
                      <Form.Select
                        label="Tillgänglighet"
                        onChange={(_, target) => {
                          const { name, value } = target;
                          if (value.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          setState((prevState) => ({
                            ...prevState,
                            [name]: value,
                          }));
                        }}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                        placeholder="välj"
                      />
                      <Form.Select
                        label="Språk"
                        multiple
                        onChange={(_, target) => {
                          const { name, value } = target;
                          if (value.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          setState((prevState) => ({
                            ...prevState,
                            [name]: value,
                          }));
                        }}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                        placeholder="välj"
                      />

                      <h5>
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </h5>
                      <Form.Input
                        onChange={handleInputChange()}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                      så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                      den länk du fyller i."
                      />

                      <h5>KONTAKTPERSONER FÖR TÄLTET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        <Form.Input
                          maxLength="140"
                          onChange={handleInputChange()}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          onChange={handleInputChange()}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        <Form.Input
                          maxLength="140"
                          onChange={handleInputChange()}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          onChange={handleInputChange()}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </Grid.Column>

                    <Grid.Column>
                      <h5>Highlights / Händer i tältet</h5>
                      <TextCopy name="highlites-text-copy" />
                      <br />
                      <br />

                      {period1 && (
                        <>
                          <Form.TextArea
                            maxLength="330"
                            onChange={handleInputChange()}
                            value={highlightsDay1 || ''}
                            id="highlightsDay1"
                            name="highlightsDay1"
                            label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                          />
                          <br />
                          <Form.TextArea
                            maxLength="330"
                            onChange={handleInputChange()}
                            value={highlightsDay2 || ''}
                            id="highlightsDay2"
                            name="highlightsDay2"
                            label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                          />
                          <br />
                        </>
                      )}
                      {period2 && (
                        <>
                          <Form.TextArea
                            maxLength="330"
                            onChange={handleInputChange()}
                            value={highlightsDay3 || ''}
                            id="highlightsDay3"
                            name="highlightsDay3"
                            label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                          />
                          <br />
                          <Form.TextArea
                            maxLength="330"
                            onChange={handleInputChange()}
                            value={highlightsDay4 || ''}
                            id="highlightsDay4"
                            name="highlightsDay4"
                            label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                          />
                          <br />
                        </>
                      )}

                      <TextCopy name="medarrangor-text-copy" />

                      <RenderCosponsor
                        cosponsors={cosponsors}
                        deleteCosponsors={deleteCosponsors}
                        handleInputChange={handleInputChange}
                      >
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            setState((prevState) => {
                              const newItemKey = `s${
                                prevState.cosponsorsCount + 1
                              }`;

                              set(
                                prevState.cosponsors,
                                [newItemKey, 'id'],
                                newItemKey,
                              );

                              return {
                                ...prevState,
                                cosponsors: {
                                  ...prevState.cosponsors,
                                },
                                cosponsorsCount: prevState.cosponsorsCount + 1,
                              };
                            });
                          }}
                          disabled={size(cosponsors) >= 4}
                        />
                      </RenderCosponsor>
                      <br />
                      <br />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    {error ? (
                      <Message
                        negative
                        header="Error occurred"
                        content={error}
                      />
                    ) : null}
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        type="button"
                        basic
                        fluid
                        primary
                        content="Tillbaka"
                        size="large"
                        icon="left arrow"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            step: prevState.step - 1,
                          }));
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        primary
                        type="submit"
                        icon="checkmark"
                        labelPosition="right"
                        size="large"
                        floated="right"
                        content="Bekräfta bokning"
                        disabled={submitting}
                        loading={submitting}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {modalOpen && (
                  <Modal
                    open={modalOpen}
                    onClose={handleClose}
                    size="tiny"
                    closeOnDimmerClick={false}
                    dimmer="inverted"
                  >
                    <Modal.Content>
                      <WhiteBox>
                        <DataBox>
                          <div>
                            <ul aria-label="Vänligen bekräfta dina uppgifter">
                              <li>
                                <TextCopy name="confirm-your-details" />
                              </li>
                              <li aria-label="Organisationsnamn">
                                <span className="form-like">
                                  {organizerName || orgName}
                                </span>
                              </li>
                              <li aria-label="Tält">
                                <span className="form-like">{taltyta}</span>
                              </li>
                              {period1 && (
                                <li aria-label="Period-1">
                                  <span className="form-like">
                                    {period1Label}
                                  </span>
                                </li>
                              )}
                              {period2 && (
                                <li aria-label="Period-2">
                                  <span className="form-like">
                                    {period2Label}
                                  </span>
                                </li>
                              )}
                              {bredvid && <li aria-label="Bredvid"> Ja</li>}
                              <li aria-label="Pris/period">
                                <span className="form-like">
                                  {Number(pris).toLocaleString('sv')} kr exkl.{' '}
                                  moms
                                </span>
                              </li>
                              <li aria-label="Antal">
                                <span className="form-like">{quantity}</span>
                              </li>
                              <li aria-label="Pris">
                                <span className="form-like">
                                  {Number(totalPrice).toLocaleString('sv')} kr
                                  exkl. moms
                                </span>
                              </li>
                            </ul>
                          </div>
                          {currentUserOrganisation && (
                            <RenderUserDetails
                              currentUserOrganisation={currentUserOrganisation}
                              firestore={firestore}
                            />
                          )}
                          <TermsCheckBox
                            handleCheckboxChange={handleCheckboxChange}
                          />
                        </DataBox>
                      </WhiteBox>
                    </Modal.Content>
                    <Modal.Actions>
                      <div className="flex no-gap">
                        <Button
                          basic
                          onClick={handleClose}
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                        <Button
                          type="submit"
                          loading={submitting}
                          disabled={
                            !(
                              godkannKopvillkorOchBekraftelse &&
                              godkannJarvaVekanKod
                            )
                          }
                          primary
                          content="Bekräfta"
                          labelPosition="right"
                          icon="checkmark"
                          onClick={() => {
                            handleClose();
                            handleSubmit();
                          }}
                        />
                      </div>
                    </Modal.Actions>
                  </Modal>
                )}
              </Form>
            </WhiteBox>
          </Fragment>
        );
      }
      case 3:
        return <BookingConfirm />;
      case 1:
      default:
        return (
          <RenderTents
            products={selectedProducts(products)}
            handleInputChange={handleInputChange()}
            handleNextButtonClick={handleNextButtonClick}
            {...state}
          />
        );
    }
  };

  return (
    <ContentContainer>
      <section>
        <WhiteBox>
          <h1>
            <ContentEditor inline contentId="page-tent-title-1">
              Tältyta
            </ContentEditor>
          </h1>

          {state.step === 1 && (
            <SelectProducts>
              {selectedProducts(products)?.map((item) => {
                const { productId, taltyta } = item;
                let localTitle = 'Tältyta';

                if (productId === 'tent5x5') {
                  localTitle = 'Tältplats';
                }

                return (
                  <a key={productId} href={`#scroll_to_${productId}`}>
                    {localTitle} {taltyta}
                  </a>
                );
              })}
            </SelectProducts>
          )}
        </WhiteBox>

        {renderSteps()}
      </section>
    </ContentContainer>
  );
}

export default Tent;
