// fix:import sort format
/**
 *
 * @param {number} startDate Event start date ({day: number, month: number})
 * @param {number} endDate Event end date ({day: number, month: number})
 * @param {number} year Event year
 * @returns {Array<{day: number, month: string, dayMonth: string, full: string, timeStamp: number}>} - An array of objects containing the short and full date representations, and the corresponding timestamp
 */
function dateRangeStrings(startDate, endDate, year) {
  const weekDates = [];

  const start = new Date(year, startDate.month - 1, startDate.day);
  const end = new Date(year, endDate.month - 1, endDate.day);

  // Days and months in Swedish
  const daysOfWeek = [
    'Söndag',
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag',
  ];
  const monthsOfYear = [
    'januari',
    'februari',
    'mars',
    'april',
    'maj',
    'juni',
    'juli',
    'augusti',
    'september',
    'oktober',
    'november',
    'december',
  ];

  // Collect dates in the specified range
  for (
    let currentDate = new Date(start);
    currentDate <= end;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const currentDayOfWeek = daysOfWeek[currentDate.getDay()];
    const day = currentDate.getDate();
    const month = monthsOfYear[currentDate.getMonth()];
    const formattedDate = `${currentDayOfWeek} ${day} ${month}`;
    weekDates.push({
      day,
      month,
      dayMonth: `${day} ${month}`,
      full: formattedDate,
      timeStamp: currentDate.getTime(),
    });
  }

  return weekDates;
}

export default dateRangeStrings;

// const result = dateRangeStrings(
//   { day: 11, month: 6 },
//   { day: 14, month: 6 },
//   2025
// );
// console.log(result);

/* [
  {
    day: 11,
    month: 'juni',
    dayMonth: '11 juni',
    full: 'Onsdag 11 juni',
    timeStamp: 1749592800000
  },
  {
    day: 12,
    month: 'juni',
    dayMonth: '12 juni',
    full: 'Torsdag 12 juni',
    timeStamp: 1749679200000
  },
  {
    day: 13,
    month: 'juni',
    dayMonth: '13 juni',
    full: 'Fredag 13 juni',
    timeStamp: 1749765600000
  },
  {
    day: 14,
    month: 'juni',
    dayMonth: '14 juni',
    full: 'Lördag 14 juni',
    timeStamp: 1749852000000
  }
] */
