import { useState } from 'react';

import { withFirestore } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';

import { Container, FormWrapper, AnimationWrapper } from './styles';
import { EVENT_YEAR, EVENT_DURATION_STRING } from '../../configs';
import { LandingPageWrapper } from '../../styledComponents';
import SignUpContainer from '../SignUpContainer';
import Login from '../../components/Login';

function LandingPage({ toPath = '/user', admin = false }) {
  const [showLogin, setShowLogin] = useState(true);
  const [step, setStep] = useState(1);
  const totalSteps = 3;

  const handelNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handelReset = () => {
    setStep(1);
    setShowLogin(true);
  };

  const handelPrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const renderText = () => {
    if (showLogin) {
      return admin ? (
        <>
          <h1>Välkommen till Järvaveckans administration!</h1>
          <p>Skriv in dina uppgifter för att logga in på bokningssidan.</p>
        </>
      ) : (
        <h1>Välkommen till Järvaveckan {EVENT_YEAR}!</h1>
      );
    }

    switch (step) {
      case 2:
        return (
          <h1 className="fz-4rem_desktop">
            Järvaveckan är den självklara mötesplatsen för social hållbarhet,
            demokrati, inkludering och mångfald. Vi välkomnar alla arrangörer
            som vill skapa positiv förändring i samhället.
          </h1>
        );
      case 3:
        return (
          <h1>
            Järvaveckan är en mötesplats för öppna och tillgängliga samtal utan
            barriärer.
          </h1>
        );
      case 4:
        return (
          <h1>
            Genom att medverka riktar du landets intresse mot alla de frågor som
            Sveriges utsatta områden ställer till samhället.
          </h1>
        );

      case 1:
      default:
        return (
          <>
            <h1>Järvaveckan {EVENT_YEAR}</h1>
            <p>
              Järvaveckan handlar om inkludering av alla människor i det
              sociala, ekonomiska och politiska livet. Tillsammans gör vi
              skillnad!
            </p>
            <p>
              Skapa ett konto på Järvaveckans bokningssida för att boka ditt
              deltagande nu.
            </p>
            <p>
              Alla ansökningar genomgår en granskning innan de godkänns för ett
              konto hos Järvaveckan.
            </p>
          </>
        );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Järvaveckans bokningssystem</title>
        <meta
          name="description"
          content={`Boka ditt deltagande nu! Vi har nu öppnat bokningen för Järvaveckan ${EVENT_YEAR}.`}
        />
      </Helmet>
      <LandingPageWrapper>
        <h2>
          <a
            href="https://jarvaveckan.se/"
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline hover-current-color"
          >
            Järvaveckan
          </a>{' '}
          <span>{EVENT_DURATION_STRING}</span>
        </h2>
        <Container>
          <section>{renderText()}</section>
          <section>
            <FormWrapper>
              {showLogin ? (
                <Login
                  toPath={toPath}
                  admin={admin}
                  goToSignUp={() => setShowLogin(false)}
                />
              ) : (
                <SignUpContainer
                  handelNextStep={handelNextStep}
                  handelPrevStep={handelPrevStep}
                  handelReset={handelReset}
                  step={step}
                  totalSteps={totalSteps}
                  goToLogin={() => setShowLogin(true)}
                />
              )}
            </FormWrapper>
          </section>
        </Container>
      </LandingPageWrapper>
      <AnimationWrapper>
        <lottie-player
          src="https://lottie.host/d71e4030-e365-4751-96ee-0fea9c838c2c/uE27fSGGsE.json"
          autoPlay
          loop
          mode="normal"
          count="0"
          speed="1"
          direction="1"
          intermission="0"
          background="#0000"
          debug="true"
        />
      </AnimationWrapper>
    </div>
  );
}

export default compose(withFirestore)(LandingPage);
