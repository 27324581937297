import map from 'lodash/map';
import { Form, Icon } from 'semantic-ui-react';

function RenderCosponsor(props) {
  const { children, cosponsors, deleteCosponsors, handleInputChange } = props;

  const inputElements = map(cosponsors, (value, key) => {
    const { hemsida, organisationsnamn, organisationsnummer } = value || {};

    return (
      <fieldset key={key}>
        <legend>Medarrangör</legend>
        <div style={{ textAlign: 'right' }}>
          <Icon
            name="trash alternate outline"
            color="red"
            size="large"
            link
            onClick={() => {
              deleteCosponsors(key);
            }}
          />
        </div>
        <Form.Input
          label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
          maxLength="120"
          onChange={handleInputChange(key)}
          name="organisationsnamn"
          value={organisationsnamn || ''}
        />

        <Form.Input
          label="Organisationsnummer (i formatet 000000-0000)"
          onChange={handleInputChange(key)}
          maxLength="20"
          minLength="11"
          placeholder="000000-0000"
          name="organisationsnummer"
          value={organisationsnummer || ''}
          // pattern="^\d{6}-\d{4}$|^\d{3}\s?\d{3}\s?\d{3}$|^\d{8}$|^\d{7}-\d{1}$"
        />

        <Form.Input
          label="Hemsida (max 160 tecken inkl. blanksteg)"
          maxLength="160"
          onChange={handleInputChange(key)}
          name="hemsida"
          value={hemsida || ''}
        />
        <br />
        <br />
      </fieldset>
    );
  });

  return (
    <>
      {inputElements}
      {children}
    </>
  );
}

export default RenderCosponsor;
