import styled from 'styled-components';

const AppContainer = styled.main`
  .columns {
    min-height: calc(100vh - 76.34px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .sidenav {
      background-color: var(--jarva-light-back);
      min-width: 255px;
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 1rem;
      height: fit-content;
    }

    .main {
      flex: 1 0 70%;
      background-color: #fff;
      overflow: hidden;
      border-radius: 1rem;
      height: fit-content;
      padding: 1.5rem;
      box-sizing: border-box;
    }

    /* Large tablets and small desktops */
    @media (max-width: 1024px) {
      .sidenav {
        min-width: 200px;
      }
    }

    /* Tablets */
    @media (max-width: 768px) {
      .sidenav {
        min-width: 180px;
      }

      .main {
        padding: 1rem;
      }
    }

    /* Large phones */
    @media (max-width: 600px) {
      flex-direction: column;

      .sidenav {
        position: static;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.75rem;

        min-width: 100%;
      }

      .main {
        padding: 1rem 0.75rem;
      }
    }

    /* Small phones */
    @media (max-width: 480px) {
      .sidenav {
        gap: 0.5rem;
      }

      .main {
        padding: 0.75rem 0.5rem;
      }
    }
  }
`;

export { AppContainer };
