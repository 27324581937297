import { useEffect, useState } from 'react';

import { Button, Form, Grid, Message } from 'semantic-ui-react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';

import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import RenderSeminarSlots from './RenderSeminarSlots';
import { BOOKING_COLLECTION_CURRENT_EVENT } from '../../configs';

const INITAL_STATE = {
  seminars: {},
  seminarsLive: {},
  sponsor: {},
  sponsor2: {},
  tent2_5x2_5: {},
  tent5x2_5: {},
  tent5x5: {},
};

export function AdminSettings(props) {
  const { stats, firestore } = props;

  const [state, setState] = useState(INITAL_STATE);
  const [error, setError] = useState(false);

  const [initialTent2_5x2_5, setInitialTent2_5x2_5] = useState();
  const [initialTent5x2_5, setInitialTent5x2_5] = useState();
  const [initialTent5x5, setInitialTent5x5] = useState();

  const [initalSponsor, setinitalSponsor] = useState();
  const [initalSponsor2, setinitalSponsor2] = useState();

  const [initialSeminars, setinitialSeminars] = useState();
  const [initialSeminarsLive, setinitialSeminarsLive] = useState();

  const [isLaoding, setIsLaoding] = useState(false);

  useEffect(() => {
    if (!stats) return;

    const {
      seminars,
      seminarsLive,
      sponsor,
      sponsor2,
      tent2_5x2_5,
      tent5x2_5,
      tent5x5,
    } = stats;

    if (!tent2_5x2_5 && !sponsor && !sponsor2 && !seminars && !seminarsLive) {
      return;
    }

    const { availableTimeSlots } = seminars;
    const { availableTimeSlots: availableTimeSlotsLive } = seminarsLive || {};

    const tent2_5x2_5_selected = {
      p1Max: tent2_5x2_5?.p1Max,
      p2Max: tent2_5x2_5?.p2Max,
    };
    const tent5x2_5_selected = {
      p1Max: tent5x2_5?.p1Max,
      p2Max: tent5x2_5?.p2Max,
    };
    const tent5x5_selected = { p1Max: tent5x5?.p1Max, p2Max: tent5x5?.p2Max };

    setState({
      ...state,
      tent2_5x2_5: tent2_5x2_5_selected,
      tent5x2_5: tent5x2_5_selected,
      tent5x5: tent5x5_selected,
      sponsor: { max: sponsor?.max ?? 0 },
      sponsor2: { max: sponsor2?.max ?? 0 },
      seminars: { ...availableTimeSlots },
      seminarsLive: { ...availableTimeSlotsLive },
    });

    setInitialTent2_5x2_5(tent2_5x2_5_selected);
    setInitialTent5x2_5(tent5x2_5_selected);
    setInitialTent5x5(tent5x5_selected);

    setinitalSponsor({ max: sponsor?.max ?? 0 });
    setinitalSponsor2({ max: sponsor2?.max ?? 0 });
    setinitialSeminars(cloneDeep(availableTimeSlots));
    setinitialSeminarsLive(cloneDeep(availableTimeSlotsLive));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats]);

  const handleChange =
    (id) =>
    ({ target: { name, value } }) => {
      if (id === 'seminars') {
        const { seminars } = state || {};
        const tempSeminars = cloneDeep(seminars);
        set(tempSeminars, `${name}`, +value || 0);
        setState({ ...state, seminars: { ...tempSeminars } });
        return;
      }
      if (id === 'seminarsLive') {
        const { seminarsLive } = state || {};
        const tempSeminars = cloneDeep(seminarsLive);
        set(tempSeminars, `${name}`, +value || 0);
        setState({ ...state, seminarsLive: { ...tempSeminars } });
        return;
      }

      if (id) {
        setState({
          ...state,
          [id]: { ...state[id], [name]: +value },
        });
      }
    };

  // form submit
  const handleSubmit = () => {
    const {
      tent2_5x2_5,
      tent5x2_5,
      tent5x5,
      sponsor,
      sponsor2,
      seminars,
      seminarsLive,
    } = state;

    if (!isEqual(tent2_5x2_5, initialTent2_5x2_5)) {
      setIsLaoding(true);

      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
            doc: 'tent2_5x2_5',
          },
          {
            ...tent2_5x2_5,
          },
          { merge: true },
        )
        .then(() => {
          console.info('tent2_5x2_5 saved successfully');
          setIsLaoding(false);
        })
        .catch((err) => {
          console.warn('Error', err);
          setError({ ...err });
          setIsLaoding(false);
        });
    }

    if (!isEqual(tent5x2_5, initialTent5x2_5)) {
      setIsLaoding(true);

      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
            doc: 'tent5x2_5',
          },
          {
            ...tent5x2_5,
          },
          { merge: true },
        )
        .then(() => {
          console.info('tent5x2_5 saved successfully');
          setIsLaoding(false);
        })
        .catch((err) => {
          console.warn('Error', err);
          setError({ ...err });
          setIsLaoding(false);
        });
    }

    if (!isEqual(tent5x5, initialTent5x5)) {
      setIsLaoding(true);

      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
            doc: 'tent5x5',
          },
          {
            ...tent5x5,
          },
          { merge: true },
        )
        .then(() => {
          console.info('tent5x5 saved successfully');
          setIsLaoding(false);
        })
        .catch((err) => {
          console.warn('Error', err);
          setError({ ...err });
          setIsLaoding(false);
        });
    }

    if (!isEqual(sponsor, initalSponsor)) {
      setIsLaoding(true);
      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
            doc: 'sponsor',
          },
          {
            ...sponsor,
          },
          { merge: true },
        )
        .then(() => {
          console.info('sponsor saved successfully');
          setIsLaoding(false);
        })
        .catch((err) => {
          console.warn('Error', err);
          setError({ ...err });
          setIsLaoding(false);
        });
    }

    if (!isEqual(sponsor2, initalSponsor2)) {
      setIsLaoding(true);
      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
            doc: 'sponsor2',
          },
          {
            ...sponsor2,
          },
          { merge: true },
        )
        .then(() => {
          console.info('sponsor2 saved successfully');
          setIsLaoding(false);
        })
        .catch((err) => {
          console.warn('Error', err);
          setError({ ...err });
          setIsLaoding(false);
        });
    }

    if (!isEqual(seminars, initialSeminars)) {
      setIsLaoding(true);
      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
            doc: 'seminars',
          },
          {
            availableTimeSlots: seminars,
          },
          { merge: true },
        )
        .then(() => {
          console.info('seminars saved successfully');
          setIsLaoding(false);
        })
        .catch((err) => {
          console.warn('Error', err);
          setError({ ...err });
          setIsLaoding(false);
        });
    }

    if (!isEqual(seminarsLive, initialSeminarsLive)) {
      setIsLaoding(true);
      firestore
        .set(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
            doc: 'seminarsLive',
          },
          {
            availableTimeSlots: seminarsLive,
          },
          { merge: true },
        )
        .then(() => {
          console.info('seminarsLive saved successfully');
          setIsLaoding(false);
        })
        .catch((err) => {
          console.warn('Error', err);
          setError({ ...err });
          setIsLaoding(false);
        });
    }
  };

  const {
    tent2_5x2_5 = {},
    tent5x2_5 = {},
    tent5x5 = {},
    sponsor = {},
    sponsor2 = {},
    seminars,
    seminarsLive,
  } = state || {};

  return (
    <ContentContainer>
      <section className="pink">
        <WhiteBox>
          <h3>Inställningar</h3>
          <DataBox>
            <Form
              error={Boolean(error)}
              loading={isLaoding}
              onSubmit={handleSubmit}
            >
              <Grid>
                <Grid.Row columns="equal">
                  <Grid.Column style={{ maxWidth: 500 }}>
                    {/* Tent 2,5x2,5*/}
                    <ul aria-label="Max antal tältplatser 2,5x2,5">
                      <li>
                        <Form.Input
                          label="period 1: 2,5x2,5"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min=""
                          max="100"
                          value={tent2_5x2_5?.p1Max || '0'}
                          name="p1Max"
                          required
                          onChange={handleChange('tent2_5x2_5')}
                        />
                        <Form.Input
                          label="Period 2: 2,5x2,5"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          max="100"
                          value={tent2_5x2_5?.p2Max || '0'}
                          name="p2Max"
                          required
                          onChange={handleChange('tent2_5x2_5')}
                        />
                        {Boolean(error) && (
                          <Message
                            error={Boolean(error)}
                            header={error.code || 'Error'}
                            content={error.message || 'Error'}
                          />
                        )}
                        <Button
                          type="submit"
                          disabled={isEqual(tent2_5x2_5, initialTent2_5x2_5)}
                        >
                          Spara
                        </Button>
                        <Button
                          type="button"
                          disabled={isEqual(tent2_5x2_5, initialTent2_5x2_5)}
                          onClick={() =>
                            setState({
                              ...state,
                              tent2_5x2_5: initialTent2_5x2_5,
                            })
                          }
                        >
                          Avbryt
                        </Button>
                      </li>
                    </ul>
                    <br />

                    {/* Tent 5x2,5*/}
                    <ul aria-label="Max antal tältplatser 5x2,5">
                      <li>
                        <Form.Input
                          label="period 1: 5x2,5"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          max="100"
                          value={tent5x2_5?.p1Max || '0'}
                          name="p1Max"
                          required
                          onChange={handleChange('tent5x2_5')}
                        />
                        <Form.Input
                          label="Period 2: 5x2,5"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          max="100"
                          value={tent5x2_5?.p2Max || '0'}
                          name="p2Max"
                          required
                          onChange={handleChange('tent5x2_5')}
                        />
                        {Boolean(error) && (
                          <Message
                            error={Boolean(error)}
                            header={error.code || 'Error'}
                            content={error.message || 'Error'}
                          />
                        )}
                        <Button
                          type="submit"
                          disabled={isEqual(tent5x2_5, initialTent5x2_5)}
                        >
                          Spara
                        </Button>
                        <Button
                          type="button"
                          disabled={isEqual(tent5x2_5, initialTent5x2_5)}
                          onClick={() =>
                            setState({
                              ...state,
                              tent5x2_5: initialTent5x2_5,
                            })
                          }
                        >
                          Avbryt
                        </Button>
                      </li>
                    </ul>

                    <br />
                    {/* Tent 5x5*/}
                    <ul aria-label="Max antal tältplatser 5x5">
                      <li>
                        <Form.Input
                          label="period 1: 5x5"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          max="100"
                          value={tent5x5?.p1Max || '0'}
                          name="p1Max"
                          required
                          onChange={handleChange('tent5x5')}
                        />
                        <Form.Input
                          label="Period 2: 5x5"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          max="100"
                          value={tent5x5?.p2Max || '0'}
                          name="p2Max"
                          required
                          onChange={handleChange('tent5x5')}
                        />
                        {Boolean(error) && (
                          <Message
                            error={Boolean(error)}
                            header={error.code || 'Error'}
                            content={error.message || 'Error'}
                          />
                        )}
                        <Button
                          type="submit"
                          disabled={isEqual(tent5x5, initialTent5x5)}
                        >
                          Spara
                        </Button>
                        <Button
                          type="button"
                          disabled={isEqual(tent5x5, initialTent5x5)}
                          onClick={() =>
                            setState({
                              ...state,
                              tent5x5: initialTent5x5,
                            })
                          }
                        >
                          Avbryt
                        </Button>
                      </li>
                    </ul>

                    <br />
                    {/* sponsor */}
                    <ul aria-label="Max antal Stödorganisation">
                      <li>
                        <Form.Input
                          label="Stödorganisation / 5x5 (hela veckan)"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          max="100"
                          value={sponsor?.max ?? 0}
                          name="max"
                          required
                          onChange={handleChange('sponsor')}
                        />
                        {Boolean(error) && (
                          <Message
                            error={Boolean(error)}
                            header={error.code || 'Error'}
                            content={error.message || 'Error'}
                          />
                        )}
                        <Button
                          type="submit"
                          disabled={isEqual(sponsor, initalSponsor)}
                        >
                          Spara
                        </Button>
                        <Button
                          type="button"
                          disabled={isEqual(sponsor, initalSponsor)}
                          onClick={() =>
                            setState({
                              ...state,
                              sponsor: initalSponsor,
                            })
                          }
                        >
                          Avbryt
                        </Button>
                      </li>
                    </ul>

                    {/* sponsor2 */}
                    <br />
                    <ul aria-label="Max antal Partner">
                      <li>
                        <Form.Input
                          label="Partner / 5x5 (hela veckan)"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          max="100"
                          value={sponsor2?.max ?? 0}
                          name="max"
                          required
                          onChange={handleChange('sponsor2')}
                        />
                        {Boolean(error) && (
                          <Message
                            error={Boolean(error)}
                            header={error.code || 'Error'}
                            content={error.message || 'Error'}
                          />
                        )}
                        <Button
                          type="submit"
                          disabled={isEqual(sponsor2, initalSponsor2)}
                        >
                          Spara
                        </Button>
                        <Button
                          type="button"
                          disabled={isEqual(sponsor2, initalSponsor2)}
                          onClick={() =>
                            setState({
                              ...state,
                              sponsor2: initalSponsor2,
                            })
                          }
                        >
                          Avbryt
                        </Button>
                      </li>
                    </ul>
                  </Grid.Column>
                </Grid.Row>

                {/* Seminar */}
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <ul aria-label="Antal seminarietider / seminaretält per tid">
                      <li>
                        <Grid>
                          <Grid.Row columns="equal">
                            <Grid.Column>
                              <RenderSeminarSlots
                                productId="seminars"
                                data={seminars}
                                handleChange={handleChange('seminars')}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns="equal">
                            {Boolean(error) && (
                              <Message
                                error={Boolean(error)}
                                header={error.code || 'Error'}
                                content={error.message || 'Error'}
                              />
                            )}
                          </Grid.Row>
                          <Grid.Row columns="equal">
                            <Button
                              type="submit"
                              disabled={isEqual(seminars, initialSeminars)}
                            >
                              Spara
                            </Button>
                            <Button
                              type="button"
                              disabled={isEqual(seminars, initialSeminars)}
                              onClick={() =>
                                setState({
                                  ...state,
                                  seminars: initialSeminars,
                                })
                              }
                            >
                              Avbryt
                            </Button>
                          </Grid.Row>
                        </Grid>
                      </li>
                    </ul>
                  </Grid.Column>
                </Grid.Row>

                {/* Seminar Live */}
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <ul aria-label="Antal seminarietider live / seminaretält live per tid">
                      <li>
                        <Grid>
                          <Grid.Row columns="equal">
                            <Grid.Column>
                              <RenderSeminarSlots
                                productId="seminarsLive"
                                data={seminarsLive}
                                handleChange={handleChange('seminarsLive')}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns="equal">
                            {Boolean(error) && (
                              <Message
                                error={Boolean(error)}
                                header={error.code || 'Error'}
                                content={error.message || 'Error'}
                              />
                            )}
                          </Grid.Row>
                          <Grid.Row columns="equal">
                            <Button
                              type="submit"
                              disabled={isEqual(
                                seminarsLive,
                                initialSeminarsLive,
                              )}
                            >
                              Spara
                            </Button>
                            <Button
                              type="button"
                              disabled={isEqual(
                                seminarsLive,
                                initialSeminarsLive,
                              )}
                              onClick={() =>
                                setState({
                                  ...state,
                                  seminarsLive: initialSeminarsLive,
                                })
                              }
                            >
                              Avbryt
                            </Button>
                          </Grid.Row>
                        </Grid>
                      </li>
                    </ul>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </DataBox>
        </WhiteBox>
      </section>
    </ContentContainer>
  );
}

export default AdminSettings;
