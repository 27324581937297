import { useState, useCallback, Fragment, useEffect } from 'react';

import { Button, Form, Grid, Message, Modal, Label } from 'semantic-ui-react';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import size from 'lodash/size';
import get from 'lodash/get';
import set from 'lodash/set';
import { isEmpty } from 'lodash';

import {
  OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
  TILLGANGLIGHET_OPTIONS,
  SPRAK_OPTIONS,
  AMNE_OPTIONS,
  EVENT_YEAR,
  MAX_SELECT,
  BOOKING_COLLECTION_CURRENT_EVENT,
  REDUX_PATHS_CURRENT_USER,
  FB_COLLECTIONS,
} from '../../configs';
import {
  ContentContainer,
  CardContainer,
  WhiteBox,
  DataBox,
  P,
} from '../../styledComponents';
import BookingInformationNote from '../../components/BookingInformationNote';
import RenderUserDetails from '../../components/RenderUserDetails';
import RenderCosponsor from '../../components/RenderCosponsor';
import BookingConfirm from '../../components/BookingConfirm';
import ContentEditor from '../../components/ContentEditor';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import RenderTents from '../../components/RenderTents';
import { uploadFileToStorage } from '../../api';
import { SelectProducts } from '../Tent/styles';
import Loader from '../../components/Loader';

const defaultState = {
  step: 1,
  fileUploadError: '',
  cosponsorsCount: 0,
  cosponsors: {},
  submitting: false,
  logo: '',
  kopvillkor: '',
  workManInfo: '',
  godkannKopvillkorOchBekraftelse: false,
  godkannJarvaVekanKod: false,
  modalOpen: false,
};

const Sponsor = (props) => {
  const [state, setState] = useState(defaultState);
  const filterKey = 'sponsor';

  const {
    products,
    firebase,
    firestore,
    currentUserOrganisation,
    currentUser,
    loading,
    auth: { uid },
  } = props;

  const {
    step,
    logo,
    kontaktProfilbild,
    kontaktProfilbild2,
    godkannKopvillkorOchBekraftelse,
    godkannJarvaVekanKod,
    error,
    ovrigt,
    cosponsors,
    highlightsDay1,
    highlightsDay2,
    highlightsDay3,
    highlightsDay4,
    submitting,
    modalOpen,
    amne,
    tillganglighet,
    sprak,
    reservationSiteLink,
    kontaktperson,
    kontaktJobbtitel,
    kontaktOrganisation,
    kontaktEmail,
    kontaktTel,
    kontaktperson2,
    kontaktJobbtitel2,
    kontaktOrganisation2,
    kontaktEmail2,
    kontaktTel2,
  } = state;

  // if step changes then we scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleOpen = useCallback(
    () => setState((prev) => ({ ...prev, modalOpen: true })),
    [],
  );
  const handleClose = useCallback(
    () => setState((prev) => ({ ...prev, modalOpen: false })),
    [],
  );

  const handleInputChange = useCallback(
    (cosponsorsId) => (e) => {
      const {
        target: { name, value, type, checked },
      } = e;
      if (cosponsorsId) {
        setState((prev) => {
          const { cosponsors } = prev;
          const newState = { ...cosponsors };
          newState[cosponsorsId] = {
            ...cosponsors[cosponsorsId],
            [name]: value,
          };
          return { ...prev, cosponsors: newState };
        });
      } else {
        const localValue = type === 'checkbox' ? checked : value;
        setState((prev) => ({ ...prev, [name]: localValue }));
      }
    },
    [],
  );

  const handleCheckboxChange = useCallback(
    (_, { name, value, type, checked }) => {
      const localValue = type === 'checkbox' ? checked : value;
      setState((prev) => ({ ...prev, [name]: localValue }));
    },
    [],
  );

  const deleteCosponsors = useCallback((key) => {
    setState((prev) => {
      const cosponsors = { ...prev.cosponsors };
      delete cosponsors[key];
      return {
        ...prev,
        cosponsors,
        cosponsorsCount: prev.cosponsorsCount - 1,
      };
    });
  }, []);

  const handleNextButtonClick = useCallback((data) => {
    setState((prev) => ({
      ...prev,
      step: prev.step + 1,
      selectedProduct: { ...data },
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    setState((prev) => ({ ...prev, submitting: true }));

    const { organizerName, orgName } = currentUserOrganisation || {};

    const omitState = [
      'step',
      'kopvillkor',
      'workManInfo',
      'modalOpen',
      'selectedProduct',
      'logo',
      'kontaktProfilbild',
      'kontaktProfilbild2',
      'submitting',
      'fileUploadError',
      'sponsorBookingsError',
    ];

    const selectedState = omit(state, omitState);

    const selectedProduct = get(state, 'selectedProduct');

    if (isEmpty(selectedProduct)) {
      return 'empty product';
    }

    const filteredProductProperties = omit(selectedProduct, [
      'createdAt',
      'updatedAt',
      'images',
    ]);

    const { pris } = filteredProductProperties;

    const tentDefaults = {
      quantity: 1,
      productQuantity: 1,
      period1: true,
      period2: true,
      totalPrice: pris, // needed to match like tent
    };

    const cleanedOrganisation = omit(
      currentUserOrganisation,
      OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
    );

    const { dbDocId, email, telefonnummer, firstName, secondName } =
      currentUser || {};
    const toEmails = [email];
    const ccEmails = cleanedOrganisation?.users?.filter(
      (userEmail) => userEmail !== email,
    );
    const emailsToSend = {
      to: toEmails,
      cc: ccEmails,
    };

    const bookedBy = {
      dbDocId,
      email,
      telefonnummer,
      firstName,
      secondName,
    };

    const bookingDataRaw = {
      ...tentDefaults,
      ...selectedState,
      ...filteredProductProperties,
      userId: uid,
      collection: BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings,
      sentInvoice: false,
      branchId: currentUserOrganisation.branchId,
      branchDbPath: currentUserOrganisation.dbPath,
      organisation: cleanedOrganisation,
      organizerName: organizerName || orgName,
      emailsToSend,
      bookedBy,
      createdAt: new Date().toISOString(),
    };

    if (logo) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid: currentUserOrganisation.branchId,
          fileList: logo,
          firebase,
        });

        if (downloadURL) {
          bookingDataRaw.logo = downloadURL;
        }
      } catch (error) {
        console.error('upload logo error', error);
      } finally {
        console.info('logo uploaded request finished');
      }
    }

    if (kontaktProfilbild) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid: currentUserOrganisation.branchId,
          fileList: kontaktProfilbild,
          firebase,
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild', error);
      } finally {
        console.info('kontaktProfilbild request finished');
      }
    }

    if (kontaktProfilbild2) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid: currentUserOrganisation.branchId,
          fileList: kontaktProfilbild2,
          firebase,
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild2 = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild2', error);
      } finally {
        console.info('kontaktProfilbild2 request finished');
      }
    }

    const bookingData = omit(bookingDataRaw, isNil);

    firestore
      .add(
        {
          collection: BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings,
        },
        bookingData,
      )
      .then(() => {
        setState((prev) => ({ ...prev, step: prev.step + 1 }));
      })
      .catch((err) => setState((prev) => ({ ...prev, error: err })))
      .finally(() => {
        firestore
          .get({
            collection: BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings,
            path: REDUX_PATHS_CURRENT_USER.currentUserSponsorBookings,
            where: [['branchId', '==', currentUserOrganisation.branchId]],
          })
          .catch((err) => console.error(err))
          .finally(() => setState((prev) => ({ ...prev, submitting: false })));

        firestore
          .get({
            collection: BOOKING_COLLECTION_CURRENT_EVENT.sponsorBookings,
            path: FB_COLLECTIONS.sponsorBookings,
          })
          .catch((err) => console.error(err))
          .finally(() => setState((prev) => ({ ...prev, submitting: false })));
      });
  }, [
    currentUser,
    currentUserOrganisation,
    firebase,
    firestore,
    kontaktProfilbild,
    kontaktProfilbild2,
    logo,
    state,
    uid,
  ]);

  const selectedProducts = useCallback(
    (products) =>
      filter(products, (item) => includes(item.category, filterKey)),
    [],
  );

  const renderSteps = () => {
    {
      const { organizerName, orgName } = currentUserOrganisation || {};

      if (loading) {
        return (
          <ContentContainer>
            <WhiteBox>
              <section>
                <p>
                  <Loader text="" />
                </p>
              </section>
            </WhiteBox>
          </ContentContainer>
        );
      }

      switch (step) {
        case 2: {
          const selectedProduct = get(state, 'selectedProduct', {});

          if (isEmpty(selectedProduct)) {
            return 'empty product';
          }

          const {
            productId,
            pris,
            taltyta,
            labelDay1,
            labelDay2,
            labelDay3,
            labelDay4,
            fullPeriodLabel,
          } = selectedProduct;

          // const { isFullPeriod, totalPrice } =
          //   priceOfSelectedProduct(selectedProduct);

          return (
            <Fragment key={productId}>
              <WhiteBox transparent>
                <h2>Information om ditt partnerskappaket</h2>
                <ContentEditor contentId="page-book-sponsor-step-2-info">
                  Här kan ni fylla i information kring era aktiviteter på
                  Järvaveckan 2024. För att säkra er plats och genomföra
                  bokningen behöver ni inledningsvis enbart fylla i uppgifterna
                  under Mitt Konto för att kunna göra en bokning.
                </ContentEditor>
                <br />
                <p>
                  <b>Period:</b> {fullPeriodLabel} <br />
                  <b>Tält:</b> {taltyta}.<br />
                  <b>Pris:</b> {Number(pris).toLocaleString('sv')} kr exkl. moms
                  <br />
                </p>

                <BookingInformationNote />
              </WhiteBox>
              <WhiteBox>
                <Form
                  error={Boolean(error)}
                  onSubmit={handleOpen}
                  loading={submitting}
                >
                  <Grid stackable columns="equal">
                    <Grid.Column>
                      <h5>Uppgifter för tältet</h5>
                      <Form.TextArea
                        onChange={handleInputChange()}
                        label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                        maxLength="800"
                        id="ovrigt"
                        name="ovrigt"
                        value={ovrigt || ''}
                      />
                      <h5>Kategorityp</h5>
                      <Form.Select
                        multiple
                        label="Ämne"
                        onChange={(_, target) => {
                          const { name, value } = target;
                          if (value.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          setState((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                        }}
                        id="amne"
                        name="amne"
                        value={amne || []}
                        options={AMNE_OPTIONS}
                        placeholder="välj ett eller flera"
                      />
                      <Form.Select
                        label="Tillgänglighet"
                        onChange={(_, target) => {
                          const { name, value } = target;
                          if (value.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          setState((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                        }}
                        id="tillganglighet"
                        name="tillganglighet"
                        value={tillganglighet || []}
                        multiple
                        options={TILLGANGLIGHET_OPTIONS}
                        placeholder="välj"
                      />
                      <Form.Select
                        label="Språk"
                        multiple
                        onChange={(_, target) => {
                          const { name, value } = target;
                          if (value.length > MAX_SELECT) {
                            return alert('Max 5 val');
                          }
                          setState((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                        }}
                        id="sprak"
                        name="sprak"
                        value={sprak || []}
                        options={SPRAK_OPTIONS}
                        placeholder="välj"
                      />

                      <h5>
                        Extern länk för anmälan via exempelvis Eventbrite eller
                        Invajo.
                      </h5>
                      <Form.Input
                        onChange={handleInputChange()}
                        id="reservationSiteLink"
                        name="reservationSiteLink"
                        value={reservationSiteLink || ''}
                        type="url"
                        placeholder="https://accomplice.se"
                        label="Fyller du i detta fält
                      så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                      den länk du fyller i."
                      />

                      <h5>KONTAKTPERSONER FÖR TÄLTET</h5>
                      <Label className="info">
                        Kontaktinfo (email & tel) kommer synas på vår hemsida
                        offentligt!
                      </Label>
                      {/* contact 1 */}
                      <fieldset>
                        <legend>Kontaktperson: 1</legend>
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktperson"
                          name="kontaktperson"
                          value={kontaktperson || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktJobbtitel"
                          name="kontaktJobbtitel"
                          value={kontaktJobbtitel || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktOrganisation"
                          name="kontaktOrganisation"
                          value={kontaktOrganisation || ''}
                          label="Organisation"
                        />
                        <Form.Input
                          maxLength="140"
                          onChange={handleInputChange()}
                          id="kontaktEmail"
                          name="kontaktEmail"
                          label="Email"
                          type="email"
                          value={kontaktEmail || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          onChange={handleInputChange()}
                          id="kontaktTel"
                          name="kontaktTel"
                          label="Tel"
                          type="tel"
                          value={kontaktTel || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                      {/* contact 2 */}
                      <fieldset>
                        <legend>Kontaktperson: 2</legend>
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktperson2"
                          name="kontaktperson2"
                          value={kontaktperson2 || ''}
                          label="Förnamn och efternamn"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktJobbtitel2"
                          name="kontaktJobbtitel2"
                          value={kontaktJobbtitel2 || ''}
                          label="Jobbtitel"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleInputChange()}
                          id="kontaktOrganisation2"
                          name="kontaktOrganisation2"
                          value={kontaktOrganisation2 || ''}
                          label="Organisation"
                        />
                        <Form.Input
                          maxLength="140"
                          onChange={handleInputChange()}
                          id="kontaktEmail2"
                          name="kontaktEmail2"
                          label="Email"
                          type="email"
                          value={kontaktEmail2 || ''}
                          placeholder="name@mail.com"
                        />
                        <Form.Input
                          maxLength="20"
                          onChange={handleInputChange()}
                          id="kontaktTel2"
                          name="kontaktTel2"
                          label="Tel"
                          type="tel"
                          value={kontaktTel2 || ''}
                          placeholder="+46123456789"
                        />
                      </fieldset>
                    </Grid.Column>

                    <Grid.Column>
                      <h5>Highlights / Händer i tältet</h5>
                      <P>
                        <TextCopy name="highlites-text-copy" />
                        <br />
                        <br />
                      </P>
                      <Form.TextArea
                        maxLength="330"
                        onChange={handleInputChange()}
                        value={highlightsDay1 || ''}
                        id="highlightsDay1"
                        name="highlightsDay1"
                        label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                      />
                      <br />
                      <Form.TextArea
                        maxLength="330"
                        onChange={handleInputChange()}
                        value={highlightsDay2 || ''}
                        id="highlightsDay2"
                        name="highlightsDay2"
                        label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                      />
                      <br />
                      <Form.TextArea
                        maxLength="330"
                        onChange={handleInputChange()}
                        value={highlightsDay3 || ''}
                        id="highlightsDay3"
                        name="highlightsDay3"
                        label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                      />
                      <br />
                      <Form.TextArea
                        maxLength="330"
                        onChange={handleInputChange()}
                        value={highlightsDay4 || ''}
                        id="highlightsDay4"
                        name="highlightsDay4"
                        label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                      />
                      <br />
                      <br />

                      <TextCopy name="medarrangor-text-copy" />

                      <RenderCosponsor
                        cosponsors={cosponsors}
                        deleteCosponsors={deleteCosponsors}
                        handleInputChange={handleInputChange}
                      >
                        <Button
                          type="button"
                          secondary
                          size="tiny"
                          icon="user plus"
                          labelPosition="right"
                          content="Lägg till medarrangörer"
                          onClick={() => {
                            setState((prev) => {
                              const newItemKey = `s${prev.cosponsorsCount + 1}`;
                              set(
                                prev.cosponsors,
                                [newItemKey, 'id'],
                                newItemKey,
                              );
                              return {
                                ...prev,
                                cosponsors: {
                                  ...prev.cosponsors,
                                },
                                cosponsorsCount: prev.cosponsorsCount + 1,
                              };
                            });
                          }}
                          disabled={size(cosponsors) >= 4}
                        />
                      </RenderCosponsor>
                      <br />
                      <br />
                    </Grid.Column>

                    {/* form  error*/}
                    <Grid.Row>
                      {error ? (
                        <Message
                          negative
                          header="Error occurred"
                          content={error}
                        />
                      ) : null}
                    </Grid.Row>

                    {/* buttons */}
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          type="button"
                          basic
                          fluid
                          primary
                          content="Tillbaka"
                          size="large"
                          icon="left arrow"
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              step: prev.step - 1,
                            }));
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          primary
                          type="submit"
                          icon="checkmark"
                          labelPosition="right"
                          size="large"
                          floated="right"
                          content="Bekräfta bokning"
                          disabled={submitting}
                          loading={submitting}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {modalOpen && (
                    <Modal
                      open={modalOpen}
                      onClose={handleClose}
                      size="tiny"
                      closeOnDimmerClick={false}
                      dimmer="inverted"
                    >
                      <Modal.Content>
                        <WhiteBox>
                          <DataBox>
                            <div>
                              <ul aria-label="Vänligen bekräfta dina uppgifter">
                                <li>
                                  <TextCopy name="confirm-your-details" />
                                </li>
                                <li aria-label="Organisationsnamn/ synas och presenteras">
                                  <span className="form-like">
                                    {organizerName || orgName}
                                  </span>
                                </li>
                                <li aria-label="Tält">
                                  <span className="form-like">{taltyta}</span>
                                </li>
                                <li aria-label="Period">
                                  <span className="form-like">
                                    {fullPeriodLabel}
                                  </span>
                                </li>
                                <li aria-label="Pris/period">
                                  <span className="form-like">
                                    {Number(pris).toLocaleString('sv')} kr exkl.
                                    moms
                                  </span>
                                </li>
                              </ul>
                            </div>
                            {currentUserOrganisation && (
                              <RenderUserDetails
                                currentUserOrganisation={
                                  currentUserOrganisation
                                }
                                firestore={firestore}
                              />
                            )}
                            <TermsCheckBox
                              handleCheckboxChange={handleCheckboxChange}
                            />
                          </DataBox>
                        </WhiteBox>
                      </Modal.Content>
                      <Modal.Actions>
                        <div className="flex no-gap">
                          <Button
                            basic
                            onClick={handleClose}
                            primary
                            content="Avbryt"
                            icon="cancel"
                            labelPosition="right"
                          />
                          <Button
                            type="submit"
                            loading={submitting}
                            disabled={
                              !(
                                godkannKopvillkorOchBekraftelse &&
                                godkannJarvaVekanKod
                              )
                            }
                            primary
                            content="Bekräfta"
                            labelPosition="right"
                            icon="checkmark"
                            onClick={() => {
                              handleClose();
                              handleSubmit();
                            }}
                          />
                        </div>
                      </Modal.Actions>
                    </Modal>
                  )}
                </Form>
              </WhiteBox>
            </Fragment>
          );
        }
        case 3:
          return <BookingConfirm />;
        case 1:
        default:
          return (
            <RenderTents
              isFromSponsor
              products={selectedProducts(products)}
              handleInputChange={handleInputChange()}
              handleNextButtonClick={handleNextButtonClick}
              {...state}
            />
          );
      }
    }
  };

  const headerSection = useCallback(
    () => (
      <WhiteBox>
        <CardContainer>
          <h3>
            <ContentEditor inline contentId="page-user-book-partnerskap-title">
              Partnerskap till Järvaveckan
            </ContentEditor>{' '}
            {EVENT_YEAR}
          </h3>
          <div className="media-text">
            <div>
              <ContentEditor contentId="page-user-book-partnerskap-main-1">
                {`<p>Partnerskapserbjudandet är en möjlighet för företag, myndigheter
              och organisationer att sprida sitt budskap samtidigt som man är en
              viktig part i att möjliggöra dialog och förståelse mellan olika
              typer av makthavare, företag och medborgare som traditionellt inte
              givits de bästa förutsättningar att delta i det offentliga
              samtalet.
              </p>
              <p>
              Som partner till Järvaveckan får företag associationsrätt och
              tillgång till den självklara mötesplatsen för samhällsförändring.
              Vi erbjuder en plattform som syftar till att verka för att alla
              ska bli inkluderade i det sociala, ekonomiska och politiska livet.
              Drygt 300 organisationer har redan insett vikten av att befinna
              sig i utanförskapsområden där politiken behövs som mest, men där
              deltagandet i den politiska debatten, näringslivet och i
              civilsamhället ofta är som svagast.
              </p>

              <h4>
              <span>Järvaveckan skapar värde</span>
              </h4>
              <p>
              Järvaveckan strävar efter att slipa ner trösklarna mellan vi och
              dom. Vi erbjuder en plattform där politiken, näringslivet och
              civilsamhället får en unik chans att skapa relationer och lära sig
              av varandra. Fördelarna med att vara med på Järvaveckan är många
              </p>
              <ul>
              <li>Ökad varumärkesexponering</li>
              <li>
                Möjlighet att engagera Järvaveckans deltagare i företagets
                viktigaste frågor
              </li>
              <li>Association till Järvaveckan </li>
              <li>
                Möjlighet att genomföra stora rekryteringar och andra
                aktiviteter såsom kontakt med boende och vardagligt folk,
                fördjupade kundrelationer och dialog med medlemmar – för att
                nämna några exempel
              </li>
              <li>
                Möt över 50 000 deltagare som är utspridda över en bred målgrupp
              </li>
              </ul>
            `}
              </ContentEditor>
            </div>
            <div className="medium-image">
              <ContentEditor contentId="page-user-book-partnerskap-main-1-image">
                {`
              <img
                src="https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FPartnerskap%20-%205x5%2Fimg-10.jpg?alt=media&token=bea2cd5e-0a72-4f23-b807-7b50400f9979"
                alt="people"
                height="auto"
                width="100%"
              />
              `}
              </ContentEditor>
            </div>
          </div>
        </CardContainer>
      </WhiteBox>
    ),
    [],
  );

  return (
    <ContentContainer>
      <section>
        <WhiteBox>
          <h1>
            <ContentEditor inline contentId="page-partnerskap-title-1">
              Partnerskap
            </ContentEditor>
          </h1>
          {step === 1 ? (
            <SelectProducts>
              {selectedProducts(products)?.map((item) => {
                const { productId, title } = item;
                return (
                  <a key={productId} href={`#scroll_to_${productId}`}>
                    {title}
                  </a>
                );
              })}
            </SelectProducts>
          ) : null}
        </WhiteBox>
        {step === 1 ? headerSection() : ''}
        {renderSteps()}
      </section>
    </ContentContainer>
  );
};

export default Sponsor;
