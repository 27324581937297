export default {
  whiteBackground: 'rgba(255, 255, 255, 1)',
  jarvaBlue: 'rgba(35, 55, 236, 1)',
  jarvaBlueLight: 'rgba(35, 55, 236, 0.1)',
  jarvaRed: '#e04747',
  jarvaTextDark: '#08152C',
  textColorGray: '#848a96',
  textGreyLight: '#1b2b46',
  ipadAndUp: 'only screen and (min-width: 750px)',
  desktopAndUp: 'only screen and (min-width: 1250px)',
  transition: '0.3s ease',
  serif: '"neue-haas-grotesk-display",sans-serif',
  sansSerif: '"neue-haas-grotesk-display",sans-serif',
};
