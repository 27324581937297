const defaultFormFields = [
  {
    type: 'textarea',
    label: 'Beskriv',
    labelInfo: 'info',
    name: 'beskriv',
    value: '',
    placeHolder: '',
    required: true,
    info: '',
  },
  {
    type: 'file',
    label: 'Bild till hemsida',
    labelInfo:
      'Ladda upp en bild till hemsidan på den maten ni kommer att erbjuda.',
    name: 'imageWeb',
    value: '',
    placeHolder: '',
    required: false,
  },
  {
    type: 'file',
    label: 'Bild',
    labelInfo: 'Ladda upp logotyp',
    name: 'logo',
    value: '',
    placeHolder: '',
    required: false,
  },
];

const foodtruckFormInputs = [
  {
    type: 'textarea',
    label: 'Beskriv',
    labelInfo:
      'Beskriv er foodtruck och den mat ni serverar. Specificera huruvida ni har vegetarisk, allergivänliga, halal och kosher alternativ. Dela gärna en fullständig meny i textraden, alternativt länk.',
    name: 'beskriv',
    value: '',
    placeHolder: '',
    required: true,
    info: '',
  },
  {
    type: 'url',
    label: 'Menu link',
    labelInfo: 'Meny i länk inklusive https:// ',
    name: 'menuLink',
    value: '',
    placeHolder: 'https://',
    info: 'Exempel på länk: https://jarvaveckan.se/',
  },
  {
    type: 'file',
    label: 'Bild till hemsida',
    labelInfo:
      'Ladda upp en bild till hemsidan på den maten ni kommer att erbjuda.',
    name: 'imageWeb',
    value: '',
    placeHolder: '',
    required: false,
  },
  {
    type: 'file',
    label: 'Bild',
    labelInfo: 'Ladda upp logotyp',
    name: 'logo',
    value: '',
    placeHolder: '',
    required: false,
  },
];

const torghandelFormInputs = [
  {
    type: 'textarea',
    label: 'Beskriv',
    labelInfo:
      'Beskriv eran verksamhet och vad ni kommer att erbjuda besökare på Järvaveckan ex. vilka varor ni kommer att sälja. Specificera gärna hur ni bidrar till en långsiktig hållbarhet.',
    name: 'beskriv',
    value: '',
    placeHolder: '',
    required: true,
    info: '',
  },
  {
    type: 'file',
    label: 'Bild till hemsida',
    labelInfo:
      'Ladda upp en bild till hemsidan som underlättar för besökarna att förstå vad ni kommer att erbjuda i Torghandeln.',
    name: 'imageWeb',
    value: '',
    placeHolder: '',
    required: false,
  },
  {
    type: 'file',
    label: 'Bild',
    labelInfo: 'Ladda upp logotyp',
    name: 'logo',
    value: '',
    placeHolder: '',
    required: false,
  },
];

export { defaultFormFields, foodtruckFormInputs, torghandelFormInputs };
