import { useEffect, useState } from 'react';

import CompanyDetails from '../../components/CompanyDetails';
import { organisationRequests } from '../../api';
import Invoice from '../../components/Invoice';
import { cleanOrgNr } from '../../utils';

export const initialCompanyState = {
  orgName: '',
  orgNr: '',
  typeOfOrg: '',
  hemsida: '',
  fakturamottagare: '',
  fakturaadress: '',
  postnummer: '',
  ort: '',
  telefonnummer: '',
  fakturaEmail: '',
  orgEmail: '',
  formFilled: false,
  godkannKopvillkorOchBekraftelse: false,
  godkannJarvaVekanKod: false,
  disableSubmit: false,
  totalSteps: 2,
  step: 1,
  apiError: null,
};

const restoreState = () => {
  const sessionState = window.sessionStorage.getItem('registrationForm');

  if (!sessionState) {
    return initialCompanyState;
  }

  try {
    const parsedState = JSON.parse(sessionState);
    return {
      ...initialCompanyState,
      ...parsedState,
      step: Number(parsedState.step) || 1,
      totalSteps: Number(parsedState.totalSteps) || 2,
    };
  } catch (error) {
    console.error('Error parsing session state:', error);
    return initialCompanyState;
  }
};

function OrganisationSignUp(props) {
  const { authId, currentUser } = props;
  // console.info('OrganisationSignUp props', currentUser);

  const [state, setState] = useState(restoreState());

  const { step } = state;

  // on step change remove checkboxes
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      godkannKopvillkorOchBekraftelse: false,
      godkannJarvaVekanKod: false,
    }));
  }, [step]);

  const handleBlur = (e) => {
    const { name, value } = e.target;

    const cleanedValue =
      typeof value === 'string' && name === 'orgNr' ? cleanOrgNr(value) : value;

    setState((prevState) => ({
      ...prevState,
      [name]: cleanedValue,
    }));
  };

  const handleInputChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;
    setState((prevState) => ({
      ...prevState,
      [name]: localValue,
      disableSubmit: false,
    }));
  };

  const setSessionStorage = (stateLocal) => {
    window.sessionStorage.setItem(
      'registrationForm',
      JSON.stringify(stateLocal),
    );
  };

  const clearSessionStorage = () => {
    window.sessionStorage.removeItem('registrationForm');
  };

  const nextStep = (e) => {
    e.preventDefault();
    setState((prevState) => {
      const newState = {
        ...prevState,
        step: Number(prevState.step) + 1,
      };

      setSessionStorage(newState);
      return newState;
    });
  };

  const prevStep = (e) => {
    e.preventDefault();

    setState((prevState) => {
      const newState = {
        ...prevState,
        step: Math.max(1, Number(prevState.step) - 1),
      };

      setSessionStorage(newState);
      return newState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSessionStorage(state);
    const { selectedOrg, createNewBranch, orgNrExists } = state;

    setState((prev) => ({
      ...prev,
      disableSubmit: true,
      loading: true,
      apiError: null,
    }));

    try {
      let requestConfig;

      const commonData = {
        data: {
          userData: { ...currentUser, userId: authId },
          state,
        },
      };

      // console.log('data', commonData.data);

      if (selectedOrg) {
        requestConfig = {
          action: 'requestToJoin',
          ...commonData,
        };
      } else if (createNewBranch) {
        requestConfig = {
          action: 'createNewBranch',
          ...commonData,
        };
      } else if (!orgNrExists) {
        requestConfig = {
          action: 'createNewOrganisation',
          ...commonData,
        };
      }

      if (requestConfig) {
        await organisationRequests.post(requestConfig);
        setState((prev) => ({
          ...prev,
          disableSubmit: false,
          loading: false,
          step: 3,
        }));
        clearSessionStorage();
      }
    } catch (error) {
      console.error('Error submitting organisation sign up:', error);
      setState((prev) => ({
        ...prev,
        disableSubmit: false,
        loading: false,
        apiError:
          error.message || 'Ett fel uppstod vid registrering av organisation',
      }));
    } finally {
      setState((prev) => ({
        ...prev,
        disableSubmit: false,
        loading: false,
      }));
    }
  };

  const handleCancelClick = () => {
    clearSessionStorage();
    setState(initialCompanyState);
  };

  switch (step) {
    case 2:
      return (
        <Invoice
          {...{
            handleInputChange,
            handleBlur,
            handleCancelClick,
            handleSubmit,
            prevStep,
            authId,
            ...state,
          }}
        />
      );
    case 3:
      return (
        <>
          <h3>Organisationsuppgifter</h3>
          <p>Fyll i organisationens uppgifter. Du kan ändra dessa senare.</p>
        </>
      );
    case 1:
    default:
      return (
        <CompanyDetails
          {...{
            handleInputChange,
            handleBlur,
            handleCancelClick,
            handleSubmit,
            nextStep,
            setState,
            authId,
            ...state,
          }}
        />
      );
  }
}

export default OrganisationSignUp;
