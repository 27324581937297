import { PureComponent } from 'react';

import { Button, Input } from 'semantic-ui-react';
import { saveAs } from 'file-saver';
import jsonexport from 'jsonexport';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import get from 'lodash/get';

import { RegisterDataBox } from '../AdminRegister/AdminRegisterStyles';
import { TableContainer } from '../../components/AccDataTable/styles';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import { IosCalendarOutline } from '../../components/Icons';
import Loader from '../../components/Loader';
import {
  BOOKING_COLLECTION_CURRENT_EVENT,
  FB_COLLECTIONS,
  REDUX_PATHS_CURRENT_USER,
} from '../../configs';

class SeminarPlacementList extends PureComponent {
  state = {
    disableSubmit: true,
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const { disableSubmit } = this.state;
    this.setState({ [name]: value });
    if (disableSubmit) this.setState({ disableSubmit: false });
  };

  // renderUserDetails = (userId, usersList) => usersList[userId];

  sortSeminarBookingsList = (seminarsList) =>
    sortBy(seminarsList, ['day', 'timeSlot', 'organizerName']);

  getValueFromProps = (id) => {
    const { orderedSeminarBookings } = this.props;
    const booking = find(orderedSeminarBookings, { id });
    return get(booking, 'seminarTent', '');
  };

  getStateValue = (id) =>
    get(this.state, `${id}`) || this.getValueFromProps(id);

  // cspell:disable
  exportData = (orderedSeminarBookings) => {
    const minimizedSeminars = this.sortSeminarBookingsList(
      orderedSeminarBookings,
    )?.map((a) => ({
      Taltnummer: a.seminarTent,
      Datum: a.dayString,
      Tid: a.timeSlot,
      Arrangor: a.organizerName,
      Titel: a.namn,
      Innehall: a.beskriv,
      Moderator: a.moderator,
      Deltagare: a.deltagare.join(', '),
    }));
    const options = {
      rowDelimiter: ';',
    };
    jsonexport(minimizedSeminars, options, (err, csv) => {
      if (err) return console.error(err);
      const csvblob = new Blob([`\ufeff${csv}`], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(csvblob, 'seminarExport.csv');
    });
  };

  // cspell:enable

  saveToDatabase = (id) => async () => {
    const { firestore } = this.props;
    firestore
      .set(
        {
          collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
          doc: id,
          path: REDUX_PATHS_CURRENT_USER.seminarBookings,
        },
        {
          seminarTent: this.state[id],
          updatedAt: new Date().toISOString(),
        },
        { merge: true },
      )
      .then(() => {
        console.info('saved successfully');
        this.setState({ [id]: '' });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error });
      });

    // get all seminar bookings
    firestore
      .get({
        collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
        path: FB_COLLECTIONS.seminarBookings,
      })
      .catch((error) => {
        console.error(error);
      });
  };

  renderTableBody = (seminarBookings) =>
    this.sortSeminarBookingsList(seminarBookings)?.map(
      ({ organizerName, timeSlot, dayString, slotId, id }) => (
        <tr key={slotId}>
          <td>{organizerName}</td>
          <td>{dayString}</td>
          <td>{timeSlot}</td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                id={id}
                name={id}
                value={this.getStateValue(id) ?? ''}
                onChange={this.handleInputChange}
                size="small"
                type="number"
                min="0"
                max="10"
              />
            </div>
          </td>
          <td>
            <Button
              primary
              basic
              content="Spara"
              icon="pencil alternate"
              labelPosition="right"
              onClick={this.saveToDatabase(id)}
              disabled={!get(this.state, `${id}`, false)}
            />
          </td>
        </tr>
      ),
    );

  render() {
    const { orderedSeminarBookings } = this.props;
    return (
      <ContentContainer>
        <section className="pink">
          <WhiteBox>
            <RegisterDataBox>
              <h3>
                <IosCalendarOutline />
                &nbsp;Seminarie placering
                <div className="filters">
                  {orderedSeminarBookings ? (
                    orderedSeminarBookings.length
                  ) : (
                    <Loader />
                  )}{' '}
                  Seminarie bokningar
                </div>
              </h3>
            </RegisterDataBox>
            <div style={{ textAlign: 'right' }}>
              <Button
                primary
                size="mini"
                basic
                content="Ladda ner data"
                icon="file excel"
                labelPosition="right"
                onClick={() => this.exportData(orderedSeminarBookings)}
              />
            </div>
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>Organisation</th>
                    <th>Datum</th>
                    <th>Time Slot</th>
                    <th>Placering</th>
                    <th>Save</th>
                  </tr>
                </thead>
                <tbody>
                  {orderedSeminarBookings &&
                    this.renderTableBody(orderedSeminarBookings)}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total Seminars</td>
                    <td colSpan={4}>
                      {orderedSeminarBookings
                        ? orderedSeminarBookings.length
                        : 0}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </TableContainer>
          </WhiteBox>
        </section>
      </ContentContainer>
    );
  }
}

export default SeminarPlacementList;
