import { useState } from 'react';

import constant from 'lodash/constant';
import get from 'lodash/get';
import times from 'lodash/times';
import { Button, Modal } from 'semantic-ui-react';

import { DataBox, WhiteBox } from '../../styledComponents';

function RenderBookings(props) {
  const {
    size,
    p1Max,
    p2Max,
    tentsPerPeriod1,
    tentsPerPeriod2,
    tentsPerPeriod1Total,
    tentsPerPeriod2Total,
    title = '',
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const renderModel = () => (
    <Modal
      dimmer="inverted"
      open={modalOpen}
      closeOnDimmerClick
      closeOnDocumentClick
      size="large"
      style={{ maxWidth: 400 }}
      onClose={() => {
        setModalOpen(false);
        setModalContent(null);
      }}
    >
      <Modal.Content scrolling>
        <WhiteBox style={{ padding: '10px', margin: 0 }}>
          <DataBox>{modalContent}</DataBox>
        </WhiteBox>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            setModalOpen(false);
            setModalContent(null);
          }}
        >
          Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <>
      {renderModel()}
      <ul>
        {/* period 1 */}
        <li className="vert">
          <div style={{ lineHeight: 0 }}>
            <h5>
              {title}Tältyta {size}m -{' '}
              <small>
                <i>period 1</i>
              </small>
            </h5>
            <p>
              Maximun {size} allowed ={' '}
              <span className="number-warn">{p1Max}</span>{' '}
            </p>
            <p>
              Current {size} booked ={' '}
              <span className="number-success">{tentsPerPeriod1Total}</span>
            </p>
            {tentsPerPeriod1?.map((item) => {
              const { quantity = 1, organizerName, userId } = item || {};
              const output = [];

              for (let i = 0; i < quantity; i += 1) {
                output.push(
                  <div
                    role="button"
                    tabIndex={0}
                    className="tent"
                    key={i}
                    onClick={() => {
                      if (!item) return;
                      const content = (
                        <div>
                          <ul aria-label="Bokning">
                            <li aria-label="Tält">
                              {title}Tältyta {`${get(item, 'taltyta')}` || ''}
                            </li>
                            <li aria-label="Organisationsnamn">
                              {organizerName}
                            </li>
                            <li aria-label="User Id">{userId}</li>
                            <li aria-label="Pris">
                              {Number(get(item, 'pris', '')).toLocaleString(
                                'sv',
                              )}{' '}
                              kr exkl. moms
                            </li>
                          </ul>
                        </div>
                      );
                      setModalOpen(true);
                      setModalContent(content);
                    }}
                  />,
                );
              }
              return output;
            })}
            {times(p1Max - tentsPerPeriod1Total, constant(null))?.map(
              (a, i) => {
                return <div className="tent empty" key={i} />;
              },
            )}
          </div>
          <br />
        </li>

        {/* period 2 */}
        <li className="vert">
          <div style={{ lineHeight: 0 }}>
            <h5>
              {title}Tältyta {size}m -{' '}
              <small>
                <i>period 2</i>
              </small>
            </h5>
            <p>
              Maximun {size} allowed ={' '}
              <span className="number-warn">{p2Max}</span>
            </p>
            <p>
              Current {size} booked ={' '}
              <span className="number-success">{tentsPerPeriod2Total}</span>
            </p>

            {tentsPerPeriod2?.map((item) => {
              const { quantity = 1, organizerName, userId } = item || {};

              const output = [];

              for (let i = 0; i < quantity; i += 1) {
                output.push(
                  <div
                    role="button"
                    tabIndex={0}
                    className="tent"
                    key={i}
                    onClick={() => {
                      if (!item) return;
                      const taltyta = get(item, 'taltyta', '');
                      const content = (
                        <div>
                          <ul aria-label="Bokning">
                            <li aria-label="Tält">
                              {' '}
                              {title}Tältyta {taltyta ? `(${taltyta})` : ''}
                            </li>
                            <li aria-label="Organisationsnamn">
                              {organizerName}
                            </li>
                            <li aria-label="User Id">{userId}</li>
                            <li aria-label="Pris">
                              {Number(get(item, 'pris', '')).toLocaleString(
                                'sv',
                              )}{' '}
                              kr exkl. moms
                            </li>
                          </ul>
                        </div>
                      );
                      setModalOpen(true);
                      setModalContent(content);
                    }}
                  />,
                );
              }
              return output;
            })}
            {times(p2Max - tentsPerPeriod2Total, constant(null))?.map(
              (a, i) => {
                return <div className="tent empty" key={i} />;
              },
            )}
          </div>
        </li>
      </ul>
    </>
  );
}

export default RenderBookings;
