import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    const { gtag } = window;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    if (typeof gtag === 'function') {
      try {
        gtag('config', 'UA-117695765-2', {
          page_location: window.location?.href,
          page_path: window.location?.pathname,
        });
      } catch (error) {
        console.error('gtag error', error);
      }
    }
  }, [pathname]);

  return children;
}

export default ScrollToTop;
