//
/**
 * @description Create db paths for organisation, branch and user
 * @param {object} props - Props object
 * @param {string} props.orgNr - Organisation number
 * @param {string} props.branchId - Branch id
 * @param {string} props.email - User email
 * @returns {object} - Db paths
 */
const createOrgDbPaths = ({ orgNr, branchId, email }) => {
  const orgPaths = {};

  orgPaths.orgPaths = {
    dbCollection: 'organisations',
    dbDocId: orgNr,
    dbPath: `organisations/${orgNr}`,
  };

  orgPaths.branchPaths = {
    dbCollection: `organisations/${orgNr}/branches`,
    dbDocId: branchId,
    dbPath: `organisations/${orgNr}/branches/${branchId}`,
  };

  if (email) {
    orgPaths.orgUserPaths = {
      dbCollection: `organisations/${orgNr}/branches/${branchId}/users`,
      dbDocId: email,
      dbPath: `organisations/${orgNr}/branches/${branchId}/users/${email}`,
    };
  }

  return orgPaths;
};

export { createOrgDbPaths };
