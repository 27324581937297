import {
  JarvaButtonSecondary,
  JarvaButtonPrimary,
  WhiteBox,
  DataBox,
} from '../../styledComponents';
import { transformNgoText } from '../../utils';
import { IosContactOutline } from '../Icons';

const RenderCompanyListItem = ({
  orgData,
  handleAccept,
  handleReject,
  disabled,
}) => {
  const {
    orgEmail,
    fakturaEmail,
    fakturaadress,
    fakturamottagare,
    hemsida,
    orgName,
    orgNr,
    ort,
    postnummer,
    telefonnummer,
    typeOfOrg,
  } = orgData;

  return (
    <WhiteBox>
      <DataBox>
        <h3>
          <IosContactOutline /> {orgName}
        </h3>
        <div className="horizontal-container">
          <ul aria-label="Kontaktuppgifter">
            <li aria-label="Organisationens e-post">{orgEmail}</li>
            <li aria-label="Organisationens telefon">{telefonnummer}</li>
          </ul>
          <ul aria-label="Organisationsuppgifter">
            <li aria-label="Organisation">{orgName}</li>
            <li aria-label="Organisationsnummer">{orgNr}</li>
            <li aria-label="Organisationstyp">{transformNgoText(typeOfOrg)}</li>
            <li aria-label="Hemsida">{hemsida}</li>
          </ul>
          <ul aria-label="Fakturauppgifter">
            <li aria-label="Fakturamottagare / referens">{fakturamottagare}</li>
            <li aria-label="Fakturaadress">{fakturaadress}</li>
            <li aria-label="Postnummer">{postnummer}</li>
            <li aria-label="Ort">{ort}</li>
            <li aria-label="E-post för PDF faktura">{fakturaEmail}</li>
          </ul>
        </div>
        <div className="buttons-padded">
          <JarvaButtonPrimary
            onClick={() => handleAccept(orgData)}
            disabled={disabled}
          >
            Godkänn
          </JarvaButtonPrimary>
          <JarvaButtonSecondary
            onClick={() => handleReject(orgData)}
            disabled={disabled}
          >
            Avböj
          </JarvaButtonSecondary>
        </div>
      </DataBox>
    </WhiteBox>
  );
};

export default RenderCompanyListItem;
