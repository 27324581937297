import { SpinnerLoaderWrapper } from './styles';

function SpinnerLoader({ isLoading = true }) {
  if (!isLoading) return null;

  return (
    <SpinnerLoaderWrapper>
      <span className="SpinnerLoader" />
    </SpinnerLoaderWrapper>
  );
}

export default SpinnerLoader;
