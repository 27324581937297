import { memo, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { withFirebase, withFirestore } from 'react-redux-firebase';

import { productsFromPeAccount, uploadSingleFileToStorage } from '../../api';
import { TableContainer } from '../../components/AccDataTable/styles';
import RenderInputs from '../../components/RenderInputs';
import Spacer from '../../components/Spacer/Spacer';
import { ContentContainer, WhiteBox, Wrapper } from '../../styledComponents';
import { deburrString } from '../../utils';
import getSwedishFormatNumber from '../../utils/getSwedishFormatNumber';
import { getProducts, getStats } from '../../redux-config/selectors';
import { formFields } from './data';
import { BOOKING_COLLECTION_CURRENT_EVENT } from '../../configs';

function ProductsSettings(props) {
  // console.log('ProductsSettings: props', props);

  const { firestore } = props;

  const [isSubmit, setIsSubmit] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [formError, setFormError] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(false);

  // Add back local state
  const [stats, setStats] = useState(props.stats || {});
  const [products, setProducts] = useState(props.products || {});

  // Sync local state with Redux state
  useEffect(() => {
    setStats(props.stats);
    setProducts(props.products);
  }, [props.stats, props.products]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    console.info('form-submitted');

    const formData = new FormData(event.currentTarget);
    const formDataObject = {};
    const formDataFiles = [];

    // Iterate over all form elements
    formData.forEach((value, name) => {
      const inputElement = event.currentTarget?.[name];

      // Handle checkboxes separately
      if (inputElement?.type === 'checkbox') {
        formDataObject[name] = inputElement.checked; // Set value to true/false based on checkbox state
      } else if (inputElement?.type === 'file') {
        formDataFiles.push({ name, value: inputElement.files?.[0] });
      } else if (
        inputElement?.type === 'number' &&
        value !== '' &&
        !Number.isNaN(+value)
      ) {
        // Convert value to number if the input type is 'number' and the value is a valid number
        formDataObject[name] = +value;
      } else if (inputElement?.nodeName === 'SELECT') {
        // Use an array for select-one values
        formDataObject[name] = formDataObject[name]
          ? [...formDataObject[name], value]
          : [value];
      } else {
        formDataObject[name] = value;
      }
    });

    // Iterate over all checkboxes and add them to FormData if they are not present
    event.currentTarget
      .querySelectorAll('input[type="checkbox"]')
      .forEach((checkbox) => {
        const { name } = checkbox;
        if (!(name in formDataObject)) {
          formDataObject[name] = false;
        }
      });

    // we need to deburr productId
    const dbDocId = deburrString(formDataObject.productId);
    formDataObject.productId = dbDocId;

    const fileUploadPromises = map(formDataFiles, async ({ name, value }) => {
      try {
        const result = await uploadSingleFileToStorage({
          file: value,
          uploadPath: `assets/products/${dbDocId}`,
        });
        return {
          [name]: result,
        };
      } catch (error) {
        console.error(`Error uploading ${name}:`, error);
        setFormError(error);
        return {
          [name]: '', // or some other default value
        };
      }
    });

    const [firstImage] = await Promise.all(fileUploadPromises);

    const dataToSave = {
      ...formDataObject,
      ...firstImage,
      updatedAt: new Date().toISOString(),
    };

    const { maxQuantity, max, count, ...productData } = dataToSave || {};

    // update stats
    const statsToSave = {
      maxQuantity,
      max,
      count,
      updatedAt: new Date().toISOString(),
    };

    try {
      // update product in pe account
      await productsFromPeAccount.update({ data: productData });

      // Update local state immediately for UI responsiveness
      setProducts((prevProducts) => ({
        ...prevProducts,
        [dbDocId]: {
          ...prevProducts[dbDocId],
          ...productData,
        },
      }));

      // update stats in firestore
      await firestore.set(
        {
          collection: BOOKING_COLLECTION_CURRENT_EVENT.stats,
          doc: dbDocId,
          path: BOOKING_COLLECTION_CURRENT_EVENT.stats,
        },
        statsToSave,
        { merge: true },
      );

      // Update local stats immediately
      setStats((prevStats) => ({
        ...prevStats,
        [dbDocId]: {
          ...prevStats[dbDocId],
          ...statsToSave,
        },
      }));

      // // remove count from products
      // await firestore.set(
      //   {
      //     collection: FB_COLLECTIONS.products,
      //     doc: dbDocId,
      //     path: FB_COLLECTIONS.products,
      //   },
      //   {
      //     maxQuantity: firestore?.FieldValue?.delete() || undefined,
      //     max: firestore?.FieldValue?.delete() || undefined,
      //     count: firestore?.FieldValue?.delete() || undefined,
      //   },
      //   { merge: true },
      // );
    } catch (error) {
      console.error('Error saving data:', error);
      setFormError(error);
    } finally {
      setShowAddForm(false);
      setShowEditForm(false);
      setIsSubmit(false);
    }
  };

  const addProductForm = () => {
    return (
      <>
        <Form
          onSubmit={handleSubmit}
          className="custom-form"
          loading={isSubmit}
          error={Boolean(formError)}
        >
          <fieldset>
            <legend>Add New Product</legend>
            <RenderInputs formFields={formFields} />
            <div className="info">
              <p>
                Section <b>Title, Description and Image</b> can be edited in
                respective products page for tent, seminars, sponsor, and
                tillagg. <a href="/user/book">Boka</a>
              </p>
            </div>
            <div className="flex">
              <input
                type="submit"
                value="Submit"
                disabled={isSubmit}
                className="ui primary button"
              />
              <input
                type="reset"
                value="Avbryt"
                className="ui button red circular"
                disabled={isSubmit}
                onClick={() => {
                  setShowAddForm(false);
                  setFormError(false);
                }}
              />
            </div>
          </fieldset>
        </Form>
        {formError && (
          <Message
            negative
            header={formError?.code || 'Error'}
            content={formError?.message || 'Check console for more info'}
          />
        )}
      </>
    );
  };

  const editProductForm = () => {
    if (!currentProduct) {
      return 'product not selected';
    }

    return (
      <>
        <Form
          onSubmit={handleSubmit}
          className="custom-form"
          loading={isSubmit}
          error={Boolean(formError)}
        >
          <fieldset>
            <legend>Edit Product</legend>
            <RenderInputs formFields={formFields} formValues={currentProduct} />
            <div className="info">
              <p>
                <b>Title, Description and Image</b> can be edited in respective
                products page. <a href="/user/book">Boka</a>
              </p>
            </div>

            <div className="flex">
              <input
                type="submit"
                value="Submit"
                disabled={isSubmit}
                className="ui primary button"
              />
              <input
                type="reset"
                value="Avbryt"
                disabled={isSubmit}
                className="ui button red circular"
                onClick={() => {
                  setShowEditForm(false);
                  setFormError(false);
                }}
              />
            </div>
          </fieldset>
        </Form>
        {formError && (
          <Message
            negative
            header={formError?.code || 'Error'}
            content={formError?.message || 'Check console for more info'}
          />
        )}
      </>
    );
  };

  const renderProductsTable = ({
    productsList,
    editable = true,
    filterCategory = '',
  }) => {
    if (isEmpty(productsList)) {
      return null;
    }

    const productsGroupedByCategory = groupBy(productsList, 'category');

    return map(productsGroupedByCategory, (value, key) => {
      return (
        <TableContainer style={{ marginBottom: '2rem' }} key={key}>
          <h4>Products in {key}</h4>
          <table>
            <thead>
              <tr>
                {editable ? <th>Edit</th> : null}
                <th>PE:account Id</th>
                <th>Product Id</th>
                <th>Show in booking page</th>
                <th>Title</th>
                <th>Pris</th>
                <th>Max Per Order</th>
                <th>Mängd tillgängliga produkter totalt</th>
                <th>count</th>
                <th>Category</th>
                <th>Sort Order</th>
              </tr>
            </thead>
            <tbody>
              {map(value, (product) => {
                if (isEmpty(product)) {
                  return null;
                }

                const {
                  peProductId,
                  productId,
                  title,
                  pris,
                  category,
                  order,
                  showInBookingPage,
                } = product;

                const { maxQuantity, max, count } = stats?.[productId] || {};

                const combinedData = {
                  ...product,
                  maxQuantity,
                  max,
                  count,
                };

                if (filterCategory && !includes(category, filterCategory)) {
                  return null;
                }

                return (
                  <tr key={productId}>
                    {editable ? (
                      <td>
                        <i
                          role="button"
                          tabIndex={0}
                          className="pencil icon"
                          onClick={() => {
                            setShowEditForm(true);
                            setCurrentProduct(combinedData);
                          }}
                        />
                      </td>
                    ) : null}
                    <td>{peProductId}</td>
                    <td>{productId}</td>
                    <td>{showInBookingPage ? 'true' : 'false'}</td>
                    <td>{title}</td>
                    <td>{getSwedishFormatNumber(pris)}</td>
                    <td>{maxQuantity}</td>
                    <td>{max}</td>
                    <td>{count}</td>
                    <td>{category.join(',')}</td>
                    <td>{order}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableContainer>
      );
    });
  };

  return (
    <ContentContainer>
      <section>
        <WhiteBox>
          <h1 className="underline">Products Settings</h1>
          <Spacer height="3rem" />
          <Button primary onClick={() => setShowAddForm(true)}>
            Add New Tillägg
          </Button>
          {showAddForm && (
            <Modal
              open={showAddForm}
              onClose={() => setShowAddForm(false)}
              closeOnDimmerClick={false}
              dimmer="inverted"
            >
              <Modal.Content>
                <Wrapper>{addProductForm()}</Wrapper>
              </Modal.Content>
            </Modal>
          )}
          {showEditForm && (
            <Modal
              open={showEditForm}
              onClose={() => setShowEditForm(false)}
              closeOnDimmerClick={false}
              dimmer="inverted"
            >
              <Modal.Content>
                <Wrapper>{editProductForm()}</Wrapper>
              </Modal.Content>
            </Modal>
          )}
        </WhiteBox>
        <WhiteBox>
          {renderProductsTable({
            productsList: products,
          })}
        </WhiteBox>
      </section>
    </ContentContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  products: getProducts,
  stats: getStats,
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  withFirebase,
  withFirestore,
  memo,
)(ProductsSettings);
