// fix:import sort format
/**
 *
 * @param {object} bookingData object
 * @returns {number} total price
 */
export default function tentsTotalPrice(bookingData) {
  const { totalPrice, discount = 0, pris } = bookingData;

  if (totalPrice) {
    return totalPrice - discount;
  }

  return pris - discount;
}
