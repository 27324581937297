import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  IosInformationCircleOutline,
  IosCalendarOutline,
  IosSettingsOutline,
  IosContactOutline,
  IosHomeOutline,
  BookingIcon,
} from '../Icons';
import { vars } from '../../styledComponents';

const BokaRegex = /^\/user\/book(\/.*)?$/;

const Menu = styled.nav`
  padding-top: 2rem;
  @media ${vars.ipadAndUp} {
    padding-top: 3.85rem;
  }

  a {
    display: inline-flex;
    padding: 10px 10px;
    min-height: 44px;
    align-items: center;
    color: ${vars.textGreyLight};
    overflow: hidden;
    position: relative;
    font-size: var(--font-size--subheading);
    text-decoration: none;
    gap: 0.63rem;
    @media ${vars.desktopAndUp} {
      display: flex;
      padding: 13px 1.21rem;
      padding-right: 3rem;
    }

    &.active {
      background-color: var(--button-grey);
    }

    :hover {
      background-color: var(--button-grey);
      text-decoration: none;
    }
  }
`;

const DropdownContainer = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  & > a {
    @media ${vars.desktopAndUp} {
      padding-left: 40px;
    }
  }
`;

function UserSideBar(props) {
  const {
    className,
    home,
    minaBookings,
    book,
    information,
    orgAccount,
    isOrgApproved,
    isOrgRejected,
    orgUserPendingCount,
  } = props;

  const { pathname } = useLocation();

  if (isOrgApproved) {
    return (
      <Menu className={className}>
        <NavLink end to={home}>
          <IosHomeOutline />
          Hem
        </NavLink>
        <NavLink end to={orgAccount}>
          <IosContactOutline />
          Min organisation{' '}
          {!pathname.includes(orgAccount) && orgUserPendingCount > 0 && (
            <span className="notification-circle">{orgUserPendingCount}</span>
          )}
        </NavLink>
        <DropdownContainer
          show={pathname.includes(orgAccount) && !isOrgRejected}
        >
          <NavLink end to={`${orgAccount}/`}>
            Information
          </NavLink>
          <NavLink end to={`${orgAccount}/medlemmar`}>
            Medlemmar{' '}
            {orgUserPendingCount > 0 && (
              <span className="notification-circle">{orgUserPendingCount}</span>
            )}
          </NavLink>
        </DropdownContainer>
        <NavLink end to={minaBookings}>
          <IosCalendarOutline />
          Mina bokningar
        </NavLink>
        <NavLink end to={book}>
          <BookingIcon />
          Boka
        </NavLink>
        <DropdownContainer show={BokaRegex.test(pathname)}>
          <NavLink to={`${book}/tent`}>Tältyta</NavLink>
          <NavLink to={`${book}/seminar`}>
            Seminarium – utan livesändning
          </NavLink>
          <NavLink to={`${book}/seminar-live`}>
            Seminarium – med livesändning
          </NavLink>
          <NavLink to={`${book}/sponsor`}>Partnerskap</NavLink>
          <NavLink to={`${book}/torghandel`}>Torghandel</NavLink>
          <NavLink to={`${book}/foodtruck`}>Foodtruck</NavLink>
          <NavLink to={`${book}/tillagg`}>
            Övriga produkter och tjänster
          </NavLink>
        </DropdownContainer>
        <NavLink to={information}>
          <IosInformationCircleOutline />
          Information
        </NavLink>
      </Menu>
    );
  }

  return (
    <Menu>
      <NavLink end to={orgAccount}>
        <IosContactOutline />
        Gå med i en organisation
      </NavLink>
      <NavLink end to={book}>
        <BookingIcon />
        Boka
      </NavLink>
      <NavLink to={information}>
        <IosInformationCircleOutline />
        Information
      </NavLink>
    </Menu>
  );
}

function AdminSideBar({
  home,
  applicantsUrl,
  applicantCount,
  register,
  bookings,
  seminarlist,
  seminarlivelist,
  settings,
  resetDatabase,
  productsSettings,
  className,
}) {
  return (
    <Menu className={className}>
      <NavLink end to={home}>
        <IosHomeOutline />
        Hem
      </NavLink>
      <NavLink to={applicantsUrl}>
        <IosContactOutline />
        Ansökningar{' '}
        {applicantCount > 0 && (
          <span className="notification-circle">{applicantCount}</span>
        )}
      </NavLink>
      <NavLink to={register}>
        <IosContactOutline />
        Register
      </NavLink>
      <NavLink to={bookings}>
        <BookingIcon />
        Bokningar
      </NavLink>
      <NavLink to={seminarlist}>
        <BookingIcon />
        Seminarlist
      </NavLink>
      <NavLink to={seminarlivelist}>
        <BookingIcon />
        Seminarlivelist
      </NavLink>
      <NavLink to={settings}>
        <IosInformationCircleOutline />
        Inställningar
      </NavLink>
      <NavLink to={productsSettings}>
        <IosSettingsOutline />
        Products Settings
      </NavLink>
      <NavLink to={resetDatabase}>
        <IosSettingsOutline />
        Reset Products DB
      </NavLink>
    </Menu>
  );
}

export { UserSideBar, AdminSideBar };
