const handleFirebaseError = (error) => {
  console.error('Firebase error: ', error);

  const { message } = error || {};

  if (message.includes('INVALID_LOGIN_CREDENTIALS')) {
    return 'E-postadressen eller lösenordet är felaktigt.';
  }

  return message;
};

export default handleFirebaseError;
