import { useEffect, useState } from 'react';

import { extend, get, isEmpty, keys, sumBy } from 'lodash';

import { REGISTER_TABLE_SUM_ROWS } from '../../configs';
import { TableContainer } from './styles';
import { handleTableDataTransform } from '../../utils/time-utils';

const getKeys = (obj) => keys(extend.apply({}, Object.values(obj)));

const totalValueByKey = (dataObj) => {
  const totals = {};

  REGISTER_TABLE_SUM_ROWS.forEach((key) => {
    totals[key] = sumBy(dataObj, (item) => {
      const value = item[key];

      if (typeof value === 'undefined') return 0;
      if (typeof value === 'string') return 0;
      if (typeof value === 'number') return value;

      const parsedValue = parseFloat(value);
      return Number.isNaN(parsedValue) ? 0 : parsedValue;
    });
  });

  return totals;
};

function AccDataTable(props) {
  const {
    obj,
    columns,
    sort,
    columnClick,
    addClass,
    filter,
    uniqKey = 'id',
  } = props;

  const [tableState, setTableState] = useState({
    dataObj: {},
    dataKeys: [],
    dataArray: [],
    sortBy: '',
    sortReverse: false,
  });

  const { dataKeys, dataObj, dataArray, sortBy, sortReverse } = tableState;

  useEffect(() => {
    if (obj !== dataObj) {
      const dataArray = Array.isArray(obj) ? obj : Object.values(obj);
      setTableState((prev) => ({
        ...prev,
        dataObj: obj,
        dataArray,
        dataKeys: columns || getKeys(obj),
      }));
    }
  }, [columns, dataObj, obj]);

  useEffect(() => {
    if (sortBy === '' && sort && sortBy !== sort) {
      setTableState((prev) => ({
        ...prev,
        sortBy: sort,
      }));
    }
  }, [sort, sortBy]);

  const handleSortClick = (name, currentSortBy) => () => {
    setTableState((prev) => ({
      ...prev,
      sortBy: name,
      sortReverse: currentSortBy === name ? !prev.sortReverse : false,
    }));
  };

  const renderHeaderRow = () => {
    const { dataKeys, sortBy, sortReverse } = tableState;
    return dataKeys?.map((cell) => {
      const name = Array.isArray(cell) ? cell[0] : cell;
      const label = Array.isArray(cell) ? cell[1] : cell;
      return (
        <th
          key={name}
          className={`${sortBy === name ? 'sorted' : ''} ${
            sortBy === name && sortReverse ? 'reversed' : ''
          }`}
          role="presentation"
          onClick={handleSortClick(name, sortBy)}
        >
          {label}
        </th>
      );
    });
  };

  const renderRow = (rowObj) => {
    if (isEmpty(rowObj)) return null;
    return dataKeys?.map((cell) => {
      // this name in table header
      const name = Array.isArray(cell) ? cell[0] : cell;
      const func = get(columnClick, name);

      const dataString = rowObj[name]
        ? handleTableDataTransform(name, rowObj[name])
        : rowObj[name];

      const classes =
        typeof addClass === 'function' ? addClass(dataString) : '';

      return (
        <td
          key={name}
          onClick={(e) => {
            e.preventDefault();
            if (typeof func === 'function') {
              func(rowObj);
            }
          }}
          className={
            typeof func === 'function' ? `hasClick ${classes}` : classes
          }
        >
          {dataString && typeof dataString !== 'object' ? dataString : ''}
        </td>
      );
    });
  };

  const renderFooterRow = (dataObj) => {
    const totals = totalValueByKey(dataObj);

    const footerRowData = dataKeys?.map((cell) => {
      const name = Array.isArray(cell) ? cell[0] : cell;
      return (
        <th key={name} style={{ fontSize: '1rem' }}>
          <b>{totals[name] ? totals[name].toLocaleString('sv-SE') : ''}</b>
        </th>
      );
    });

    footerRowData[0] = (
      <th key="Sammanfattning" style={{ fontSize: '1rem' }}>
        <b>
          <i>Sammanfattning</i>
        </b>
      </th>
    );

    return footerRowData;
  };

  let filteredArrayData = [...dataArray];

  if (typeof filter === 'function') {
    filteredArrayData = filteredArrayData?.filter(filter);
  }

  if (sortBy !== '') {
    filteredArrayData.sort((a, b) => {
      if (sortReverse) {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
      return a[sortBy] >= b[sortBy] ? 1 : -1;
    });
  }

  return (
    <TableContainer className="acc-data-table">
      <table>
        <thead>
          <tr>{renderHeaderRow()}</tr>
        </thead>
        {filteredArrayData.length > 0 && (
          <tbody>
            {filteredArrayData?.map((rowData, index) => (
              <tr key={`${rowData[uniqKey]}-${index}`}>{renderRow(rowData)}</tr>
            ))}
          </tbody>
        )}
        <thead>
          <tr>{renderHeaderRow()}</tr>
        </thead>
        <tfoot>
          <tr>{renderFooterRow(filteredArrayData)}</tr>
        </tfoot>
      </table>
    </TableContainer>
  );
}

export default AccDataTable;
