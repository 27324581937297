export const cleanValue = (value) => {
  if (typeof value === 'string') {
    return String(value || '')
      .trim()
      .replace(/\s+/g, ' ');
  }
  return value;
};

export const cleanFormData = (form) => {
  let formData;

  if (form instanceof HTMLFormElement) {
    formData = new FormData(form);
    // Update form input values with cleaned values
    Array.from(form.elements).forEach((element) => {
      if (element.name) {
        const cleanedValue = cleanValue(element.value);
        element.value = cleanedValue;
      }
    });
  } else if (form instanceof FormData) {
    formData = form;
  } else {
    throw new Error('Input must be a FormData instance or form submit event');
  }

  const cleanedFormData = new FormData();
  formData.forEach((value, key) => {
    cleanedFormData.set(key, cleanValue(value));
  });
  return cleanedFormData;
};
