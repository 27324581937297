import { useCallback, useEffect, useMemo, useState } from 'react';

import { get, groupBy, map } from 'lodash';
import { Button, Dropdown, Modal } from 'semantic-ui-react';

import AccDataTable from '../../components/AccDataTable';
import Loader from '../../components/Loader';
import SpinnerLoader from '../../components/SpinnerLoader';
import { EVENT_YEAR, INITIAL_LOADING_TIME } from '../../configs';
import { branchIdWithOrgNr } from '../../redux-config/selectors';
import { DataBox, WhiteBox } from '../../styledComponents';
import getOrganisationFullBookingsData from '../../utils/getOrganisationFullBookingsData';
import AdminAccount from '../AdminAccount/AdminAccount';
import { BookingRegisterContentContainer } from './BookingRegisterStyles';
import {
  addUserToAllBookings,
  addUserToBooking,
  addUserToSeminar,
  exportData,
} from './helper';

// link: http://localhost:1234/admin/bookings

function BookingRegister(props) {
  const [exportOption, setExportOption] = useState('all');
  const [fileFormat, setFileFormat] = useState('csv');
  const [showDetailsView, setShowDetailsView] = useState(false);
  const [selectedUserRow, setSelectedUserRow] = useState({});
  const [tent2_5x2_5_Open, setTent2_5x2_5_Open] = useState(false);
  const [tent5x2_5_Open, setTent5x2_5_Open] = useState(false);
  const [tent5x5_Open, setTent5x5_Open] = useState(false);
  const [sponsorsOpen, setSponsorsOpen] = useState(false);
  const [sponsorsOpen2, setSponsorsOpen2] = useState(false);
  const [seminarsOpen, setSeminarsOpen] = useState(false);
  const [seminarsLiveOpen, setSeminarsLiveOpen] = useState(false);
  const [tillaggBookingsOpen, setTillaggBookingsOpen] = useState(false);
  const [productBookingsOpen, setProductBookingsOpen] = useState(false);

  const [fullData, setFullData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Handle loading state with cleanup
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), INITIAL_LOADING_TIME);
    return () => clearTimeout(timer);
  }, []);

  const {
    branchData,
    users,
    bookings,
    tillaggBookings,
    productBookings,
    sponsorBookings,
    seminarBookings,
    seminarLiveBookings,
    firestore,
    firebase,
    claims,
    orderedBookings,
    orderedTillaggBookings,
    orderedProductBookings,
    orderedSeminarBookings,
    orderedSeminarLiveBookings,
    orderedSponsorBookings,
  } = props;

  const tent2_5x2_5Data = addUserToBooking(
    bookings,
    branchData,
    (a) => get(a, 'productId') === 'tent2_5x2_5',
  );
  const tent5x2_5Data = addUserToBooking(
    bookings,
    branchData,
    (a) => get(a, 'productId') === 'tent5x2_5',
  );
  const tent5x5Data = addUserToBooking(
    bookings,
    branchData,
    (a) => get(a, 'productId') === 'tent5x5',
  );
  const sponsorData = addUserToBooking(
    sponsorBookings,
    branchData,
    (a) => get(a, 'productId') === 'sponsor',
  );
  const sponsorData2 = addUserToBooking(
    sponsorBookings,
    branchData,
    (a) => get(a, 'productId') === 'sponsor2',
  );

  // need to split with productId
  const tillaggData = addUserToBooking(
    tillaggBookings,
    branchData,
    () => true, // using all bookings in the collection
  );

  // need to split with productId
  const productDataWithUsers = addUserToBooking(
    productBookings,
    branchData,
    () => true, // using all bookings in the collection
  );

  const productBookingsGroupedById = groupBy(productDataWithUsers, 'productId');

  const seminarData = addUserToSeminar(seminarBookings, branchData);
  const seminarLiveData = addUserToSeminar(seminarLiveBookings, branchData);

  // data preview
  const handleselectedUserIDClick = useCallback((data) => {
    setShowDetailsView(true);
    setSelectedUserRow(data);
  }, []);

  const handleCancelPreview = () => {
    setShowDetailsView(false);
    setSelectedUserRow({});
  };

  const handleBookingsExport = () => {
    const allBookingsWithUsers = addUserToAllBookings(
      [
        ...orderedBookings,
        ...orderedTillaggBookings,
        ...orderedProductBookings,
        ...orderedSeminarBookings,
        ...orderedSeminarLiveBookings,
        ...orderedSponsorBookings,
      ],
      branchData,
    );

    let dataToExport;

    if (exportOption) {
      dataToExport = groupBy(allBookingsWithUsers, exportOption);
    }

    dataToExport = allBookingsWithUsers;

    exportData(dataToExport, fileFormat);
  };

  const exportOptions = [
    {
      key: 'all',
      text: 'All Bookings',
      value: 'all',
    },
    {
      key: 'orgName',
      text: 'Organisation',
      value: 'orgName',
    },
    {
      key: 'category.[0]',
      text: 'Product Category',
      value: 'category.[0]',
    },
    {
      key: 'title',
      text: 'Product Title',
      value: 'title',
    },
    {
      key: 'productId',
      text: 'Product Id',
      value: 'productId',
    },
  ];

  const fileFormatOptions = [
    {
      key: 'csv',
      text: 'CSV File',
      value: 'csv',
    },
    {
      key: 'json',
      text: 'JSON File',
      value: 'json',
    },
  ];

  const columnClick = useMemo(
    () => ({
      orgName: handleselectedUserIDClick,
    }),
    [handleselectedUserIDClick],
  );

  useEffect(() => {
    setFullData(
      getOrganisationFullBookingsData({
        users,
        branchData,
        orderedBookings,
        orderedTillaggBookings,
        orderedProductBookings,
        orderedSeminarBookings,
        orderedSeminarLiveBookings,
        orderedSponsorBookings,
      }),
    );
  }, [
    branchData,
    orderedBookings,
    orderedProductBookings,
    orderedSeminarBookings,
    orderedSeminarLiveBookings,
    orderedSponsorBookings,
    orderedTillaggBookings,
    users,
  ]);

  return (
    <>
      <BookingRegisterContentContainer>
        <section>
          <WhiteBox>
            <DataBox>
              <div style={{ display: 'block' }}>
                <h3>Export Data Feature</h3>
                <p>
                  Export Data feature allows you to download booking information
                  with various grouping options:
                </p>
                <div>
                  <Dropdown
                    value={exportOption}
                    selection
                    options={exportOptions}
                    onChange={(event, data) => {
                      setExportOption(data.value);
                    }}
                  />
                  <span style={{ margin: '0 .5rem' }} />
                  <Dropdown
                    value={fileFormat}
                    selection
                    options={fileFormatOptions}
                    onChange={(event, data) => {
                      setFileFormat(data.value);
                    }}
                  />
                  <span style={{ margin: '0 .5rem' }} />
                  <Button
                    primary
                    size="mini"
                    basic
                    content="Ladda ner data"
                    icon="file excel"
                    labelPosition="right"
                    onClick={handleBookingsExport}
                  />
                </div>
              </div>
            </DataBox>
          </WhiteBox>
        </section>
        <section>
          <WhiteBox>
            <DataBox>
              <h3>Total summa {EVENT_YEAR}</h3>
            </DataBox>
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={tent2_5x2_5_Open ? 'collapsable' : 'collapsable closed'}
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setTent2_5x2_5_Open(!tent2_5x2_5_Open);
                }}
              >
                2,5x2,5 Tält
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(tent2_5x2_5Data).length})
                </span>
              </h3>
            </DataBox>
            {!tent2_5x2_5Data ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={tent2_5x2_5Data}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['pris', 'Pris'],
                  ['quantity', 'Tält'],
                  ['tent', 'Tälttyp'],
                  ['period1count', 'Period_1'],
                  ['period2count', 'Period_2'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="branchId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={tent5x2_5_Open ? 'collapsable' : 'collapsable closed'}
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setTent5x2_5_Open(!tent5x2_5_Open);
                }}
              >
                5x2,5 Tält
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(tent5x2_5Data).length})
                </span>
              </h3>
            </DataBox>
            {!tent5x2_5Data ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={tent5x2_5Data}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['pris', 'Pris'],
                  ['quantity', 'Tält'],
                  ['tent', 'Tälttyp'],
                  ['period1count', 'Period_1'],
                  ['period2count', 'Period_2'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="branchId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={tent5x5_Open ? 'collapsable' : 'collapsable closed'}
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setTent5x5_Open(!tent5x5_Open);
                }}
              >
                5x5 Tält
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(tent5x5Data).length})
                </span>
              </h3>
            </DataBox>
            {!tent5x5Data ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={tent5x5Data}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['pris', 'Pris'],
                  ['quantity', 'Tält'],
                  ['tent', 'Tälttyp'],
                  ['period1count', 'Period_1'],
                  ['period2count', 'Period_2'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="branchId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={sponsorsOpen ? 'collapsable' : 'collapsable closed'}
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setSponsorsOpen(!sponsorsOpen);
                }}
              >
                Stödorganisation
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(sponsorData).length})
                </span>
              </h3>
            </DataBox>
            {!sponsorData ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={sponsorData}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['pris', 'Pris'],
                  ['quantity', 'Tält'],
                  ['tent', 'Tälttyp'],
                  ['period1count', 'Period_1'],
                  ['period2count', 'Period_2'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="branchId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={sponsorsOpen2 ? 'collapsable' : 'collapsable closed'}
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setSponsorsOpen2(!sponsorsOpen2);
                }}
              >
                Partner
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(sponsorData2).length})
                </span>
              </h3>
            </DataBox>
            {!sponsorData2 ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={sponsorData2}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['pris', 'Pris'],
                  ['quantity', 'Tält'],
                  ['tent', 'Tälttyp'],
                  ['period1count', 'Period_1'],
                  ['period2count', 'Period_2'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="branchId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={seminarsOpen ? 'collapsable' : 'collapsable closed'}
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setSeminarsOpen(!seminarsOpen);
                }}
              >
                Seminarium
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(seminarData).length})
                </span>
              </h3>
            </DataBox>
            {!seminarData ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={seminarData}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['dayString', 'Dag'],
                  ['timeSlot', 'Tid'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="slotId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={seminarsLiveOpen ? 'collapsable' : 'collapsable closed'}
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setSeminarsLiveOpen(!seminarsLiveOpen);
                }}
              >
                Seminarium Live
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(seminarLiveData).length})
                </span>
              </h3>
            </DataBox>
            {!seminarLiveData ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={seminarLiveData}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['dayString', 'Dag'],
                  ['timeSlot', 'Tid'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="slotId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        <section>
          <WhiteBox
            className={
              tillaggBookingsOpen ? 'collapsable' : 'collapsable closed'
            }
          >
            <DataBox>
              <h3
                onClick={(e) => {
                  e.preventDefault();
                  setTillaggBookingsOpen(!tillaggBookingsOpen);
                }}
              >
                Tillägg
                <span className="small">
                  {' '}
                  (antal bokningar {Object.keys(tillaggData).length})
                </span>
              </h3>
            </DataBox>
            {!tillaggData ? (
              <div>
                <Loader />
              </div>
            ) : (
              <AccDataTable
                obj={tillaggData}
                columns={[
                  ['orgName', 'Organisation namn'],
                  ['organizerName', 'organizer Name'],
                  ['orgNr', 'orgNr'],
                  ['typeOfOrg', 'orgtyp'],
                  ['createdAt', 'Bokat'],
                  ['fakturaDatum', 'Faktura'],
                  ['pris', 'Pris'],
                  ['quantity', 'Antal'],
                  ['sum', 'Summa'],
                  ['discount', 'Discount'],
                  ['title', 'Product Name'],
                  ['productId', 'Product Id'],
                  ['fakturamottagare', 'fakturamottagare'],
                  ['fakturaEmail', 'fakturaEmail'],
                  ['orgEmail', 'orgEmail'],
                  ['branchId', 'branchId'],
                  ['peIds', 'PE:Accounting Id'],
                ]}
                uniqKey="slotId"
                sort="createdAt"
                addClass={(item) => {
                  if (typeof item === 'string') {
                    if (item.indexOf('Skickad') >= 0) {
                      return 'sent';
                    }
                    if (item.indexOf('Skickas') >= 0) {
                      return 'to-send';
                    }
                  }
                }}
                columnClick={columnClick}
              />
            )}
          </WhiteBox>
        </section>

        {map(productBookingsGroupedById, (productData) => {
          return (
            <section>
              <WhiteBox
                className={
                  productBookingsOpen ? 'collapsable' : 'collapsable closed'
                }
              >
                <DataBox>
                  <h3
                    onClick={(e) => {
                      e.preventDefault();
                      setProductBookingsOpen(!productBookingsOpen);
                    }}
                  >
                    {productData?.[0]?.title || 'Bookings'}
                    <span className="small">
                      {' '}
                      (antal bokningar {Object.keys(productData).length})
                    </span>
                  </h3>
                </DataBox>
                {!productData ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <AccDataTable
                    obj={productData}
                    columns={[
                      ['orgName', 'Organisation namn'],
                      ['organizerName', 'organizer Name'],
                      ['orgNr', 'orgNr'],
                      ['typeOfOrg', 'orgtyp'],
                      ['createdAt', 'Bokat'],
                      ['fakturaDatum', 'Faktura'],
                      ['pris', 'Pris'],
                      ['quantity', 'Antal'],
                      ['sum', 'Summa'],
                      ['discount', 'Discount'],
                      ['title', 'Product Name'],
                      ['productId', 'Product Id'],
                      ['fakturamottagare', 'fakturamottagare'],
                      ['fakturaEmail', 'fakturaEmail'],
                      ['orgEmail', 'orgEmail'],
                      ['branchId', 'branchId'],
                      ['peIds', 'PE:Accounting Id'],
                    ]}
                    uniqKey="slotId"
                    sort="createdAt"
                    addClass={(item) => {
                      if (typeof item === 'string') {
                        if (item.indexOf('Skickad') >= 0) {
                          return 'sent';
                        }
                        if (item.indexOf('Skickas') >= 0) {
                          return 'to-send';
                        }
                      }
                    }}
                    columnClick={{
                      orgName: handleselectedUserIDClick,
                    }}
                  />
                )}
              </WhiteBox>
            </section>
          );
        })}
      </BookingRegisterContentContainer>
      {/* Modal */}
      <Modal
        open={showDetailsView}
        closeOnDimmerClick
        closeOnDocumentClick
        onClose={handleCancelPreview}
        size="fullscreen"
        dimmer="blurring"
        centered={false}
        style={{ padding: '1rem 1rem' }}
      >
        <Modal.Header>
          {selectedUserRow?.orgName} - ({selectedUserRow?.orgNr})
        </Modal.Header>
        <Modal.Content scrolling>
          <AdminAccount
            {...fullData[branchIdWithOrgNr(selectedUserRow)]}
            firestore={firestore}
            firebase={firebase}
            claims={claims}
            handleCancelPreview={handleCancelPreview}
            allowDelete={false}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            icon="arrow left"
            labelPosition="left"
            primary
            content="Tillbaka till register"
            onClick={handleCancelPreview}
          />
        </Modal.Actions>
      </Modal>
      {/* Spinner */}
      <SpinnerLoader isLoading={isLoading} />
    </>
  );
}

export default BookingRegister;
