import axios from 'axios';

import { IS_EMULATOR, IS_LIVE } from '../configs';
import { firebase } from '../redux-config/configureStore';
import { isValidEmail } from '../utils';

const AUTH_ERRORS_TO_LOGOUT = [
  'auth/id-token-revoked',
  'auth/id-token-expired',
];

// API URL Configuration
const getApiUrl = () => {
  if (IS_EMULATOR) {
    return 'http://127.0.0.1:5001/jarvaveckan-stage/europe-north1/api';
  }
  if (IS_LIVE) {
    return 'https://europe-north1-jarvaveckan-live.cloudfunctions.net/api';
  }
  return 'https://europe-north1-jarvaveckan-stage.cloudfunctions.net/api';
};

// Create an instance for open endpoints (no auth required)
const publicApiInstance = () => {
  const instance = axios.create({
    baseURL: getApiUrl(),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Response Interceptor to Handle Auth Errors
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // get custom error from api
      const customError = error?.response?.data || error?.message;
      console.error('Test customError', customError);

      return Promise.reject(customError);
    },
  );

  return instance;
};

// Utility to Get Firebase Token
const getIdToken = async () => {
  const user = firebase.auth().currentUser;
  if (!user) throw new Error('User not authenticated');
  return user.getIdToken();
};

// API Instance Creation
const securedApiInstance = () => {
  const instance = axios.create({
    baseURL: getApiUrl(),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Request Interceptor to Add Auth Token
  instance.interceptors.request.use(
    async (config) => {
      try {
        const token = await getIdToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    (error) => Promise.reject(error),
  );

  // Response Interceptor to Handle Auth Errors
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      let logoutTimeout = null;

      // get custom error from api
      const customError = error?.response?.data || error?.message;
      console.error('Test customError', customError);
      const { code, message } = customError;

      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }

      if (AUTH_ERRORS_TO_LOGOUT.includes(code)) {
        customError.message = `${message} - Du kommer att loggas ut om 2 sekunder`;

        logoutTimeout = setTimeout(() => {
          console.error('Session expired - logging out in 2 seconds');
          firebase.auth().signOut();
        }, 2000);
      }

      return Promise.reject(customError);
    },
  );

  return instance;
};

const publicApi = publicApiInstance();
const securedApi = securedApiInstance();

// API Methods publicApi
const checkUserExist = async (email) => {
  if (!isValidEmail(email)) {
    throw new Error('Invalid email');
  }

  const response = await publicApi.post('/checkUserExist', { email });
  return response?.data?.userExists ?? false;
};

// API Methods securedApi
const productsFromPeAccount = {
  update: async ({ data }) => securedApi.post('/products', data),
};

const checkOrgNrExist = async ({ data = {} }) =>
  securedApi.post('/checkOrgNrExist', data);

const organisationRequests = {
  post: async ({ action, data }) =>
    securedApi.post('/organisationRequests', { action, data }),
};

const deleteOrganisation = async ({ data = {} }) =>
  securedApi.post('/deleteOrganisation', data);

// Exports
export {
  checkOrgNrExist,
  checkUserExist,
  deleteOrganisation,
  organisationRequests,
  productsFromPeAccount,
};
