// fix:import sort format
import { firebase } from '../redux-config/configureStore';

/**
 * Saves data to the server using Firestore.
 *
 * @param {Object} options - The options for saving to the server.
 * @param {string} options.path - The path to the document in Firestore.
 * @param {string} [options.method='set'] - The method to use for saving (default: 'set').
 * @param {Object} options.data - The data to save to the server.
 * @param {boolean} [options.merge=false] - Whether to merge the data (only applicable for 'set' method).
 * @param {Object} [options.batch] - Optional batch object for batch operations.
 * @returns {Promise|void} A promise that resolves when the data is successfully saved to the server, or void if using batch.
 * @throws {Error} If Firebase is not initialized or if an invalid method is specified.
 */
const saveToServer = ({
  path,
  method = 'set',
  data = {},
  merge = false,
  batch = null,
}) => {
  const firestore = firebase.firestore();
  if (!firestore) {
    throw new Error('Firebase must be initialized before using saveToServer!');
  }

  const docRef = firestore.doc(path);

  switch (method) {
    case 'set':
    case 'update':
      if (batch) {
        return batch.set(docRef, { ...(data || {}) }, { merge });
      }
      return docRef.set({ ...(data || {}) }, { merge });
    case 'add':
      if (batch) {
        const collectionRef = firestore.collection(path);
        const newDocRef = collectionRef.doc();
        batch.set(newDocRef, { ...(data || {}) });
        return newDocRef;
      }
      return firestore.collection(path).add({ ...(data || {}) });
    default:
      throw new Error(
        `Invalid method: ${method}. Supported methods are 'set', 'update', and 'add'.`,
      );
  }
};

/**
 * Creates a new batch instance for batch operations.
 *
 * @returns {Object} A new batch instance
 * @throws {Error} If Firebase is not initialized
 */
const createBatch = () => {
  const firestore = firebase.firestore();
  if (!firestore) {
    throw new Error('Firebase must be initialized before creating a batch!');
  }
  return firestore.batch();
};

export { saveToServer, createBatch };
