import get from 'lodash/get';

function priceOfSelectedProduct(product) {
  const { productId, pris, fullPeriodDiscount = 0 } = product;

  const quantity = get(product, `quantity_${productId}`, 1);

  const period1 = get(product, `period1_${productId}`, false);
  const period2 = get(product, `period2_${productId}`, false);

  const bredvid = get(product, `bredvid_${productId}`, false);

  let noOfPeriods = 0;

  if (period1) {
    noOfPeriods += 1;
  }
  if (period2) {
    noOfPeriods += 1;
  }

  // periods are always 1 or 2
  noOfPeriods = noOfPeriods || 1;

  const isFullPeriod = noOfPeriods >= 2;

  let totalPrice = 0;
  let priceDiscount = 0;
  let productQuantity = 1;

  productQuantity = quantity * noOfPeriods;

  totalPrice = pris * productQuantity;

  if (isFullPeriod) {
    priceDiscount = fullPeriodDiscount * quantity;
    totalPrice -= priceDiscount;
  }

  return {
    productQuantity,
    quantity,
    period1,
    period2,
    bredvid,
    noOfPeriods,
    isFullPeriod,
    totalPrice,
    priceDiscount,
  };
}

export default priceOfSelectedProduct;
