import { PureComponent } from 'react';

import constant from 'lodash/constant';
import times from 'lodash/times';
import get from 'lodash/get';

import { SeminarStyles } from './SeminarStatsStyles';
import { EVENT_DAYS_ARRAY } from '../../configs';

class SeminarStats extends PureComponent {
  makeSeminarOptions = (optionDay) => {
    const { seminars, updateModal, available, timeSlots } = this.props;

    const availableTimeSlots = available;

    const seminarArray = !seminars ? [] : Object.values(seminars);
    const bookings = seminarArray?.filter((o) => get(o, 'day') === optionDay);

    const bookingsPerSlot = timeSlots?.map((slot) => {
      const slotBookings = [];
      bookings.forEach((booking) => {
        if (booking.timeSlot === slot) {
          slotBookings.push(booking);
        }
      });
      return slotBookings;
    });

    return bookingsPerSlot?.map((slotBookings, index) => {
      const indexToDayKey = EVENT_DAYS_ARRAY[optionDay];
      const slotArray = get(availableTimeSlots, `[${indexToDayKey}][${index}]`);

      return (
        <div
          key={index}
          className={
            slotBookings.length >= slotArray ? 'select full' : 'select'
          }
        >
          <h5>
            {timeSlots[index]}{' '}
            <span style={{ display: 'none' }}>({slotBookings.length})</span>
          </h5>
          <div className="selectedGrid">
            {times(slotArray, constant(null))?.map((item, i) => {
              return (
                <span
                  role="button"
                  tabIndex={0}
                  className={i < slotBookings.length ? 'booked' : ''}
                  key={i}
                  onClick={() => {
                    if (!slotBookings[i]) return;
                    const seminar = slotBookings[i];

                    const content = (
                      <div>
                        <ul aria-label="Bokning">
                          <li aria-label="Organisationsnamn">
                            {get(seminar, 'organizerName', '')}
                          </li>
                          <li aria-label="User Id">{get(seminar, 'userId')}</li>
                          <li aria-label="Pris">
                            {Number(get(seminar, 'pris', '')).toLocaleString(
                              'sv',
                            )}{' '}
                            kr exkl. moms
                          </li>
                        </ul>
                      </div>
                    );

                    updateModal({
                      modalContent: content,
                      modalOpen: true,
                    });
                  }}
                >
                  {item}
                </span>
              );
            })}
          </div>
        </div>
      );
    });
  };

  render() {
    const { days, timeSlots, available } = this.props;

    if (!days && !timeSlots && !available) return null;
    return (
      <SeminarStyles>
        {days &&
          days?.map((day, index) => (
            <li className="vert" key={day}>
              <h6>{day}</h6>
              <div className="bookables">{this.makeSeminarOptions(index)}</div>
            </li>
          ))}
      </SeminarStyles>
    );
  }
}

export default SeminarStats;
