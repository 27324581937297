/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 */

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

import ContentEditor from '../../components/ContentEditor';
import { WarnLogo } from '../../components/Logos';
import {
  ContentContainer,
  JarvaButtonPrimary,
  JarvaButtonSecondary,
  JarvaDivider,
  WhiteBox,
} from '../../styledComponents';
import {
  getTotalCurrentTentBookings,
  getTotalCurrentSeminarBookings,
  getTotalCurrentSeminarLiveBookings,
  getTotalCurrentSponsorBookings,
  getProfile,
} from '../../redux-config/selectors';
import { ButtonContainer, StyledTextWrapper } from './homePageStyles';

function HomePage(props) {
  const {
    isOrgApproved,
    profile,
    totalCurrentTentBookings,
    totalCurrentSeminarBookings,
    totalCurrentSeminarLiveBookings,
    totalCurrentSponsorBookings,
    orgUserPendingCount,
  } = props;
  const navigate = useNavigate();

  const { orgName, orgNr, pendingOrganisation } = profile;

  const renderNotApproved = () => (
    <>
      <div className="text-wrapper">
        <WarnLogo />
        <ContentEditor
          contentId="page-user-content-not-approved"
          className="p pt-0"
        >
          Vi har inte hunnit gå igenom er ansökan för att verifiera er som
          deltagare på Järvaveckan. Vi kontaktar er via mail så fort vi har
          verifierat era uppgifter. Då får ni tillgång till våra
          bokningsalternativ. Vi återkommer så snart vi kan!
        </ContentEditor>
      </div>
      <JarvaDivider />

      <br />
      <ButtonContainer>
        <JarvaButtonPrimary
          as="a"
          target="_blank"
          rel="noopener"
          href="http://jarvaveckan.se"
        >
          <ContentEditor contentId="page-user-button-tillbaka">
            Tillbaka till Järvaveckan.se
          </ContentEditor>
        </JarvaButtonPrimary>

        <JarvaButtonSecondary
          onClick={() => {
            navigate('/user/book');
          }}
        >
          <ContentEditor contentId="page-user-button-bookings">
            Se bokningsalternativ
          </ContentEditor>
        </JarvaButtonSecondary>
      </ButtonContainer>
      <br />
    </>
  );

  const renderApproved = () => (
    <>
      <div className="text-wrapper">
        <ContentEditor contentId="page-user-content-1" className="p pt-0">
          Vi vill att alla arrangörer fyller i sin information senast den 3 maj
          2024. Men mindre justeringar kan göras fram till och under
          Järvaveckan.
        </ContentEditor>
      </div>
      <JarvaDivider />
      <br />
      <ButtonContainer>
        <JarvaButtonPrimary
          onClick={() => {
            navigate('/user/book');
          }}
        >
          <ContentEditor
            inline
            isButton
            contentId="page-user-content-button-text"
          >
            Boka din plats på Järvaveckan
          </ContentEditor>
        </JarvaButtonPrimary>
      </ButtonContainer>
      <br />
    </>
  );

  const renderOrderSummary = () => (
    <StyledTextWrapper className="text-wrapper">
      <p>
        Ni har bokat:
        {totalCurrentTentBookings ? (
          <>
            <br /> {`${totalCurrentTentBookings} tältyta`}
          </>
        ) : (
          ''
        )}
        {totalCurrentSeminarBookings ? (
          <>
            <br /> {`${totalCurrentSeminarBookings} seminarietider`}
          </>
        ) : (
          ''
        )}
        {totalCurrentSeminarLiveBookings ? (
          <>
            <br /> {`${totalCurrentSeminarLiveBookings} seminarietider live`}
          </>
        ) : (
          ''
        )}
        {totalCurrentSponsorBookings ? (
          <>
            <br /> {`${totalCurrentSponsorBookings} sponsorskap`}
          </>
        ) : (
          ''
        )}
      </p>
      <Button
        onClick={() => {
          navigate('/user/bookings');
        }}
        primary
        basic
        content="Se dina bokningar"
      />
      <br />
      <br />

      <WarnLogo />
      <ContentEditor
        contentId="page-user-content-booking-reminder-info"
        className="p"
      >
        Alla bokningar har 5 dagar från bokningsdatumet på sig att ändra datum
        eller avboka din bokning. Efter det kan du fortfarande ändra
        beskrivningen av ditt tält eller seminarium.
      </ContentEditor>
    </StyledTextWrapper>
  );

  const hasBookings =
    totalCurrentTentBookings ||
    totalCurrentSeminarBookings ||
    totalCurrentSponsorBookings;

  const renderSteps = () => {
    if (isOrgApproved) {
      if (hasBookings) {
        return renderOrderSummary();
      }
      return renderApproved();
    }
    return renderNotApproved();
  };

  return (
    <ContentContainer>
      <section style={{ paddingBottom: '0px' }}>
        <WhiteBox>
          <div className="text-wrapper">
            <>
              <h2>
                {hasBookings ? (
                  <ContentEditor inline contentId="page-user-title-tillbaka">
                    Välkommen tillbaka!
                  </ContentEditor>
                ) : (
                  <ContentEditor inline contentId="page-user-title-default">
                    Välkommen till Järvaveckans bokningssida
                  </ContentEditor>
                )}
              </h2>
              <br />
              {orgUserPendingCount > 0 && (
                <div className="invitations invitations-container">
                  <h3>Väntande medlemsansökningar</h3>
                  <p>
                    Du har <b>{orgUserPendingCount}</b>{' '}
                    {orgUserPendingCount === 1
                      ? 'ansökan från en användare som önskar gå med i din organisation'
                      : 'ansökningar från medlemmar som önskar gå med i din organisation'}
                    .
                    <br />
                    <br />
                    <JarvaButtonPrimary
                      onClick={() => {
                        navigate('/user/org-account/medlemmar');
                      }}
                    >
                      Gå till medlemmar
                    </JarvaButtonPrimary>
                  </p>
                </div>
              )}
              <p>
                Du är inloggad som medlem i <b>{orgName}</b> med orgnr{' '}
                <b>{orgNr}</b>.
              </p>
              {pendingOrganisation && (
                <p>
                  Din ansökan är under granskning. Du kommer få ett mail när den
                  är godkänd.
                </p>
              )}
              <JarvaDivider />
              {renderSteps()}
            </>
          </div>
        </WhiteBox>
      </section>
    </ContentContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  totalCurrentTentBookings: getTotalCurrentTentBookings,
  totalCurrentSeminarBookings: getTotalCurrentSeminarBookings,
  totalCurrentSeminarLiveBookings: getTotalCurrentSeminarLiveBookings,
  totalCurrentSponsorBookings: getTotalCurrentSponsorBookings,
  profile: getProfile,
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(HomePage);
