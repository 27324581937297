import { useEffect, useState } from 'react';

function FileOrImagePreview({ img }) {
  const [previewSrc, setPreviewSrc] = useState('');

  useEffect(() => {
    if (img instanceof FileList && img[0]) {
      const reader = new FileReader();
      reader.onloadend = () => setPreviewSrc(reader.result);
      reader.readAsDataURL(img[0]);
    } else if (typeof img === 'string') {
      setPreviewSrc(img);
    }
  }, [img]);

  if (!previewSrc) return null;

  return (
    <img className="uploads-preview" src={previewSrc} alt="upload media" />
  );
}

export default FileOrImagePreview;
