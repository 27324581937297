import { useState } from 'react';

import { Button, Message } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import { organisationRequests } from '../../api';

const Invitations = (props) => {
  const { currentUser, authId, invites, getCurrentUserInvites, branchId } =
    props || {};

  const isExistingUser = Boolean(branchId);

  // set error and success state
  const [error, setError] = useState(null);

  if (isEmpty(invites)) return null;

  // Handle accept invitation
  const handleAcceptInvitation = async (e) => {
    e.preventDefault();
    const confirm = window.confirm(
      'Är du säker på att du vill acceptera inbjudan?',
    );

    if (confirm) {
      try {
        const response = await organisationRequests.post({
          action: 'inviteUserToOrganisationAccept',
          data: { ...currentUser, userId: authId, invites },
        });

        console.info('handleAcceptInvitation response', response);
      } catch (error) {
        console.error('handleAcceptInvitation error', error);
        setError(error?.message);
      } finally {
        getCurrentUserInvites();
      }
    }
  };

  // Handle reject invitation
  const handleRejectInvitation = async (e) => {
    e.preventDefault();
    const confirm = window.confirm(
      'Är du säker på att du vill avvisa inbjudan?',
    );

    if (confirm) {
      try {
        const response = await organisationRequests.post({
          action: 'inviteUserToOrganisationReject',
          data: {
            ...currentUser,
            userId: authId,
            invites,
            isExistingUser,
          },
        });

        console.info('handleRejectInvitation response', response);
      } catch (error) {
        console.error('handleRejectInvitation error', error);
        setError(error?.message);
      } finally {
        getCurrentUserInvites();
      }
    }
  };

  const { orgData, invitedBy } = invites || {};

  const { orgName, orgNr } = orgData || {};
  const { name, email } = invitedBy || {};

  return (
    <div className="invitations invitations-container">
      <h3>Inbjudan till {branchId ? 'en ny' : ''} organisation</h3>
      <p>
        Du har en inbjudan att gå med i {orgName} ({orgNr}) av <i>{name}</i> med
        e-postadressen <i>{email}</i>.
        {branchId ? (
          <>
            <br />
            <br />
            Om du väljer att acceptera inbjudan kommer du att bytas till den nya
            organisationen.
          </>
        ) : null}
      </p>
      <br />
      {error && <Message negative content={error} header="Något gick fel" />}
      <div className="invitation-actions">
        <Button type="button" primary onClick={handleAcceptInvitation}>
          Acceptera {branchId ? 'och byt organisation' : ''}
        </Button>

        <Button type="button" negative onClick={handleRejectInvitation}>
          Avvisa
        </Button>
      </div>
    </div>
  );
};

export default Invitations;
