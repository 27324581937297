import { useState } from 'react';

import { Button, Form, Icon, Label, Modal } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import get from 'lodash/get';
import map from 'lodash/map';

import {
  OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
  TILLGANGLIGHET_OPTIONS,
  OPTIONS_ARRAY_TO_LIMIT,
  EVENT_DAYS_ARRAY,
  SPRAK_OPTIONS,
  AMNE_OPTIONS,
  MAX_SELECT,
  BOOKING_COLLECTION_CURRENT_EVENT,
  REDUX_PATHS_CURRENT_USER,
  FB_COLLECTIONS,
} from '../../configs';
import {
  ContentContainer,
  CardContainer,
  WhiteBox,
  DataBox,
} from '../../styledComponents';
import BookingInformationNote from '../../components/BookingInformationNote';
import getSwedishFormatNumber from '../../utils/getSwedishFormatNumber';
import { CoArrangeContainer, GalleryContainer } from './SeminarStyles';
import RenderUserDetails from '../../components/RenderUserDetails';
import BookingConfirm from '../../components/BookingConfirm';
import ContentEditor from '../../components/ContentEditor';
import GalleryEditor from '../../components/GalleryEditor';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import RenderBookingSection from './RenderBookingSection';
import { SeminarLogo } from '../../components/Logos';
import generateKey from '../../utils/generateKey';
import { uploadFileToStorage } from '../../api';
import { scrollToTop } from '../../utils';

const defaultState = {
  userSelections: [],
  userBookings: {},
  step: 0,
  error: false,
  modalOpen: false,
  submitting: false,
};

// http://localhost:1234/user/book/seminar

const Seminar = (props) => {
  const [state, setState] = useState(defaultState);

  const handleOpen = () =>
    setState((prevState) => ({ ...prevState, modalOpen: true }));

  const handleClose = () =>
    setState((prevState) => ({ ...prevState, modalOpen: false }));

  // #region handleSubmit
  const handleSubmit = async () => {
    setState((prevState) => ({ ...prevState, submitting: true }));

    const {
      firestore,
      authId,
      firebase,
      currentUserOrganisation,
      currentUser,
    } = props;
    const { days } = get(props, 'products.seminars', {});

    // state
    const { userBookings } = state;

    if (isEmpty(userBookings)) {
      console.warn('seminar bookings empty');
      setState((prevState) => ({ ...prevState, submitting: false }));
      return;
    }

    const bookingKeys = Object.keys(userBookings);

    for (const key of bookingKeys) {
      const booking = userBookings[key];

      if (isEmpty(booking)) {
        console.warn('seminar bookings empty');
        setState((prevState) => ({ ...prevState, submitting: false }));
        return;
      }

      const {
        coArrangers,
        deltagare,
        logo,
        moderatorBild,
        kontaktProfilbild,
        kontaktProfilbild2,
        day,
        productId,
      } = booking;

      const removeFields = [
        'coArrangers',
        'deltagare',
        'createdAt',
        'updatedAt',
        'moderatorBild',
        'kontaktProfilbild',
        'kontaktProfilbild2',
        'logo',
      ];

      const selectedBookingData = omit(booking, removeFields);
      const cleanedOrganisation = omit(
        currentUserOrganisation,
        OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
      );

      const { organizerName, orgName } = currentUserOrganisation || {};
      // add to booking used for sending emails
      const { dbDocId, email, telefonnummer, firstName, secondName } =
        currentUser || {};

      const toEmails = [email];
      const ccEmails = cleanedOrganisation?.users?.filter(
        (userEmail) => userEmail !== email,
      );

      const emailsToSend = {
        to: toEmails,
        cc: ccEmails,
      };

      const bookedBy = {
        dbDocId,
        email,
        telefonnummer,
        firstName,
        secondName,
      };

      const bookingData = {
        ...selectedBookingData,
        ...get(props, `products.${productId}`),
        userId: authId,
        branchId: currentUserOrganisation.branchId,
        branchDbPath: currentUserOrganisation.dbPath,
        organisation: cleanedOrganisation,
        organizerName: organizerName || orgName,
        collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
        sentInvoice: false,
        dayString: days[day],
        emailsToSend,
        bookedBy,
        createdAt: new Date().toISOString(),
      };

      // add to booking data
      const coArrangersFiltered = coArrangers?.filter(Boolean);
      bookingData.coArrangers = coArrangersFiltered;

      // upload all files if exists
      const deltagareFiltered = [];
      for (const item of deltagare) {
        if (!isEmpty(item)) {
          const { logo: itemLogo, ...restItem } = item || {};

          const newDeltagare = {
            ...restItem,
          };

          let downloadUrl = '';
          if (itemLogo) {
            downloadUrl = await uploadFileToStorage({
              fileList: itemLogo,
              uid: currentUserOrganisation.branchId,
              firebase,
            });
          }

          newDeltagare.logo = downloadUrl || '';

          deltagareFiltered.push(newDeltagare);
        }
      }

      // add to booking data
      bookingData.deltagare = deltagareFiltered?.filter(Boolean);

      if (moderatorBild) {
        const downloadUrl = await uploadFileToStorage({
          fileList: moderatorBild,
          uid: currentUserOrganisation.branchId,
          firebase,
        });

        bookingData.moderatorBild = downloadUrl || '';
      }

      if (kontaktProfilbild) {
        const downloadUrl = await uploadFileToStorage({
          fileList: kontaktProfilbild,
          uid: currentUserOrganisation.branchId,
          firebase,
        });

        bookingData.kontaktProfilbild = downloadUrl || '';
      }

      if (kontaktProfilbild2) {
        const downloadUrl = await uploadFileToStorage({
          fileList: kontaktProfilbild2,
          uid: currentUserOrganisation.branchId,
          firebase,
        });

        bookingData.kontaktProfilbild2 = downloadUrl || '';
      }

      if (logo) {
        const downloadUrl = await uploadFileToStorage({
          fileList: logo,
          uid: currentUserOrganisation.branchId,
          firebase,
        });

        bookingData.logo = downloadUrl || '';
      }

      await firestore
        .add(
          {
            collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
          },
          bookingData,
        )
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            step: 2,
            submitting: false,
          }));
          console.info('saved to database');
        })
        .catch((error) => {
          console.error('seminar booking error error', error);
        })
        .finally(() => {
          firestore
            .get({
              collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
              where: [['branchId', '==', currentUserOrganisation.branchId]],
              path: REDUX_PATHS_CURRENT_USER.currentUserSeminarsBookings,
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setState((prevState) => ({ ...prevState, submitting: false }));
            });
          firestore
            .get({
              collection: BOOKING_COLLECTION_CURRENT_EVENT.seminarBookings,
              path: FB_COLLECTIONS.seminarBookings,
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setState((prevState) => ({ ...prevState, submitting: false }));
            });
        });
    }
  };
  // #endregion

  const handleInputChange = (slotId) => (event, semanticEvent) => {
    const { files } = event.target || {};

    const { name, value } = semanticEvent;

    if (OPTIONS_ARRAY_TO_LIMIT.includes(name)) {
      if (value?.length > MAX_SELECT) {
        return alert('Max 5 val');
      }
    }

    setState((prevState) => {
      const updatedUserBookings = prevState.userBookings[slotId];
      updatedUserBookings[name] = files || value;

      return {
        ...prevState,
        userBookings: {
          ...prevState.userBookings,
          [slotId]: updatedUserBookings,
        },
      };
    });
  };

  const handleCheckboxChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;
    setState((prevState) => ({ ...prevState, [name]: localValue }));
  };

  const handleCoArrangeChange =
    ({ slotId, index }) =>
    (event) => {
      const {
        target: { value, files, name },
      } = event;

      setState((prevState) => {
        const updatedUserBookings = prevState.userBookings[slotId];
        updatedUserBookings.coArrangers[index] = {
          ...updatedUserBookings.coArrangers[index],
          [name]: files || value,
        };

        return {
          ...prevState,
          userBookings: {
            ...prevState.userBookings,
            [slotId]: updatedUserBookings,
          },
        };
      });
    };

  const handleDeltagareChange =
    ({ slotId, index }) =>
    (event) => {
      const {
        target: { value, files, name },
      } = event;

      setState((prevState) => {
        const newUserBookings = prevState.userBookings[slotId];
        newUserBookings.deltagare[index] = {
          ...newUserBookings.deltagare[index],
          [name]: files || value,
        };

        return {
          ...prevState,
          userBookings: {
            ...prevState.userBookings,
            [slotId]: newUserBookings,
          },
        };
      });
    };

  // #region handleUserSelection
  const handleUserSelection = (day, slot, productId) => {
    setState((prevState) => {
      const prevUserSelections = prevState.userSelections;
      let isBooked = false;

      const result = prevUserSelections?.filter((booking) => {
        const hasBooking = booking.day === day && booking.timeSlot === slot;
        if (hasBooking) {
          isBooked = true;
        }
        return !hasBooking;
      });

      if (!isBooked) {
        result.push({
          day,
          timeSlot: slot,
          deltagare: [''],
          coArrangers: [],
          slotId: generateKey(),
          productId,
        });
      }

      return {
        ...prevState,
        userSelections: result,
      };
    });
  };
  // #endregion

  const createBookings = () => {
    //  Create object from selection array
    setState((prevState) => ({
      ...prevState,
      userBookings: reduce(
        prevState.userSelections,
        (obj, param) => {
          const ob = obj;
          ob[param.slotId] = param;
          return ob;
        },
        {},
      ),
      step: 1,
    }));
  };

  // #region makeSeminarOptions
  const makeSeminarOptions = (optionDay) => {
    const { userSelections } = state;
    const { seminars, stats } = props;

    const timeSlots = get(props, 'products.seminars.timeSlots', []);
    const availableTimeSlots = get(stats, 'seminars.availableTimeSlots', []);

    const seminarArray = !seminars ? [] : Object.values(seminars);
    const bookings = seminarArray?.filter((o) => get(o, 'day') === optionDay);

    const formatedPris = `${getSwedishFormatNumber(
      get(props, 'products.seminars.pris', 0),
    )} kr`;
    const formatedPris75 = `${getSwedishFormatNumber(
      get(props, 'products.seminars75min.pris', 0),
    )} kr`;

    const bookingsPerSlot = timeSlots?.map((slot) => {
      const slotBookings = [];
      bookings.forEach((booking) => {
        if (booking.timeSlot === slot) {
          slotBookings.push(booking);
        }
      });
      return slotBookings;
    });

    return bookingsPerSlot?.map((slotBookings, index) => {
      const userBookings = userSelections?.filter(
        ({ day, timeSlot }) =>
          day === optionDay && timeSlot === timeSlots[index],
      );

      const indexToDayKey = EVENT_DAYS_ARRAY[optionDay];
      const slotArray = get(availableTimeSlots, `[${indexToDayKey}][${index}]`);

      const is75MinSeminar = index === 0; // first seminar of the day
      const productId = is75MinSeminar ? 'seminars75min' : 'seminars';

      return (
        <button
          type="button"
          key={index}
          className={
            (slotBookings.length >= slotArray ? 'select full' : 'select') +
            (userBookings.length > 0 ? ' user-selected' : '')
          }
          onClick={() =>
            handleUserSelection(optionDay, timeSlots[index], productId)
          }
          disabled={slotBookings.length > slotArray}
        >
          {timeSlots[index]} <br />
          <small>
            <i>
              {is75MinSeminar ? '75min' : '45min'}
              <br />
              {is75MinSeminar ? formatedPris75 : formatedPris}
            </i>
          </small>
          <br />
          <span>
            ({slotBookings.length}/{slotArray})
          </span>
        </button>
      );
    });
  };
  // #endregion

  // #region bookSection
  const bookSection = (slotId, index) => {
    const { userBookings } = state;
    //
    const { productId, day, timeSlot, deltagare, coArrangers } =
      userBookings[slotId];

    const { days, pris } = get(props, `products.${productId}`, {});

    return (
      <div key={slotId}>
        <WhiteBox transparent>
          <h2>
            <ContentEditor inline contentId="page-book-seminarie-step-2-title">
              Information om ditt seminarie
            </ContentEditor>{' '}
            #{index + 1}
          </h2>
          <ContentEditor contentId="page-book-seminarie-step-2-info">
            Här kan ni fylla i information kring era aktiviteter på Järvaveckan
            2024. För att säkra er plats och genomföra bokningen behöver ni
            inledningsvis enbart fylla i Namn på seminariet.
          </ContentEditor>
          <br />
          <p>
            <b>Tid: </b>
            {days[day]}, {timeSlot}
            <br />
            <b>Pris: </b> {Number(pris).toLocaleString('sv')} kr/st exkl moms
          </p>
          <BookingInformationNote />
        </WhiteBox>
        <WhiteBox>
          <DataBox>
            {/* section */}
            <div className="list-container">
              <ul
                aria-label="Uppgifter för seminariet"
                style={{ maxWidth: '50%' }}
              >
                <li>
                  <Form.Input
                    maxLength="90"
                    onChange={handleInputChange(slotId)}
                    label="Namn på seminariet (max 90 tecken inkl. blanksteg)"
                    id="Namn"
                    name="namn"
                    required
                    autoComplete="off"
                  />
                  <Form.TextArea
                    onChange={handleInputChange(slotId)}
                    id="beskriv"
                    name="beskriv"
                    label="Beskrivning av seminariet (max 800 tecken inkl. blanksteg) "
                    maxLength="800"
                  />
                  <br />
                  <br />
                  <h5>
                    Välj de ämnen, språk och tillgänglighetsanpassningar som
                    passar in på ert seminarium. det går att välja flera
                    alternativ.
                  </h5>
                  <Form.Select
                    multiple
                    label="Ämne"
                    onChange={handleInputChange(slotId)}
                    value={get(state, `userBookings[${slotId}].amne`) || []}
                    id="amne"
                    name="amne"
                    options={AMNE_OPTIONS}
                    placeholder="välj ett eller flera"
                  />
                  <Form.Select
                    label="Tillgänglighet"
                    onChange={handleInputChange(slotId)}
                    id="tillganglighet"
                    name="tillganglighet"
                    value={
                      get(state, `userBookings[${slotId}].tillganglighet`) || []
                    }
                    multiple
                    options={TILLGANGLIGHET_OPTIONS}
                    placeholder="välj"
                  />
                  <Form.Select
                    label="Språk"
                    multiple
                    onChange={handleInputChange(slotId)}
                    id="sprak"
                    name="sprak"
                    value={get(state, `userBookings[${slotId}].sprak`) || []}
                    options={SPRAK_OPTIONS}
                    placeholder="välj"
                  />
                </li>
                <li>
                  <h5>
                    Extern länk för anmälan via exempelvis Eventbrite eller
                    Invajo.
                  </h5>
                  <Form.Input
                    onChange={handleInputChange(slotId)}
                    id="reservationSiteLink"
                    name="reservationSiteLink"
                    // value={reservationSiteLink || ''}
                    type="url"
                    placeholder="https://accomplice.se"
                    label="Fyller du i detta fält
                      så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                      den länk du fyller i."
                  />
                </li>
                <li>
                  <h5>Moderator</h5>
                  <fieldset>
                    <legend>Moderator</legend>
                    <Form.Input
                      maxLength="120"
                      onChange={handleInputChange(slotId)}
                      id="moderator"
                      name="moderator"
                      // value={moderator || ''}
                      label="Namn på moderator"
                    />
                    <Form.Input
                      maxLength="120"
                      // readOnly={readOnlySeminarBookings}
                      onChange={handleInputChange(slotId)}
                      id="moderatorJobbtitel"
                      name="moderatorJobbtitel"
                      // value={moderatorJobbtitel || ''}
                      label="Jobbtitel"
                    />
                    <Form.Input
                      maxLength="120"
                      // readOnly={readOnlySeminarBookings}
                      onChange={handleInputChange(slotId)}
                      id="moderatorOrganisation"
                      name="moderatorOrganisation"
                      // value={moderatorOrganisation || ''}
                      label="Organisation"
                    />
                    <Form.TextArea
                      maxLength="330"
                      onChange={handleInputChange(slotId)}
                      id="moderatorBio"
                      name="moderatorBio"
                      // value={moderatorBio || ''}
                      label="Kort bio (max 330 tecken inkl. blanksteg)"
                    />
                    <Form.Input
                      type="file"
                      label="Lägg upp profilbild (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                      id="moderatorBild"
                      name="moderatorBild"
                      accept="image/*,.svg"
                      onChange={handleInputChange(slotId)}
                    />
                    <Form.Input
                      maxLength="120"
                      // readOnly={readOnlySeminarBookings}
                      onChange={handleInputChange(slotId)}
                      id="moderatorFotograf"
                      name="moderatorFotograf"
                      // value={moderatorFotograf || ''}
                      label="Fotograf/bildkälla"
                    />
                  </fieldset>
                </li>
                <li>
                  <h5>KONTAKTPERSONER FÖR SEMINARIET</h5>
                  <Label className="info">
                    Kontaktinfo (email & tel) kommer synas på vår hemsida
                    offentligt!
                  </Label>
                  {/* contack 1 */}
                  <fieldset>
                    <legend>Kontaktperson: 1</legend>
                    <Form.Input
                      maxLength="120"
                      onChange={handleInputChange(slotId)}
                      id="kontaktperson"
                      name="kontaktperson"
                      // value={kontaktperson || ''}
                      label="Förnamn och efternamn"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={handleInputChange(slotId)}
                      id="kontaktJobbtitel"
                      name="kontaktJobbtitel"
                      // value={kontaktJobbtitel || ''}
                      label="Jobbtitel"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={handleInputChange(slotId)}
                      id="kontaktOrganisation"
                      name="kontaktOrganisation"
                      // value={kontaktOrganisation || ''}
                      label="Organisation"
                    />
                    {/* <Form.Input
                      type="file"
                      label="Lägg upp profilbild (valfritt) (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                      id="kontaktProfilbild"
                      name="kontaktProfilbild"
                      accept="image/*,.svg"
                      onChange={this.handleInputChange(slotId)}
                    />
                    <Form.Input
                      maxLength="120"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktFotograf"
                      name="kontaktFotograf"
                      // value={kontaktFotograf || ''}
                      label="Fotograf/bildkälla"
                    />
                    <Form.TextArea
                      maxLength="330"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktKortbio"
                      name="kontaktKortbio"
                      // value={kontaktKortbio || ''}
                      label="Kort bio (max 330 tecken inkl. blanksteg)"
                    /> */}
                    <Form.Input
                      maxLength="140"
                      onChange={handleInputChange(slotId)}
                      id="kontaktEmail"
                      name="kontaktEmail"
                      label="Email"
                      type="email"
                      // value={kontaktEmail || ''}
                      placeholder="name@mail.com"
                    />
                    <Form.Input
                      maxLength="20"
                      onChange={handleInputChange(slotId)}
                      id="kontaktTel"
                      name="kontaktTel"
                      label="Tel"
                      type="tel"
                      // value={kontaktTel || ''}
                      placeholder="+46123456789"
                    />
                  </fieldset>
                  {/* contack 2 */}
                  <fieldset>
                    <legend>Kontaktperson: 2</legend>
                    <Form.Input
                      maxLength="120"
                      onChange={handleInputChange(slotId)}
                      id="kontaktperson2"
                      name="kontaktperson2"
                      // value={kontaktperson2 || ''}
                      label="Förnamn och efternamn"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={handleInputChange(slotId)}
                      id="kontaktJobbtitel2"
                      name="kontaktJobbtitel2"
                      // value={kontaktJobbtitel2 || ''}
                      label="Jobbtitel"
                    />
                    <Form.Input
                      maxLength="120"
                      onChange={handleInputChange(slotId)}
                      id="kontaktOrganisation2"
                      name="kontaktOrganisation2"
                      // value={kontaktOrganisation2 || ''}
                      label="Organisation"
                    />
                    {/* <Form.Input
                      type="file"
                      label="Lägg upp profilbild (valfritt) (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                      id="kontaktProfilbild2"
                      name="kontaktProfilbild2"
                      accept="image/*,.svg"
                      onChange={this.handleInputChange(slotId)}
                    />
                    <Form.Input
                      maxLength="120"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktFotograf2"
                      name="kontaktFotograf2"
                      // value={kontaktFotograf2 || ''}
                      label="Fotograf/bildkälla"
                    />
                    <Form.TextArea
                      maxLength="330"

                      onChange={this.handleInputChange(slotId)}
                      id="kontaktKortbio2"
                      name="kontaktKortbio2"
                      // value={kontaktKortbio2 || ''}
                      label="Kort bio (max 330 tecken inkl. blanksteg)"
                    /> */}
                    <Form.Input
                      maxLength="140"
                      onChange={handleInputChange(slotId)}
                      id="kontaktEmail2"
                      name="kontaktEmail2"
                      label="Email"
                      type="email"
                      // value={kontaktEmail2 || ''}
                      placeholder="name@mail.com"
                    />
                    <Form.Input
                      maxLength="20"
                      onChange={handleInputChange(slotId)}
                      id="kontaktTel2"
                      name="kontaktTel2"
                      label="Tel"
                      type="tel"
                      // value={kontaktTel2 || ''}
                      placeholder="+46123456789"
                    />
                  </fieldset>
                </li>
              </ul>
              <ul aria-label="Paneldeltagare" style={{ maxWidth: '50%' }}>
                <li>
                  {deltagare?.map((field, i) => (
                    <Form.Group key={i} grouped>
                      <fieldset>
                        <legend>Paneldeltagare {`${i + 1}`}</legend>
                        <div style={{ textAlign: 'right' }}>
                          <Icon
                            name="trash alternate outline"
                            color="red"
                            size="large"
                            link
                            onClick={(e) => {
                              e.preventDefault();
                              setState((prevState) => {
                                const localStateCopy =
                                  prevState.userBookings[slotId];

                                // delete localStateCopy.deltagare[i];

                                localStateCopy.deltagare.splice(i, 1);

                                return {
                                  ...prevState,
                                  userBookings: {
                                    ...prevState.userBookings,
                                    [slotId]: localStateCopy,
                                  },
                                };
                              });
                            }}
                          />
                        </div>

                        <Form.Input
                          maxLength="120"
                          onChange={handleDeltagareChange({
                            slotId,
                            index: i,
                          })}
                          label="Namn på deltagare"
                          type="text"
                          name="deltagare"
                          autoComplete="off"
                        />
                        <Form.Input
                          type="file"
                          id="logo"
                          label="Lägg upp profilbild (* allowed: jpg, jpeg, png, svg - max size 5MB)"
                          name="logo"
                          accept="image/*,.svg"
                          onChange={handleDeltagareChange({
                            slotId,
                            index: i,
                          })}
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleDeltagareChange({
                            slotId,
                            index: i,
                          })}
                          label="Fotograf/bildkälla"
                          type="text"
                          name="fotografCaption"
                          autoComplete="off"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleDeltagareChange({
                            slotId,
                            index: i,
                          })}
                          label="Jobbtitel"
                          type="text"
                          name="jobbTitel"
                          autoComplete="off"
                        />
                        <Form.Input
                          maxLength="120"
                          onChange={handleDeltagareChange({
                            slotId,
                            index: i,
                          })}
                          label="Organisation"
                          type="text"
                          name="organisation"
                          autoComplete="off"
                        />
                        <Form.TextArea
                          maxLength="330"
                          onChange={handleDeltagareChange({
                            slotId,
                            index: i,
                          })}
                          label="Kort beskrivning om deltagaren (max 330 tecken)"
                          name="beskrivning"
                        />
                      </fieldset>
                    </Form.Group>
                  ))}
                  <Button
                    secondary
                    size="tiny"
                    content="Lägg till deltagare"
                    icon="plus user"
                    labelPosition="right"
                    onClick={(e) => {
                      e.preventDefault();
                      setState((prevState) => {
                        const newUserBookings = prevState.userBookings[slotId];
                        newUserBookings.deltagare.push('');
                        return {
                          ...prevState,
                          userBookings: {
                            ...prevState.userBookings,
                            [slotId]: newUserBookings,
                          },
                        };
                      });
                    }}
                  />
                </li>
              </ul>
            </div>

            {/* section */}
            <div className="list-container">
              <ul style={{ maxWidth: '66%' }}>
                <li>
                  <TextCopy name="medarrangor-text-copy" />
                </li>
                <li>
                  {coArrangers?.map((field, i) => (
                    <fieldset key={i}>
                      <legend>Medarrangör</legend>
                      <CoArrangeContainer>
                        <div style={{ textAlign: 'right' }}>
                          <Icon
                            name="trash alternate outline"
                            color="red"
                            size="large"
                            link
                            onClick={(e) => {
                              e.preventDefault();

                              setState((prevState) => {
                                const newUserBookings =
                                  prevState.userBookings[slotId];
                                newUserBookings.coArrangers.splice(i, 1);
                                return {
                                  ...prevState,
                                  userBookings: {
                                    ...prevState.userBookings,
                                    [slotId]: newUserBookings,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <Form.Input
                          maxLength="120"
                          onChange={handleCoArrangeChange({
                            slotId,
                            index: i,
                          })}
                          name="organisationsnamn"
                          label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
                          type="text"
                          autoComplete="off"
                        />
                        <Form.Input
                          maxLength="20"
                          minLength="11"
                          label="Organisationsnummer (i formatet 000000-0000)"
                          placeholder="000000-0000"
                          name="organisationsnummer"
                          onChange={handleCoArrangeChange({
                            slotId,
                            index: i,
                          })}
                          type="text"
                          autoComplete="off"
                          // pattern="^\d{6}-\d{4}$|^\d{3}\s?\d{3}\s?\d{3}$|^\d{8}$|^\d{7}-\d{1}$"
                        />
                        <Form.Input
                          maxLength="160"
                          onChange={handleCoArrangeChange({
                            slotId,
                            index: i,
                          })}
                          label="Hemsida (max 160 tecken inkl. blanksteg)"
                          name="hemsida"
                          type="text"
                          autoComplete="off"
                        />
                      </CoArrangeContainer>
                    </fieldset>
                  ))}
                  <Button
                    secondary
                    size="tiny"
                    icon="plus user"
                    labelPosition="right"
                    content="Lägg till medarrangör"
                    onClick={(e) => {
                      e.preventDefault();
                      setState((prevState) => {
                        const newUserBookings = prevState.userBookings[slotId];
                        newUserBookings.coArrangers.push({});
                        return {
                          ...prevState,
                          userBookings: {
                            ...prevState.userBookings,
                            [slotId]: newUserBookings,
                          },
                        };
                      });
                    }}
                  />
                </li>
              </ul>
            </div>
          </DataBox>
        </WhiteBox>
      </div>
    );
  };
  // #endregion

  // #region renderSteps
  const renderSteps = () => {
    if (!get(props, 'products.seminars', false)) return;

    const { firestore, currentUserOrganisation } = props;

    const { seminars, seminars75min } = get(props, 'products', {});
    const { days, pris } = seminars;
    const { pris: pris75 } = seminars75min;

    const {
      userSelections,
      userBookings,
      step,
      submitting,
      error,
      modalOpen,
      godkannKopvillkorOchBekraftelse,
      godkannJarvaVekanKod,
    } = state;

    switch (step) {
      case 2:
        return (
          <section>
            <BookingConfirm />
          </section>
        );
      case 1:
        return (
          <section className="pink">
            <Form
              onSubmit={handleOpen}
              loading={submitting}
              error={Boolean(error)}
            >
              {Object.keys(userBookings)?.map(bookSection)}
              <WhiteBox transparent>
                <Button
                  size="large"
                  type="button"
                  icon="arrow left"
                  basic
                  primary
                  content="Tillbaka"
                  onClick={(e) => {
                    e.preventDefault();
                    setState((prevState) => ({ ...prevState, step: 0 }));
                  }}
                />
                <Button
                  primary
                  type="submit"
                  icon="checkmark"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Slutför din bokning"
                  disabled={submitting}
                  loading={submitting}
                />
              </WhiteBox>

              {modalOpen && (
                <Modal
                  open={modalOpen}
                  onClose={handleClose}
                  size="tiny"
                  closeOnDimmerClick={false}
                  dimmer="inverted"
                >
                  <Modal.Content>
                    <WhiteBox>
                      <DataBox>
                        <div>
                          <ul aria-label="Vänligen bekräfta dina uppgifter">
                            <li>
                              <TextCopy name="confirm-your-details" />
                            </li>
                          </ul>
                        </div>
                        {map(userBookings, (o) => {
                          const {
                            namn = '',
                            slotId,
                            day,
                            timeSlot,
                            productId,
                          } = o;

                          // default seminars
                          let currentPrice = pris;
                          const currentDays = days;

                          // seminars75min
                          if (productId === 'seminars75min') {
                            currentPrice = seminars75min?.pris;
                          }

                          return (
                            <div
                              key={slotId}
                              style={{
                                padding: '1rem',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                              }}
                            >
                              <ul aria-label={`Bekräfta bokning ${namn}`}>
                                <li
                                  aria-label="Namn"
                                  style={{
                                    margin: 0,
                                    paddingTop: 0,
                                  }}
                                >
                                  <span className="form-like">{namn}</span>
                                </li>
                                <li aria-label="Tid">
                                  <span className="form-like">{`${currentDays[day]}, ${timeSlot}`}</span>
                                </li>
                                <li aria-label="Pris">
                                  <span className="form-like">
                                    {Number(currentPrice).toLocaleString('sv')}{' '}
                                    kr exkl. moms
                                  </span>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                        {currentUserOrganisation && (
                          <RenderUserDetails
                            currentUserOrganisation={currentUserOrganisation}
                            firestore={firestore}
                          />
                        )}
                        <TermsCheckBox
                          handleCheckboxChange={handleCheckboxChange}
                        />
                      </DataBox>
                    </WhiteBox>
                  </Modal.Content>
                  <Modal.Actions>
                    <div className="flex no-gap">
                      <Button
                        basic
                        onClick={handleClose}
                        primary
                        content="Avbryt"
                        icon="cancel"
                        labelPosition="right"
                      />
                      <Button
                        type="submit"
                        loading={submitting}
                        disabled={
                          !(
                            godkannKopvillkorOchBekraftelse &&
                            godkannJarvaVekanKod
                          )
                        }
                        primary
                        content="Bekräfta"
                        labelPosition="right"
                        icon="checkmark"
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose();
                          handleSubmit();
                        }}
                      />
                    </div>
                  </Modal.Actions>
                </Modal>
              )}
            </Form>
          </section>
        );
      case 0:
      default:
        return (
          <section className="pink">
            <WhiteBox>
              <CardContainer>
                <h3 className="flex align-v-center">
                  <SeminarLogo />
                  <ContentEditor
                    inline
                    contentId="page-user-book-seminar-title"
                  >
                    Boka scentid – ej för livesändning eller inspelning
                  </ContentEditor>
                </h3>
                <ContentEditor contentId="page-user-book-seminar-description">
                  {`<i>
                    Boka ett av våra scentält och nå ut med ert budskap till
                    våra och era besökare. Scenen kan användas för seminarier,
                    mindre föreläsningar, rundabordssamtal, mingel, workshops
                    m.m. Plats för ca 60 personer. Bokning per timme varav 45min
                    är programtid. Detta ingår vid bokning av scentid i mindre
                    tält.
                  </i>`}
                </ContentEditor>
                <br />
                <br />
                <div>
                  <div>
                    <ContentEditor contentId="page-user-book-seminar-details" />
                    <p>
                      Seminarium 45 min: {Number(pris).toLocaleString('sv')} kr
                      ex moms
                    </p>
                    <p>
                      Seminarium 75 min: {Number(pris75).toLocaleString('sv')}{' '}
                      kr exkl. moms
                    </p>
                  </div>
                  <GalleryContainer>
                    <GalleryEditor contentId="gallery-seminars" />
                  </GalleryContainer>
                </div>
              </CardContainer>
            </WhiteBox>
            <RenderBookingSection
              days={days}
              makeSeminarOptions={makeSeminarOptions}
            />
            <WhiteBox>
              <Button
                primary
                size="large"
                floated="right"
                labelPosition="right"
                icon="right arrow"
                content="Nästa Steg"
                disabled={userSelections.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  createBookings();
                  scrollToTop();
                }}
              />
            </WhiteBox>
          </section>
        );
    }
  };

  // #endregion
  return (
    <ContentContainer>
      {renderSteps()}
      <br />
      <br />
    </ContentContainer>
  );
};

export default Seminar;
