import { useState } from 'react';

import {
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  Message,
  Button,
  Table,
} from 'semantic-ui-react';

import { organisationRequests } from '../../api';

function RenderBranchUsers(props) {
  const { users, currentAdminUser, currentUser, updateState } = props || {};

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!users || users.length === 0) return null;

  const handleDelete = async (e, user) => {
    e.preventDefault();
    // confirm
    const confirm = window.confirm(
      'Är du säker på att du vill ta bort användaren?',
    );
    if (!confirm) return;

    setLoading(true);
    setError(null);

    const payload = {
      action: 'rejectNewBranchUser', // this can be same as reject action
      data: {
        subAction: 'deleteUserFromOrg',
        user,
        currentAdminUser,
        currentUser: { ...currentUser, userId: currentUser.id },
      },
    };

    try {
      await organisationRequests.post(payload);
    } catch (err) {
      console.error('error handleDelete', err);
      setError(err?.message);
    } finally {
      setLoading(false);
      updateState();
    }
  };

  return (
    <div>
      <h4>Godkända medlemmar</h4>
      <Table striped>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Namn</TableHeaderCell>
            <TableHeaderCell>E-post</TableHeaderCell>
            <TableHeaderCell>Rättighet</TableHeaderCell>
            <TableHeaderCell>Godkänd</TableHeaderCell>
            <TableHeaderCell>Åtgärd</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => {
            const {
              role = '',
              firstName = '',
              secondName = '',
              approved = '',
              name = '',
              email,
            } = user || {};
            return (
              <TableRow key={email}>
                <TableCell>{name || `${firstName} ${secondName}`}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>{approved ? 'Ja' : 'Nej'}</TableCell>
                <TableCell>
                  {email !== currentUser?.email && (
                    <Button
                      negative
                      type="button"
                      onClick={(e) => handleDelete(e, user)}
                      icon="trash"
                      content="Ta bort"
                      disabled={loading}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div>
        {error && <Message>{error}</Message>}
        {loading && <Message>Laddar...</Message>}
      </div>
    </div>
  );
}

export default RenderBranchUsers;
