import { useState } from 'react';

import { Button, Checkbox, Form } from 'semantic-ui-react';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { compose } from 'redux';
import get from 'lodash/get';

import { isCurrentCountMax, transformNgoText } from '../../utils';
import { CardContainer, WhiteBox } from '../../styledComponents';
import { getStats } from '../../redux-config/selectors';
import ContentEditor from '../ContentEditor';
import GalleryEditor from '../GalleryEditor';
import { TentLogo } from '../Logos';

function RenderTents(props) {
  const { isFromSponsor, handleNextButtonClick, products, stats } = props;
  const navigate = useNavigate();

  const [state, setState] = useState({});

  const handleInputChange = (e) => {
    const {
      target: { name, value, type, checked },
    } = e;

    const localValue = type === 'checkbox' ? checked : value;

    setState((prev) => {
      return {
        ...prev,
        [name]: localValue,
      };
    });
  };

  const renderTents = () => {
    if (isEmpty(products)) {
      return (
        <WhiteBox>
          <h4>Produkter inte tillgängliga.</h4>
        </WhiteBox>
      );
    }

    return products?.map((item) => {
      const { productId, pris, period1Label, period2Label } = item;

      const disableButton = (stateId) =>
        !(
          get(state, `period1_${stateId}`, '') ||
          get(state, `period2_${stateId}`, '')
        );

      const renderPriceOptions = () => {
        switch (productId) {
          case 'tent5x5': {
            return (
              <>
                <p>
                  <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                </p>
                <h4>Välj period</h4>
                <div>
                  <Form.Field
                    name={`period1_${productId}`}
                    id={`period1_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats,
                      })?.label
                    }
                    checked={get(state, `period1_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats,
                      })?.isDisabled
                    }
                  />
                  <Form.Field
                    name={`period2_${productId}`}
                    id={`period2_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats,
                      })?.label
                    }
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats,
                      })?.isDisabled
                    }
                    checked={get(state, `period2_${productId}`, false)}
                  />
                </div>
              </>
            );
          }

          case 'tent5x2_5': {
            return (
              <>
                <p>
                  <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                </p>
                {/* <i>
                  * 10 000kr rabatt vid bokning av båda perioderna (gäller till
                  och med 8 april kl 12.00)
                </i> */}
                <h4>Välj period</h4>
                <div>
                  <Form.Field
                    name={`period1_${productId}`}
                    id={`period1_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats,
                      })?.label
                    }
                    checked={get(state, `period1_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats,
                      })?.isDisabled
                    }
                  />
                  <Form.Field
                    name={`period2_${productId}`}
                    id={`period2_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats,
                      })?.label
                    }
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats,
                      })?.isDisabled
                    }
                    checked={get(state, `period2_${productId}`, false)}
                  />
                </div>
              </>
            );
          }

          case 'tent2_5x2_5': {
            return (
              <>
                <p>
                  <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                </p>
                {/* <i>
                  * 5000kr rabatt vid bokning av båda perioderna – hela veckan
                  (gäller till och med 1 jan kl 12.00)
                </i> */}
                <h4>Välj period</h4>
                <div>
                  <Form.Field
                    name={`period1_${productId}`}
                    id={`period1_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats,
                      })?.label
                    }
                    checked={get(state, `period1_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p1Count',
                        fieldLabel: period1Label,
                        stats,
                      })?.isDisabled
                    }
                  />
                  <Form.Field
                    name={`period2_${productId}`}
                    id={`period2_${productId}`}
                    control={Checkbox}
                    onChange={handleInputChange}
                    label={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats,
                      })?.label
                    }
                    checked={get(state, `period2_${productId}`, false)}
                    disabled={
                      isCurrentCountMax({
                        productId,
                        period: 'p2Count',
                        fieldLabel: period2Label,
                        stats,
                      })?.isDisabled
                    }
                  />
                </div>
              </>
            );
          }

          default:
            return false;
        }
      };

      switch (productId) {
        case 'tent2_5x2_5':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNextButtonClick({
                    ...item,
                    typeOfOrg: transformNgoText(item.typeOfOrg),
                    ...state,
                  });
                }}
              >
                <CardContainer>
                  <h3 className="flex product-title">
                    <TentLogo />
                    <ContentEditor
                      inline
                      contentId="page-book-tent-product-25-25-title"
                    >
                      {'<span class="no-break">Tältyta 2,5 x 2,5m</span>'}
                    </ContentEditor>
                  </h3>
                  <div className="media-text">
                    <div>
                      <ContentEditor contentId="page-book-tent-product-25-25-details" />
                      {renderPriceOptions()}
                    </div>
                    <div>
                      <GalleryEditor contentId="gallery-tent2_5x2_5" />
                    </div>
                  </div>
                </CardContainer>
                <Button
                  primary
                  type="submit"
                  disabled={disableButton(productId)}
                  icon="right arrow"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Nästa Steg"
                />
              </Form>
            </WhiteBox>
          );

        case 'tent5x2_5':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNextButtonClick({
                    ...item,
                    ...state,
                  });
                }}
              >
                <CardContainer>
                  <h3 className="flex product-title">
                    <TentLogo />
                    <ContentEditor
                      inline
                      contentId="page-book-tent-product-5-25-title"
                    >
                      {'<span class="no-break">Tältyta 5 x 2,5m</span>'}
                    </ContentEditor>
                  </h3>
                  <div className="media-text">
                    <div>
                      <ContentEditor contentId="page-book-tent-product-5-25-details" />
                      {renderPriceOptions()}
                      <br />
                      <br />
                    </div>
                    <div>
                      <GalleryEditor contentId="gallery-tent5x2_5" />
                    </div>
                  </div>
                </CardContainer>
                <Button
                  primary
                  type="submit"
                  icon="right arrow"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Nästa Steg"
                  disabled={disableButton(productId)}
                />
              </Form>
            </WhiteBox>
          );

        case 'tent5x5':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNextButtonClick({
                    ...item,
                    ...state,
                  });
                }}
              >
                <CardContainer>
                  <h3 className="flex product-title">
                    <TentLogo />
                    <ContentEditor
                      inline
                      contentId="page-book-tent-product-5-5-title"
                    >
                      {'<span className="no-break">Tältplats 5 x 5m</span>'}
                    </ContentEditor>
                  </h3>
                  <div className="media-text">
                    <div>
                      <ContentEditor contentId="page-book-tent-product-5-5-details" />
                      {renderPriceOptions()}
                      <br />
                      <br />
                      <h4>Mängd tält</h4>
                      <Form.Group inline>
                        <Form.Field
                          control="input"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min={1}
                          max={5}
                          onChange={handleInputChange}
                          value={get(state, `quantity_${productId}`, 1)}
                          name={`quantity_${productId}`}
                        />
                        <Form.Field
                          name={`bredvid_${productId}`}
                          id={`bredvid_${productId}`}
                          control={Checkbox}
                          onChange={handleInputChange}
                          label="Våra tält ska stå bredvid varandra"
                          checked={get(state, `bredvid_${productId}`, false)}
                          disabled={
                            get(state, `quantity_${productId}`, '') <= 1
                          }
                        />
                      </Form.Group>
                    </div>
                    <div>
                      <GalleryEditor contentId="gallery-tent5x5" />
                    </div>
                  </div>
                </CardContainer>
                <Button
                  primary
                  type="submit"
                  icon="right arrow"
                  labelPosition="right"
                  size="large"
                  floated="right"
                  content="Nästa Steg"
                  disabled={disableButton(productId)}
                />
              </Form>
            </WhiteBox>
          );

        case 'sponsor':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <CardContainer>
                <h3 className="flex product-title">
                  <TentLogo />
                  <ContentEditor
                    inline
                    contentId="page-book-tent-product-partnerskap-title"
                  >
                    Stödorganisation
                  </ContentEditor>
                </h3>
                <div className="media-text">
                  <div>
                    <ContentEditor contentId="page-book-tent-product-partnerskap-details" />
                    <p>
                      <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                    </p>
                  </div>
                  <div>
                    <GalleryEditor contentId="gallery-sponsor" />
                  </div>
                </div>
              </CardContainer>
              {isFromSponsor ? (
                <Button
                  primary
                  onClick={() =>
                    handleNextButtonClick({
                      ...item,
                      ...state,
                    })
                  }
                  floated="right"
                  labelPosition="right"
                  size="large"
                  icon="arrow right"
                  content={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats,
                    })?.label
                  }
                  disabled={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats,
                    })?.isDisabled
                  }
                />
              ) : (
                <>
                  <br />
                  <br />
                  <Button
                    primary
                    size="large"
                    floated="right"
                    icon="right arrow"
                    labelPosition="right"
                    content="Läs mer om att bli partner"
                    onClick={() => {
                      navigate('/user/book/sponsor');
                    }}
                  />
                </>
              )}
            </WhiteBox>
          );

        case 'sponsor2':
          return (
            <WhiteBox key={productId} id={`scroll_to_${productId}`}>
              <CardContainer>
                <h3 className="flex product-title">
                  <TentLogo />
                  <ContentEditor
                    inline
                    contentId="page-book-tent-product-partnerskap2-title"
                  >
                    Partner
                  </ContentEditor>
                </h3>
                <div className="media-text">
                  <div>
                    <ContentEditor contentId="page-book-tent-product-partnerskap2-details" />
                    <p>
                      <b>{Number(pris).toLocaleString('sv')}</b> kr exkl. moms
                    </p>
                  </div>
                  <div>
                    <GalleryEditor contentId="gallery-sponsor2" />
                  </div>
                </div>
              </CardContainer>
              {isFromSponsor ? (
                <Button
                  primary
                  onClick={() =>
                    handleNextButtonClick({
                      ...item,
                      ...state,
                    })
                  }
                  floated="right"
                  labelPosition="right"
                  size="large"
                  icon="arrow right"
                  content={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats,
                    })?.label
                  }
                  disabled={
                    isCurrentCountMax({
                      productId,
                      fieldLabel: 'Nästa Steg',
                      stats,
                    })?.isDisabled
                  }
                />
              ) : (
                <>
                  <br />
                  <br />
                  <Button
                    primary
                    size="large"
                    floated="right"
                    icon="right arrow"
                    labelPosition="right"
                    content="Läs mer om att bli partner"
                    onClick={() => {
                      navigate('/user/book/sponsor');
                    }}
                  />
                </>
              )}
            </WhiteBox>
          );

        default:
          return false;
      }
    });
  };

  return (
    <div>
      {renderTents()}
      <br />
      <br />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  stats: getStats,
});

export default compose(connect(mapStateToProps))(RenderTents);
