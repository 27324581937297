import { isEmpty, cloneDeep, filter, keyBy } from 'lodash';

import { totalCost } from './getTotalPrice';

const filterByBranchId = (data, branchId) => filter(data, { branchId });

/* {
    "orgNr": "085455-5330",
    "orgName": "Socialdemokrater för tro och solidaritet Stockholm",
    "branchId": "socialdemokrater_for_tro_och_solidaritet_stockholm",
} */

const getOrganisationFullBookingsData = ({
  branchData,
  users: usersIdArray,
  orderedBookings,
  orderedTillaggBookings,
  orderedProductBookings,
  orderedSeminarBookings,
  orderedSeminarLiveBookings,
  orderedSponsorBookings,
}) => {
  if (isEmpty(branchData)) return {};

  const usersWithEmailKey = keyBy(usersIdArray, 'email');

  const fullData = cloneDeep(branchData || {});

  const createDataWithBookings = (data) => {
    if (!data) return {};

    const { branchId, isOrgPending, isOrgApproved, users } = data;

    if (!users) return {};

    const branchUsersArray = users?.map((email) => ({
      ...usersWithEmailKey[email],
      role: 'admin',
      approved: true,
    }));
    const approvedUsers = branchUsersArray?.filter(
      (user) => user.isOrgApproved,
    );
    const branchUsers = keyBy(approvedUsers, 'email');
    const currentUserOrganisation = {
      ...data,
      branchUsers, // this not actual data from org
    };
    const currentUser =
      approvedUsers.length > 0
        ? { ...approvedUsers[0], isOrgPending, isOrgApproved }
        : null;

    const currentOrderedBookings = filterByBranchId(orderedBookings, branchId);
    const currentOrderedTillaggBookings = filterByBranchId(
      orderedTillaggBookings,
      branchId,
    );
    const currentOrderedProductBookings = filterByBranchId(
      orderedProductBookings,
      branchId,
    );
    const currentOrderedSeminarBookings = filterByBranchId(
      orderedSeminarBookings,
      branchId,
    );
    const currentOrderedSeminarLiveBookings = filterByBranchId(
      orderedSeminarLiveBookings,
      branchId,
    );
    const currentOrderedSponsorBookings = filterByBranchId(
      orderedSponsorBookings,
      branchId,
    );
    const totalCostAll = totalCost({
      OrderedBookings: currentOrderedBookings,
      OrderedTillaggBookings: currentOrderedTillaggBookings,
      OrderedProductBookings: currentOrderedProductBookings,
      OrderedSeminarBookings: currentOrderedSeminarBookings,
      OrderedSeminarLiveBookings: currentOrderedSeminarLiveBookings,
      OrderedSponsorBookings: currentOrderedSponsorBookings,
    });
    const tentCount = currentOrderedBookings?.reduce(
      (sum, item) => sum + parseInt(item.quantity, 10),
      0,
    );
    const tillaggCount = currentOrderedTillaggBookings?.reduce(
      (sum, item) => sum + parseInt(item.quantity, 10),
      0,
    );
    const productCount = currentOrderedProductBookings?.reduce(
      (sum, item) => sum + parseInt(item.quantity, 10),
      0,
    );

    const newUserData = {
      ...data,
      currentUserOrganisation,
      currentUser,
      totalCost: totalCostAll,
      tentBookings: currentOrderedBookings,
      tent: tentCount,
      tillagg: tillaggCount,
      seminar: currentOrderedSeminarBookings.length,
      seminarLive: currentOrderedSeminarLiveBookings.length,
      sponsor: currentOrderedSponsorBookings.length,
      productBookings: productCount,
      currentUserBookings: keyBy(currentOrderedBookings, 'id'),
      currentUserTillaggBookings: keyBy(currentOrderedTillaggBookings, 'id'),
      currentUserProductBookings: keyBy(currentOrderedProductBookings, 'id'),
      currentUserSeminars: keyBy(currentOrderedSeminarBookings, 'id'),
      currentUserSeminarsLive: keyBy(currentOrderedSeminarLiveBookings, 'id'),
      currentUserSponsor: keyBy(currentOrderedSponsorBookings, 'id'),
    };

    return newUserData;
  };

  const fullDataWithBookings = Object.keys(fullData).reduce((acc, branchId) => {
    acc[branchId] = createDataWithBookings(fullData[branchId]);
    return acc;
  }, {});

  return fullDataWithBookings;
};

export default getOrganisationFullBookingsData;
