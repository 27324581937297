import { useEffect, useState } from 'react';

import { Button, Grid, Input } from 'semantic-ui-react';
import omit from 'lodash/omit';
import map from 'lodash/map';

import { saveToServer, createBatch } from '../../utils/saveToServer';
import { WhiteBox } from '../../styledComponents';
import { StyledContentContainer } from './styles';
import { productsFromPeAccount } from '../../api';
import {
  BOOKING_COLLECTION_CURRENT_EVENT,
  PRODUCTS,
  PRODUCTS_IDS_WITH_PERIODS,
  RESET_BOOKING_COUNT,
  RESET_PERIOD_BOOKING_COUNT,
  STATS,
} from '../../configs';

const PASSWORD = 'acc';

function ResetDatabase(props) {
  const { isAdmin, stats, products } = props;

  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (password === PASSWORD) {
      setIsDisabled(false);
    } else if (!isDisabled) {
      setIsDisabled(true);
    }
  }, [isDisabled, password]);

  const handleChange = (e, { value }) => setPassword(value);

  // console.log(props?.products);

  const updateProductsPeIds = async () => {
    try {
      if (!products || Object.keys(products).length === 0) {
        throw new Error('No products found to update');
      }

      const promiseArray = map(products, (item) => {
        if (!item) {
          throw new Error('Invalid product data');
        }

        const dataToSave = {
          ...item,
        };
        const omitState = ['createdAt', 'updatedAt'];
        const selectedState = {
          ...omit(dataToSave, omitState),
          updatedAt: new Date().toISOString(),
        };

        return productsFromPeAccount
          .update({
            data: selectedState,
          })
          .catch((error) => {
            console.error(`Failed to update product: ${item.productId}`, error);
            throw error;
          });
      });

      await Promise.all(promiseArray);
      console.info('Successfully updated all product PE IDs');
    } catch (error) {
      console.error('Error updating product PE IDs:', error);
      throw error; // Re-throw to let caller handle
    }
  };

  const handleSubmit =
    ({ path, data, isFromCodeProductsArray, isStatsObject }) =>
    async () => {
      // clearing the password
      setPassword('');

      if (
        !(
          path &&
          window.confirm(`Do you want to overwrite ${path} in database?`)
        )
      ) {
        return console.info('\n', path, 'update canceled\n');
      }

      // create and update products from config file
      try {
        if (data && isFromCodeProductsArray) {
          const batch = createBatch();

          data.forEach((item) => {
            const { productId } = item;

            // Get the products from database and update the products
            const product = products?.[productId] || {};
            let dataFromServer = {};
            if (product) {
              console.info(
                `Product with ID ${productId} from firebase\n`,
                product,
              );
              const omitState = ['createdAt', 'updatedAt'];
              dataFromServer = omit(product, omitState) || {};
            }

            const dataToSave = {
              ...dataFromServer,
              ...item,
              createdAt: new Date().toISOString(),
            };

            saveToServer({
              path: `${path}/${productId}`,
              data: dataToSave,
              batch,
            });
          });

          // Commit the batch
          await batch.commit();
          await updateProductsPeIds();
          console.info(`${path} upload finish`);
        }
      } catch (error) {
        console.error(`Error uploading products: ${error.message}`);
      }

      // stats object from code
      try {
        if (data && isStatsObject) {
          const batch = createBatch();

          // Update stats from code
          map(data, (item, key) => {
            saveToServer({
              path: `${path}/${key}`,
              data: {
                ...item,
                updatedAt: new Date().toISOString(),
              },
              batch,
            });
          });

          // Update stats from firestore
          map(stats, (item, key) => {
            const newStatsData = {
              ...item,
              updatedAt: new Date().toISOString(),
              ...(PRODUCTS_IDS_WITH_PERIODS.includes(key)
                ? RESET_PERIOD_BOOKING_COUNT
                : RESET_BOOKING_COUNT),
            };

            saveToServer({
              path: `${path}/${key}`,
              data: newStatsData,
              batch,
            });
          });

          await batch.commit();
          return console.info(path, 'upload finish');
        }
      } catch (error) {
        console.error(`Error uploading stats reset: ${error.message}`);
      }
    };

  if (!isAdmin) {
    return null;
  }

  return (
    <StyledContentContainer>
      <section>
        <WhiteBox>
          <h1>Reset Products Database</h1>

          <Grid celled>
            {/* password to edit the form */}
            <Grid.Row>
              <Grid.Column>
                <Input
                  fluid
                  labelPosition="left"
                  label="Developer Password"
                  value={password}
                  name="password"
                  type="text"
                  placeholder={PASSWORD}
                  onChange={handleChange}
                />
                <p className="note info">**enter password to edit details</p>
              </Grid.Column>
            </Grid.Row>

            {/* Overrites Products */}
            <Grid.Row>
              <Grid.Column>
                <h4>
                  Products - Creates products in database
                  <span className="info" style={{ color: 'red' }}>
                    ** don&apos;t do it after bookings started
                  </span>
                </h4>

                <details>
                  <summary>Products Data in code</summary>
                  <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    <p>
                      <b>Products</b> update and created in code.
                    </p>
                    <pre>{JSON.stringify(PRODUCTS, null, 2)}</pre>
                  </div>
                </details>
                <br />

                <Button
                  icon="upload"
                  primary
                  basic
                  labelPosition="left"
                  type="button"
                  onClick={() => {
                    handleSubmit({
                      path: '/products',
                      data: PRODUCTS,
                      isFromCodeProductsArray: true,
                    })();
                  }}
                  disabled={isDisabled}
                  content="Update Products"
                />
                <p className="note warn">
                  ** This action will delete and overwrite data
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* Overrites Stats */}
            <Grid.Row>
              <Grid.Column>
                <h4>
                  Reset Stats to default zeros
                  <span className="info" style={{ color: 'red' }}>
                    ** don&apos;t do it after bookings started
                  </span>
                </h4>
                <p>
                  Reset stats in database including <b>products</b> collection
                </p>

                <details>
                  <summary>Stats Data</summary>
                  <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    <p>Stats Data to reset to</p>
                    <pre>{JSON.stringify(STATS, null, 2)}</pre>
                  </div>
                </details>
                <br />

                <Button
                  icon="upload"
                  primary
                  basic
                  labelPosition="left"
                  type="button"
                  onClick={() => {
                    handleSubmit({
                      path: BOOKING_COLLECTION_CURRENT_EVENT.stats,
                      data: STATS,
                      isStatsObject: true,
                    })();
                  }}
                  disabled={isDisabled}
                  content="Resets stats"
                />
                <span className="info" style={{ color: 'red' }}>
                  ** ** This action will delete and overwrite data
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </WhiteBox>
      </section>
    </StyledContentContainer>
  );
}

export default ResetDatabase;
