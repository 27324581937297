import { IS_LIVE } from './env-config';

// cspell:disable
const fbConfig = () => {
  if (IS_LIVE) {
    return {
      apiKey: 'AIzaSyDZg_smslN2twDEgeEGlGFUnLk6t2wJUbc',
      authDomain: 'jarvaveckan-live.firebaseapp.com',
      databaseURL: 'https://jarvaveckan-live.firebaseio.com',
      projectId: 'jarvaveckan-live',
      storageBucket: 'jarvaveckan-live.appspot.com',
      messagingSenderId: '551008308511',
    };
  }
  return {
    apiKey: 'AIzaSyCS2Rn89kHHiJsch5uzIbBLYtTpCq-M8OY',
    authDomain: 'jarvaveckan-stage.firebaseapp.com',
    databaseURL: 'https://jarvaveckan-stage.firebaseio.com',
    projectId: 'jarvaveckan-stage',
    storageBucket: 'jarvaveckan-stage.appspot.com',
    messagingSenderId: '214419158978',
    appId: '1:214419158978:web:e9b0779157b91d106e485b',
    measurementId: 'G-XHRLQPZFF0',
  };
};

export default fbConfig;
