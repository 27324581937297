import { deburr, kebabCase } from 'lodash';

/**
 * Validates and cleans European organization/VAT numbers
 * @param {string} input - The organization number to validate
 * @return {string} - Cleaned organization number or false if invalid
 */
const cleanOrgNr = (input) => {
  // Early return for invalid input
  if (!input || typeof input !== 'string') {
    return '';
  }

  // Normalize and clean the input
  const normalizedValue = deburr(input.trim());

  // Normalize dashes and remove all whitespace
  const normalizedInput = normalizedValue
    .replace(/[\u2013\u2014]/g, '-') // Replace special dashes with standard dash
    .replace(/[^a-zA-Z0-9\s-]/g, '-') // Replace invalid chars with hyphen, preserving existing hyphens
    .replace(/\s+/g, '') // Remove all whitespace
    .replace(/-+/g, '-') // Replace multiple consecutive hyphens with single hyphen
    .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
    .trim();

  // Convert to kebab case and return
  return kebabCase(normalizedInput);
};

export { cleanOrgNr };

// test
// console.log(cleanOrgNr('12345-   67890  '));
// console.log(cleanOrgNr('true'));
