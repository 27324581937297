import styled, { css } from 'styled-components';

import DataBox from './DataBox';
import { pinkGradient } from './style-utils';
import vars from './vars';

// #region PageWrapper
const PageWrapper = styled.div`
  padding: 20px;
  @media ${vars.desktopAndUp} {
    padding: 1.21rem 1.21rem;
  }
  color: white;
`;
// #endregion

const OfflineOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(154deg, white, var(--jarva-blue));
  z-index: 100;
  pointer-events: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 4rem;
  font-size: 35px;
  padding: 0 10px;
  text-align: center;
`;

// #region ContentContainer
const ContentContainer = styled.div`
  box-sizing: border-box;
  text-align: left;
  margin: 2rem 2rem 4rem 2rem;

  /* TODO: check this for mobile */
  @media ${vars.ipadAndUp} {
    margin-top: 3.85rem;
    margin-left: 4.85rem;
  }

  h1 {
    font-size: 3.6rem;
  }

  .text-wrapper {
    margin: 0;
    max-width: 1000px;
    h2 {
      font-family: ${vars.serif};
      font-size: 3.6rem;
      line-height: 3.6rem;
    }
    @media (max-width: 600px) {
      h2 {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }
`;
// #endregion

const ContentContainerXOnly = styled.div`
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 2rem;

  h1 {
    font-size: 3.6rem;
  }

  .text-wrapper {
    margin: 0;
    max-width: 1000px;
    h2 {
      font-family: ${vars.serif};
      font-size: 3.6rem;
      line-height: 3.6rem;
    }
    @media (max-width: 600px) {
      h2 {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const wordWrap = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

// #region StyledFormContainer
const StyledFormContainer = styled.div`
  color: var(--jarva-text);
  @media ${vars.desktopAndUp} {
    padding: 2rem;
  }
`;

// #endregion

const LandingPageWrapper = styled(PageWrapper)`
  height: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0.4;
    z-index: 1;
  }
  h3,
  h2 {
    position: relative;
    z-index: 2;
    color: ${vars.jarvaTextDark};
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: var(--font-size--label);
    span {
      font-weight: 450;
      margin-left: 0.6rem;
      font-size: var(--font-size--label);
    }
  }
  h3 {
    font-weight: 450;
    opacity: 0.6;
  }
  & h2 {
    margin: 0;
    padding: 0;
  }
`;

// #region JarvaButtonBasic
const JarvaButtonBasic = css`
  color: #fff;
  background-color: var(--jarva-blue);
  text-shadow: none;
  background-image: none;
  border: 0;
  border-radius: 9999px;
  font-weight: 450;
  letter-spacing: 1px;
  padding: 0 1.21rem;
  text-decoration: none;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size--subheading);
  padding: 0.6em 0.85em;
  transition: all ${vars.transition};
  cursor: pointer;
  &:hover {
    color: var(--jarva-blue);
    background-color: #fff;
    text-decoration: none !important;
    outline: 1px solid currentColor;
  }
  :disabled,
  :disabled:hover,
  :disabled:active {
    border-color: transparent;
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
`;
// #endregion

// #region JarvaButtonPrimary
const JarvaButtonPrimary = styled.button`
  ${JarvaButtonBasic};
`;
// #endregion

// #region JarvaButtonSecondary
const JarvaButtonSecondary = styled.button`
  ${JarvaButtonBasic};
  background-color: ${({ basic, danger }) =>
    basic ? 'transparent' : danger ? 'red' : vars.jarvaTextDark};
  color: ${(props) => (props.basic ? vars.jarvaTextDark : '#fff')};
  border-color: ${(props) =>
    props.basic ? vars.jarvaTextDark : 'transparent'};
  &:hover {
    background-color: ${(props) =>
      props.basic ? vars.jarvaTextDark : 'rgba(0,0,0,.1)'};
    border-color: ${(props) =>
      props.basic ? 'transparent' : vars.jarvaTextDark};
    color: ${(props) => (props.basic ? 'rgba(0,0,0,.1)' : vars.jarvaTextDark)};
  }
`;
// #endregion

// #region DataList
const DataList = styled.ul`
  background-color: #fff;
  color: ${vars.jarvaTextDark};
  border-radius: 1px;
  list-style-type: none;
  margin: 0;
  padding: 17px;
  @media ${vars.desktopAndUp} {
    padding: 40px;
  }
  li {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    &:last-child {
      border-bottom: 0px solid rgba(0, 0, 0, 0.08);
    }
    &.header-row {
      font-size: 1.3em;
      font-family: ${vars.sanSerif};
      font-weight: bold;
      color: ${vars.jarvaBlue};
      padding-bottom: 30px;
      margin-bottom: 20px;
      border-bottom: 0px solid ${vars.jarvaTextDark};
      position: relative;
      &:after {
        content: ' ';
        width: 100%;
        height: 2px;
        background-color: pink;
        position: absolute;
        bottom: 0px;
        left: 0px;
        ${pinkGradient('45deg', vars.jarvaBlue)}
      }
    }
  }
`;
// #endregion

// #region LoaderDots
const LoaderDots = styled.span`
  .dot {
    line-height: 50%;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    font-size: 3.5rem;
    opacity: 0;
    transform: translateY(-50%);
    animation: showHideDot 2.5s ease-in-out infinite;
    font-family: 'Times New Roman';
    &.one {
      animation-delay: 0.2s;
      ::before {
        content: '.';
      }
    }
    &.two {
      animation-delay: 0.4s;
      ::before {
        content: '.';
      }
    }
    &.three {
      animation-delay: 0.6s;
      ::before {
        content: '.';
      }
    }
  }
  @keyframes showHideDot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
// #endregion

// #region WhiteBox
const WhiteBox = styled.div`
  position: relative;
  margin-bottom: 2.9rem;

  h3,
  h5 {
    font-size: 2rem;
    padding: 7px 0px;
    margin: 0;
    margin-bottom: 20px;
    position: relative;
    svg {
      transform: scale(1.4) translateY(-2px);
      margin-right: 12px;
      margin-left: 2px;
    }
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background-color: var(--border-color);
    }
  }

  h2 {
    color: ${vars.jarvaTextDark};
    font-size: 2.8rem;
    padding: 0;
  }

  h4 {
    font-size: 1.7rem;
  }

  h5 {
    font-size: var(--font-size--label) !important;
    font-style: normal;
    font-weight: 450;
    letter-spacing: 0.06em !important;
    line-height: 160% !important;
    color: ${vars.jarvaTextDark};
    text-transform: uppercase;
    margin-top: 1rem;
    border: 0;
  }

  &.collapsable {
    --accordion-max-height: 9000px;

    h3 {
      cursor: pointer;
      position: relative;
      &:before {
        content: ' ';
        border-top: 2px solid ${vars.jarvaTextDark};
        border-right: 2px solid ${vars.jarvaTextDark};
        transform: rotate(-45deg) scale(1.5);
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -5px;
        height: 7px;
        width: 7px;
        transition: all 0.2s ease;
        opacity: 1;
        @media ${vars.desktopAndUp} {
          right: 14px;
        }
      }
    }
    .grid {
      max-height: var(--accordion-max-height, auto);
      transition: max-height 0.4s ease !important;
    }
    .acc-data-table {
      max-height: var(--accordion-max-height, auto);
      transition: max-height 0.7s ease !important;
    }
  }

  &.closed {
    .grid,
    .acc-data-table {
      overflow: hidden;
      max-height: 0px;
      opacity: 0px;
      transition: all 0.3s ease;
    }
    h3 {
      &:before {
        margin-top: -6px;
        transform: rotate(135deg) scale(1.5);
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  .button.floated {
    margin-left: auto;
  }
`;
// #endregion

// #region JarvaDivider
const JarvaDivider = styled.span`
  width: 100%;
  height: 2px;
  margin: 0;
  display: block;
  padding: 0;
  background-color: var(--border-color);
  display: none;
`;
const CardContainer = styled.div`
  background-color: white;
  .product-title {
    font-size: 1.5rem;
    .no-break {
      font-size: 1.5rem !important;
    }
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    > :first-child {
      flex: 1;
      flex-basis: 40%;
      h4 {
        font-size: var(--font-size--label) !important;
        font-style: normal;
        font-weight: 450;
        letter-spacing: 0.06em !important;
        line-height: 160% !important;
        opacity: 0.6;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      ul {
        display: block;
        list-style-type: disc;
        margin-top: 1em;
        margin-bottom: 1 em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 15px;
        max-width: 600px;
        li {
          line-height: 1.4em;
        }
        &:before {
          content: attr(aria-label);
          padding-bottom: 1px;
          border-bottom: 1px solid #08152c;
        }
        > :first-child {
          margin-top: 10px;
        }
      }

      .select-containor {
        display: inline-block;
        border: 2px solid transparent;
        width: 100%;
        max-width: 270px;
        padding: 10px 0px;
        input[type='checkbox'] {
          margin-left: 20px;
          cursor: pointer;
        }
        label {
          padding-left: 10px;
        }
      }
      > button {
        width: 100%;
        max-width: 270px;
      }
    }
  }
`;
// #endregion

const PasswordError = styled.div`
  display: ${(props) => (props.isPasswordError ? 'block' : 'none')};
  margin-bottom: 20px;
  p {
    color: red;
  }
`;

const UnderlineHeader = styled.h4`
  span {
    font-size: 18px;
    color: black;
    padding-bottom: 1px;
    border-bottom: 1px solid #08152c;
  }
`;

const P = styled.p`
  max-width: 625px;
  margin: 0;
`;

export {
  pinkGradient,
  PageWrapper,
  StyledFormContainer,
  LandingPageWrapper,
  Wrapper,
  vars,
  ContentContainer,
  DataList,
  LoaderDots,
  JarvaButtonBasic,
  JarvaButtonPrimary,
  JarvaButtonSecondary,
  CardContainer,
  DataBox,
  WhiteBox,
  JarvaDivider,
  wordWrap,
  PasswordError,
  UnderlineHeader,
  P,
  OfflineOverlay,
  ContentContainerXOnly,
};
