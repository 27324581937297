import 'tinymce/icons/default';
import 'tinymce/models/dom/model';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import 'tinymce/themes/silver';

import { contentUiCss, contentCss } from './tinymce-styles';
import { editorImagesUpload } from '../../api';

import 'tinymce/plugins/importcss';
import 'tinymce/skins/ui/oxide/skin.min.css';

// DOM model
// Theme
// Toolbar icons
// Editor styles
// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load

// Content styles, including inline UI like fake cursors

const imageCss = `
  img {
    max-inline-size: 100%;
    block-size: auto;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

const inlineSettings = {
  menubar: false,
  toolbar: 'undo redo',
  inline: true,
  valid_elements: 'strong/b,em/i,u,br',
};

const defaultInitSettings = {
  skin: false,
  content_css: false,
  content_style: [contentCss, contentUiCss, imageCss].join('\n'),
  file_picker_types: 'image',
  image_uploadtab: true,
  images_upload_handler: editorImagesUpload,
  plugins: [
    'advlist',
    'autolink',
    'link',
    'image',
    'lists',
    'charmap',
    'preview',
    'anchor',
    'pagebreak',
    'searchreplace',
    'wordcount',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'autoresize',
  ],
  toolbar:
    'fullscreen image | undo redo | styles | bold italic forecolor backcolor| alignleft aligncenter alignright alignjustify | ' +
    'bullist numlist outdent indent',
  // Define the menu structure
  menu: {
    file: {
      title: 'File',
      items:
        'newdocument restoredraft | preview | export print | deleteallconversations',
    },
    edit: {
      title: 'Edit',
      items: 'undo redo | cut copy paste pastetext | selectall | searchreplace',
    },
    view: {
      title: 'View',
      items:
        'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments',
    },
    insert: {
      title: 'Insert',
      items:
        'image link media addcomment pageembed template codesample inserttable | charmap hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime',
    },
    format: {
      title: 'Format',
      items:
        'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontsize align lineheight | forecolor backcolor | language | removeformat',
    },
    tools: {
      title: 'Tools',
      items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount',
    },
    table: {
      title: 'Table',
      items:
        'inserttable | cell row column | advtablesort | tableprops deletetable',
    },
    help: { title: 'Help', items: 'help' },
  },
  // Specify which menus to display
  //menubar: 'file edit view insert tools table help'
  menubar: 'file edit insert view format table tools help',
  setup(editor) {
    editor.on('keydown', (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        // 27 is the keyCode for the Escape key
        if (editor.plugins.fullscreen.isFullscreen()) {
          // Check if the editor is in fullscreen mode
          editor.execCommand('mceFullScreen'); // Toggle the fullscreen mode
        }
      }
    });

    editor.on('focus', () => {
      const img = editor.dom.select('img');
      // editor.dom.setAttrib(img, 'width', 'auto');
      editor.dom.setAttrib(img, 'height', '186');
    });
  },
};

export { inlineSettings, defaultInitSettings };
