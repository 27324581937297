import { LoaderDots } from '../../styledComponents';

function Loader({ text }) {
  return (
    <LoaderDots>
      {text && <span>{text || 'Laddar...'}</span>}
      <span className="dot one" />
      <span className="dot two" />
      <span className="dot three" />
    </LoaderDots>
  );
}

export default Loader;
