import { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import 'tinymce/tinymce';
import { Editor } from '@tinymce/tinymce-react';

import { getClaims, getContent } from '../../redux-config/selectors';
import { saveToServer } from '../../utils/saveToServer';
import { ContentEditorContainer } from './styles';
import { inlineSettings, defaultInitSettings } from './tinymce-imports';

function ContentEditor(props) {
  const {
    contentId,
    children,
    content,
    className,
    claims,
    placeHolder = 'Lägg till innehåll här...',
    inline = false,
    isButton = false,
  } = props;

  const editorRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setEditMode] = useState(false);
  const [editorContent, setEditorContent] = useState(() => {
    return get(content, `${contentId}.content`, children);
  });

  useEffect(() => {
    if (content?.[contentId]) {
      setEditorContent(get(content, `${contentId}.content`, children));
    } else {
      setEditorContent(children);
    }
  }, [contentId, content, children]);

  const saveContentToFirebase = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSaving(true);

    if (!editorRef.current) {
      return;
    }

    const editedContent = editorRef.current?.getContent();

    const { firestore } = props;

    try {
      await saveToServer({
        path: `content/${contentId}`,
        data: {
          content: editedContent,
          updatedAt: new Date().toISOString(),
        },
        firestore,
      });
      console.info('Data saved successfully.', `content/${contentId}`);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setIsSaving(false);
      // Exit edit mode after saving
      setEditMode(false);
      // set time
      setEditorContent(editedContent);
    }
  };

  const mergedInitSettings = inline
    ? {
        ...defaultInitSettings,
        ...inlineSettings,
      }
    : defaultInitSettings;

  const computedClass = classNames(
    className,
    {
      isButton,
    },
    { isAdmin: claims?.admin },
    { inline },
  );

  return (
    <ContentEditorContainer className={computedClass}>
      {claims?.admin && isEdit ? (
        <div
          className="editable-content"
          tabIndex={0}
          role="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Editor
            initialValue={editorContent}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            init={mergedInitSettings}
          />

          <div className="button-container">
            <span
              role="button"
              tabIndex={0}
              className={classNames(
                'edit-button',
                'editable-content--save-button',
                { disabled: isSaving },
              )}
              onClick={saveContentToFirebase}
            >
              {isSaving ? 'Sparande...' : 'Spara'}
              <i className="checkmark icon" />
            </span>
            <span
              role="button"
              tabIndex={0}
              className={classNames(
                'edit-button',
                'editable-content--cancel-button',
                { disabled: isSaving },
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditMode(false);
              }}
            >
              Avbryt
              <i className="cancel icon" />
            </span>
          </div>
        </div>
      ) : (
        <div className="editable-content">
          <div
            dangerouslySetInnerHTML={{
              __html:
                editorContent ||
                (claims?.admin &&
                  `<span class="placeholder-text">${placeHolder}</span>`),
            }}
          />
          {claims?.admin && (
            <span
              role="button"
              tabIndex={0}
              className="edit-button icon-only editable-content--edit-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditMode(true);
              }}
            >
              <i className="pencil icon" />
            </span>
          )}
        </div>
      )}
    </ContentEditorContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  claims: getClaims,
  content: getContent,
});

const withConnect = connect(mapStateToProps);

export default compose(withFirestore, withConnect)(ContentEditor);
